import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminTeacherrAttendanceMenu, userTeacherAttendanceMenu } from './menus'
import { adminRoutes, userRoutes } from './routes'
import { teacherProfileRecentSubRoutes } from './slots'

/**
 * 教师考勤
 */
export const initTeacherAttendance: InitPluginFeature = {
  name: PluginNameEnum.TeacherAttendance,
  init: () => ({
    routes: [...adminRoutes, ...userRoutes],
    menus: [adminTeacherrAttendanceMenu, userTeacherAttendanceMenu],
    slots: {
      teacherProfileRecentSubRoutes,
    },
  }),
}
