/**
 * @file 教师档案路由
 */
import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

const adminTeacherArchivesSubRoutes: RouteConfig[] = [
  {
    path: 'edit-form',
    getTitle: () => $t('填报管理'),
    component: lazy(() =>
      import('@/plugins/features/teacher-profile/pages/admin/EditForm').then(
        m => ({ default: m.EditForms }),
      ),
    ),
  },
  {
    path: 'setting',
    getTitle: () => $t('设置'),
    component: lazy(() =>
      import('@/plugins/features/teacher-profile/pages/admin/Settings').then(
        m => ({ default: m.TeacherArchivesSetting }),
      ),
    ),
  },
]

const adminDetailsSubRoutes: RouteConfig[] = [
  {
    path: 'show-details',
    getTitle: () => $t('填报详情'),
    component: lazy(() =>
      import(
        '@/plugins/features/teacher-profile/pages/admin/FormDetails/ShowDetails'
      ).then(m => ({ default: m.ShowDetails })),
    ),
  },
  {
    path: 'static',
    getTitle: () => $t('结果统计'),
    component: lazy(() =>
      import(
        '@/plugins/features/teacher-profile/pages/admin/FormDetails/ResultStatic'
      ).then(m => ({
        default: m.StatisticsByQuestion,
      })),
    ),
  },
  {
    path: 'settings',
    getTitle: () => $t('填报设置'),
    component: lazy(() =>
      import(
        '@/plugins/features/teacher-profile/pages/admin/FormDetails/FormSettings'
      ).then(m => ({ default: m.FormSettings })),
    ),
  },
]

export const adminTeacherArchives: RouteConfig[] = [
  {
    path: 'admin/plugin/teacher-profile',
    getTitle: () => $t('教师档案管理'),
    module: [PluginCategoryEnum.教师发展, SchoolPluginsEnum.TeacherProfile],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: adminTeacherArchivesSubRoutes,
  },
  {
    path: 'admin/plugin/teacher-profile/details/:formId/:teacherProfileId',
    getTitle: () => $t('填报详情'),
    name: 'AdminTeacherProfileFormDetails',
    component: lazy(() =>
      import('@/plugins/features/teacher-profile/pages/admin/FormDetails').then(
        m => ({
          default: m.FormDetails,
        }),
      ),
    ),
    subRoutes: adminDetailsSubRoutes,
  },
  {
    path: 'admin/plugin/teacher-profile/show-details/:id',
    getTitle: () => $t('查看填报详情'),
    name: 'AdminTeacherProfileShowFormDetailsSingle',
    component: lazy(() =>
      import(
        '@/plugins/features/teacher-profile/pages/admin/FormDetails/ShowDetails/SingleDetails'
      ).then(m => ({
        default: m.ShowSingleDetails,
      })),
    ),
    subRoutes: adminDetailsSubRoutes,
  },
]

export const modalRoutes = [
  {
    name: 'EditTeacherProfileModal',
    getTitle: () => $t('新增填报'),
    component: lazy(() =>
      import(
        '@/plugins/features/teacher-profile/pages/admin/EditForm/EditNewForm'
      ).then(m => ({
        default: m.EditTeacherProfileModal,
      })),
    ),
  },
  {
    name: 'TeacherProfileViewFormDetails',
    getTitle: () => $t('填报详情'),
    component: lazy(() =>
      import(
        '@/plugins/features/teacher-profile/pages/admin/FormDetails/ShowDetails/SingleDetails'
      ).then(m => ({
        default: m.ShowSingleDetails,
      })),
    ),
  },
  {
    name: 'SubmitTeacherProfileModal',
    getTitle: () => $t('填报详情'),
    component: lazy(() =>
      import(
        '@/plugins/features/teacher-profile/pages/user/SubmitTeacherProfileModal'
      ).then(m => ({
        default: m.SubmitTeacherProfileModal,
      })),
    ),
  },
]
