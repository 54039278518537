/**
 * @file 行政班详情-成绩界面 subRoutes slots
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const adminClassGradeSubRoutesTail: RouteConfig[] = [
  {
    path: 'grades-analysis',
    name: 'AdminGradesAnalysis',
    getTitle: () => $t('成绩分析'),
    component: lazy(() =>
      import('@/plugins/features/gz-exam-res-analysis/pages/admin').then(m => ({
        default: m.AdminClassScoreAnalysis,
      })),
    ),
  },
]
