/**
 * @file 动态菜单
 */

import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { isMenuOpenedAtom } from 'packages/feature-utils/menus'
import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
} from 'packages/feature-utils/plugins'
import { getCurrentReflection } from 'packages/feature-utils/store'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { GstTemplate } from 'packages/sdks-next/scms'

import { gstTemplatesAtom } from './atoms'

/**
 * 编排工具 menus
 *
 * @returns menus
 */
export const adminGstMenu = loadable(
  atom(get => {
    const isManager = get(hasManageableParentPluginAtom(SchoolPluginsEnum.Gst))

    if (!get(hasUsablePluginAtom(SchoolPluginsEnum.Gst)) && !isManager) {
      return []
    }

    const enabledPlugin = get(
      findEnabledPluginsAtom(SchoolPluginsEnum.Gst),
    )?.[0]

    const shortcutIcon = enabledPlugin
      ? getPluginIcon(enabledPlugin)
      : 'Other+477cff'

    const menuOpened = get(
      isMenuOpenedAtom([PluginCategoryEnum.通用工具, SchoolPluginsEnum.Gst]),
    )

    const me = getCurrentReflection()

    const gstTemplates: GstTemplate[] =
      menuOpened && me ? get(gstTemplatesAtom) : []

    return [
      {
        prefix: [PluginCategoryEnum.通用工具],
        name: SchoolPluginsEnum.Gst,
        label: $t('编排工具'),
        type: MenuType.AdminApps,
        sort: 800,
        icon: 'ListCheckSolid' as const,
        shortcutIcon,
        lazy: true,
        subMenus: [
          ...gstTemplates.map(t => ({
            label: t.name,
            path: `/admin/plugin/gst/template/${t.id}`,
            icon: 'Schedule' as const,
            permission: () => isManager || t.adminIds.includes(me.id),
          })),
          {
            label: $t('模板管理'),
            path: '/admin/plugin/gst/templates-manage',
            icon: 'Template' as const,
            permission: () => isManager,
          },
        ],
      },
    ]
  }),
)
