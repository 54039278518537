import { PluginNameEnum } from 'packages/plugins/types'

import { studentProfileRoutes } from '@/plugins/features/report-center/routes/studentProfileRoutes'
import { teacherProfileRoutes } from '@/plugins/features/report-center/routes/teacherProfileRoutes'
import { InitPluginFeature } from '@/plugins/types'

import { adminReportCenterMenu, userReportCenterMenu } from './menus'
import { adminModalRoutes, adminRoutes } from './routes/admin'
import { userRoutes } from './routes/user'
import { gradeSubRoutes } from './slots/gradeSubRoutes'
import { studentProfileTabSlots, teacherProfileTabSlots } from './slots/routes'

export const initReportCenter: InitPluginFeature = {
  name: PluginNameEnum.ReportCenter,
  init: plugins => {
    // 是否是北京十五中高中部
    const isBj15g = plugins.length && plugins[0].schoolId === 159

    const slots = {
      profileSubRoutes: studentProfileTabSlots,
      teacherProfileSubRoutes: teacherProfileTabSlots,
      profileModulePermissions: () =>
        import('./slots/permission').then(
          m => m.getProfileModulePermissionsSlots,
        ),
      ...(isBj15g
        ? {
            gradeSubRoutes,
          }
        : {}),
    }

    return {
      routes: [
        ...adminRoutes,
        ...userRoutes,
        ...studentProfileRoutes,
        ...teacherProfileRoutes,
      ],
      modalRoutes: adminModalRoutes,
      menus: [adminReportCenterMenu, userReportCenterMenu],
      slots,
    }
  },
}
