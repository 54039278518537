/**
 * @file 广州中学定制准考证导出及考试安排表
 */

import { lazy } from 'react'

import { InitPluginFeature } from '@/plugins/types'

export const initExamExport: InitPluginFeature = {
  name: 'ticket_and_venues',
  init: () => ({
    slots: {
      examRoomsAdminScheduleHeader: lazy(() =>
        import('./slots/AdmissionTicketsExport').then(m => ({
          default: m.AdmissionTicketsExport,
        })),
      ),
    },
  }),
}
