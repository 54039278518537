/**
 * @file slots
 */

import React from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

// 学生档案-契约路由
export const tabSlot: RouteConfig[] = [
  {
    path: 'contract',
    name: 'PluginContractProfileContract',
    getTitle: () => $t('契约'),
    component: React.lazy(() =>
      import('./pages/user/ProfileContract').then(m => ({
        default: m.ProfileContract,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.ContractContract,
    },
  },
]
