/**
 * @file 教师档案
 */

import { RoleEnum } from 'packages/sdks-next/chalk'

import { InitPluginFeature } from '@/plugins/types'

import { adminTeacherProfileMenu } from './menu'
import { adminTeacherArchives, modalRoutes } from './routes'
import { teacherProfilesRoutes } from './slots/teacherProfileRoute'
import { todos } from './todos'

export const initTeacherArchives: InitPluginFeature = {
  name: 'teacher_profile',
  init: () => ({
    setting: {
      type: 'json',
      config: {
        enabledScopes: [RoleEnum.Teacher],
      },
    },
    routes: [...adminTeacherArchives],
    menus: [adminTeacherProfileMenu],
    modalRoutes,
    slots: {
      teacherProfileSubRoutesByTerm: () =>
        import('./slots/teacherProfileSubRoutesByTerm').then(
          m => m.teacherProfileSubRoutesByTerm,
        ),
      teacherProfilesRoutes,
      userCommonApplicationsEntry: () =>
        import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
    todos,
  }),
}
