/**
 * @file 目标管理 - 常量
 */

import { $t } from 'packages/locale'

export const PLUGIN_NAME = 'academic-goal'

/**
 * 目标类型
 *
 * @returns 目标类型列表
 */
export const getInternationalGradeTypeList = () => [
  {
    key: 'ieltsGrade',
    title: 'IELTS',
  },
  {
    key: 'ieltsListeningScore',
    title: 'IELTS Listening',
  },
  {
    key: 'ieltsSpeakingScore',
    title: 'IELTS Speaking',
  },
  {
    key: 'ieltsReadingScore',
    title: 'IELTS Reading',
  },
  {
    key: 'ieltsWritingScore',
    title: 'IELTS Writing',
  },
  {
    key: 'toeflGrade',
    title: 'TOEFL',
  },
  {
    key: 'toeflListeningScore',
    title: 'TOEFL IBT High Listening',
  },
  {
    key: 'toeflSpeakingScore',
    title: 'TOEFL IBT High Speaking',
  },
  {
    key: 'toeflReadingScore',
    title: 'TOEFL IBT High Reading',
  },
  {
    key: 'toeflWritingScore',
    title: 'TOEFL IBT High Writing',
  },
  {
    key: 'satGrade',
    title: 'SAT',
  },
  {
    key: 'actGrade',
    title: 'ACT',
  },
]

/**
 * 获取大陆高考类型
 *
 * @returns 大陆高考类型列表
 */
export const getMainLandGradeTypeList = () => [
  {
    key: 'gaokaoGrade',
    title: $t('高考成绩'),
  },
  {
    key: 'mathScore',
    title: $t('高考数学'),
  },
  {
    key: 'chineseScore',
    title: $t('高考语文'),
  },
  {
    key: 'englishScore',
    title: $t('高考外语'),
  },
  {
    key: 'physicsScore',
    title: $t('高考物理'),
  },
  {
    key: 'chemistryScore',
    title: $t('高考化学'),
  },
  {
    key: 'historyScore',
    title: $t('高考历史'),
  },
  {
    key: 'biologyScore',
    title: $t('高考生物'),
  },
  {
    key: 'politicsScore',
    title: $t('高考政治'),
  },
  {
    key: 'geographyScore',
    title: $t('高考地理'),
  },
]

/**
 * GPA 类型
 *
 * @returns GPA 类型列表
 */
export const getGPAGradeTypeList = () => [
  // 与后端一致
  {
    key: 'overallGpa',
    title: $t('综合GPA'),
  },
  {
    key: 'nineSubjectGpa',
    title: $t('九科GPA'),
  },
  {
    key: 'sixSubjectGpa',
    title: $t('六科 GPA'),
  },
]

/**
 * 获取所有目标类型
 *
 * @returns 所有目标类型
 */
export const getGradeKeys = () =>
  [
    ...getMainLandGradeTypeList(),
    ...getInternationalGradeTypeList(),
    ...getGPAGradeTypeList(),
  ].map(({ key }) => key)

/**
 * 目标-选科-确认角色目前使用固定选项
 *
 * @returns 角色列表
 */
export const getRoles = () => [
  { value: 'guardian', label: $t('家长') },
  { value: 'mentor', label: $t('导师') },
  { value: 'admin_class_teacher', label: $t('班主任') },
]
