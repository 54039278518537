/**
 * @file 广州中学定制成绩单，由于广中修改了部分内容，暂时直接复制了一份深圳湾的定制成绩单，然后在此基础上修改
 */

import { InitPluginFeature } from '@/plugins/types'

import { adminCgrGzzxMenu } from './menus'
import { routes } from './routes'
import { tabSlot } from './slots/slots'

export const initCgrGzzx: InitPluginFeature = {
  name: 'gzzxcgr',
  /**
   * cgr-gzzx 不管有没有权限，都要初始化。原因：cgr 默认范围应该是全部用户，又没有地方可以配置为全部用户
   */
  force: true,
  init: () => ({
    setting: {
      type: 'json' as const,
      component: {
        appendSchema: () => [
          {
            name: 'scopes',
            visible: false,
            staticValue: {
              guardian: {},
              staff: {},
              student: {},
              teacher: {},
            },
          },
        ],
      },
    },
    routes,
    menus: [adminCgrGzzxMenu],
    slots: {
      profileSubRoutes: tabSlot,
      profileModulePermissions: () =>
        import('./slots/profileModulePermissions').then(
          m => m.profileModulePermissions,
        ),
    },
  }),
}
