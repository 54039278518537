import { lazy } from 'react'

import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'

/**
 * 获取子路由
 *
 * @returns sub routes
 */
const getManageSubRoutes = (): RouteConfig[] => [
  {
    path: 'details',
    name: 'Details',
    getTitle: () => $t('阅卷详情'),
    component: lazy(() =>
      import('./pages/admin/ScoringManage/ScoringDetails').then(m => ({
        default: m.ScoringDetailsEntry,
      })),
    ),
    subRoutes: [
      {
        path: 'subjective',
        name: 'Subjective',
        getTitle: () => $t('主观题'),
        component: lazy(() =>
          import('./pages/admin/ScoringManage/ScoringDetails/Subjective').then(
            m => ({
              default: m.Subjective,
            }),
          ),
        ),
      },
      {
        path: 'objective',
        name: 'Objective',
        getTitle: () => $t('客观题'),
        component: lazy(() =>
          import('./pages/admin/ScoringManage/ScoringDetails/Objective').then(
            m => ({
              default: m.Objective,
            }),
          ),
        ),
      },
    ],
  },
  {
    path: 'student-score',
    name: 'StudentScore',
    getTitle: () => $t('学生得分'),
    component: lazy(() =>
      import('./pages/admin/ScoringManage/StudentGainedScore').then(m => ({
        default: m.StudentGainedScore,
      })),
    ),
  },
]

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/exams/:examId/scorings',
    name: 'AdminExamScorings',
    getTitle: () => $t('阅卷列表'),
    component: lazy(() =>
      import('./pages/admin/Scorings').then(m => ({
        default: m.Scorings,
      })),
    ),
  },
  {
    path: 'admin/exams/:examId/scorings/:scoringId/manage',
    name: 'AdminExamScoringManage',
    getTitle: () => $t('阅卷管理'),
    component: lazy(() =>
      import('./pages/admin/ScoringManage').then(m => ({
        default: m.ScoringManage,
      })),
    ),
    subRoutes: getManageSubRoutes(),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'AdminExamAnswerSheetSetting',
    getTitle: () => $t('制作答题卡'),
    component: lazy(() =>
      import('./pages/admin/AnswerSheetSetting').then(m => ({
        default: m.AdminExamAnswerSheetMaking,
      })),
    ),
  },
  {
    name: 'AdminExamScoringItemSetting',
    getTitle: () => $t('阅卷设置'),
    component: lazy(() =>
      import('./pages/admin/ScoringItemSetting').then(m => ({
        default: m.ScoringItemSetting,
      })),
    ),
  },
  {
    name: 'AdminExamScoringImportScoreSetting',
    getTitle: () => $t('阅卷设置'),
    component: lazy(() =>
      import('./pages/admin/ImportScoreSetting').then(m => ({
        default: m.ImportScoreSetting,
      })),
    ),
  },
  {
    name: 'AdminExamScoringStudentScoreDetails',
    getTitle: () => $t('学生得分详情'),
    component: lazy(() =>
      import('./pages/admin/ScoringManage/StudentScoreDetails').then(m => ({
        default: m.StudentScoreDetails,
      })),
    ),
  },
  {
    name: 'AdminExamScoringExceptionPaperHandling',
    getTitle: () => $t('问题卷列表'),
    component: lazy(() =>
      import('./pages/admin/ScoringManage/ExceptionPaperHandling').then(m => ({
        default: m.ExceptionPaperHandling,
      })),
    ),
  },
  {
    name: 'TeacherExamScoring',
    getTitle: () => $t('教师阅卷'),
    component: lazy(() =>
      import('./pages/teacher/TeacherScoring').then(m => ({
        default: m.TeacherScoring,
      })),
    ),
  },
]
