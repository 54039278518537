/**
 * @file 注册德育审批渲染器
 */
import React from 'react'

import { ChalkRegisterWorkflowRenderType } from '@/features/workflows/register'

export const workflowRenders: ChalkRegisterWorkflowRenderType = {
  bizType: 'moral_assessment',
  render: {
    FlowCard: React.lazy(() =>
      import('./FlowCard').then(m => ({ default: m.MoralFlowCard })),
    ),
    TodoCard: React.lazy(() =>
      import('./TodoCard').then(m => ({ default: m.MoralTodoCard })),
    ),
    NewFlowModal: React.lazy(() =>
      import('./NewFlowModal').then(m => ({ default: m.NewMoralFlowModal })),
    ),
    ShowFlowModal: React.lazy(() =>
      import('./ShowFlowModal').then(m => ({
        default: m.ShowMoralFlowModal,
      })),
    ),
    WorkflowAdminTable: React.lazy(() =>
      import('./WorkflowAdminTable').then(m => ({
        default: m.MoralAdminTable,
      })),
    ),
  },
}
