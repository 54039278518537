import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { calendarEvents } from './calendar-events'
import { adminDormMenu, userDormMenu } from './menus'
import { userRoutes, adminRoutes, modalRoutes } from './routes'
import { adminClassDefaultSubRoutes } from './slots/adminClassDefaultSubRoutes'
import { tabSlot } from './slots/tab-slots'
import { todos } from './todos'

export const initDorms: InitPluginFeature = {
  name: PluginNameEnum.Dorm,
  init: () => ({
    calendarEvents,
    routes: [...userRoutes, ...adminRoutes],
    menus: [adminDormMenu, userDormMenu],
    modalRoutes,
    todos,
    slots: {
      profileGroupsSubRoutes: tabSlot,
      adminClassDefaultSubRoutes,
      archiveStudentCheckItems: () =>
        import('./slots/archiveStudentCheckItems').then(
          m => m.archiveStudentCheckItems,
        ),
    },
  }),
}
