/**
 * @file 活动待办注册
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'
import { WorkflowBizType } from 'packages/features/workflows/types'

import { ChalkRegisterWorkflowRenderType } from '@/features/workflows/register'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.EventApproval,
    Render: lazy(() =>
      import('./EventApprovalTodo').then(m => ({
        default: m.EventApprovalTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.EventMemberApproval,
    Render: lazy(() =>
      import('./EventMemberTodo').then(m => ({ default: m.EventMemberTodo })),
    ),
  },
  {
    type: TodoTypeEnum.EventAttendanceDailyNotice,
    Render: lazy(() =>
      import('./EventAttendanceTodo').then(m => ({
        default: m.EventAttendanceTodo,
      })),
    ),
  },
]

/**
 * 活动审批流待办注册
 */
export const workflowRenders: ChalkRegisterWorkflowRenderType[] = [
  {
    bizType: 'event' as WorkflowBizType,
    render: {
      TodoCard: lazy(() =>
        import('./EventWorkflowTodo').then(m => ({
          default: m.EventWorkflowTodo,
        })),
      ),
    },
  },
]
