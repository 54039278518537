/**
 * @file 深圳中学停课
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminClassStopMenu } from './menus'
import { routes, modalRoutes } from './routes'

export const initClosedClass: InitPluginFeature = {
  name: PluginNameEnum.Tkgl,
  init: () => ({
    routes,
    modalRoutes,
    menus: [adminClassStopMenu],
  }),
}
