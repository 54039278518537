/**
 * @file net-disk sub routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { RouteMenus } from '@/router'

export const adminPersonSubRoutes = [
  {
    path: 'student',
    getTitle: () => $t('学生'),
    name: 'student',
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/admin/users/student').then(
        m => ({
          default: m.AdminStudentEntry,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('在校学生'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/admin/users/student/normal'
          ).then(m => ({
            default: m.NormalStudent,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档学生'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/admin/users/student/archived'
          ).then(m => ({
            default: m.ArchivedStudent,
          })),
        ),
      },
    ],
  },
  {
    path: 'teacher',
    name: 'teacher',
    getTitle: () => $t('教师'),
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/admin/users/student').then(
        m => ({
          default: m.AdminStudentEntry,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('在校教师'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/admin/users/teacher/normal'
          ).then(m => ({
            default: m.NormalTeacher,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档教师'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/admin/users/teacher/archived'
          ).then(m => ({
            default: m.ArchivedTeacher,
          })),
        ),
      },
    ],
  },
]

export const adminGroupSubRoutes = [
  {
    path: 'course',
    getTitle: () => $t('课程库'),
    name: 'course',
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/admin/groups/course').then(
        m => ({
          default: m.CourseNetDisk,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('课程库'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/admin/groups/course/normal'
          ).then(m => ({
            default: m.NormalCourse,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档课程'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/admin/groups/course/archived'
          ).then(m => ({
            default: m.ArchivedCourse,
          })),
        ),
      },
    ],
  },
  {
    path: 'class',
    getTitle: () => $t('课程班'),
    name: 'class',
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/admin/groups/class').then(
        m => ({
          default: m.ClassNetDisk,
        }),
      ),
    ),
  },
  {
    path: 'customized-groups/:type',
    name: 'CustomizedGroup',
    getTitle: () => $t('通用群组'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/admin/groups/customized-groups'
      ).then(m => ({
        default: m.CustomizedGroupEntry,
      })),
    ),
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('通用群组'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/admin/groups/customized-groups/normal'
          ).then(m => ({
            default: m.NormalGroup,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档通用群组'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/admin/groups/customized-groups/archived'
          ).then(m => ({
            default: m.ArchivedGroups,
          })),
        ),
      },
    ],
  },
]
export const myFileSubRoutes = [
  {
    path: 'history',
    getTitle: () => $t('操作历史'),
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/user/MyFiles/History').then(
        m => ({
          default: m.OperatingHistory,
        }),
      ),
    ),
  },
  {
    path: 'trash',
    name: 'Trash',
    getTitle: () => $t('回收站'),
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/user/MyFiles/Trash').then(
        m => ({
          default: m.TrashFileList,
        }),
      ),
    ),
  },
]
export const customizedFileSubRoutes = [
  {
    path: 'files',
    name: 'FileList',
    getTitle: () => $t('文件列表'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/group/components/GroupNetDiskFileList'
      ).then(m => ({
        default: m.CustomizedNetDiskFileList,
      })),
    ),
  },
  {
    path: 'shared',
    getTitle: () => $t('分享历史'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/group/components/GroupNetDiskShareHistory'
      ).then(m => ({
        default: m.GroupNetDiskShareHistory,
      })),
    ),
  },
  ...myFileSubRoutes,
]

export const sharedNetdiskFileSubRoutes = [
  {
    path: 'files',
    name: 'FileList',
    getTitle: () => $t('文件列表'),
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/share/Detail/FileList').then(
        m => ({
          default: m.ShareNetDiskFileList,
        }),
      ),
    ),
  },
  {
    path: 'shared',
    getTitle: () => $t('分享历史'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/share/Detail/SharedHistory'
      ).then(m => ({
        default: m.ShareNetDiskSharedHistory,
      })),
    ),
  },
  ...myFileSubRoutes,
]

export const userWorkspaceSubRoutes = [
  {
    path: 'my-file',
    name: 'MyFile',
    getTitle: () => $t('我的网盘'),
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/user/MyFiles').then(m => ({
        default: m.MyFiles,
      })),
    ),
    subRoutes: [
      {
        path: 'files',
        name: 'FileList',
        getTitle: () => $t('文件列表'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/user/MyFiles/FileList'
          ).then(m => ({
            default: m.NetdiskMyFileList,
          })),
        ),
      },
      {
        path: 'shared',
        getTitle: () => $t('分享历史'),
        component: lazy(() =>
          import('@/plugins/features/net-disk/pages/user/MyFiles/Shared').then(
            m => ({
              default: m.NetdiskShareList,
            }),
          ),
        ),
      },
      ...myFileSubRoutes,
    ],
  },
  {
    path: 'groups',
    getTitle: () => $t('群组网盘'),
    name: 'Groups',
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'class',
        name: 'Class',
        getTitle: () => $t('课程班'),
        component: lazy(() =>
          import('@/plugins/features/net-disk/pages/group/Class').then(m => ({
            default: m.ClassNetDisk,
          })),
        ),
      },
      {
        path: 'course',
        name: 'Course',
        getTitle: () => $t('课程库'),
        component: lazy(() =>
          import('@/plugins/features/net-disk/pages/group/Course').then(m => ({
            default: m.CourseNetDisk,
          })),
        ),
      },
      {
        path: 'customized/:type',
        name: 'CustomizedGroup',
        getTitle: () => $t('通用群组'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/group/CustomizedGroup'
          ).then(m => ({
            default: m.CustomizedGroupNetDisk,
          })),
        ),
      },
      {
        path: 'details/:type/:ownerId',
        name: 'Detail',
        getTitle: () => $t('文件详情'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/group/components/GroupNetDiskFileDetails'
          ).then(m => ({
            default: m.CustomizedNetDiskDetails,
          })),
        ),
        subRoutes: customizedFileSubRoutes,
      },
    ],
  },
  {
    path: 'collection',
    name: 'Collection',
    getTitle: () => $t('我的收藏'),
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/user/Collection').then(m => ({
        default: m.NetdiskCollection,
      })),
    ),
  },
]

export const adminGroupFileListSubRoutes = [
  {
    path: 'files',
    name: 'FileList',
    getTitle: () => $t('文件列表'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/admin/groups/AdminGroupsNetDiskDetails/AdminGroupNetDiskFileList'
      ).then(m => ({
        default: m.AdminGroupNetDiskFileList,
      })),
    ),
  },
  {
    path: 'shared',
    getTitle: () => $t('分享历史'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/group/components/GroupNetDiskShareHistory'
      ).then(m => ({
        default: m.GroupNetDiskShareHistory,
      })),
    ),
  },
  ...myFileSubRoutes,
]

export const adminSharedNetdiskSubRoutes = [
  {
    path: 'files',
    name: 'FileList',
    getTitle: () => $t('文件列表'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/admin/share/Detail/FileList'
      ).then(m => ({
        default: m.AdminShareNetDiskFileList,
      })),
    ),
  },
  {
    path: 'shared',
    getTitle: () => $t('分享历史'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/admin/share/Detail/SharedHistory'
      ).then(m => ({
        default: m.AdminShareNetDiskSharedHistory,
      })),
    ),
  },
  ...myFileSubRoutes,
]
