/**
 * @file 契约待办注册入口
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.ContractConfirm,
    Render: lazy(() =>
      import('./ContractConfirmTodo').then(m => ({
        default: m.ContractConfirmTodo,
      })),
    ),
  },
]
