import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = [
  {
    name: 'PreviewExaminee',
    path: 'exam-rooms/:examId/examinees/:rid',
    getTitle: () => $t('预览准考证'),
    component: lazy(() =>
      import('./pages/user/PreviewExaminee').then(m => ({
        default: m.PreviewExaminee,
      })),
    ),
  },
  {
    name: 'PreviewExaminer',
    path: 'exam-rooms/:examId/examiners/:examinerId',
    getTitle: () => $t('预览监考安排'),
    component: lazy(() =>
      import('./pages/user/PreviewExaminer').then(m => ({
        default: m.PreviewExaminer,
      })),
    ),
  },
  {
    name: 'PreviewPatrolExaminer',
    path: 'exam-rooms/:examId/patrol-examiners/:examinerId',
    getTitle: () => $t('预览巡考安排'),
    component: lazy(() =>
      import('./pages/user/PreviewPatrolExaminer').then(m => ({
        default: m.PreviewPatrolExaminer,
      })),
    ),
  },
  {
    name: 'PreviewExaminerMe',
    path: 'exam-rooms/:examId/examiners-me',
    getTitle: () => $t('巡考安排'),
    component: lazy(() =>
      import('./pages/user/PreviewMeExaminerInfo').then(m => ({
        default: m.PreviewMeExaminerInfo,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'AdminExamRoomSettings',
    getTitle: () => $t('排考场 - 基础设置'),
    component: lazy(() =>
      import('./pages/admin/Settings').then(m => ({
        default: m.AdminExamRoomSettings,
      })),
    ),
  },
  {
    name: 'AdminExamRoomSchedules',
    getTitle: () => $t('排考场'),
    component: lazy(() =>
      import('./pages/admin/Schedules').then(m => ({
        default: m.AdminExamRoomSchedules,
      })),
    ),
  },
]
