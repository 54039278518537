/**
 * @file routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = [
  {
    path: 'plugin/elections',
    name: 'Elections',
    getTitle: () => $t('选课'),
    component: lazy(() =>
      import('./pages/ElectionList').then(m => ({
        default: m.ElectionList,
      })),
    ),
  },
  {
    path: 'plugin/elections/:id',
    getTitle: () => $t('选课班级列表'),
    name: 'ElectionClasses',
    component: lazy(() =>
      import('./pages/ElectionClasses').then(m => ({
        default: m.ElectionClasses,
      })),
    ),
  },
]
