import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { GstTemplate, gstApi$queryGstTemplates } from 'packages/sdks-next/scms'

/**
 * 触发更新编排工具模板 atom 的 atom
 */
export const updateTemplatesFlagAtom = atom(1)

/**
 * 编排工具模板 atom
 */
export const gstTemplatesAtom = atom<Promise<GstTemplate[]>>(async get => {
  get(updateTemplatesFlagAtom)

  const { data: templates } = await gstApi$queryGstTemplates.api({
    disabled: false,
  })

  return templates
})

/**
 * 加载完的编排工具模板 atom
 */
export const gstTemplatesLoadableAtom = loadable(
  atom(get => get(gstTemplatesAtom)),
)
