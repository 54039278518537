import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

import {
  adminPersonSubRoutes,
  adminGroupSubRoutes,
  userWorkspaceSubRoutes,
  adminGroupFileListSubRoutes,
  adminSharedNetdiskSubRoutes,
  sharedNetdiskFileSubRoutes,
} from './net-disk-sub-routes'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/net-disk',
    name: 'AdminNetdisk',
    module: [PluginCategoryEnum.通用工具, SchoolPluginsEnum.NetDisk],
    getTitle: () => $t('网盘管理'),
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'users',
        name: 'AdminNetdiskUsers',
        getTitle: () => $t('个人网盘管理'),
        component: lazy(() =>
          import('@/plugins/features/net-disk/pages/admin/users').then(m => ({
            default: m.UsersAdminEntry,
          })),
        ),
        subRoutes: adminPersonSubRoutes,
      },
      {
        path: 'groups',
        name: 'AdminNetdiskGroups',
        getTitle: () => $t('群组网盘管理'),
        module: [PluginCategoryEnum.通用工具, SchoolPluginsEnum.NetDisk],
        component: lazy(() =>
          import('@/plugins/features/net-disk/pages/admin/groups').then(m => ({
            default: m.GroupAdminEntry,
          })),
        ),
        subRoutes: adminGroupSubRoutes,
      },
      {
        path: 'shared',
        name: 'AdminNetdiskShared',
        getTitle: () => $t('共享网盘管理'),
        module: [PluginCategoryEnum.通用工具, SchoolPluginsEnum.NetDisk],
        component: lazy(() =>
          import('@/plugins/features/net-disk/pages/admin/share/List').then(
            m => ({
              default: m.ShareList,
            }),
          ),
        ),
      },
      {
        path: 'files',
        name: 'AdminNetdiskFiles',
        getTitle: () => $t('文件列表'),
        module: [PluginCategoryEnum.通用工具, SchoolPluginsEnum.NetDisk],
        component: lazy(() =>
          import('@/plugins/features/net-disk/pages/admin/files').then(m => ({
            default: m.FileList,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/net-disk/user/:rid',
    name: 'AdminNetdiskUser',
    getTitle: () => $t('用户网盘'),
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/user').then(m => ({
        default: m.AdminUserNetDisk,
      })),
    ),
    subRoutes: userWorkspaceSubRoutes,
  },
  {
    path: 'admin/net-disk/shared/:id',
    name: 'AdminSharedNetdiskDetail',
    getTitle: () => $t('共享网盘详情'),
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/admin/share/Detail').then(
        m => ({
          default: m.AdminSharedNetDiskDetail,
        }),
      ),
    ),
    subRoutes: adminSharedNetdiskSubRoutes,
  },
  {
    path: 'admin/net-disk/skip',
    name: 'AdminNetDiskNavigator',
    getTitle: () => $t('网盘管理'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/admin/AdminNetDiskNavigator'
      ).then(m => ({
        default: m.AdminNetDiskNavigator,
      })),
    ),
  },
  {
    path: 'admin/net-disk/groups/details/:ownerId/:type',
    name: 'AdminNetdiskGroupsDetails',
    getTitle: () => $t('群组网盘详情'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/pages/admin/groups/AdminGroupsNetDiskDetails'
      ).then(m => ({
        default: m.GroupsNetDiskDetails,
      })),
    ),
    subRoutes: adminGroupFileListSubRoutes,
  },
]

export const usersWorkspaceRoutes: RouteConfig[] = [
  {
    path: 'net-disk/workspace/users',
    name: 'NetDisk',
    getTitle: () => $t('网盘'),
    module: [SchoolPluginsEnum.NetDisk],
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/user').then(m => ({
        default: m.UserNetDiskWithoutMenu,
      })),
    ),
    subRoutes: userWorkspaceSubRoutes,
  },
  {
    path: 'net-disk/workspace/users/shared',
    getTitle: () => $t('共享网盘'),
    name: 'NetDisk.SharedNetdisk',
    component: lazy(() =>
      import('@/plugins/features/net-disk/pages/share').then(m => ({
        default: m.RedirectRoute,
      })),
    ),
    subRoutes: [
      {
        path: ':id',
        name: 'Detail',
        getTitle: () => $t('文件详情'),
        component: lazy(() =>
          import('@/plugins/features/net-disk/pages/share/Detail').then(m => ({
            default: m.SharedNetDiskDetail,
          })),
        ),
        subRoutes: sharedNetdiskFileSubRoutes,
      },
    ],
  },
]
