/**
 * @file 素养评价
 */

import { lazy } from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminAccMenu } from './menus'
import { adminRoutes } from './routes'

export const initAccAssessment: InitPluginFeature = {
  name: PluginNameEnum.AccAssessment,
  init: () => ({
    setting: {
      type: 'json',
    },
    routes: adminRoutes,
    menus: [adminAccMenu],
    slots: {
      taskFormSchema: () =>
        import('./slots/data-slots').then(m => m.taskFormSchema),
      teacherTaskSubmissionForm: () =>
        import('./slots/data-slots').then(m => m.teacherTaskSubmissionForm),
      profileSubRoutes: [
        {
          path: 'acc-assessments',
          getTitle: () => $t('素养评价'),
          component: lazy(() =>
            import('./pages/user/ProfileAccAssessments').then(m => ({
              default: m.ProfileAccAssessments,
            })),
          ),
          customMeta: {
            groupPermission: GroupPermissionTypeEnum.AccomplishmentAll,
          },
        },
      ],
      profileModulePermissions: () =>
        import('./slots/data-slots').then(m => m.profileModulePermissions),
    },
  }),
}
