/**
 * @file 和平实验小学成绩单
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const adminRoutes: RouteConfig[] = [
  {
    name: 'SzhpsyGradeReport',
    path: 'plugin/szhpsy-grade-export',
    getTitle: () => $t('和平实验小学成绩单下载'),
    component: lazy(() =>
      import('./pages/report').then(m => ({
        default: m.DownloadHpsyReport,
      })),
    ),
  },
]
