/**
 * @file 成绩分析详情
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const adminClassRoutes: RouteConfig[] = [
  {
    path: 'admin/admin-classes/analysis/details/:examId/:rid/:examName',
    name: 'AdminScoreAnalysisDetails',
    getTitle: () => $t('成绩分析详情'),
    component: lazy(() =>
      import(
        '@/plugins/features/gz-exam-res-analysis/pages/admin/AdminScoreAnalysisDetails'
      ).then(m => ({
        default: m.AdminScoreAnalysisDetails,
      })),
    ),
  },
]
