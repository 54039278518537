import React from 'react'

import { $t } from 'packages/locale'
import { RoleEnum, WidgetTypeEnum } from 'packages/sdks-next/chalk'

import {
  RegisterWidget,
  WidgetKeyEnum,
  WidgetLayoutSize,
} from '@/features/widgets/types'

export const widgets: RegisterWidget[] = [
  {
    key: WidgetKeyEnum.HomeTodosConcise,
    label: () => $t('待办'),
    icon: 'Todo',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Large],
    layout: {
      rows: 5,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./Todos').then(m => ({
        default: m.TodoListPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./Todos').then(m => ({
        default: m.TodoList,
      })),
    ),
  },
  {
    key: WidgetKeyEnum.HomeMessagesLarge,
    label: () => $t('通知'),
    icon: 'Notice',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Large],
    layout: {
      rows: 6,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./Notices').then(m => ({
        default: m.NoticeListPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./Notices').then(m => ({
        default: m.NoticeList,
      })),
    ),
  },
  {
    key: WidgetKeyEnum.HomeCalendarDay,
    label: () => $t('日程'),
    icon: 'Calendar',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Large],
    layout: {
      rows: 4,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./Calendar').then(m => ({
        default: m.CalendarPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./Calendar').then(m => ({
        default: m.Calendar,
      })),
    ),
  },
  {
    key: WidgetKeyEnum.HomeToolsRecent,
    label: () => $t('最近访问'),
    icon: 'Grid',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Small, WidgetLayoutSize.Large],
    layout: {
      rows: 5,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./PluginsShortcut').then(m => ({
        default: m.PluginsShortcutPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./PluginsShortcut').then(m => ({
        default: m.PluginsShortcutRecent,
      })),
    ),
  },
  {
    key: WidgetKeyEnum.HomeToolsFrequent,
    label: () => $t('最近访问'),
    icon: 'Grid',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Small, WidgetLayoutSize.Large],
    layout: {
      rows: 5,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./PluginsShortcut').then(m => ({
        default: m.PluginsShortcutPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./PluginsShortcut').then(m => ({
        default: m.PluginsShortcutFrequent,
      })),
    ),
  },
  {
    key: WidgetKeyEnum.AIChart,
    label: () => $t('AI 图表'),
    icon: 'Chart',
    type: WidgetTypeEnum.PluginBiz,
    enableRoles: [RoleEnum.Teacher, RoleEnum.Guardian],
    sizes: [WidgetLayoutSize.Large],
    layout: {
      rows: 6,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./AIChart').then(m => ({
        default: m.AIChartPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./AIChart').then(m => ({
        default: m.AIChartWidget,
      })),
    ),
  },
  {
    key: WidgetKeyEnum.Tracker,
    label: () => $t('进度追踪'),
    icon: 'Target',
    type: WidgetTypeEnum.PluginBiz,
    enableRoles: [RoleEnum.Teacher],
    sizes: [WidgetLayoutSize.Small],
    layout: {
      rows: 6,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./Tracker').then(m => ({
        default: m.TrackerPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./Tracker').then(m => ({
        default: m.TrackerWidget,
      })),
    ),
  },
]
