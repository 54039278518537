/**
 * @file Menus
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
} from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminTeacherProfileMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(SchoolPluginsEnum.TeacherProfile))) {
    return []
  }

  const enabledPlugin = get(
    findEnabledPluginsAtom(SchoolPluginsEnum.TeacherProfile),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.教师发展],
      name: SchoolPluginsEnum.TeacherProfile,
      type: MenuType.AdminApps,
      sort: 800,
      path: '/admin/plugin/teacher-profile',
      label: $t('教师档案管理'),
      icon: 'GraduateInformation' as const,
      shortcutIcon,
      subMenus: [
        {
          path: '/admin/plugin/teacher-profile/edit-form',
          label: $t('填报管理'),
          icon: 'Report' as const,
        },
        {
          path: '/admin/plugin/teacher-profile/setting',
          label: $t('设置'),
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})
