/**
 * @file 宿舍 routes
 */

import React, { lazy } from 'react'

import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'

import { RouteMenus } from '@/router'

const dormStatisticsRoutes = {
  path: 'dorms/statistics',
  getTitle: () => $t('统计分析'),
  requireModules: [SchoolModulesEnum.Dorm],
  module: [SchoolModulesEnum.Dorm],
  component: React.lazy(() =>
    Promise.resolve({
      default: RouteMenus,
    }),
  ),
  subRoutes: [
    {
      path: 'sta',
      getTitle: () => $t('数据统计'),
      component: lazy(() =>
        import('../pages/admin/Statistics/List').then(m => ({
          default: m.Index,
        })),
      ),
      subRoutes: [
        {
          path: 'data',
          getTitle: () => $t('宿舍称号统计'),
          requireModules: [SchoolModulesEnum.Dorm],
          module: [SchoolModulesEnum.Dorm],
          component: lazy(() =>
            import('../pages/user/sta/index').then(m => ({
              default: m.DormTitleSta,
            })),
          ),
          requireSchools: [194, 145],
        },
        {
          path: 'assessmentRecord',
          getTitle: () => $t('评价记录'),
          component: lazy(() =>
            import('../pages/user/sta/AssessmentRecord').then(m => ({
              default: m.AssessmentRecord,
            })),
          ),
          requireSchools: [194, 145],
        },
      ],
    },
  ],
}

export const userRoutes = [
  {
    path: 'dorms/home',
    name: 'DormHome',
    requireModules: [SchoolModulesEnum.Dorm],
    module: [SchoolModulesEnum.Dorm],
    getTitle: () => $t('宿舍主页'),
    component: lazy(() =>
      import('../pages/user').then(m => ({
        default: m.Home,
      })),
    ),
  },
  {
    path: 'dorms/attendances/management',
    name: 'DormAttendancesManagement',
    requireModules: [SchoolModulesEnum.Dorm],
    module: [SchoolModulesEnum.Dorm],
    getTitle: () => $t('全部宿舍'),
    component: lazy(() =>
      import('../pages/user/attendances/admin').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'attendances',
        getTitle: () => $t('每日考勤'),
        component: lazy(() =>
          import('../pages/user/attendances/admin/Day').then(m => ({
            default: m.Day,
          })),
        ),
      },
      {
        path: 'dorm-stat',
        getTitle: () => $t('宿舍考勤统计'),
        component: lazy(() =>
          import('../pages/user/attendances/admin/DormStat').then(m => ({
            default: m.DormStat,
          })),
        ),
      },
      {
        path: 'reflection-stat',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import('../pages/user/attendances/admin/ReflectionStat').then(m => ({
            default: m.ReflectionStat,
          })),
        ),
      },
    ],
  },
  {
    path: 'dorms/:dormId/attendances/management',
    name: 'DormAttendancesManagementDetail',
    requireModules: [SchoolModulesEnum.Dorm],
    module: [SchoolModulesEnum.Dorm],
    getTitle: () => $t('宿舍详情'),
    component: lazy(() =>
      import('../pages/user/attendances/admin/Detail').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'detail',
        getTitle: () => $t('考勤详情'),
        component: lazy(() =>
          import('../pages/user/attendances/admin/Detail/Dorm').then(m => ({
            default: m.Detail,
          })),
        ),
      },
      {
        path: 'reflection-stat',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import('../pages/user/attendances/admin/Detail/Reflection').then(
            m => ({
              default: m.Reflection,
            }),
          ),
        ),
      },
    ],
  },
  {
    path: 'dorms/:dormId/attendances/reflections/:rid',
    name: 'DormAttendancesReflectionDetail',
    requireModules: [SchoolModulesEnum.Dorm],
    module: [SchoolModulesEnum.Dorm],
    getTitle: () => $t('宿舍详情'),
    component: lazy(() =>
      import('../pages/user/attendances/user').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'dorms',
        getTitle: () => $t('考勤'),
        component: lazy(() =>
          import('../pages/user/attendances/user/Dorm').then(m => ({
            default: m.Dorm,
          })),
        ),
      },
    ],
  },
  {
    path: 'dorms/assessments/:id',
    name: 'DormAssessmentDetail',
    getTitle: () => $t('宿舍评价'),
    requireModules: [SchoolModulesEnum.Dorm],
    module: [SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/Assessment').then(m => ({
        default: m.DormAssessment,
      })),
    ),
    subRoutes: [
      {
        path: 'overview',
        getTitle: () => $t('汇总'),
        component: lazy(() =>
          import('../pages/Assessment/Overview').then(m => ({
            default: m.Overview,
          })),
        ),
      },
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: lazy(() =>
          import('../pages/Assessment/Entry').then(m => ({
            default: m.Entry,
          })),
        ),
      },
    ],
  },
  {
    path: 'dorms/member',
    getTitle: () => $t('宿生列表'),
    requireModules: [SchoolModulesEnum.Dorm],
    module: [SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Dorms/List/Member').then(m => ({
        default: m.MemberList,
      })),
    ),
  },
  dormStatisticsRoutes,
]
