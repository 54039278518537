/**
 * @file 国际校目标管理菜单
 */

import { atom } from 'jotai'

import {
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminIntlGoalMenus = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.IntlGoal))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.学生成长],
      name: PluginEnum.IntlGoal,
      // 这是国际校目标管理
      label: $t('目标管理'),
      icon: 'Target' as const,
      type: MenuType.AdminApps,
      sort: 705,
      subMenus: [
        {
          icon: 'Target' as const,
          label: $t('目标列表'),
          path: '/admin/plugin/intl-goal/goals',
        },
        {
          icon: 'Activity' as const,
          label: $t('里程碑模版库'),
          path: '/admin/plugin/intl-goal/milestone-templates',
        },
        {
          icon: 'Setting' as const,
          label: $t('设置'),
          path: '/admin/plugin/intl-goal/setting',
        },
      ],
    },
  ]
})
