/**
 * @file Chalk Rematch Stores
 */

import createLoadingPlugin from '@rematch/loading'
import createRematchPersist from '@rematch/persist'
import createSelectPlugin from '@rematch/select'
import { env } from '@seiue/env'
import { RematchDispatch, RematchRootState, init } from '@seiue/rematch-core'
import { isEqual } from '@seiue/util'
import { Atom, Getter, atom } from 'jotai'
import {
  useDispatch as originUseDispatch,
  useSelector as originUseSelector,
} from 'react-redux'
import storage from 'redux-persist/lib/storage'

import { pageDirtyFlags } from 'packages/components/PageDirtyPrompt'
import { entities } from 'packages/entities-store'
import { setStore } from 'packages/feature-utils/store'
import { SessionState } from 'packages/features/sessions'
import {
  session,
  sessionPersistTransform,
} from 'packages/features/sessions/stores/sessions'
import { setReduxRootStore, todosStore } from 'packages/features/todos/store'
import { localePersistTransform, localeStore } from 'packages/locale/store'
import { LoadingState } from 'packages/rematch-loading-types'
import { dicts } from 'packages/shared-stores/dicts'
import { migratePersistedState } from 'packages/utils/redux'

import { chalkClasses } from '@/features/classes/store'
import {
  globalPersistTransform,
  globalSearchStore,
} from '@/features/global-search/store'
import { systemSettings } from '@/features/system-settings/store'

const loading = createLoadingPlugin({})
const select = createSelectPlugin()

const models = {
  dicts,
  pageDirtyFlags,
  session,
  entities,
  locale: localeStore,
  systemSettings,
  todos: todosStore,
  chalkClasses,
  globalSearch: globalSearchStore,
}

const persist = createRematchPersist({
  /**
   * 1. 持久化 session 以记住用户
   * 2. 缓存创建通知时填写的数据
   * 3. 记忆课程班任务列表每个章节收起/展开的状态
   * 4. 记忆课程班任务列表每个章节收起/展开的状态
   * 5. 语言包
   * 6. 左下角选中学期
   */
  whitelist: [
    'session',
    'dicts',
    'notices',
    'chalkClasses',
    'locale',
    'semesters',
    'globalSearch',
  ],

  // 最多 1 秒存储一次
  throttle: 1000,

  // 升版本以让旧缓存失效
  version: 5,

  storage,

  transforms: [
    sessionPersistTransform,
    localePersistTransform,
    globalPersistTransform,
  ],

  // 当前的迁移策略是不迁移... version 变更后废弃旧 version 的缓存
  migrate: migratePersistedState,
})

// FIXME 为了让每个 e2e test case 相互没有缓存影响，测试环境暂不开启 persist 插件
const plugins = [select, loading]
if (env('ENV') !== 'test') plugins.push(persist)

export const store = init({ models, plugins })

// 向待办注册一下根 store
setReduxRootStore(store)

// export type Store = typeof store
export type Models = typeof models
export type Dispatch = RematchDispatch<Models>
export type RootState = RematchRootState<Models> & LoadingState<Models>

/**
 * useDispatch
 *
 * @deprecated 尽可能不再使用 redux，而是使用 jotai
 * @returns dispatch
 */
export const useDispatch = () => originUseDispatch<Dispatch>()

/**
 * useSelector
 *
 * @deprecated 尽可能不再使用 redux，而是使用 jotai
 *
 * @param selector - selector
 * @returns selector
 */
export const useSelector = <T>(selector: (state: RootState) => T) =>
  originUseSelector<RootState, T>(selector)

function atomWithStore(): Atom<{
  session: SessionState
}> {
  const state = store.getState()
  type StateType = typeof state
  ;(window as any).store = store

  const getDataForAtom = (reduxState: StateType) => ({
    session: reduxState.session,
  })

  const baseAtom = atom(getDataForAtom(state))

  let oldState = {}

  baseAtom.onMount = setValue => {
    const callback = () => {
      const newState = getDataForAtom(store.getState())

      if (!isEqual(oldState, newState)) {
        setValue(newState)
        oldState = newState
      }
    }

    const unsub = store.subscribe(callback)
    callback()
    return unsub
  }

  return baseAtom
}

export const storeAtom = atomWithStore()

/**
 * getStoreForAtom
 *
 * @param get - getter
 * @returns store
 */
export const getStoreForAtom = (get: Getter) => get(storeAtom)

// Export store access to jotai
setStore(store)
