import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { calendarEvents } from './calendar-events'
import { classReviewAdminMenu, classReviewUserMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { teacherProfilesEvaluationSubRoutes } from './slots/teacherProfileSubRoutes'
import { todos } from './todos'

export const initClassReview: InitPluginFeature = {
  name: PluginNameEnum.ClassReview,
  init: () => ({
    routes,
    menus: [classReviewAdminMenu, classReviewUserMenu],
    modalRoutes,
    todos,
    calendarEvents,
    slots: {
      teacherProfilesEvaluationSubRoutes,
    },
  }),
}
