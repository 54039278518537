/**
 *@file 学分管理 routes
 */

import { omit } from '@seiue/util'
import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

/**
 * 获取学分管理路由
 *
 * @param isPluginManager - 是否为插件管理员
 * @returns 路由配置
 */
export const getRoutes = (isPluginManager: boolean) => {
  const subRoutes: RouteConfig[] = [
    {
      path: 'students',
      name: 'Students',
      getTitle: () => $t('学生列表'),
      component: lazy(() =>
        import('./pages/admin/Students').then(m => ({
          default: m.List,
        })),
      ),
    },
    {
      path: 'structures',
      name: 'Structures',
      getTitle: () => $t('学分结构'),
      component: lazy(() =>
        import('./pages/admin/Structure').then(m => ({
          default: m.Structure,
        })),
      ),
      requirePermissions: [PermissionNameEnum.CreditWrite],
    },
    {
      path: 'awards',
      name: 'Awards',
      getTitle: () => $t('认定学分'),
      component: lazy(() =>
        import('./pages/admin/Awards').then(m => ({
          default: m.Awards,
        })),
      ),
      requirePermissions: [PermissionNameEnum.CreditWrite],
    },
    {
      path: 'awards/new',
      name: 'NewAwards',
      getTitle: () => $t('新增认定学分'),
      component: lazy(() =>
        import('./pages/admin/Awards/Edit').then(m => ({
          default: m.AwardedCreditEdit,
        })),
      ),
    },
    {
      path: 'awards/:id/edit',
      name: 'EditAwards',
      getTitle: () => $t('编辑认定学分'),
      component: lazy(() =>
        import('./pages/admin/Awards/Edit').then(m => ({
          default: m.AwardedCreditEdit,
        })),
      ),
    },
  ]

  if (isPluginManager) {
    subRoutes.push({
      path: 'settings',
      getTitle: () => $t('设置'),
      component: lazy(() =>
        import('./pages/admin/Settings').then(m => ({
          default: m.Settings,
        })),
      ),
    })
  }

  return [
    {
      name: 'AdminCreditManagement',
      path: 'admin/plugin/credit',
      getTitle: () => $t('学分管理'),
      module: [PluginCategoryEnum.教务教学, SchoolPluginsEnum.Credit],
      component: lazy(() =>
        Promise.resolve({
          default: RouteMenus,
        }),
      ),
      subRoutes: isPluginManager
        ? subRoutes.map(item => omit(item, 'requirePermissions'))
        : subRoutes,
    },
    {
      name: 'NewCreditStructure',
      path: 'admin/plugin/credit/new',
      getTitle: () => $t('创建学分结构'),
      component: lazy(() =>
        import('./pages/admin/Structure/New').then(m => ({
          default: m.StructureNew,
        })),
      ),
    },
    {
      name: 'EditCreditStructure',
      path: 'admin/plugin/credit/:rid/edit',
      getTitle: () => $t('编辑学分结构'),
      component: lazy(() =>
        import('./pages/admin/Structure/New').then(m => ({
          default: m.StructureNew,
        })),
      ),
    },
    {
      name: 'ShowCreditStructure',
      path: 'admin/plugin/credit/:rid/show',
      getTitle: () => $t('学分结构详情'),
      component: lazy(() =>
        import('./pages/admin/Structure/Show').then(m => ({
          default: m.ShowStructure,
        })),
      ),
    },
    {
      name: 'ShowCreditDetail',
      path: 'admin/plugin/credit/detail/:rid',
      getTitle: () => $t('学分详情'),
      component: lazy(() =>
        import('./pages/admin/Detail').then(m => ({
          default: m.Detail,
        })),
      ),
    },
    {
      path: 'admin/plugin/credit/settings/roles/new',
      name: 'AdminCreditSettingsRolesNew',
      getTitle: () => $t('创建授权管理角色'),
      component: lazy(() =>
        import('./pages/admin/Settings/NewRole').then(m => ({
          default: m.NewRole,
        })),
      ),
    },
    {
      path: 'admin/plugin/credit/settings/roles/:roleId/edit',
      name: 'AdminCreditSettingsRolesEdit',
      getTitle: () => $t('编辑授权管理角色'),
      component: lazy(() =>
        import('./pages/admin/Settings/NewRole').then(m => ({
          default: m.NewRole,
        })),
      ),
    },
  ]
}
