/**
 * @file 十五中成绩报告插件
 */

import { InitPluginFeature } from '@/plugins/types'

export const initBjszgzbGradesReports: InitPluginFeature = {
  name: 'bj4g-grades-reports',
  init: () => ({
    slots: {
      exportGradesReportItem: () =>
        import('./slot/export-grades-report-item').then(
          m => m.exportGradesReportItem,
        ),
    },
  }),
}
