/**
 * @file Plugin Slots
 */
import { reportToSentry } from '@seiue/sentry'
import { compact, flatten } from '@seiue/util'
import { ComponentType, Suspense, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

import { RouteConfig } from 'packages/route'

import { ComponentSlotTypes, ComponentSlots } from './component-slots'
import { DataSlotOptions, DataSlotTypes } from './data-slots'
import { RouteSlotTypes, RouteSlotNames } from './route-slots'

export type SlotTypes = RouteSlotTypes & ComponentSlotTypes & DataSlotTypes

// map of slot name to list of slot functions
const _map: { [slotName: string]: any[] } = {}

/**
 * 注册 slot
 *
 * @param name - slot 名称
 * @param slot - slot 所需参数
 */
export const registerToSlot = <SlotName extends keyof SlotTypes>(
  name: SlotName,
  slot: SlotTypes[SlotName],
) => {
  _map[name] = _map[name] ?? []
  _map[name].push(slot)
}

/**
 * 获取 route slots
 *
 * @param name - slot 名称
 * @returns 路由定义
 */
export const getRouteSlots = <SlotName extends RouteSlotNames>(
  name: SlotName,
): RouteConfig[] => flatten(_map[name])

/**
 * 获取 component slots
 *
 * @param name - slot 名称
 * @param fallback - component slot 渲染失败时显示的组件
 * @returns slots 的执行结果
 */
export const useComponentSlots = <SlotName extends keyof ComponentSlots>(
  name: SlotName,
  fallback?: ComponentType<FallbackProps>,
): ComponentSlots[SlotName][] =>
  useMemo(
    () =>
      (_map[name] || []).map(C => (props: any) => (
        /*
         * 隔离 Plugin Slot 可能出现的渲染问题
         * 使其不再影响 Slot 所处的页面的整体渲染
         */
        <Suspense fallback={null}>
          <ErrorBoundary
            FallbackComponent={fallback || (() => null)}
            onError={e => {
              console.error(`Seiue Plugin Slot ${name} crash!`)

              reportToSentry(e, {
                ExceptionType: 'SlotCrash',
              })
            }}
          >
            <C {...props} />
          </ErrorBoundary>
        </Suspense>
      )),
    [name, fallback],
  )

/**
 * 获取 Data Slots
 *
 * @param name - slot 名称
 * @param params - slot 所需参数
 * @param options - 配置项
 * @param options.disable - 是否禁用 data slots 的加载
 * @returns slots 的执行结果
 */
export const useDataSlots = <SlotName extends keyof DataSlotOptions>(
  name: SlotName,
  params: DataSlotOptions[SlotName]['props'],
  options?: {
    disable?: boolean
  },
) => {
  const { disable } = options || {}

  const slots = _map[name] as DataSlotTypes[SlotName][] | undefined

  const [asyncSlotResult, setAsyncSlotResult] = useState<
    Awaited<DataSlotOptions[SlotName]['result']>[]
  >([])

  const [loading, setLoading] = useState(!disable)

  const paramsString = JSON.stringify(params || {})

  useEffect(() => {
    setLoading(!disable && !!slots?.length)
    setAsyncSlotResult([])

    if (!disable && slots?.length) {
      Promise.all(
        slots.map(loadSlot =>
          loadSlot()
            .then(slot => slot(params))
            .catch(() => undefined),
        ),
      )
        .then(result => {
          setAsyncSlotResult(compact(result))
        })
        .finally(() => setLoading(false))
    }

    // eslint-disable-next-line
  }, [slots, paramsString, disable])

  return {
    slots: useMemo(() => compact(asyncSlotResult), [asyncSlotResult]),
    loading,
  }
}
