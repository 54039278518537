import { lazy } from 'react'

import { InitPluginFeature } from '@/plugins/types'

export const initTranscriptSetting: InitPluginFeature = {
  name: 'transcript-setting',
  init: () => ({
    slots: {
      AdminGradeTranscriptSetting: lazy(() =>
        import('./slots/Report').then(m => ({
          default: m.Report,
        })),
      ),
      exportGradesReportItem: () =>
        import('./slots/exportGradesReportItem').then(
          m => m.exportGradesReportItem,
        ),
      profileExportGradesReportItem: () =>
        import('./slots/profileExportGradesReportItem').then(
          m => m.profileExportGradesReportItem,
        ),
    },
  }),
}
