/**
 * @file 微信公众号插件入口, 可绑定学校自有公众号, 或调整微信公众号推送行为
 */

import { lazy } from 'react'

import { InitPluginFeature } from '@/plugins/types'

export const initWechatMp: InitPluginFeature = {
  name: 'wechat-mp',
  init: () => ({
    setting: {
      type: 'module',
      component: lazy(() =>
        import('./pages/Settings').then(m => ({ default: m.Settings })),
      ),
    },
  }),
}
