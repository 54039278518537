/**
 * @file 认证 - menus
 */

import { atom } from 'jotai'

import {
  CertificationPluginKind,
  getCertificationPluginKind,
  isElecCertPlugin,
  isOnlineIncentiveCertPlugin,
  isPunishmentCertPlugin,
} from 'packages/feature-utils/certifications'
import {
  findEnabledParentPluginAtom,
  findManageableChildPluginsAtom,
  findUsableChildPluginsAtom,
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuItemUnion } from 'packages/web-layout/types'

export const adminCertificationMenu = atom(get => {
  // 是否为认证插件管理员
  const isCertificationManager = get(
    hasManageableParentPluginAtom(PluginEnum.Certification),
  )

  // 已启用的认证插件列表
  const enabledPlugins = get(
    findManageableChildPluginsAtom(PluginEnum.Certification),
  )

  // 认证积分插件
  const certificationCreditPlugin = get(
    findEnabledParentPluginAtom(PluginEnum.CertificationCredit),
  )

  if (!isCertificationManager && !enabledPlugins.length) {
    return []
  }

  const menus: MenuItemUnion[] = []

  // 注册认证插件菜单
  menus.push(
    ...enabledPlugins.map(enabledPlugin => {
      const basePath = `/admin/certification-plugins/${enabledPlugin.id}`

      const subMenus = [
        {
          label: $t('{name}列表', { name: enabledPlugin.label }),
          path: `${basePath}/certifications`,
        },
        {
          label: $t('数据统计'),
          path: `${basePath}/student-stats`,
        },
      ]

      // 线上虚拟或电子证书才有申请记录
      if (
        isOnlineIncentiveCertPlugin(enabledPlugin) ||
        isElecCertPlugin(enabledPlugin)
      ) {
        subMenus.push({
          label: $t('申请记录'),
          path: `${basePath}/application-logs`,
        })
      }

      return {
        label: enabledPlugin.label,
        path: basePath,
        icon: 'Certification' as const,
        subMenus,
      }
    }),
  )

  // 注册认证积分菜单
  if (certificationCreditPlugin) {
    const basePath = '/admin/certification-credit'

    menus.push({
      label: certificationCreditPlugin.label,
      icon: 'CircleStar' as const,
      path: basePath,
      subMenus: [
        {
          label: $t('学生汇总'),
          path: `${basePath}/students`,
        },
        {
          label: $t('奖品列表'),
          path: `${basePath}/prizes`,
        },
        {
          label: $t('设置'),
          path: `${basePath}/settings`,
        },
      ],
    })
  }

  return [
    {
      prefix: [PluginCategoryEnum.学生成长],
      name: PluginEnum.Certification,
      label: $t('认证管理'),
      sort: 700,
      type: MenuType.AdminApps,
      icon: 'Authentication' as const,
      subMenus: menus,
    },
  ]
})

export const userCertificationMenu = atom(get => {
  const isManager = get(hasManageableParentPluginAtom(PluginEnum.Certification))

  const enabledPlugins = get(
    findUsableChildPluginsAtom(PluginEnum.Certification),
  )

  if (!isManager && !enabledPlugins.length) {
    return []
  }

  return enabledPlugins.map(plugin => {
    const basePath = `/certification-plugins/${plugin.id}`

    const subMenus: MenuItemUnion[] = [
      {
        path: `${basePath}/certifications`,
        label: isPunishmentCertPlugin(plugin)
          ? $t('添加{name}', { name: plugin.label })
          : $t('授予{name}', { name: plugin.label }),
        icon: 'Certification',
      },
    ]

    // 线上虚拟才有申请记录
    if (
      getCertificationPluginKind(plugin) ===
      CertificationPluginKind.OnlineIncentive
    ) {
      subMenus.push({
        path: `${basePath}/application-logs`,
        label: $t('申请记录'),
        icon: 'Questionnaire',
      })
    }

    return {
      type: MenuType.Apps,
      name: PluginEnum.Certification,
      label: plugin.label,
      path: basePath,
      subMenus,
    }
  })
})
