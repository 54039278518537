/**
 * @file 直播任务插件
 */

import { PluginEnum } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@/plugins/types'

import { calendarEvents } from './calendar-events'

export const initLiveTasks: InitPluginFeature = {
  name: PluginEnum.LiveTasks,
  init: () => ({
    calendarEvents,
    slots: {
      taskOptions: () =>
        import('./slots/task-options').then(m => m.taskOptions),
      taskStatColumns: () =>
        import('./slots/task-stat-columns').then(m => m.taskStatColumns),
    },
  }),
}
