/**
 * @file 广州中学新生入学问卷
 */
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { userGzFreshmanMenu, adminGzMenu } from './menus'
import { routes } from './routes'

export const initGzFreshmanQuestionnaire: InitPluginFeature = {
  name: PluginNameEnum.GzFreshmanQuestionnaire,
  init: () => ({
    routes,
    menus: [userGzFreshmanMenu, adminGzMenu],
    slots: {
      submitQuestionnaireModalOverride: () =>
        import('./slots/data-slots').then(
          m => m.submitQuestionnaireModalOverride,
        ),
      userCommonApplicationsEntry: () =>
        import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
  }),
}
