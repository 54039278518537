/**
 * @file routes
 */

import React, { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    path: 'admin/plugin/gst',
    getTitle: () => $t('应用中心路由跳转'),
    component: lazy(() =>
      import('./components/CommonApplicationsEntry').then(mod => ({
        default: mod.CommonApplicationsEntry,
      })),
    ),
  },
  {
    path: 'admin/plugin/gst/template/:id',
    name: 'AdminPluginGstTasks',
    getTitle: () => $t('任务列表'),
    module: [PluginCategoryEnum.通用工具, SchoolPluginsEnum.Gst],
    component: lazy(() =>
      import('./pages/tasks/List').then(mod => ({
        default: mod.Tasks,
      })),
    ),
  },
  {
    path: 'admin/plugin/gst/templates-manage',
    name: 'AdminPluginGstTemplates',
    getTitle: () => $t('模板管理'),
    module: [PluginCategoryEnum.通用工具, SchoolPluginsEnum.Gst],
    component: lazy(() =>
      import('./pages/templates/List').then(m => ({
        default: m.Templates,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.EditGstTemplate',
    getTitle: () => $t('编辑模板'),
    component: React.lazy(() =>
      import('./pages/templates/Edit/index').then(m => ({
        default: m.EditGstTemplate,
      })),
    ),
  },
  {
    name: 'Plugin.EditGstTask',
    getTitle: () => $t('编辑任务-基础设置'),
    component: React.lazy(() =>
      import('./pages/tasks/Edit/index').then(m => ({
        default: m.EditGstTemplate,
      })),
    ),
  },
  {
    name: 'Plugin.ScheduleGstTask',
    getTitle: () => $t('编排任务'),
    component: React.lazy(() =>
      import('./pages/tasks/Schedule/index').then(m => ({
        default: m.ScheduleGstTask,
      })),
    ),
  },
  {
    name: 'Plugin.ChangeGstResource',
    getTitle: () => $t('选择换回人员'),
    component: React.lazy(() =>
      import('./pages/tasks/Schedule/components/ChangeResource').then(m => ({
        default: m.ChangeResource,
      })),
    ),
  },
]
