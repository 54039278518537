/**
 * @file 课程申报待办注册
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.Declaration,
    Render: lazy(() =>
      import('./DeclarationTodoCard').then(m => ({
        default: m.DeclarationTodoRender,
      })),
    ),
  },
]
