/**
 * @file 电子证书
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initElecCertificate: InitPluginFeature = {
  name: PluginNameEnum.ElecCertificate,
  init: () => ({
    setting: {
      type: 'module',
      component: lazy(() =>
        import('./components/PluginSetting').then(m => ({
          default: m.Setting,
        })),
      ),
    },
    slots: {
      certificationModeItem: () =>
        import('./slots/certification-mode-item').then(
          m => m.certificationModeItem,
        ),
    },
  }),
}
