import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  PluginEnum,
  findManageableChildPluginsAtom,
  findEnabledParentPluginAtom,
} from 'packages/feature-utils/plugins'
import { $ct, $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import {
  ChatInstanceTypeEnum,
  PermissionNameEnum,
  PluginCategoryEnum,
  chatApi$listChatInstances,
} from 'packages/sdks-next/chalk'
import {
  ExtraMenuGroup,
  MenuItemUnion,
  MenuType,
} from 'packages/web-layout/types'

/**
 * 约谈后台菜单
 */
export const adminChatMenu = loadable(
  atom<Promise<MenuItemUnion[]>>(async get => {
    if (!get(findEnabledParentPluginAtom(PluginEnum.Chat))) {
      return []
    }

    // 约谈子插件列表
    const manageablePlugins = get(
      findManageableChildPluginsAtom(PluginEnum.Chat),
    )

    const subMenus: MenuItemUnion[] = []

    if (manageablePlugins.length) {
      const { data: chatInstances } = await chatApi$listChatInstances.api({
        type: ChatInstanceTypeEnum.Chat,
        expand: ['settings'],
      })

      subMenus.push(
        ...manageablePlugins.map(plugin => {
          const pluginSubMenus: MenuItemUnion[] = []

          const pluginChatInstance = chatInstances.find(
            chatInstance => chatInstance.schoolPluginId === plugin.id,
          )

          const baseUrl = `/admin/plugin/chats/${pluginChatInstance?.id ?? 0}`

          if (pluginChatInstance?.settings.isRegularMode) {
            pluginSubMenus.push({
              path: `${baseUrl}/open-tasks`,
              label: $t('开放预约任务'),
            })
          }

          pluginSubMenus.push(
            ...[
              {
                path: `${baseUrl}/teacher-sections`,
                label: $t('教师可预约时段'),
              },
              {
                path: `${baseUrl}/list`,
                label: $t('约谈记录'),
              },
              {
                path: `${baseUrl}/statistics`,
                label: $t('约谈统计'),
              },
              {
                path: `${baseUrl}/settings`,
                label: $t('约谈设置'),
              },
            ],
          )

          return {
            label: $ct(plugin.label, 'plugin'),
            icon: 'Interview' as const,
            subMenus: pluginSubMenus,
            path: pluginSubMenus[0].path,
          }
        }),
      )
    }

    subMenus.push({
      label: $t('应用配置'),
      icon: 'Setting',
      path: '/admin/plugin/chats/settings',
      permission: [PermissionNameEnum.CorePluginWrite],
    })

    return [
      {
        prefix: [PluginCategoryEnum.通用工具],
        name: PluginNameEnum.Chat,
        label: $t('约谈管理'),
        icon: 'Interview',
        type: MenuType.AdminApps,
        sort: 800,
        subMenus,
      },
    ]
  }),
)

/**
 * 约谈前台菜单
 */
export const userChatMenu = atom<MenuItemUnion[]>(get => {
  const subMenus: MenuItemUnion[] = [
    {
      path: '/plugin/chat/list',
      label: $t('全部约谈'),
      icon: 'Interview',
    },
  ]

  const manageablePlugins = get(findManageableChildPluginsAtom(PluginEnum.Chat))

  if (manageablePlugins.length) {
    const adminChatMenuAtom = get(adminChatMenu)

    const firstMenuPath =
      adminChatMenuAtom.state === 'hasData'
        ? (
            adminChatMenuAtom.data.at(0) as ExtraMenuGroup | undefined
          )?.subMenus.at(0)?.path
        : null

    if (firstMenuPath) {
      subMenus.push({
        label: $t('前往约谈管理'),
        path: firstMenuPath,
        icon: 'Backstage',
      })
    }
  }

  return [
    {
      name: PluginNameEnum.Chat,
      label: $t('约谈'),
      type: MenuType.Apps,
      path: '/plugin/chat',
      subMenus,
    },
  ]
})
