/**
 * @file 动态菜单
 */

import { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import {
  analysisPermission,
  reportPermission,
  szGradeReportsPermissions,
} from './utils/utils'

export const routes: RouteConfig[] = [
  {
    path: 'user/sz-grades-reports',
    getTitle: () => $t('成绩报告'),
    module: [PluginEnum.SzGradesReports],
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/index').then(m => ({
        default: m.AdminGradeMain,
      })),
    ),
    requirePermissions: szGradeReportsPermissions,
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('在校学生'),
        requirePermissions: reportPermission,
        component: lazy(() =>
          import('@/features/sz-grades-reports/pages/reports/List').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        path: 'archived',
        requirePermissions: reportPermission,
        getTitle: () => $t('已归档学生'),
        component: lazy(() =>
          import('@/features/sz-grades-reports/pages/reports/List').then(m => ({
            default: m.List,
          })),
        ),
      },
    ],
  },
  {
    path: 'user/sz-grades-reports/analysis',
    getTitle: () => $t('成绩分析'),
    module: [PluginEnum.SzGradesReports],
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/analysis').then(m => ({
        default: m.Analysis,
      })),
    ),
    requirePermissions: analysisPermission,
  },
  {
    path: 'user/sz-grades-reports/status',
    getTitle: () => $t('成绩状态'),
    module: [PluginEnum.SzGradesReports],
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/status').then(m => ({
        default: m.Status,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },
  {
    path: 'user/sz-grades-reports/settings',
    getTitle: () => $t('设置'),
    module: [PluginEnum.SzGradesReports],
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/settings').then(m => ({
        default: m.Settings,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },
  {
    path: 'user/sz-grades-reports/student/:rid',
    getTitle: () => $t('学生报告单'),
    name: 'SZUserReport',
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/reports/report').then(m => ({
        default: m.Report,
      })),
    ),

    subRoutes: [
      {
        path: 'exam-report',
        name: 'userExam',
        getTitle: () => $t('考试报告单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/ExamReport'
          ).then(m => ({
            default: m.ExamReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzExamReportRead],
      },
      {
        path: 'semester-report',
        name: 'userSemester',
        getTitle: () => $t('学期报告单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SemesterReport'
          ).then(m => ({
            default: m.SemesterReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzSemesterReportRead],
      },
      {
        path: 'global-academic-report',
        name: 'userGlobal',
        getTitle: () => $t('总学业报告单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SingleReport'
          ).then(m => ({
            default: m.SingleReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzGlobalAcademicReportRead],
      },
      {
        path: 'chuguo-report',
        name: 'userChuguo',
        getTitle: () => $t('出国成绩单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SingleReport'
          ).then(m => ({
            default: m.SingleReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzChuguoReportRead],
      },
      {
        path: 'resit-report',
        name: 'userResit',
        getTitle: () => $t('补考成绩单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/ResitReport'
          ).then(m => ({
            default: m.ResitReport,
          })),
        ),
        requirePermissions: [PermissionNameEnum.SzResitReportRead],
      },
    ],
  },
  {
    path: 'user/sz-grades-reports/settings/roles/new',
    getTitle: () => $t('新增成绩分析角色'),
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/settings/edit-role').then(
        m => ({
          default: m.EditRole,
        }),
      ),
    ),
    // requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },
  {
    path: 'user/sz-grades-reports/settings/roles/:roleId/edit',
    getTitle: () => $t('编辑成绩分析角色'),
    component: lazy(() =>
      import('@/features/sz-grades-reports/pages/settings/edit-role').then(
        m => ({
          default: m.EditRole,
        }),
      ),
    ),
    // requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },
]
