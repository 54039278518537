/**
 * @file 动态菜单
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasUsablePluginAtom,
  manageablePluginsAtom,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuType } from 'packages/web-layout/types'

export const adminGzMenu = atom(get => {
  if (!get(findEnabledPluginsAtom(PluginNameEnum.GzFreshmanQuestionnaire))) {
    return []
  }

  const manageablePlugins = get(manageablePluginsAtom)

  const repairPlugin = manageablePlugins.find(
    // eslint-disable-next-line seiue/missing-formatted-message
    plugin => plugin.label === '广中报修申请',
  )

  const amsPlugin = manageablePlugins.find(
    // eslint-disable-next-line seiue/missing-formatted-message
    plugin => plugin.label === '资产管理',
  )

  const gzzxxsPlugin = manageablePlugins.find(
    // eslint-disable-next-line seiue/missing-formatted-message
    plugin => plugin.label === '新生入学教育系统',
  )

  const menus = []

  if (repairPlugin) {
    menus.push({
      prefix: [PluginCategoryEnum.后勤管理],
      name: 'gzzx-repair',
      label: $t('广中报修申请'),
      shortcutIcon: getPluginIcon(repairPlugin),
      path: '/admin/workflows/14350/list',
      type: MenuType.Admin,
      sort: 840,
    })
  }

  if (amsPlugin) {
    menus.push({
      prefix: [PluginCategoryEnum.后勤管理],
      name: 'asset-management',
      shortcutIcon: getPluginIcon(amsPlugin),
      label: $t('资产管理'),
      path: 'https://ams.seiue.com/material',
      type: MenuType.Admin,
      sort: 850,
    })
  }

  if (gzzxxsPlugin) {
    menus.push({
      prefix: [PluginCategoryEnum.学生成长],
      name: 'gzzx-xs',
      label: $t('新生入学教育系统'),
      shortcutIcon: getPluginIcon(gzzxxsPlugin),
      path: 'http://gzzxxs.seiue.com',
      type: MenuType.Admin,
      sort: 790,
    })
  }

  return menus
})

export const userGzFreshmanMenu = atom(get => {
  if (!get(hasUsablePluginAtom(PluginNameEnum.GzFreshmanQuestionnaire))) {
    return []
  }

  return [
    {
      name: PluginNameEnum.GzFreshmanQuestionnaire,
      label: $t('新生入学问卷'),
      path: '/plugin/gz-freshman-questionnaire/list',
      type: MenuType.Apps,
      subMenus: [
        {
          label: $t('问卷列表'),
          path: '/plugin/gz-freshman-questionnaire/list',
          icon: 'Questionnaire',
        },
      ],
    },
  ]
})
