/**
 * @file 深中-选科（志愿调研）
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { subjectSelectionFormStatSubRoutes } from './slots/subjectSelectionFormStatSubRoutes'
import { subjectSelectionResultStatSubRoutes } from './slots/subjectSelectionResultStatSubRoutes'

export const initSzSubjectSelectionStat: InitPluginFeature = {
  name: PluginNameEnum.SzSubjectSelectionStat,
  init: () => ({
    slots: {
      subjectSelectionFormStatSubRoutes,
      subjectSelectionResultStatSubRoutes,
    },
  }),
}
