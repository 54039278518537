/**
 * @file 学生心理档案-页面路由定义-管理中心
 */

import React from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

import { PLUGIN_NAME } from '@/plugins/features/psychological-file/const'
import { RouteMenus } from '@/router'

import { getPsyProfileSubRoutes } from './profile-subroutes'

// 心理测评
const psychologicalAssessmentRoutes: RouteConfig[] = [
  {
    name: 'PluginPsychologicalFileAdmin',
    path: 'admin/psychological-file',
    getTitle: () => $t('心理档案'),
    module: [PluginCategoryEnum.学生成长, PLUGIN_NAME],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'information',
        name: 'PsychologicalFileInfo',
        getTitle: () => $t('档案信息'),
        component: React.lazy(() =>
          import('../pages/admin/Information').then(m => ({
            default: m.Index,
          })),
        ),
        requirePermissions: [PermissionNameEnum.PsychologicalProfileRead],
        subRoutes: [
          {
            path: 'students',
            getTitle: () => $t('学生列表'),
            component: React.lazy(() =>
              import('../pages/admin/Information/Students').then(m => ({
                default: m.Students,
              })),
            ),
            requirePermissions: [PermissionNameEnum.PsychologicalProfileRead],
          },
          {
            path: 'logs',
            getTitle: () => $t('操作日志'),
            component: React.lazy(() =>
              import('../pages/admin/Information/Logs').then(m => ({
                default: m.Logs,
              })),
            ),
            requirePermissions: [PermissionNameEnum.PsychologicalProfileRead],
          },
          {
            path: 'setting',
            name: 'Setting',
            getTitle: () => $t('设置'),
            component: React.lazy(() =>
              import('../pages/admin/Information/Setting').then(m => ({
                default: m.Settings,
              })),
            ),
            requirePermissions: [
              PermissionNameEnum.PsychologicalProfileProblemSetUpdate,
            ],
          },
        ],
      },
      {
        name: 'AssessmentsManagement',
        path: 'assessments-management',
        getTitle: () => $t('测评管理'),
        component: React.lazy(() =>
          import('../pages/admin/AssessmentsManagement/index').then(m => ({
            default: m.AssessmentsManagement,
          })),
        ),
        requirePermissions: [PermissionNameEnum.PsychologicalEiManageRead],
        subRoutes: [
          {
            name: 'Assessments',
            path: 'assessments',
            getTitle: () => $t('测评计划'),
            component: React.lazy(() =>
              import('../pages/admin/AssessmentsManagement/Assessments').then(
                m => ({
                  default: m.Assessments,
                }),
              ),
            ),
          },
          {
            name: 'ScaleSettings',
            path: 'scale-settings',
            getTitle: () => $t('量表设置'),
            component: React.lazy(() =>
              import('../pages/admin/AssessmentsManagement/Settings').then(
                m => ({
                  default: m.Settings,
                }),
              ),
            ),
          },
        ],
      },
      {
        name: 'ForewarningEvaluations',
        path: 'forewarning-evaluations',
        getTitle: () => $t('预警评估'),
        component: React.lazy(() =>
          import('../pages/admin/ForewarningEvaluation').then(m => ({
            default: m.ForewarningEvaluation,
          })),
        ),
        requirePermissions: [PermissionNameEnum.PsychologicalForewarningRead],
        subRoutes: [
          {
            name: 'Evaluations',
            path: 'evaluations',
            getTitle: () => $t('评估列表'),
            component: React.lazy(() =>
              import('../pages/admin/ForewarningEvaluation/Evaluations').then(
                m => ({
                  default: m.Evaluations,
                }),
              ),
            ),
          },
          {
            name: 'Forewarnings',
            path: 'forewarnings',
            getTitle: () => $t('预警在库'),
            component: React.lazy(() =>
              import('../pages/admin/ForewarningEvaluation/Forewarnings').then(
                m => ({
                  default: m.Forewarnings,
                }),
              ),
            ),
          },
          {
            name: 'ForewarningStatistics',
            path: 'forewarning-statistics',
            getTitle: () => $t('预警统计'),
            component: React.lazy(() =>
              import(
                '../pages/admin/ForewarningEvaluation/ForewarningStatistics'
              ).then(m => ({
                default: m.ForewarningStatistics,
              })),
            ),
          },
        ],
      },
      {
        name: 'CrisisInterventions',
        path: 'crisis-interventions',
        getTitle: () => $t('危机干预'),
        component: React.lazy(() =>
          import('../pages/admin/CrisisIntervention').then(m => ({
            default: m.CrisisIntervention,
          })),
        ),
        requirePermissions: [PermissionNameEnum.PsychologicalEiManageRead],
        subRoutes: [
          {
            name: 'Interventions',
            path: 'interventions',
            getTitle: () => $t('干预记录'),
            component: React.lazy(() =>
              import('../pages/admin/CrisisIntervention/Interventions').then(
                m => ({
                  default: m.Interventions,
                }),
              ),
            ),
          },
          {
            name: 'Settings',
            path: 'settings',
            getTitle: () => $t('设置'),
            component: React.lazy(() =>
              import('../pages/admin/CrisisIntervention/Settings').then(m => ({
                default: m.Settings,
              })),
            ),
          },
        ],
      },
      {
        name: 'ReportManagement',
        path: 'report',
        getTitle: () => $t('问题上报'),
        component: React.lazy(() =>
          import('../pages/admin/report').then(m => ({
            default: m.ReportEntry,
          })),
        ),
        requirePermissions: [PermissionNameEnum.PsychologicalRecordRead],
        subRoutes: [
          {
            name: 'List',
            path: 'list',
            getTitle: () => $t('上报列表'),
            component: React.lazy(() =>
              import('../pages/admin/report/List').then(m => ({
                default: m.List,
              })),
            ),
          },
          {
            name: 'Setting',
            path: 'setting',
            getTitle: () => $t('设置'),
            component: React.lazy(() =>
              import('../pages/admin/report/Setting').then(m => ({
                default: m.Setting,
              })),
            ),
          },
        ],
      },
      {
        name: 'ConsultManagement',
        path: 'consult-management',
        getTitle: () => $t('咨询管理'),
        component: React.lazy(() =>
          import('../pages/admin/ConsultManagement').then(m => ({
            default: m.ConsultManagement,
          })),
        ),
        subRoutes: [
          {
            name: 'Consults',
            path: 'consults',
            getTitle: () => $t('咨询列表'),
            component: React.lazy(() =>
              import('../pages/admin/ConsultManagement/Consults').then(m => ({
                default: m.Consults,
              })),
            ),
          },
          {
            name: 'Settings',
            path: 'setting',
            getTitle: () => $t('设置'),
            component: React.lazy(() =>
              import('../pages/admin/ConsultManagement/Settings').then(m => ({
                default: m.Settings,
              })),
            ),
          },
        ],
      },
      {
        path: 'permissions',
        getTitle: () => $t('权限管理'),
        component: React.lazy(() =>
          import('../pages/admin/Permissions/List').then(m => ({
            default: m.AuthList,
          })),
        ),
        requirePermissions: [PermissionNameEnum.PsychologicalAdminAuthRead],
      },
    ],
  },
  {
    name: 'ScaleSettingsDetails',
    path: 'admin/psychological-file/assessments-management/scale-settings/:id/details',
    getTitle: () => $t('查看量表'),
    component: React.lazy(() =>
      import('../pages/admin/AssessmentsManagement/Settings/Details').then(
        m => ({
          default: m.ScaleSettingsDetails,
        }),
      ),
    ),
  },
  {
    name: 'PluginPsychologicalFileAdminAssessmentDetail',
    path: 'admin/psychological-file/assessments-management/assessments/:id',
    getTitle: () => $t('测评结果详情'),
    component: React.lazy(() =>
      import('../pages/admin/assessments/Detail').then(m => ({
        default: m.Detail,
      })),
    ),
    subRoutes: [
      {
        path: 'result',
        name: 'Result',
        getTitle: () => $t('测评结果'),
        requirePermissions: [
          PermissionNameEnum.PsychologicalAssessmentResultDetailRead,
        ],
        component: React.lazy(() =>
          import('../pages/admin/assessments/Detail/Result').then(m => ({
            default: m.Result,
          })),
        ),
      },
      {
        path: 'analysis',
        name: 'Analysis',
        getTitle: () => $t('量表分析'),
        requirePermissions: [
          PermissionNameEnum.PsychologicalAssessmentResultDetailRead,
        ],
        component: React.lazy(() =>
          import('../pages/admin/assessments/Detail/Analysis').then(m => ({
            default: m.Analysis,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/psychological-file/assessments/:id/analysis/:scaleId/statistics',
    name: 'AdminAssessmentDetailStatistics',
    getTitle: () => $t('结果统计'),
    requirePermissions: [
      PermissionNameEnum.PsychologicalAssessmentResultStatsRead,
    ],
    component: React.lazy(() =>
      import('../pages/admin/assessments/Detail/Statistics').then(m => ({
        default: m.Statistics,
      })),
    ),
  },
]

// 心理档案
const psychologicalInformationRoutes = [
  {
    path: 'admin/psychological-file/information/setting/questions',
    getTitle: () => $t('编辑心理问题类型'),
    name: 'PsychologicalQuestionsEdit',
    component: React.lazy(() =>
      import(
        '../pages/admin/Information/Setting/PsychologicalQuestionsEdit'
      ).then(m => ({
        default: m.PsychologicalQuestionsEdit,
      })),
    ),
  },

  {
    path: 'admin/psychological-file/information/students/:profileId/:studentId/details',
    name: 'AdminStudentPsychologicalProfile',
    getTitle: () => $t('心理档案详情'),
    component: React.lazy(() =>
      import('../pages/admin/Information/StudentDetail/index').then(m => ({
        default: m.Student,
      })),
    ),
    subRoutes: getPsyProfileSubRoutes(),
  },
  {
    path: 'admin/psychological-file/intervention/:studentId/:evaluationId',
    name: 'PsychologicalEvaluationInterventionDetails',
    getTitle: () => $t('评估干预详情'),
    module: [PluginCategoryEnum.学生成长, PLUGIN_NAME],
    component: React.lazy(() =>
      import(
        '../pages/admin/Information/StudentDetail/InterventionDetails'
      ).then(m => ({
        default: m.EvaluationInterventionDetails,
      })),
    ),
  },
]

// 档案权限
const profilePermissionRoutes = [
  {
    path: 'admin/psychological-file/permissions/roles/:roleId/edit',
    name: 'AdminPFRoleEdit',
    getTitle: () => $t('档案职务管理'),
    component: React.lazy(() =>
      import('../pages/admin/Permissions/AdminEdit').then(m => ({
        default: m.AuthAdminEdit,
      })),
    ),
    requirePermissions: [
      PermissionNameEnum.PsychologicalAdminAuthRead,
      PermissionNameEnum.PsychologicalAdminAuthWrite,
      PermissionNameEnum.PsychologicalResponsibilityCreate,
      PermissionNameEnum.PsychologicalResponsibilityUpdate,
      PermissionNameEnum.PsychologicalResponsibilityDelete,
      PermissionNameEnum.PsychologicalPositionCreate,
      PermissionNameEnum.PsychologicalPositionUpdate,
      PermissionNameEnum.PsychologicalPositionDelete,
    ],
  },
  {
    path: 'admin/psychological-file/permissions/roles/new',
    name: 'AdminPFRoleNew',
    getTitle: () => $t('档案权限管理'),
    component: React.lazy(() =>
      import('../pages/admin/Permissions/AdminEdit').then(m => ({
        default: m.AuthAdminEdit,
      })),
    ),
    requirePermissions: [
      PermissionNameEnum.PsychologicalAdminAuthWrite,
      PermissionNameEnum.PsychologicalResponsibilityCreate,
      PermissionNameEnum.PsychologicalResponsibilityUpdate,
      PermissionNameEnum.PsychologicalResponsibilityDelete,
      PermissionNameEnum.PsychologicalPositionCreate,
      PermissionNameEnum.PsychologicalPositionUpdate,
      PermissionNameEnum.PsychologicalPositionDelete,
    ],
  },
]

export const adminRoutes: RouteConfig[] = [
  ...psychologicalInformationRoutes,
  ...profilePermissionRoutes,
  ...psychologicalAssessmentRoutes,
]
