import { atom } from 'jotai'

import {
  PluginEnum,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminTeacherrAttendanceMenu = atom(get => {
  const isManager = get(
    hasManageableParentPluginAtom(PluginEnum.TeacherAttendance),
  )

  if (!isManager) return []

  const baseURL = '/admin/teacher-attendance'

  return [
    {
      prefix: [PluginCategoryEnum.教师发展],
      name: PluginEnum.TeacherAttendance,
      label: $t('教师考勤'),
      sort: 700,
      type: MenuType.AdminApps,
      subMenus: [
        {
          label: $t('考勤统计'),
          icon: 'Statistics' as const,
          path: `${baseURL}`,
        },
        {
          label: $t('设置'),
          icon: 'Setting' as const,
          path: `${baseURL}/settings`,
        },
      ],
    },
  ]
})

export const userTeacherAttendanceMenu = atom(get => {
  if (!get(hasUsablePluginAtom(PluginEnum.TeacherAttendance))) {
    return []
  }

  return [
    {
      type: MenuType.Apps,
      name: PluginEnum.TeacherAttendance,
      label: $t('教师考勤'),
      path: '/teacher-attendance',
      subMenus: [
        {
          label: $t('我的考勤'),
          path: '/teacher-attendance/me-records',
          icon: 'Examination' as const,
        },
      ],
    },
  ]
})
