import { find } from '@seiue/util'

import { aiTeacherSession } from 'packages/feature-utils/ai-teacher'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RoleEnum } from 'packages/sdks-next/chalk'

import { InitPluginFeature } from '@/plugins/types'

import { adminAITeacherMenu, userAITeacherMenu } from './menus'
import { adminRoutes, modalRoutes, userRoutes } from './routes'

export const initAITeacher: InitPluginFeature = {
  name: PluginNameEnum.AITeacher,
  init: plugin => {
    const aiTeacherPlugin = find(
      plugin,
      p => p.pluginName === PluginNameEnum.AITeacher,
    )

    if (aiTeacherPlugin) {
      const token = (aiTeacherPlugin?.settings as { secretToken: string })?.[
        'secretToken'
      ]

      aiTeacherSession.setToken(token ?? '')
    }

    return {
      routes: [...adminRoutes, ...userRoutes],
      modalRoutes,
      menus: [userAITeacherMenu, adminAITeacherMenu],
      setting: {
        type: 'json',
        component: {
          appendSchema: () => [
            {
              name: 'appId',
              getTitle: () => 'App ID',
              type: 'string',
              required: true,
              'x-index': 0,
              'x-component-props': {
                placeholder: $t('请输入 App ID'),
                style: {
                  width: '320px',
                },
              },
            },
            {
              name: 'secretToken',
              getTitle: () => 'Secret token',
              type: 'string',
              required: true,
              'x-index': 1,
              'x-component-props': {
                placeholder: $t('请输入 Secret token'),
                style: {
                  width: '320px',
                },
              },
            },
            {
              name: 'spaceId',
              getTitle: () => 'Space id',
              type: 'string',
              required: true,
              'x-index': 2,
              'x-component-props': {
                placeholder: $t('请输入 Space id'),
                style: {
                  width: '320px',
                },
              },
            },
            {
              name: 'roleId',
              getTitle: () => 'Role id',
              type: 'string',
              required: true,
              'x-index': 3,
              'x-component-props': {
                placeholder: $t('请输入 Role id'),
                style: {
                  width: '320px',
                },
              },
            },
          ],
          mapping: {
            'settings.appId': 'appId',
            'settings.secretToken': 'secretToken',
            'settings.spaceId': 'spaceId',
            'settings.roleId': 'roleId',
          },
        },
        config: {
          enabledScopes: [RoleEnum.Teacher],
        },
        beforeChecked: async p => {
          const schoolPluginSettings = p?.schoolPlugin?.settings
          let option = { checked: true, openSetting: false }
          // 未设置范围时 settings 为一个空数组，或者 undefined
          if (
            !schoolPluginSettings ||
            (Array.isArray(schoolPluginSettings) &&
              schoolPluginSettings.length === 0)
          ) {
            // 如果首次启用，范围设置为空，直接进入设置页面，否则只启用
            option = { checked: true, openSetting: true }
          }

          return option
        },
      },
      slots: {
        ClassGroupMemberRight: () =>
          import('./slots').then(m => m.ClassGroupMemberRight),
      },
    }
  },
}
