import { reportToEngineer } from '@seiue/sentry'
import { compact } from '@seiue/util'

import { EnabledSchoolPlugin, ExpandPlugin } from 'packages/sdks-next/chalk'
import { MenuItemUnion, isExtraMenuGroup } from 'packages/web-layout/types'

export const BaseMenuKey = 'menu'

const SPLIT_LINE = '__'

/**
 * 创建菜单的 key
 *
 * @param menu - 菜单项
 * @param parent - 菜单项的父菜单项
 * @returns key
 */
export const createKeyByMenu = (
  menu: MenuItemUnion,
  parent?: MenuItemUnion,
) => {
  if (parent && !parent.name) {
    reportToEngineer(
      // eslint-disable-next-line
      new Error(`菜单项${menu.label}的父菜单项没有 name 字段，请确认')`),
    )

    return ''
  }

  if (!menu.name && !menu.path) {
    reportToEngineer(
      new Error(
        // eslint-disable-next-line
        `菜单项${menu.label}没有 name 和 path 字段，请确认至少要有其中一个`,
      ),
    )

    return ''
  }

  /**
   * key 取「父菜单项的 name」 和 「菜单的 name or path」组合
   * 虽然在类型定义上不稳定，这是两个最可能存在的字段
   *
   * 这里同时预设了，菜单的深度不会超过 2 级
   */
  return compact([BaseMenuKey, parent?.name, menu.name || menu.path]).join(
    SPLIT_LINE,
  )
}

/**
 * 根据 key 查出对应的菜单项，是上面方法的逆向操作
 *
 * @param key - 菜单项的 key
 * @param menus - 菜单项列表
 * @returns 菜单项
 */
export const getMenuByKey = (key: string, menus: MenuItemUnion[]) => {
  if (!key.startsWith(BaseMenuKey)) return null

  const menuKeys = key
    .replace(`${BaseMenuKey}${SPLIT_LINE}`, '')
    .split(SPLIT_LINE)

  const parentKey = menuKeys.length === 2 ? menuKeys[0] : null
  const menuKey = menuKeys.length === 2 ? menuKeys[1] : menuKeys[0]

  let targetMenu: MenuItemUnion | null = null

  if (parentKey) {
    targetMenu = menus.find(menu => menu.name === parentKey) || null

    if (targetMenu && isExtraMenuGroup(targetMenu)) {
      targetMenu =
        targetMenu.subMenus.find(
          menu => menu.name === menuKey || menu.path === menuKey,
        ) || null
    }
  } else {
    targetMenu =
      menus.find(menu => menu.name === menuKey || menu.path === menuKey) || null
  }

  return targetMenu
}

export const BasePluginKey = 'plugin'

/**
 * 创建插件的 key
 *
 * @param pluginId - 插件 id
 * @returns key
 */
export const createKeyByPlugin = (pluginId: number) => {
  return `${BasePluginKey}${SPLIT_LINE}${pluginId}`
}

/**
 * 根据 key 查出对应的插件，是上面方法的逆向操作
 *
 * @param key - 插件的 key
 * @param plugins - 插件列表
 * @returns 插件
 */
export const getPluginByKey = (
  key: string,
  plugins: (EnabledSchoolPlugin &
    Required<{
      plugin: ExpandPlugin
    }>)[],
) => {
  if (!key.startsWith(BasePluginKey)) return null

  const pluginId = key.replace(`${BasePluginKey}${SPLIT_LINE}`, '')

  const targetPlugin = plugins.find(plugin => plugin.id === Number(pluginId))

  return targetPlugin || null
}
