/**
 * @file 注册审批渲染器
 */
import React from 'react'

import { ChalkRegisterWorkflowRenderType } from '@/features/workflows/register'

export const workflowRenders: ChalkRegisterWorkflowRenderType = {
  bizType: 'approval',
  render: {
    FlowCard: React.lazy(() =>
      import('./FlowCard').then(m => ({ default: m.ApprovalFlowCard })),
    ),
    NewFlowModal: React.lazy(() =>
      import('./NewFlowModal').then(m => ({ default: m.NewApprovalFlowModal })),
    ),
    ShowFlowModal: React.lazy(() =>
      import('./ShowFlowModal').then(m => ({
        default: m.ShowApprovalFlowModal,
      })),
    ),
    WorkflowAdminTable: React.lazy(() =>
      import('./WorkflowAdminTable').then(m => ({
        default: m.ApprovalAdminTable,
      })),
    ),
    EditFlowModal: React.lazy(() =>
      import('./EditFlowModal').then(m => ({
        default: m.EditApprovalFlowModal,
      })),
    ),
  },
}
