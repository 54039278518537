/**
 * @file 人事管理 - 路由
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

const teacherRoutes = [
  {
    path: 'admin/personnel-profile/teachers',
    name: 'AdminPersonnelProfileTeachers',
    getTitle: () => $t('信息管理'),
    module: [PluginCategoryEnum.教师发展, PluginNameEnum.PersonnelProfile],
    component: lazy(() =>
      import('./pages/teachers/List').then(m => ({ default: m.TeacherList })),
    ),
    subRoutes: [
      {
        path: 'normal',
        name: 'Normal',
        getTitle: () => $t('在校教师'),
        component: lazy(() =>
          import('./pages/teachers/List/Normal').then(m => ({
            default: m.NormalList,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档教师'),
        component: lazy(() =>
          import('./pages/teachers/List/Archived').then(m => ({
            default: m.ArchivedList,
          })),
        ),
      },
      {
        path: 'record',
        getTitle: () => $t('操作日志'),
        component: lazy(() =>
          import('./pages/teachers/List/Record').then(m => ({
            default: m.RecordList,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/personnel-profile/teachers/fields',
    getTitle: () => $t('字段管理'),
    component: lazy(() =>
      import('./pages/teachers/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
  },
  {
    path: 'admin/personnel-profile/teachers/batch-edit',
    getTitle: () => $t('批量编辑教师'),
    component: lazy(() =>
      import('./pages/teachers/BatchEdit').then(m => ({
        default: m.TeacherBatchEdit,
      })),
    ),
  },
  {
    path: 'admin/personnel-profile/teachers/:teacherId/view',
    getTitle: () => $t('教师信息'),
    name: 'AdminPersonnelProfileForTeacherView',
    component: lazy(() =>
      import('./pages/teachers/View').then(m => ({
        default: m.TeacherView,
      })),
    ),
  },
  {
    path: 'admin/personnel-profile/teachers/:teacherId/edit',
    getTitle: () => $t('编辑教师'),
    component: lazy(() =>
      import('./pages/teachers/Edit').then(m => ({
        default: m.TeacherEdit,
      })),
    ),
  },
]

export const routes = [...teacherRoutes]
