/**
 * @file 【用户】强制填写问卷弹窗
 */
import { useOnUpdate } from '@seiue/util'
import React, { lazy, Suspense, useEffect, useState } from 'react'

import { useCounters, CounterSubjectEnum } from 'packages/features/counter'
import { useCurrentModalName } from 'packages/route'
import { questionnaireApi$loadMeQuestionnaire } from 'packages/sdks-next/form'

import { RootInterrupt } from '@/uikit'

const QuestionnairesEntryModal = lazy(() =>
  import('./EntryModal').then(m => ({ default: m.QuestionnairesEntryModal })),
)

/**
 * 强制填写问卷弹窗
 *
 * @returns 强制填写问卷弹窗
 */
export const QuestionnairesForceModal: React.FC = () => {
  const currentModalName = useCurrentModalName()

  const { counterSubjectIds, skip } = useCounters(
    CounterSubjectEnum.ForcedQuestionnaires,
  )

  const [questionnairenIds, setIds] = useState<string[]>([])

  const goNext = () => {
    setIds(prevIds => {
      const nextIds = [...prevIds]

      nextIds.shift()

      return nextIds
    })
  }

  useOnUpdate(() => {
    setIds(counterSubjectIds)
  }, [counterSubjectIds.join(',')])

  const canShow = RootInterrupt.useCanShow(
    'questionnairesForce',
    !questionnairenIds.length,
  )

  const questionnairenId = Number(questionnairenIds[0])

  const { data: questionnaire, loading: questionnaireLoading } =
    questionnaireApi$loadMeQuestionnaire.useApi(
      {
        id: questionnairenId,
        query: {
          expand: ['isFilled'],
        },
      },
      { disable: Number.isNaN(questionnairenId) },
    )

  useEffect(() => {
    if (questionnaire?.isFilled && questionnaire.id === questionnairenId) {
      skip(CounterSubjectEnum.ForcedQuestionnaires, `${questionnairenId}`)
      goNext()
    }
  }, [questionnaire?.id, questionnaire?.isFilled, questionnairenId, skip])

  if (
    !canShow ||
    !questionnairenIds.length ||
    currentModalName === 'SubmitQuestionnaireModal' ||
    questionnaireLoading
  ) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <QuestionnairesEntryModal
        questionnairenId={Number(questionnairenIds[0])}
        reloadForcedQuestionnaires={goNext}
      />
    </Suspense>
  )
}
