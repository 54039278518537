/**
 * @file yuque
 */

import { $t } from 'packages/locale'

import { InitPluginFeature } from '@/plugins/types'

export const initYuque: InitPluginFeature = {
  name: 'yuque',
  init: () => ({
    setting: {
      type: 'json',
      component: {
        appendSchema: () => [
          {
            name: 'url',
            getTitle: () => $t('语雀网址'),
            type: 'string',
            required: true,
            'x-component-props': {
              placeholder: 'http://xxx.yuque.com',
              style: {
                width: '320px',
              },
            },
          },
          {
            name: 'admins',
            visible: false,
          },
        ],
        mapping: {
          'entryUrls.web': 'url',
          'entryUrls.mobile': 'url',
        },
      },
    },
  }),
}
