/**
 * @file ClassIn 菜单
 */

import { atom } from 'jotai'

import {
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const userOpenClassMenu = atom(get => {
  if (!get(hasUsablePluginAtom(PluginEnum.OpenCourse))) {
    return []
  }

  return [
    {
      label: $t('公开课'),
      path: '/plugin/class-in/open-classes',
      name: SchoolPluginsEnum.OpenCourse,
      type: MenuType.Apps,
      icon: 'OnlineClass' as const,
      subMenus: [
        {
          label: $t('ClassIn 开放课'),
          path: '/plugin/class-in/open-classes',
          icon: 'OnlineClass' as const,
        },
      ],
    },
  ]
})

export const classinAdminMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.ClassIn))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: PluginEnum.ClassIn,
      label: $t('ClassIn 管理'),
      type: MenuType.Admin,
      icon: 'OnlineClass' as const,
      shortcutIcon: 'OnlineClassSolid+17A0DE',
      sort: 705,
      path: '/admin/plugin/class-in',
      subMenus: [
        {
          label: $t('本校线上课'),
          path: '/admin/plugin/class-in/online-classes',
          icon: 'OnlineClass' as const,
        },
        {
          label: $t('跨校共享课'),
          path: '/admin/plugin/class-in/shared-classes',
          icon: 'OnlineClass' as const,
        },
        {
          label: $t('操作日志'),
          path: '/admin/plugin/class-in/online-class-logs',
          icon: 'Log' as const,
        },
        {
          label: $t('设置'),
          path: '/admin/plugin/class-in/settings',
          icon: 'Setting' as const,
        },
        {
          label: $t('前往 ClassIn 后台'),
          path: 'https://www.eeo.cn/cn/login',
          icon: 'ClassInBgSystem' as const,
        },
      ],
    },
  ]
})
