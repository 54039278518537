import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuProvider } from 'packages/web-layout/menu-utils'

export const adminSmsMenu: MenuProvider = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.SmsPlatform))) {
    return []
  }

  const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.SmsPlatform))?.[0]
  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.通用工具],
      name: PluginEnum.SmsPlatform,
      label: $t('短信管理'),
      sort: 700,
      type: MenuType.AdminApps,
      path: '/admin/plugin/sms-platform',
      icon: 'ListCheckSolid' as const,
      shortcutIcon,
      subMenus: [
        {
          label: $t('任务列表'),
          path: '/admin/plugin/sms-platform/list',
          icon: 'ListCheckSolid' as const,
        },
        {
          label: $t('设置'),
          path: '/admin/plugin/sms-platform/settings',
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})
