/**
 * @file 宿舍 routes
 */

import React, { lazy } from 'react'

import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

const dormRoutes: RouteConfig[] = [
  {
    name: 'AdminDorms',
    path: 'admin/dorms',
    requirePermissions: [PermissionNameEnum.CoreDormRead],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    requireModules: [SchoolModulesEnum.Dorm],
    module: [PluginCategoryEnum.德育管理, SchoolModulesEnum.Dorm],
    getTitle: () => $t('宿舍管理'),
    component: lazy(() =>
      import('../pages/admin/Dorms/List').then(m => ({
        default: m.DormList,
      })),
    ),
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('宿舍列表'),
        component: lazy(() =>
          import('../pages/admin/Dorms/List/Normal').then(m => ({
            default: m.NormalList,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档宿舍'),
        component: lazy(() =>
          import('../pages/admin/Dorms/List/Archived').then(m => ({
            default: m.ArchivedList,
          })),
        ),
      },
      {
        path: 'member',
        getTitle: () => $t('宿生列表'),
        component: lazy(() =>
          import('../pages/admin/Dorms/List/Member').then(m => ({
            default: m.MemberList,
          })),
        ),
      },
      {
        path: 'berths',
        getTitle: () => $t('宿位管理'),
        component: lazy(() =>
          import('../pages/admin/Dorms/List/Berths/index').then(m => ({
            default: m.BerthsManagement,
          })),
        ),
      },
      {
        path: 'logs',
        getTitle: () => $t('宿舍操作日志'),
        component: lazy(() =>
          import('../pages/admin/Dorms/List/LogsList').then(m => ({
            default: m.AdminDormsLogs,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/dorms/fields',
    getTitle: () => $t('宿舍字段管理'),
    requireModules: [SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Dorms/FieldManagement').then(m => ({
        default: m.FieldManagement,
      })),
    ),
    requirePermissions: [PermissionNameEnum.CoreDormWrite],
    requireManageablePlugins: [PluginNameEnum.Dorm],
  },
  /*
   * {
   *   path: 'admin/dorms/list/dorm/batch-edit',
   *   getTitle: () => $t('批量操作'),
   *   component: lazy(() =>
   *     import('../pages/Management/Dorms/BatchEdit').then(m => ({
   *       default: m.BatchEdit,
   *     })),
   *   ),
   * },
   */
  {
    path: 'admin/dorms/new',
    getTitle: () => $t('新增宿舍'),
    requireModules: [SchoolModulesEnum.Dorm],
    requirePermissions: [PermissionNameEnum.CoreDormWrite],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Dorms/New').then(m => ({
        default: m.DormNew,
      })),
    ),
  },
  {
    path: 'admin/dorms/batch-edit',
    getTitle: () => $t('批量编辑宿舍'),
    requireModules: [SchoolModulesEnum.Dorm],
    requirePermissions: [PermissionNameEnum.CoreDormWrite],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Dorms/BatchEdit').then(m => ({
        default: m.DormBatchEdit,
      })),
    ),
  },
  {
    path: 'admin/dorms/:dormId/view',
    name: 'AdminDormsView',
    getTitle: () => $t('宿舍'),
    requireModules: [SchoolModulesEnum.Dorm],
    requirePermissions: [PermissionNameEnum.CoreDormRead],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Dorms/View').then(m => ({ default: m.Index })),
    ),
    subRoutes: [
      {
        path: 'detail',
        getTitle: () => $t('查看宿舍详情'),
        component: lazy(() =>
          import('../pages/admin/Dorms/View/View').then(m => ({
            default: m.DormView,
          })),
        ),
      },
      {
        path: 'members',
        getTitle: () => $t('人员管理'),
        component: lazy(() =>
          import('../pages/admin/Dorms/View/DormMembers').then(m => ({
            default: m.DormMembers,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/dorms/:dormId/edit',
    getTitle: () => $t('编辑宿舍'),
    requireModules: [SchoolModulesEnum.Dorm],
    requirePermissions: [PermissionNameEnum.CoreDormWrite],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Dorms/Edit').then(m => ({
        default: m.DormEdit,
      })),
    ),
  },
]

const dormAssessmentRoutes: RouteConfig[] = [
  {
    path: 'admin/dorms/assessments',
    requirePermissions: [PermissionNameEnum.CoreDormRead],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    getTitle: () => $t('评价管理'),
    requireModules: [SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Assessments/List').then(m => ({
        default: m.Index,
      })),
    ),
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('评价信息'),
        component: lazy(() =>
          import('../pages/admin/Assessments/List/Normal').then(m => ({
            default: m.DormAssessmentNormalList,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('归档评价'),
        component: lazy(() =>
          import('../pages/admin/Assessments/List/Archived').then(m => ({
            default: m.DormAssessmentArchivedList,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/dorms/assessments/new',
    requirePermissions: [PermissionNameEnum.CoreDormWrite],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    getTitle: () => $t('新建宿舍评价'),
    requireModules: [SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Assessments/New').then(m => ({
        default: m.DormAssessmentNew,
      })),
    ),
  },
  {
    path: 'admin/dorms/assessments/:id/edit',
    requirePermissions: [PermissionNameEnum.CoreDormWrite],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    getTitle: () => $t('编辑宿舍评价'),
    requireModules: [SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Assessments/Edit').then(m => ({
        default: m.DormAssessmentEdit,
      })),
    ),
  },
  {
    path: 'admin/dorms/assessments/batch-edit',
    requirePermissions: [PermissionNameEnum.CoreDormWrite],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    requireModules: [SchoolModulesEnum.Dorm],
    getTitle: () => $t('批量编辑宿舍评价'),
    component: lazy(() =>
      import('../pages/admin/Assessments/BatchEdit').then(m => ({
        default: m.DormAssessmentBatchEdit,
      })),
    ),
  },
  {
    path: 'admin/dorms/assessments/:id/show',
    getTitle: () => $t('宿舍评价'),
    requireModules: [SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Assessments/Show').then(m => ({
        default: m.DormAssessmentShow,
      })),
    ),
  },
  {
    path: 'admin/dorms/assessments/:id/assessment',
    name: 'AdminDormAssessmentDetail',
    getTitle: () => $t('宿舍评价'),
    requireModules: [SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/Assessment').then(m => ({
        default: m.DormAssessment,
      })),
    ),
    subRoutes: [
      {
        path: 'overview',
        getTitle: () => $t('汇总'),
        component: lazy(() =>
          import('../pages/Assessment/Overview').then(m => ({
            default: m.Overview,
          })),
        ),
      },
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: lazy(() =>
          import('../pages/Assessment/Entry').then(m => ({
            default: m.Entry,
          })),
        ),
      },
      {
        path: 'structure',
        name: 'Structure',
        getTitle: () => $t('结构'),
        component: lazy(() =>
          import('../pages/Assessment/Structure').then(m => ({
            default: m.Structure,
          })),
        ),
      },
    ],
  },
]

const dormAttendancesRoutes: RouteConfig[] = [
  {
    path: 'admin/attendances/dorms',
    getTitle: () => $t('考勤管理'),
    module: [PluginCategoryEnum.德育管理, SchoolModulesEnum.Dorm],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    requirePermissions: [
      PermissionNameEnum.CoreDormRead,
      PermissionNameEnum.CoreDormWrite,
    ],
    component: lazy(() =>
      import('../pages/admin/Attendance').then(m => ({
        default: m.AdminAttendanceIndex,
      })),
    ),
    subRoutes: [
      {
        path: 'stats/list',
        getTitle: () => $t('宿舍考勤统计'),
        component: lazy(() =>
          import('../pages/admin/Attendance/DormListStats').then(m => ({
            default: m.DormListStats,
          })),
        ),
      },
      {
        path: 'stats/students',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import(
            '@/features/attendances/pages/Management/StudentListStats'
          ).then(m => ({
            default: m.StudentListStats,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('考勤设置'),
        component: lazy(() =>
          import('@/features/attendances/pages/Management/Settings').then(
            m => ({
              default: m.Setting,
            }),
          ),
        ),
      },
    ],
  },
  {
    path: 'admin/attendances/dorms/:dormId/stats',
    name: 'AdminDormAttendance',
    getTitle: () => $t('宿舍详情'),
    module: [PluginCategoryEnum.德育管理, SchoolModulesEnum.Dorm],
    component: lazy(() =>
      import('../pages/admin/Attendance/DormStats').then(m => ({
        default: m.DormStats,
      })),
    ),
    subRoutes: [
      {
        path: 'times',
        getTitle: () => $t('考勤详情'),
        component: lazy(() =>
          import('../pages/admin/Attendance/DormTimesStats').then(m => ({
            default: m.DormTimesStats,
          })),
        ),
      },
      {
        path: 'students',
        getTitle: () => $t('学生考勤统计'),
        component: lazy(() =>
          import(
            '@/features/attendances/pages/Management/BizStats/StudentsStats'
          ).then(m => ({
            default: m.StudentsStats,
          })),
        ),
      },
    ],
  },
]

const dormStatisticsRoutes = [
  {
    path: 'admin/dorms/statistics',
    getTitle: () => $t('统计分析'),
    requireModules: [SchoolModulesEnum.Dorm],
    requireManageablePlugins: [PluginNameEnum.Dorm],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'data',
        getTitle: () => $t('数据统计'),
        component: lazy(() =>
          import('../pages/admin/Statistics/List').then(m => ({
            default: m.Index,
          })),
        ),
        subRoutes: [
          {
            path: 'member',
            getTitle: () => $t('宿生统计'),
            component: lazy(() =>
              import('../pages/admin/Statistics/List/Member').then(m => ({
                default: m.Member,
              })),
            ),
          },
          {
            path: 'assessmentRecord',
            getTitle: () => $t('评价记录'),
            component: lazy(() =>
              import('../pages/admin/Statistics/List/AssessmentRecord').then(
                m => ({
                  default: m.AssessmentRecord,
                }),
              ),
            ),
          },
        ],
      },
      {
        path: 'chart',
        getTitle: () => $t('图表分析'),
        component: lazy(() =>
          import('../pages/admin/Statistics/List/Chart/index').then(m => ({
            default: m.Chart,
          })),
        ),
        subRoutes: [
          {
            name: 'AnalysisByGrade',
            path: 'analysis-by-grade',
            getTitle: () => $t('按年级分析'),
            component: lazy(() =>
              import(
                '../pages/admin/Statistics/List/Chart/AnalysisByGrade'
              ).then(m => ({
                default: m.AnalysisByGrade,
              })),
            ),
          },
          {
            name: 'AnalysisByDorm',
            path: 'analysis-by-dorm',
            getTitle: () => $t('按宿舍分析'),
            component: lazy(() =>
              import(
                '../pages/admin/Statistics/List/Chart/AnalysisByDorm'
              ).then(m => ({
                default: m.AnalysisByDorm,
              })),
            ),
          },
          {
            name: 'AnalysisByItem',
            path: 'analysis-by-item',
            getTitle: () => $t('按评价类别分析'),
            component: lazy(() =>
              import(
                '../pages/admin/Statistics/List/Chart/AnalysisByItem'
              ).then(m => ({
                default: m.AnalysisByItem,
              })),
            ),
          },
          {
            name: 'MemberCountAnalysis',
            path: 'member-count-analysis',
            getTitle: () => $t('宿舍人数分析'),
            component: lazy(() =>
              import('../pages/admin/Statistics/List/Chart/MemberCount').then(
                m => ({
                  default: m.MemberCount,
                }),
              ),
            ),
          },
          {
            name: 'DormTitleSta',
            path: 'dorm-title-sta',
            getTitle: () => $t('宿舍称号统计'),
            component: lazy(() =>
              import('../pages/admin/Statistics/List/Chart/DormTitleSta').then(
                m => ({
                  default: m.DormTitleSta,
                }),
              ),
            ),
            requireSchools: [194, 145],
          },
        ],
      },
    ],
  },
]

export const adminRoutes: RouteConfig[] = [
  ...dormRoutes,
  ...dormAssessmentRoutes,
  ...dormAttendancesRoutes,
  ...dormStatisticsRoutes,
  {
    name: 'AdminDormSettings',
    path: 'admin/dorms/settings',
    getTitle: () => $t('设置'),
    component: lazy(() =>
      import('../pages/admin/Dorms/List/Settings').then(m => ({
        default: m.Settings,
      })),
    ),
  },
]
