/**
 * @file 认证待办注册入口
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.CertificationApprove,
    Render: lazy(() =>
      import('./CertificationApproveTodo').then(m => ({
        default: m.CertificationApproveTodo,
      })),
    ),
  },
]
