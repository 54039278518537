/**
 * @file 动态菜单
 */

import { atom } from 'jotai'

import {
  PluginEnum,
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
} from 'packages/feature-utils/plugins'
import { useHasCurrentPermission } from 'packages/features/roles'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

export const adminGzArchiveMenu = atom(get => {
  const isPluginManager = get(
    hasManageableParentPluginAtom(PluginEnum.GzArchive),
  )

  const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.GzArchive))?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.学生成长],
      name: PluginEnum.GzArchive,
      sort: 20,
      label: $t('学生综合档案'),
      permission: (func: typeof useHasCurrentPermission) => {
        return (
          func({
            permission: PermissionNameEnum.CoreGzArchiveRead,
            isManager: true,
          }) || isPluginManager
        )
      },
      icon: 'User' as const,
      shortcutIcon,
      type: MenuType.AdminApps,
      subMenus: [
        {
          label: $t('学生列表'),
          path: '/admin/plugin/gz-archive/students',
          icon: 'User' as const,
        },
      ],
    },
    {
      prefix: [PluginCategoryEnum.教师发展],
      name: PluginEnum.GzArchive,
      sort: 20,
      label: $t('教师综合档案'),
      permission: (func: typeof useHasCurrentPermission) => {
        return (
          func({
            permission: PermissionNameEnum.CoreTeacherArchiveRead,
            isManager: true,
          }) || isPluginManager
        )
      },
      icon: 'User' as const,
      shortcutIcon,
      type: MenuType.AdminApps,
      subMenus: [
        {
          label: $t('教师综合档案'),
          path: '/admin/plugin/gz-archive/teachers',
          icon: 'User' as const,
        },
      ],
    },
  ]
})
