/**
 * @file 高级个性化设置
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { schoolConfigSubRoutes } from './slots'

export const initPersonalization: InitPluginFeature = {
  name: PluginNameEnum.Personalization,
  init: () => {
    return {
      slots: {
        schoolConfigSubRoutes,
      },
    }
  },
}
