/**
 * @file 教师档案 - 档案 - 评价 sub routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const teacherProfilesSubRoutes: RouteConfig[] = [
  {
    path: 'assessment',
    getTitle: () => $t('教师考核'),
    component: lazy(() =>
      import('../pages/teacher/Profile').then(m => ({
        default: m.TeacherAssessmentGradeProfile,
      })),
    ),
  },
]
