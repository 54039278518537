/**
 * @file 活动 menu
 */
import { find } from '@seiue/util'
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  findEnabledPluginsAtom,
  findManageableChildPluginsAtom,
  findUsableOrManageableChildPluginsAtom,
  getPluginIcon,
  getPluginLabel,
  hasEnabledPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  EventInstanceType,
  PluginCategoryEnum,
  eventApi$listInstances,
} from 'packages/sdks-next/chalk'
import { MenuItemUnion } from 'packages/web-layout/types'

export const adminEventMenu = loadable(
  atom(async get => {
    if (!get(hasEnabledPluginAtom(PluginEnum.Event))) {
      return []
    }

    const eventPlugins = get(findManageableChildPluginsAtom(PluginEnum.Event))
    const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.Event))[0]
    const shortcutIcon = enabledPlugin
      ? getPluginIcon(enabledPlugin)
      : 'Other+477cff'

    const { data: instances } = await eventApi$listInstances.api()

    const subMenus = eventPlugins.map(plugin => {
      // 产品定义：打卡活动不显示活动日历
      const isPunch = find(
        instances,
        instance =>
          !!(
            instance.type === EventInstanceType.Punch &&
            instance.id === plugin.bizId
          ),
      )

      return {
        path: `/admin/plugin/event/${plugin.bizId}`,
        label: getPluginLabel(plugin),
        icon: 'Activity' as const,
        subMenus: [
          {
            label: $t('活动列表'),
            path: `/admin/plugin/event/${plugin.bizId}/list/active`,
          },
          {
            label: $t('申报列表'),
            path: `/admin/plugin/event/${plugin.bizId}/application`,
          },
          {
            label: $t('成员统计'),
            path: `/admin/plugin/event/${plugin.bizId}/member-statistics`,
          },
          ...(isPunch
            ? []
            : [
                {
                  label: $t('活动日历'),
                  path: `/admin/plugin/event/${plugin.bizId}/events-calendar`,
                },
              ]),
          {
            label: $t('设置'),
            path: `/admin/plugin/event/${plugin.bizId}/settings`,
          },
        ],
      }
    })

    return [
      {
        prefix: [PluginCategoryEnum.学生成长],
        name: SchoolPluginsEnum.Event,
        label: $t('活动管理'),
        icon: 'Activity' as const,
        shortcutIcon,
        sort: 700,
        subMenus,
      },
    ]
  }),
)

export const userEventMenu = atom(get => {
  if (!get(hasEnabledPluginAtom(PluginEnum.Event))) {
    return []
  }

  const eventPlugins = get(
    findUsableOrManageableChildPluginsAtom(PluginEnum.Event),
  )

  return eventPlugins.map(plugin => {
    const subMenus: MenuItemUnion[] = [
      {
        label: $t('活动列表'),
        path: `/plugin/event/${plugin.bizId}/list`,
        icon: 'Activity' as const,
      },
      {
        label: $t('我报名的'),
        path: `/plugin/event/${plugin.bizId}/Applied`,
        icon: 'Examination' as const,
      },
      {
        label: $t('我发起的'),
        path: `/plugin/event/${plugin.bizId}/initiate`,
        icon: 'Activity' as const,
      },
    ]

    if (plugin.isAdmin) {
      subMenus.push({
        label: $t('前往活动管理'),
        path: `/admin/plugin/event/${plugin.bizId}/list/active`,
        icon: 'Backstage' as const,
      })
    }

    return {
      type: MenuType.Apps,
      name: 'event',
      label: getPluginLabel(plugin),
      path: `/plugin/event/${plugin.bizId}`,
      subMenus,
    }
  })
})
