/**
 * @file 后台 routes
 */

import React from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/chats/settings',
    getTitle: () => $t('约谈应用配置'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.Chat],
    component: React.lazy(() =>
      import('../pages/admin/Settings').then(m => ({
        default: m.Settings,
      })),
    ),
  },
  {
    path: 'admin/plugin/chats/:instanceId',
    getTitle: () => $t('约谈管理'),
    name: 'Plugin.AdminChats',
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.Chat],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'teacher-sections',
        name: 'TeacherSections',
        getTitle: () => $t('教师可预约时段'),
        component: React.lazy(() =>
          import('../pages/admin/TeacherSections').then(m => ({
            default: m.TeacherSections,
          })),
        ),
      },
      {
        path: 'open-tasks',
        name: 'OpenTasks',
        getTitle: () => $t('开放预约任务'),
        component: React.lazy(() =>
          import('../pages/admin/OpenTaskList').then(m => ({
            default: m.OpenTaskList,
          })),
        ),
      },
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('约谈记录'),
        component: React.lazy(() =>
          import('../pages/admin/List').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        path: 'statistics',
        name: 'Statistics',
        getTitle: () => $t('约谈统计'),
        component: React.lazy(() =>
          import('../pages/admin/Statistics').then(m => ({
            default: m.AdminChatStatistics,
          })),
        ),
      },
      {
        path: 'settings',
        name: 'Settings',
        getTitle: () => $t('约谈设置'),
        component: React.lazy(() =>
          import('../pages/admin/ChatSettings').then(m => ({
            default: m.ChatSettings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/chats/:instanceId/statistics/reflections/:rid/:name',
    name: 'Plugin.AdminChats.ReflectionStatistic',
    getTitle: () => $t('约谈情况'),
    component: React.lazy(() =>
      import('../pages/admin/ReflectionStatistic').then(m => ({
        default: m.ReflectionStatistic,
      })),
    ),
  },
  {
    path: 'admin/plugin/chats/:instanceId/open-tasks/:taskId',
    name: 'Plugin.AdminChats.OpenTaskView',
    getTitle: () => $t('开放预约任务详情'),
    component: React.lazy(() =>
      import('../pages/admin/OpenTaskView').then(m => ({
        default: m.OpenTaskView,
      })),
    ),
  },
]
