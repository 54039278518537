/**
 * @file utils
 */

import { PermissionNameEnum } from 'packages/sdks-next/chalk'

/**
 * 获取学分插件权限
 *
 * @returns 学分插件权限
 */
export const getCreditPluginAnyPermission = () => [
  PermissionNameEnum.CreditRead,
  PermissionNameEnum.CreditWrite,
  PermissionNameEnum.CreditDownload,
]
