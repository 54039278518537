/**
 * @file modal routes
 */

import React, { lazy } from 'react'

import { $t } from 'packages/locale'

export const modalRoutes = [
  {
    name: 'PluginCustomizedGroupsNoticesLogsModal',
    getTitle: () => $t('历史公告'),
    component: React.lazy(() =>
      import('../pages/user/Home/NoticeLogsModal').then(m => ({
        default: m.NoticeLogsModal,
      })),
    ),
  },
  {
    name: 'PluginCustomizedGroupsNoticeDetailModal',
    getTitle: () => $t('公告详情'),
    component: React.lazy(() =>
      import('../pages/user/Home/NoticeDetailModal').then(m => ({
        default: m.NoticeDetailModal,
      })),
    ),
  },
  {
    name: 'AdminPluginCustomizedGroupsSignupEditModal',
    getTitle: () => $t('新增报名'),
    component: React.lazy(() =>
      import('../pages/admin/Signups/Edit').then(m => ({
        default: m.Edit,
      })),
    ),
  },
  {
    name: 'PluginCustomizedGroupsMemberSignupModal',
    getTitle: () => $t('社团报名'),
    component: React.lazy(() =>
      import('../pages/user/SignupModal').then(m => ({
        default: m.SignupModal,
      })),
    ),
  },
  {
    name: 'AdminPluginCustomizedGroupsReviewSignupSubmissionModal',
    getTitle: () => $t('报名情况'),
    component: React.lazy(() =>
      import('../pages/user/ReviewSignupSubmissionModal').then(m => ({
        default: m.ReviewSignupSubmissionModal,
      })),
    ),
  },
  {
    name: 'PluginCustomizedGroupsInputAttendanceModal',
    getTitle: () => $t('录入考勤'),
    component: lazy(() =>
      import('../pages/user/attendances/Admin/InputAttendanceModal').then(
        m => ({
          default: m.InputAttendanceModal,
        }),
      ),
    ),
  },
  {
    name: 'Plugin.CustomizedGroups.SignInCodeModal',
    getTitle: () => $t('扫码签到'),
    component: lazy(() =>
      import('../pages/user/SignInCode/SignInCodeModal').then(m => ({
        default: m.SignInCodeModal,
      })),
    ),
  },
]
