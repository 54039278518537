import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

import { BasicRoute } from './BasicRoute'

export const adminModalRoutes: ModalRouteNode[] = [
  {
    name: 'EditReport',
    getTitle: () => $t('编辑报表'),
    component: lazy(() =>
      import('../pages/admin/report/EditReport').then(m => ({
        default: m.EditReport,
      })),
    ),
  },
  {
    name: 'EditReportPublish',
    getTitle: () => $t('编辑报表发布'),
    component: lazy(() =>
      import('../pages/admin/reportPublish/Edit').then(m => ({
        default: m.EditReportPublish,
      })),
    ),
  },
  {
    name: 'AdminReportCenter.ExportReportsAsPDFModal',
    getTitle: () => $t('批量导出报告单'),
    component: lazy(() =>
      import('../pages/admin/ExportReportsAsPDF').then(m => ({
        default: m.ExportReportsAsPDFModal,
      })),
    ),
  },
]

const reportRoute = {
  path: 'reports',
  name: 'Reports',
  getTitle: () => $t('全部报表'),
  component: lazy(() =>
    Promise.resolve({
      default: BasicRoute,
    }),
  ),
  subRoutes: [
    {
      path: ':categoryId',
      name: 'List',
      getTitle: () => $t('报表列表'),
      component: lazy(() =>
        import('../pages/admin/report/List').then(m => ({
          default: m.ReportList,
        })),
      ),
    },
    {
      path: ':categoryId/:id/detail',
      name: 'Detail',
      getTitle: () => $t('报表详情'),
      component: lazy(() =>
        import('../pages/admin/report/Detail').then(m => ({
          default: m.Detail,
        })),
      ),
    },
  ],
}

const dataSourceRoute = {
  path: 'data-source',
  name: 'DataSource',
  getTitle: () => $t('数据源'),
  component: lazy(() =>
    Promise.resolve({
      default: RouteMenus,
    }),
  ),
  subRoutes: [
    {
      path: 'sys',
      name: 'Sys',
      getTitle: () => $t('系统表'),
      component: lazy(() =>
        import('../pages/admin/dataSource/List/System').then(m => ({
          default: m.SystemList,
        })),
      ),
    },
    {
      path: 'custom',
      name: 'Custom',
      getTitle: () => $t('自定义表'),
      component: lazy(() =>
        import('../pages/admin/dataSource/List/Custom').then(m => ({
          default: m.CustomList,
        })),
      ),
    },
    {
      path: ':name/record',
      name: 'Record',
      getTitle: () => $t('数据源详情'),
      component: lazy(() =>
        import('../pages/admin/dataSource/List/Record').then(m => ({
          default: m.RecordList,
        })),
      ),
    },
    {
      path: 'custom/new',
      name: 'CustomNew',
      getTitle: () => $t('创建表'),
      component: lazy(() =>
        import('../pages/admin/dataSource/New').then(m => ({ default: m.New })),
      ),
    },
    {
      path: 'custom/:name/edit',
      name: 'CustomEdit',
      getTitle: () => $t('编辑表'),
      component: lazy(() =>
        import('../pages/admin/dataSource/Edit').then(m => ({
          default: m.Edit,
        })),
      ),
    },
  ],
}

const reportPublish = {
  path: 'report-publish',
  name: 'ReportPublish',
  getTitle: () => $t('报表发布'),
  component: lazy(() =>
    Promise.resolve({
      default: RouteMenus,
    }),
  ),
  subRoutes: [
    {
      path: '',
      name: '',
      getTitle: () => $t('报表发布'),
      component: lazy(() =>
        import('../pages/admin/reportPublish/List/Admin').then(m => ({
          default: m.List,
        })),
      ),
    },
    {
      path: ':id/report',
      name: 'Detail.Report',
      getTitle: () => $t('报表详情'),
      component: lazy(() =>
        import('../pages/admin/reportPublish/Detail/ReportDetail').then(m => ({
          default: m.ReportDetail,
        })),
      ),
    },
    {
      path: ':id',
      name: 'Detail',
      getTitle: () => $t('发布详情'),
      component: lazy(() =>
        import('../pages/admin/reportPublish/Detail').then(m => ({
          default: m.PublishedView,
        })),
      ),
    },
  ],
}

export const warningRoutes = {
  path: 'warning',
  name: 'Warning',
  getTitle: () => $t('预警'),
  component: lazy(() =>
    Promise.resolve({
      default: RouteMenus,
    }),
  ),
  subRoutes: [
    {
      path: 'manage',
      name: 'Manage',
      getTitle: () => $t('预警管理'),
      component: lazy(() =>
        import('../pages/admin/warning/List').then(m => ({ default: m.List })),
      ),
    },
    {
      path: 'history',
      name: 'History',
      getTitle: () => $t('预警历史'),
      component: lazy(() =>
        import('../pages/admin/warning/History').then(m => ({
          default: m.History,
        })),
      ),
    },
    {
      path: 'new',
      name: 'New',
      getTitle: () => $t('创建预警策略'),
      component: lazy(() =>
        import('../pages/admin/warning/Edit').then(m => ({ default: m.Edit })),
      ),
    },
    {
      path: 'edit/:id',
      name: 'Edit',
      getTitle: () => $t('编辑预警策略'),
      component: lazy(() =>
        import('../pages/admin/warning/Edit').then(m => ({ default: m.Edit })),
      ),
    },
  ],
}

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/report-center',
    name: 'AdminReportCenter',
    getTitle: () => $t('报表中心'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.ReportCenter],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      reportRoute,
      dataSourceRoute,
      reportPublish,
      warningRoutes,
      {
        path: 'settings',
        name: 'ReportCenterSettings',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('../pages/admin/Settings').then(m => ({
            default: m.SettingList,
          })),
        ),
      },
    ],
  },
]
