import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminCertificationMenu, userCertificationMenu } from './menus'
import {
  adminRoutes,
  modalRoutes,
  studentRoutes,
  teacherRoutes,
} from './routes'
import {
  profileSubRoutesSlot,
  classSubRoutesSlot,
  adminClassSubRoutesSlot,
} from './slots'
import { todos } from './todos'

/**
 * 认证插件
 */
export const initCertification: InitPluginFeature = {
  name: PluginNameEnum.Certification,
  init: () => ({
    setting: {
      type: 'extendable',
      component: lazy(() =>
        import('./pages/settings').then(m => ({
          default: m.CertificationSettings,
        })),
      ),
    },
    routes: [...adminRoutes, ...teacherRoutes, ...studentRoutes],
    modalRoutes,
    menus: [adminCertificationMenu, userCertificationMenu],
    slots: {
      profileSubRoutes: profileSubRoutesSlot,
      profileClassGroupSubRoutes: classSubRoutesSlot,
      adminClassDefaultSubRoutes: adminClassSubRoutesSlot,
      userCommonApplicationsEntry: () =>
        import('./slots').then(m => m.CommonUseApplicationsEntry),
    },
    todos,
  }),
}
