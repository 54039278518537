/**
 * @file 课程库 Routes slot
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { myFileSubRoutes } from '../../net-disk-sub-routes'

export const profileCourseGroupSubRoutes: RouteConfig[] = [
  {
    path: 'net-disk',
    name: 'CourseGroupNetDisk',
    getTitle: () => $t('网盘'),
    component: lazy(() =>
      import(
        '@/plugins/features/net-disk/slots/CourseWorkspaceRoutes/CourseGroupNetDisk'
      ).then(m => ({
        default: m.CourseGroupNetDisk,
      })),
    ),
    subRoutes: [
      {
        path: 'files',
        name: 'CourseNetDiskFileList',
        getTitle: () => $t('文件列表'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/slots/CourseWorkspaceRoutes/CourseNetDiskFileList'
          ).then(m => ({
            default: m.CourseNetDiskFileList,
          })),
        ),
      },
      {
        path: 'shared',
        getTitle: () => $t('分享历史'),
        component: lazy(() =>
          import(
            '@/plugins/features/net-disk/pages/group/components/GroupNetDiskShareHistory'
          ).then(m => ({
            default: m.GroupNetDiskShareHistory,
          })),
        ),
      },
      ...myFileSubRoutes,
    ],
  },
]
