/**
 * @file 工具方法
 */
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const analysisPermission = [
  PermissionNameEnum.SzNjcjhzReportDownload,
  PermissionNameEnum.SzJbcjhzReportDownload,
  PermissionNameEnum.SzBjcjhzReportDownload,
  PermissionNameEnum.SzKmcjhzReportDownload,
  PermissionNameEnum.SzDkjfdbReportDownload,
  PermissionNameEnum.SzDuokjfdbReportDownload,
  PermissionNameEnum.SzDuokjfdb2ReportDownload,
  PermissionNameEnum.SzDankgffxReportDownload,
  PermissionNameEnum.SzDuokgffxReportDownload,
  PermissionNameEnum.SzDankecjfdfxReportDownload,
  PermissionNameEnum.SzDuokecjfdfxReportDownload,
]

export const reportPermission = [
  PermissionNameEnum.SzExamReportRead,
  PermissionNameEnum.SzSemesterReportRead,
  PermissionNameEnum.SzChuguoReportRead,
  PermissionNameEnum.SzResitReportRead,
  PermissionNameEnum.SzGlobalAcademicReportRead,
]

export const szGradeReportsPermissions = [
  PermissionNameEnum.CoreGradeReportWrite,
  ...analysisPermission,
  ...reportPermission,
]
