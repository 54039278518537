/**
 * @file 前台 routes
 */

import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { GroupPermissionTypeEnum } from 'packages/features/groups/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { RouteMenus } from '@/router'

export const userRoutes: RouteConfig[] = [
  {
    path: 'plugin/chat',
    getTitle: () => $t('约谈'),
    name: 'PluginChatForUser',
    module: [PluginEnum.Chat],
    requireModules: [PluginEnum.Chat],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('全部约谈'),
        component: React.lazy(() =>
          import('../pages/user/List').then(m => ({
            default: m.List,
          })),
        ),
      },
    ],
  },
]

export const profileRecentRoutes: RouteConfig[] = [
  {
    path: 'chats',
    getTitle: () => $t('约谈'),
    component: React.lazy(() =>
      import('../pages/user/ProfileChats').then(m => ({
        default: m.ProfileChats,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.RecentChat,
    },
  },
]
