import { compact } from '@seiue/util'
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { reportCategoriesAtom } from 'packages/feature-utils/report-center/atoms'
import { getCurrentReflection } from 'packages/feature-utils/store'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum, RoleEnum } from 'packages/sdks-next/chalk'
import { MenuItemUnion, MenuType } from 'packages/web-layout/types'

export const adminReportCenterMenu = loadable(
  atom(async get => {
    if (!get(hasManageableParentPluginAtom(PluginEnum.ReportCenter))) {
      return []
    }

    const enabledPlugin = get(
      findEnabledPluginsAtom(PluginEnum.ReportCenter),
    )?.[0]

    const shortcutIcon = enabledPlugin
      ? getPluginIcon(enabledPlugin)
      : 'Other+477cff'

    const subMenus = []

    const reportCategories = get(reportCategoriesAtom)

    if (reportCategories.length) {
      subMenus.push({
        path: '/admin/report-center/reports',
        label: $t('全部报表'),
        icon: 'Form' as const,
        subMenus: reportCategories.map(item => {
          return {
            path: `/admin/report-center/reports/${item.id}`,
            label: item.name,
          }
        }),
      })
    }

    return [
      {
        prefix: [PluginCategoryEnum.通用工具],
        label: $t('报表中心'),
        name: PluginNameEnum.ReportCenter,
        shortcutIcon,
        sort: 700,
        path: '/admin/report-center',
        subMenus: [
          ...subMenus,
          {
            path: '/admin/report-center/data-source',
            label: $t('数据源'),
            sort: 11,
            icon: 'DataAcquisition' as const,
            subMenus: [
              {
                path: '/admin/report-center/data-source/sys',
                label: $t('系统表'),
              },
              {
                path: '/admin/report-center/data-source/custom',
                label: $t('自定义表'),
              },
            ],
          },
          {
            path: '/admin/report-center/warning',
            label: $t('预警'),
            sort: 10,
            icon: 'Warning' as const,
            subMenus: [
              {
                path: '/admin/report-center/warning/manage',
                label: $t('预警管理'),
              },
              {
                path: '/admin/report-center/warning/history',
                label: $t('预警历史'),
              },
            ],
          },
          {
            path: '/admin/report-center/report-publish',
            label: $t('报表发布'),
            sort: 1,
            icon: 'SendOut' as const,
          },
          {
            path: '/admin/report-center/settings',
            label: $t('设置'),
            sort: 100,
            icon: 'Setting' as const,
          },
        ],
        type: MenuType.AdminApps,
      },
    ]
  }),
)

export const userReportCenterMenu = loadable(
  atom(async get => {
    const isManager = get(
      hasManageableParentPluginAtom(PluginEnum.ReportCenter),
    )

    if (!get(hasUsablePluginAtom(PluginEnum.ReportCenter)) && !isManager) {
      return []
    }

    const me = getCurrentReflection()
    const isTeacher = me.role === RoleEnum.Teacher
    const subMenus: MenuItemUnion[] = compact([
      isTeacher
        ? {
            path: '/user/report-center/report-manage',
            label: $t('我管理的报表'),
            icon: 'Form',
          }
        : undefined,
      {
        path: '/user/report-center/report-published/publishes',
        label: $t('我管理的发布'),
        icon: 'CopyFor',
      },
      {
        path: '/user/report-center/report-published/received',
        label: $t('我接收的'),
        icon: 'Receive',
      },
    ])

    if (isManager) {
      subMenus.push({
        path: '/admin/report-center',
        label: $t('前往报表管理'),
        icon: 'Backstage',
      })
    }

    return [
      {
        name: PluginEnum.ReportCenter,
        label: $t('报表中心'),
        type: MenuType.Apps,
        subMenus,
      },
    ]
  }),
)
