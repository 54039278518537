import { lazy } from 'react'

import { $t } from 'packages/locale'
import { ModalRouteNode } from 'packages/route'

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'PluginStudentMergeToolModal',
    getTitle: () => $t('学生聚合工具'),
    component: lazy(() =>
      import('./pages/StudentMergeToolModal').then(m => ({
        default: m.StudentMergeToolModal,
      })),
    ),
  },
]
