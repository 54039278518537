/**
 * @file 请假管理插件
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminAbsenceMenu } from './menus'
import { modalRoutes, routes } from './routes'
import { workflowRenders } from './workflow'

export const initAbsence: InitPluginFeature = {
  name: PluginNameEnum.Absence,
  init: () => ({
    setting: {
      type: 'extendable',
      component: lazy(() =>
        import('./pages/Settings').then(m => ({
          default: m.AbsenceSettings,
        })),
      ),
    },
    routes,
    menus: [adminAbsenceMenu],
    modalRoutes,
    workflowRenders,
    slots: {
      userCommonApplicationsEntry: () =>
        import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
  }),
}
