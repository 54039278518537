/**
 * @file 编排工具
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminGstMenu } from './menus'
import { modalRoutes, routes } from './routes'

export const initGst: InitPluginFeature = {
  name: PluginNameEnum.Gst,
  init: () => ({
    routes,
    modalRoutes,
    menus: [adminGstMenu],
    slots: {
      adminClassBatchMenuItem: () =>
        import('./slots/item-slots').then(m => m.batchImportAdminClassMember),
    },
  }),
}
