/**
 * @file 宿舍 menus
 */

import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { isMenuOpenedAtom } from 'packages/feature-utils/menus'
import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasEnabledPluginAtom,
  hasManageableParentPluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { getCurrentReflection } from 'packages/feature-utils/store'
import { useHasCurrentPermission } from 'packages/features/roles'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { MenuType } from 'packages/route'
import {
  MemberTypeEnum,
  PermissionNameEnum,
  PluginCategoryEnum,
  RoleEnum,
  groupApi$findJoinedGroups,
} from 'packages/sdks-next/chalk'
import { AttendanceGroupTypeEnum } from 'packages/sdks-next/sams'
import { dormApi$queryEvaluatedDormAssessments } from 'packages/sdks-next/vnas'
import { MenuItemUnion } from 'packages/web-layout/types'

export const userDormMenu = loadable(
  atom(async get => {
    if (!get(hasEnabledPluginAtom(SchoolModulesEnum.Dorm))) {
      return []
    }

    const me = getCurrentReflection()
    if (!me) {
      return []
    }

    const menuOpened = get(isMenuOpenedAtom(['dorms', ModuleEnum.Dorm]))
    const isManager =
      me.role === RoleEnum.Teacher || me.role === RoleEnum.Shadow

    const { data: joinedDorms } = menuOpened
      ? await groupApi$findJoinedGroups.api(me.id, {
          scopeIn: AttendanceGroupTypeEnum.Dorm,
          memberTypeIn: MemberTypeEnum.Member,
        })
      : { data: [] }

    const menus = []

    if (isManager) {
      menus.push({
        path: '/dorms/attendances/management',
        label: $t('宿舍考勤'),
        icon: 'Dormitory' as const,
        sort: 1,
        subMenus: joinedDorms.length
          ? [
              {
                path: '/dorms/attendances/management',
                label: $t('全部宿舍'),
              },
              ...joinedDorms.map(v => ({
                path: `/dorms/${v.bizId}/attendances/reflections/${me.id}`,
                label: v.name,
              })),
            ]
          : undefined,
      })
    } else {
      menus.push(
        ...joinedDorms.map(v => ({
          icon: 'Dormitory' as const,
          path: `/dorms/${v.bizId}/attendances/reflections/${me.id}`,
          label: v.name,
        })),
      )
    }

    if (me.role === RoleEnum.Teacher) {
      menus.push({
        label: $t('宿生列表'),
        icon: 'Student' as const,
        path: '/dorms/member',
        sort: 0,
      })

      if (me.schoolId === 194 || me.schoolId === 145) {
        menus.push({
          path: '/dorms/statistics',
          label: $t('统计分析'),
          icon: 'Statistics' as const,
          sort: 3,
          subMenus: [
            {
              path: '/dorms/statistics/sta',
              label: $t('数据统计'),
            },
          ],
        })
      }
    }

    const { data: assessments } = menuOpened
      ? await dormApi$queryEvaluatedDormAssessments.api({
          paginated: 0,
          isArchived: false,
        })
      : { data: [] }

    const subMenus = [
      ...menus,
      {
        label: $t('宿舍评价'),
        icon: 'Performance' as const,
        sort: 2,
        subMenus: assessments.map(ass => ({
          path: `/dorms/assessments/${ass.id}`,
          label: ass.name,
        })),
      },
    ]

    return [
      {
        prefix: ['dorms'],
        name: ModuleEnum.Dorm,
        label: $t('宿舍'),
        type: MenuType.Apps,
        lazy: true,
        sort: 900,
        subMenus,
      },
    ]
  }),
)

export const adminDormMenu = atom<MenuItemUnion[]>(get => {
  if (!get(hasEnabledPluginAtom(ModuleEnum.Dorm))) {
    return []
  }

  const enabledPlugin = get(findEnabledPluginsAtom(ModuleEnum.Dorm))[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.德育管理],
      permission: (func: typeof useHasCurrentPermission) => {
        return (
          func({
            permission: PermissionNameEnum.CoreDormRead,
          }) ||
          func({
            permission: PermissionNameEnum.CoreDormWrite,
          }) ||
          get(hasManageableParentPluginAtom(PluginNameEnum.Dorm))
        )
      },
      module: true,
      label: $t('宿舍管理'),
      name: SchoolModulesEnum.Dorm,
      icon: 'Dormitory' as const,
      shortcutIcon,
      sort: 4000,
      type: MenuType.AdminApps,
      subMenus: [
        {
          path: '/admin/dorms',
          label: $t('宿舍管理'),
          icon: 'Dormitory',
          sort: 0,
        },
        {
          path: '/admin/dorms/assessments',
          label: $t('评价管理'),
          icon: 'Performance',
          sort: 1,
        },
        {
          path: '/admin/attendances/dorms',
          label: $t('考勤管理'),
          icon: 'CheckWorkAttendance',
          sort: 2,
        },
        {
          path: '/admin/dorms/statistics',
          label: $t('统计分析'),
          icon: 'Statistics',
          sort: 3,
          subMenus: [
            {
              path: '/admin/dorms/statistics/data',
              label: $t('数据统计'),
            },
            {
              path: '/admin/dorms/statistics/chart',
              label: $t('图表分析'),
            },
          ],
        },
        {
          path: '/admin/dorms/settings',
          label: $t('设置'),
          icon: 'Setting',
          sort: 4,
        },
      ],
    },
  ]
})
