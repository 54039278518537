/**
 * @file 短信平台 apis
 */
import { useCurrentReflection } from 'packages/features/sessions'
import {
  SchoolPlugin,
  acmApi$queryConfigItemsByGroup,
  acmApi$mutationConfigItemsByGroup,
} from 'packages/sdks-next/chalk'

export const useQuerySmsConfig = () => {
  const reflection = useCurrentReflection()

  return acmApi$queryConfigItemsByGroup.useApi({
    scope: `school.${reflection.schoolId}`,
    service: 'seiue.chalk',
    group: 'sms.setting',
  })
}

export const querySmsConfig = async (schoolId: number) => {
  const { data: configItems } = await acmApi$queryConfigItemsByGroup.api(
    `school.${schoolId}`,
    'seiue.chalk',
    'sms.setting',
  )

  return configItems
}

export const mutationSmsConfig = async ({
  schoolPlugin,
  value,
}: {
  schoolPlugin: SchoolPlugin | undefined
  value: {
    smsType: string
    sdkAppId: string
    secretId: string
    secretKey: string
  }
}) => {
  await acmApi$mutationConfigItemsByGroup.api(
    `school.${schoolPlugin?.schoolId}`,
    'seiue.chalk',
    'sms.setting',
    [
      {
        name: 'sms_config',
        value,
      },
    ],
  )
}
