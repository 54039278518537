/**
 * @file 学案路由
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/handouts',
    name: 'AdminPluginHandouts',
    getTitle: () => $t('学案库管理'),
    module: [PluginCategoryEnum.教务教学, PluginNameEnum.Handout],
    requirePermissions: [
      PermissionNameEnum.CorePluginRead,
      PermissionNameEnum.CorePluginWrite,
      PermissionNameEnum.HandoutRead,
    ],
    requireManageablePlugins: [PluginNameEnum.Handout],
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/admin').then(m => ({
        default: m.HandoutsEntry,
      })),
    ),
    subRoutes: [
      {
        path: 'normal-list',
        name: 'NormalList',
        getTitle: () => $t('学案列表'),
        component: lazy(() =>
          import(
            '@/plugins/features/handouts/pages/admin/AdminHandoutsList'
          ).then(m => ({
            default: m.AdminHandoutsNormalList,
          })),
        ),
      },
      {
        path: 'archive-list',
        name: 'ArchiveList',
        getTitle: () => $t('已归档学案'),
        component: lazy(() =>
          import(
            '@/plugins/features/handouts/pages/admin/AdminHandoutsList'
          ).then(m => ({
            default: m.AdminHandoutsArchiveList,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/handouts/warehouse/gradeGroupId/:gradeGroupId',
    name: 'AdminPluginHandoutsWarehouse',
    getTitle: () => $t('题库管理'),
    requirePermissions: [
      PermissionNameEnum.CorePluginRead,
      PermissionNameEnum.CorePluginWrite,
      PermissionNameEnum.BankRead,
    ],
    requireManageablePlugins: [PluginNameEnum.Handout],
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/admin/Warehouse').then(m => ({
        default: m.HandoutWarehouse,
      })),
    ),
  },
  {
    path: 'admin/plugin/handouts/settings',
    name: 'AdminPluginHandoutSettings',
    requirePermissions: [
      PermissionNameEnum.CorePluginWrite,
      PermissionNameEnum.HandoutWrite,
    ],
    requireManageablePlugins: [PluginNameEnum.Handout],
    getTitle: () => $t('设置'),
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/admin/Setting').then(m => ({
        default: m.HandoutsSetting,
      })),
    ),
  },
  {
    path: 'admin/plugin/handouts/settings/roles/new',
    name: 'AdminPluginHandoutsNewRole',
    getTitle: () => $t('权限设置'),
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/admin/Setting/NewRole').then(
        m => ({
          default: m.NewRole,
        }),
      ),
    ),
  },
  {
    path: 'admin/plugin/handouts/settings/roles/:roleId/edit',
    name: 'AdminPluginHandoutsEditRole',
    getTitle: () => $t('权限设置'),
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/admin/Setting/NewRole').then(
        m => ({
          default: m.NewRole,
        }),
      ),
    ),
  },
  {
    path: 'admin/plugin/handouts/normal-list/editor/:handoutId',
    name: 'AdminPluginHandoutsEditor',
    getTitle: () => $t('编辑学案'),
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/admin/Editor/index').then(
        m => ({
          default: m.EditorEntry,
        }),
      ),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'HandoutReviewModal',
    getTitle: () => $t('教师批阅'),
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/user/Class/ReviewModal').then(
        m => ({
          default: m.ReviewModal,
        }),
      ),
    ),
  },
  {
    name: 'CreateQuestionModal',
    getTitle: () => $t('新增题目'),
    component: lazy(() =>
      import(
        '@/plugins/features/handouts/pages/admin/Warehouse/CreateQuestionModal'
      ).then(m => ({
        default: m.CreateQuestionModal,
      })),
    ),
  },
  {
    name: 'EditKnowledgePointModal',
    getTitle: () => $t('知识点管理'),
    component: lazy(() =>
      import(
        '@/plugins/features/handouts/pages/admin/KnowledgePointModal'
      ).then(m => ({
        default: m.KnowledgePointModal,
      })),
    ),
  },
]
