/**
 * @file 素养评价 routes
 */

import React from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/acc-assessments',
    name: 'Plugin.AdminAccAssessments',
    module: [PluginCategoryEnum.教务教学, PluginNameEnum.AccAssessment],
    getTitle: () => $t('素养评价'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('评价列表'),
        component: React.lazy(() =>
          import('@/plugins/features/acc-assessments/pages/admin/List').then(
            m => ({
              default: m.AdminAccAssessmentList,
            }),
          ),
        ),
      },
      {
        path: 'table',
        getTitle: () => $t('素养课程图谱'),
        component: React.lazy(() =>
          import('@/plugins/features/acc-assessments/pages/admin/Table').then(
            m => ({
              default: m.AdminAccAssessmentTable,
            }),
          ),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/acc-assessments/:mode/new',
    name: 'Plugin.AdminAccAssessmentNew',
    getTitle: () => $t('新增素养评价'),
    component: React.lazy(() =>
      import('@/plugins/features/acc-assessments/pages/admin/Edit').then(m => ({
        default: m.AdminAccAssessmentNew,
      })),
    ),
  },
  {
    path: 'admin/plugin/acc-assessments/:id/edit',
    name: 'Plugin.AdminAccAssessmentEdit',
    getTitle: () => $t('编辑素养评价'),
    component: React.lazy(() =>
      import('@/plugins/features/acc-assessments/pages/admin/Edit').then(m => ({
        default: m.AdminAccAssessmentEdit,
      })),
    ),
  },
  {
    path: 'admin/plugin/acc-assessments/:id/view',
    name: 'Plugin.AdminAccAssessmentView',
    getTitle: () => $t('素养评价详情'),
    component: React.lazy(() =>
      import('@/plugins/features/acc-assessments/pages/admin/View').then(m => ({
        default: m.AdminAccAssessmentView,
      })),
    ),
  },
]
