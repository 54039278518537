/**
 * @file 出入校管理路由定义
 */

import React, { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminRoutes: RouteConfig[] = [
  {
    name: 'PluginAdminEemsWorkflow',
    path: 'admin/plugin/eems/workflows/:schoolPluginId',
    getTitle: () => $t('审批流程'),
    module: [PluginCategoryEnum.后勤管理, SchoolPluginsEnum.Eems],
    component: lazy(() =>
      import('@/features/workflows/pages/admin/index').then(m => ({
        default: m.AdminWorkflow,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('审批列表'),
        component: React.lazy(() =>
          import('@/features/workflows/pages/admin/WorkflowList').then(m => ({
            default: m.AdminWorkflowList,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('@/features/workflows/pages/admin/Settings').then(m => ({
            default: m.AdminWorkflowSettings,
          })),
        ),
      },
    ],
  },
  {
    name: 'Plugin.Eems.IotManagement',
    path: 'admin/plugin/eems/iot-management',
    module: [PluginCategoryEnum.后勤管理, SchoolPluginsEnum.Eems],
    getTitle: () => $t('物联网管理'),
    component: lazy(() =>
      import('./pages/admin/IotManagement').then(m => ({
        default: m.IotManagement,
      })),
    ),
    subRoutes: [
      {
        path: 'devices',
        getTitle: () => $t('设备管理'),
        component: React.lazy(() =>
          import('./pages/admin/IotManagement/DeviceList').then(m => ({
            default: m.DeviceList,
          })),
        ),
      },
      {
        path: 'users',
        name: 'Users',
        getTitle: () => $t('人员管理'),
        component: React.lazy(() =>
          import('./pages/admin/IotManagement/UserList').then(m => ({
            default: m.UserList,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('./pages/admin/IotManagement/Settings').then(m => ({
            default: m.Settings,
          })),
        ),
      },
    ],
  },
  {
    name: 'Plugin.Eems.IotLogs',
    path: 'admin/plugin/eems/iot-logs',
    module: [PluginCategoryEnum.后勤管理, SchoolPluginsEnum.Eems],
    getTitle: () => $t('打卡日志'),
    component: lazy(() =>
      import('./pages/admin/IotLogs').then(m => ({
        default: m.IotLogs,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.VisitorSettingsModal',
    getTitle: () => $t('设置审批'),
    component: React.lazy(() =>
      import('./pages/Settings').then(m => ({
        default: m.VisitorSettingsRouteModal,
      })),
    ),
  },
  {
    name: 'Plugin.NewVisitorModal',
    getTitle: () => $t('新建出入校审批'),
    component: React.lazy(() =>
      import('./workflow-renders/NewVisitorModal').then(m => ({
        default: m.NewVisitorFlowModal,
      })),
    ),
  },
  {
    name: 'Plugin.Eems.NewIotDevice',
    getTitle: () => $t('添加设备'),
    component: React.lazy(() =>
      import('./pages/admin/NewIotDevice').then(m => ({
        default: m.NewIotDevice,
      })),
    ),
  },
]
