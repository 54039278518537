/**
 * @file 学籍管理 - 菜单声明
 */

import { compact } from '@seiue/util'
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { hasManageableParentPluginAtom } from 'packages/feature-utils/plugins'
import { useHasCurrentPermission } from 'packages/features/roles'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'
import { MenuType } from 'packages/web-layout/types'

export const adminStudentStatusProfileMenu = loadable(
  atom(get => {
    const isPluginManager = get(
      hasManageableParentPluginAtom(PluginNameEnum.StudentStatusProfile),
    )

    return [
      {
        prefix: [PluginCategoryEnum.教务教学],
        label: $t('学籍管理'),
        name: PluginNameEnum.StudentStatusProfile,
        sort: 700,
        permission: (func: typeof useHasCurrentPermission) => {
          return (
            func({
              permission: PermissionNameEnum.StudentStatusConfigAdmin,
              isManager: true,
            }) || isPluginManager
          )
        },
        subMenus: compact([
          {
            path: '/admin/student-status-profile/students/normal',
            label: $t('在校学生'),
            icon: 'Student' as const,
          },
          {
            path: '/admin/student-status-profile/students/archived',
            label: $t('已归档学生'),
            icon: 'ArchiveUsers' as const,
          },
          {
            path: '/admin/student-status-profile/form',
            label: $t('学籍表管理'),
            icon: 'Archives' as const,
            permission: () => isPluginManager,
          },
          {
            path: '/admin/student-status-profile/record',
            label: $t('操作日志'),
            icon: 'Log' as const,
            permission: () => isPluginManager,
          },
        ]),
        type: MenuType.AdminApps,
      },
    ]
  }),
)
