/**
 * @file 教师档案 - 档案 - 评价 sub routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const teacherProfilesEvaluationSubRoutes: RouteConfig[] = [
  {
    path: 'class-review',
    getTitle: () => $t('听评课'),
    component: lazy(() =>
      import('../pages/teacher/Profile').then(m => ({
        default: m.TeacherEvaluationProfile,
      })),
    ),
  },
]
