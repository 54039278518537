import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminDataScreenMenu } from './menus'
import { adminRoutes, modalRoutes } from './routes'

export const initDataScreen: InitPluginFeature = {
  name: PluginNameEnum.DataScreen,
  init: () => {
    return {
      routes: adminRoutes,
      menus: [adminDataScreenMenu],
      modalRoutes,
    }
  },
}
