/**
 * @file 后台 routes
 */

import React from 'react'

import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/academic-goal',
    getTitle: () => $t('学业目标'),
    module: [PluginCategoryEnum.学生成长, SchoolModulesEnum.Goal],
    name: 'PluginAcademicGoalsForAdmin',
    component: React.lazy(() =>
      import('../pages/admin').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('目标列表'),
        component: React.lazy(() =>
          import('../pages/admin/Goals').then(m => ({
            default: m.Goals,
          })),
        ),
      },
      {
        path: 'ways',
        name: 'Ways',
        getTitle: () => $t('方向路径库'),
        component: React.lazy(() =>
          import('../pages/admin/Ways').then(m => ({
            default: m.Ways,
          })),
        ),
      },
      {
        path: 'milestones',
        name: 'Milestones',
        getTitle: () => $t('里程碑库'),
        component: React.lazy(() =>
          import('../pages/admin/milestones/List').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        path: 'setting',
        name: 'Setting',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('../pages/admin/Settings').then(m => ({
            default: m.Settings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/academic-goal/ways/new',
    name: 'PluginAcademicGoalsForAdminNewWay',
    getTitle: () => $t('新增路径'),
    component: React.lazy(() =>
      import('../pages/admin/WayEdit').then(m => ({
        default: m.WayEdit,
      })),
    ),
  },
  {
    path: 'admin/plugin/academic-goal/ways/:wid/edit',
    name: 'PluginAcademicGoalsForAdminEditWay',
    getTitle: () => $t('编辑路径'),
    component: React.lazy(() =>
      import('../pages/admin/WayEdit').then(m => ({
        default: m.WayEdit,
      })),
    ),
  },
  {
    path: 'admin/plugin/academic-goal/ways/:wid',
    name: 'PluginAcademicGoalsForAdminWayDetail',
    getTitle: () => $t('路径详情'),
    component: React.lazy(() =>
      import('../pages/admin/WayDetail').then(m => ({
        default: m.WayDetail,
      })),
    ),
    subRoutes: [
      {
        path: 'detail',
        name: 'Detail',
        getTitle: () => $t('路径信息'),
        component: React.lazy(() =>
          import('../pages/admin/WayDetail/Detail').then(m => ({
            default: m.WayDetail,
          })),
        ),
      },
      {
        path: 'milestones',
        name: 'Milestones',
        getTitle: () => $t('里程碑'),
        component: React.lazy(() =>
          import('../pages/admin/WayDetail/List').then(m => ({
            default: m.List,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/academic-goal/direction/:did',
    name: 'PluginAcademicGoalsForAdminEditDirection',
    getTitle: () => $t('编辑目标方向'),
    component: React.lazy(() =>
      import('../pages/admin/DirectionEdit').then(m => ({
        default: m.DirectionEdit,
      })),
    ),
  },
  {
    path: 'admin/plugin/graduates-info',
    getTitle: () => $t('毕业生信息'),
    module: [PluginCategoryEnum.学生成长, SchoolModulesEnum.Goal],
    name: 'AdminPluginGraduatesInfo',
    component: React.lazy(() =>
      import('../pages/admin/GraduatesInfo').then(m => ({
        default: m.GraduatesInfo,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('毕业生列表'),
        component: React.lazy(() =>
          import('../pages/admin/GraduatesInfo/List').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        path: 'setting',
        name: 'Setting',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('../pages/admin/GraduatesInfo/Setting').then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/graduates-info/view/:id',
    name: 'PluginAcademicGoalsAdminGraduatesInfoView',
    getTitle: () => $t('查看信息'),
    component: React.lazy(() =>
      import('../pages/admin/GraduatesInfo/SingleGraduateInfo').then(m => ({
        default: m.SingleGraduate,
      })),
    ),
  },
  {
    path: 'admin/plugin/graduates-info/edit/:id',
    name: 'PluginAcademicGoalsAdminGraduatesInfoEdit',
    getTitle: () => $t('编辑信息'),
    component: React.lazy(() =>
      import('../pages/admin/GraduatesInfo/SingleGraduateInfo').then(m => ({
        default: m.SingleGraduate,
      })),
    ),
  },
]
