/**
 * @file 人员信息管理 - 菜单声明
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuType } from 'packages/web-layout/types'

export const adminPersonnelProfileMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.PersonnelProfile))) {
    return []
  }

  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.PersonnelProfile),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.教师发展],
      label: $t('人事管理'),
      name: PluginNameEnum.PersonnelProfile,
      icon: 'GraduateInformation' as const,
      shortcutIcon,
      sort: 700,
      path: '/admin/personnel-profile/teachers',
      subMenus: [
        {
          path: '/admin/personnel-profile/teachers',
          label: $t('信息管理'),
          icon: 'Frame' as const,
        },
      ],
      type: MenuType.AdminApps,
    },
  ]
})
