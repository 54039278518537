/**
 * @file 学籍管理 - 路由
 */

import React, { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum, StatusEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

const generateStudentStatusSubRoutes = (status: StatusEnum) => {
  return [
    {
      path: '',
      getTitle: () => $t('学生列表'),
      customMeta: {
        type: status,
      },
      component: lazy(() =>
        import('./pages/students').then(m => ({
          default: m.StudentList,
        })),
      ),
    },
    {
      path: 'fields',
      getTitle: () => $t('字段管理'),
      component: lazy(() =>
        import('./pages/students/FieldManagement').then(m => ({
          default: m.FieldManagement,
        })),
      ),
    },
    {
      path: ':formId/:studentId/view',
      getTitle: () => $t('查看学籍表'),
      component: lazy(() =>
        import('./pages/students/View').then(m => ({
          default: m.StudentView,
        })),
      ),
    },
    {
      path: ':formId/:studentId/edit',
      getTitle: () => $t('编辑学籍表'),
      component: lazy(() =>
        import('./pages/students/Edit').then(m => ({
          default: m.StudentEdit,
        })),
      ),
    },
    {
      path: ':formId/:studentId/formView',
      getTitle: () => $t('查看学籍表'),
      component: lazy(() =>
        import('./pages/students/StudentFormView').then(m => ({
          default: m.StudentFormView,
        })),
      ),
    },
  ]
}

const studentRoutes = [
  {
    path: 'admin/student-status-profile',
    name: 'AdminStudentStatusProfile',
    getTitle: () => $t('信息管理'),
    module: [PluginCategoryEnum.教务教学, PluginNameEnum.StudentStatusProfile],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'students/normal',
        getTitle: () => $t('在校学生'),
        component: lazy(() =>
          Promise.resolve({
            default: RouteMenus,
          }),
        ),
        subRoutes: generateStudentStatusSubRoutes(StatusEnum.Normal),
      },
      {
        path: 'students/archived',
        getTitle: () => $t('已归档学生'),
        component: lazy(() =>
          Promise.resolve({
            default: RouteMenus,
          }),
        ),
        subRoutes: generateStudentStatusSubRoutes(StatusEnum.Archived),
      },
      {
        path: 'record',
        getTitle: () => $t('操作日志'),
        component: lazy(() =>
          import('./pages/Record').then(m => ({
            default: m.RecordList,
          })),
        ),
      },
      {
        path: 'form',
        getTitle: () => $t('学籍表管理'),
        component: lazy(() =>
          import('./pages/Form/List').then(m => ({
            default: m.FormList,
          })),
        ),
      },
    ],
  },
]

export const routes = [...studentRoutes]

export const modalRoutes = [
  {
    name: 'StudentStatusProfile.EditForm',
    getTitle: () => $t('编辑学籍表'),
    component: React.lazy(() =>
      import('./pages/Form/Edit').then(m => ({
        default: m.EditFormRouteModal,
      })),
    ),
  },
]
