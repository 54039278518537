import { EnabledPlugin } from 'packages/feature-utils/plugins'

import { initPluginFeatures } from './features'
import { registerSetting } from './setting'
import { registerToSlot } from './slot'
import { GlobalInitPluginParams } from './types'

export { getSetting } from './setting'
export {
  getRouteSlots,
  useComponentSlots,
  useDataSlots,
  registerToSlot,
} from './slot'

/**
 * 初始化插件
 *
 * @param params - 初始化所需参数
 * @param enabledPlugins - 已启用的插件列表
 * @returns promise
 */
export const initPlugins = (
  params: GlobalInitPluginParams,
  enabledPlugins: EnabledPlugin[],
) =>
  initPluginFeatures(
    {
      ...params,
      registerToSlot,
      registerSetting,
    },
    enabledPlugins,
  )
