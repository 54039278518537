/**
 * @file routes
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const analysisRoutes: RouteConfig[] = [
  {
    path: 'exam/analysis/details/:examId/:examName',
    name: 'ExamScoreDetails',
    getTitle: () => $t('成绩分析详情'),
    component: lazy(() =>
      import('@/plugins/features/gz-exam-res-analysis/pages/user').then(m => ({
        default: m.ExamResultAnalysis,
      })),
    ),
  },
]
