/**
 * @file 竞停生成绩认定
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initStopStudentGrade: InitPluginFeature = {
  name: PluginNameEnum.StopStudentGrade,
  init: () => ({
    slots: {
      adminGradeListExportItem: () =>
        import('./slot').then(m => m.stopStudentGradeReport),
    },
  }),
}
