/**
 * @file routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    path: 'admin/plugin/closed-class/list',
    getTitle: () => $t('停课生'),
    module: [PluginCategoryEnum.教务教学, PluginNameEnum.Tkgl],
    component: lazy(() =>
      import('./pages/closed-manage/List').then(mod => ({
        default: mod.Entry,
      })),
    ),
  },

  {
    path: 'admin/plugin/closed-class/new',
    getTitle: () => $t('新增停课生'),
    module: [PluginCategoryEnum.教务教学, PluginNameEnum.Tkgl],
    component: lazy(() =>
      import('./pages/closed-manage/new').then(m => ({
        default: m.AddClosedClass,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'Plugin.StopClassSyncScore',
    getTitle: () => $t('同步成绩'),
    component: lazy(() =>
      import('./pages/closed-manage/SyncScore').then(m => ({
        default: m.SyncScoreModal,
      })),
    ),
  },
]
