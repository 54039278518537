/**
 * @file 学生心理档案-弹窗路由
 */

import React from 'react'

import { $t } from 'packages/locale'
import { ModalRouteNode } from 'packages/route'
// 心理测评
const psychologicalAssessmentModalRoutes: ModalRouteNode[] = [
  {
    name: 'PluginPsychologicalFileAdminEditAssessmentsModal',
    getTitle: () => $t('编辑计划'),
    component: React.lazy(() =>
      import('../pages/admin/assessments/Edit').then(m => ({
        default: m.EditAssessmentsModal,
      })),
    ),
  },
  {
    name: 'PluginPsychologicalFileSubmitAssessmentsModal',
    getTitle: () => $t('测评计划'),
    component: React.lazy(() =>
      import('../pages/user/SubmitAssessmentModal').then(m => ({
        default: m.SubmitAssessmentsModal,
      })),
    ),
  },
  {
    name: 'PluginPsychologicalFileAssessmentUnfilledStudentsModal',
    getTitle: () => $t('未填报学生列表'),
    component: React.lazy(() =>
      import('../pages/user/UnfilledStudentsModal').then(m => ({
        default: m.UnfilledStudentsModal,
      })),
    ),
  },
  {
    name: 'GZStudentPsyFormFillModal',
    getTitle: () => $t('学生心理档案表'),
    component: React.lazy(() =>
      import(
        '@/plugins/features/gz-student-psy-form/pages/StudentPsyFormFillModal'
      ).then(m => ({
        default: m.StudentPsyFormFillModal,
      })),
    ),
  },
  {
    name: 'PluginPsychologicalDownloadReportModal',
    getTitle: () => $t('下载报告'),
    component: React.lazy(() =>
      import('../pages/user/DownloadReportModal').then(m => ({
        default: m.DownloadReportModal,
      })),
    ),
  },
]

// 问题上报
const psychologicalReportModalRoutes: ModalRouteNode[] = [
  {
    name: 'PluginPsyUserReportDetails',
    getTitle: () => $t('问题上报详情'),
    component: React.lazy(() =>
      import('../pages/user/ReportDetails').then(m => ({
        default: m.ReportDetailsModal,
      })),
    ),
  },

  {
    name: 'PsychologicalFileReportUser.CreateReport',
    getTitle: () => $t('学生问题上报'),
    component: React.lazy(() =>
      import('../pages/user/CreateReport').then(m => ({
        default: m.CreateReportModal,
      })),
    ),
  },
]

// 心理档案
const psychologicalProfileModalRoutes: ModalRouteNode[] = [
  {
    name: 'StudentPsychologicalProfile.EditBackgroundInfo',
    getTitle: () => $t('编辑背景信息'),
    component: React.lazy(() =>
      import(
        '../pages/admin/Information/StudentDetail/BackgroundInfoModal'
      ).then(m => ({
        default: m.BackgroundInfoModal,
      })),
    ),
  },
  {
    name: 'EvaluationModal',
    getTitle: () => $t('新增评估报告'),
    component: React.lazy(() =>
      import(
        '../pages/admin/Information/StudentDetail/NewEvaluationModal'
      ).then(m => ({
        default: m.NewEvaluationModal,
      })),
    ),
  },
  {
    name: 'InterventionModal',
    getTitle: () => $t('新增干预方案'),
    component: React.lazy(() =>
      import(
        '../pages/admin/Information/StudentDetail/NewInterventionModal'
      ).then(m => ({
        default: m.NewInterventionModal,
      })),
    ),
  },
  {
    name: 'PsychologicalFileIntrevention.BackgroundInfo',
    getTitle: () => $t('背景信息配置'),
    component: React.lazy(() =>
      import('../pages/admin/Information/Setting/BackgroundInfo').then(m => ({
        default: m.BackgroundInfo,
      })),
    ),
  },
  {
    name: 'EntryInterventionResultModal',
    getTitle: () => $t('录入干预结果'),
    component: React.lazy(() =>
      import(
        '../pages/admin/Information/StudentDetail/EntryInterventionResultModal'
      ).then(m => ({
        default: m.EntryInterventionResultModal,
      })),
    ),
  },
  {
    name: 'PsychologicalFileIntrevention.BackgroundInfoPreview',
    getTitle: () => $t('背景信息预览'),
    component: React.lazy(() =>
      import('../pages/admin/Information/Setting/Form/PreviewForm').then(m => ({
        default: m.PreviewForm,
      })),
    ),
  },
]

const psychologicalConsultsModalRoutes: ModalRouteNode[] = [
  {
    name: 'PluginPsyChatTimetableModal',
    getTitle: () => $t('查看排班'),
    component: React.lazy(() =>
      import(
        '../pages/admin/ConsultManagement/modals/ConsultantsTimetable'
      ).then(m => ({
        default: m.ConsultantsTimetable,
      })),
    ),
  },
  {
    name: 'PluginPsyChatStudentInitiateModal',
    getTitle: () => $t('预约心理咨询'),
    component: React.lazy(() =>
      import('../pages/user/PsyConsultModal').then(m => ({
        default: m.PsyConsultModal,
      })),
    ),
  },
  {
    name: 'PluginPsyChatResultModal',
    getTitle: () => $t('预约结果'),
    component: React.lazy(() =>
      import('../pages/user/PsyConsultModal/ChatResultModal').then(m => ({
        default: m.ChatResultModal,
      })),
    ),
  },
  {
    name: 'NewPsyChatFlowModal',
    getTitle: () => $t('发起咨询审批'),
    component: React.lazy(() =>
      import('../pages/user/NewPsyChatFlowModal').then(m => ({
        default: m.NewPsyChatFlowModal,
      })),
    ),
  },
  {
    name: 'Plugin.ChatSettingsModal',
    getTitle: () => $t('咨询审批设置'),
    component: React.lazy(() =>
      import('../pages/admin/ConsultManagement/PsyChatWorkflowSetting').then(
        m => ({
          default: m.PsyChatWorkflowSetting,
        }),
      ),
    ),
  },
  {
    name: 'PluginPsySetTimeSlotModal',
    getTitle: () => $t('设置咨询时段'),
    component: React.lazy(() =>
      import('@/plugins/features/chat/pages/user/SetTimesModal').then(m => ({
        default: m.SetTimesModal,
      })),
    ),
  },
  {
    name: 'PluginPsyChatTeacherCreateModal',
    getTitle: () => $t('创建咨询'),
    component: React.lazy(() =>
      import('@/plugins/features/chat/pages/user/CreateChatModal').then(m => ({
        default: m.CreateChatModal,
      })),
    ),
  },
  {
    name: 'PluginPsyChatDetailModal',
    getTitle: () => $t('咨询详情'),
    component: React.lazy(() =>
      import('@/plugins/features/chat/pages/user/ChatDetailModal').then(m => ({
        default: m.ChatDetailModal,
      })),
    ),
  },
]

export const modalRoutes = [
  ...psychologicalAssessmentModalRoutes,
  ...psychologicalReportModalRoutes,
  ...psychologicalProfileModalRoutes,
  ...psychologicalConsultsModalRoutes,
]
