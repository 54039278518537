/**
 * @file 我的档案 - 教师档案 routes slots
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { RoleEnum } from 'packages/sdks-next/chalk'

export const teacherProfilesRoutes: RouteConfig[] = [
  {
    path: 'teacher-profiles/form',
    name: 'PluginTeacherProfileFormEdit',
    requireRoles: [RoleEnum.Teacher],
    getTitle: () => $t('档案信息填报'),
    component: lazy(() =>
      import('../pages/user').then(m => ({
        default: m.TeacherProfileFormEdit,
      })),
    ),
  },
]
