/**
 * @file 行政班详情-成绩界面 subRoutes slots
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const adminClassGradeSubRoutesTail: RouteConfig[] = [
  {
    path: 'grades-health',
    name: 'AdminGradesHealth',
    getTitle: () => $t('体质成绩'),
    requireSchools: [142, 312],
    component: lazy(() =>
      import('@/plugins/features/cgr/components/HealthTable').then(m => ({
        default: m.AdminClassHealthTable,
      })),
    ),
  },
]
