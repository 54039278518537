import React, { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/exams/:examId/scores/gainedscore/:subjectId/manage',
    name: 'AdminExamScoresGainedScoreDetails',
    getTitle: () => $t('出题详情'),
    component: lazy(() =>
      import('@/plugins/features/third-party-scoring/pages/admin/index').then(
        m => ({
          default: m.AdminDetail,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'detail',
        name: 'Detail',
        getTitle: () => $t('全部小题'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/third-party-scoring/pages/GainedScore/Details'
          ).then(m => ({
            default: m.ExamGainedScoreDetailsEntry,
          })),
        ),
      },
      {
        path: 'score',
        name: 'Score',
        getTitle: () => $t('学生成绩'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/third-party-scoring/pages/admin/ScoreManagement'
          ).then(m => ({
            default: m.ScoreManagement,
          })),
        ),
      },
    ],
  },
]

export const userRoutes: RouteConfig[] = [
  {
    path: 'teacher/exams/question-list/detail/:semesterId/:subjectId',
    name: 'ThirdPartyScoringExamQuestionDetail',
    getTitle: () => $t('试题详情'),
    component: lazy(() =>
      import(
        '@/plugins/features/third-party-scoring/pages/user/ExamQuestionDetail'
      ).then(m => ({
        default: m.ExamQuestionDetail,
      })),
    ),
  },
]
