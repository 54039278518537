import { Getter } from 'jotai'

import { PermissionQuery } from 'packages/features/roles/types'
import {
  filterRoles,
  getRolesByIsManager,
  hasPermissionInScopeAssignments,
} from 'packages/features/roles/utils'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { getStoreForAtom } from '@/store'

/**
 * 生成一个 function，用于检查当前用户是否有指定的权限
 * 同时从 currentPermissions 和 currentRoles 中查找
 *
 * 此函数用于 atom 中（比如菜单 atom）
 * 在 packages/src/features/roles/hooks.ts 有一个相同的 hook 版本
 *
 * @param get - jotai get
 * @returns 判断函数
 */
export const hasCurrentPermissionFnAtom = (get: Getter) => {
  const store = getStoreForAtom(get)

  const currentPermissions = store.session.currentPermissions || []
  const currentManagedRoles = store.session.currentManagedRoles || []
  const currentManagerRoles = store.session.currentManagerRoles || []
  const canWriteUser = store.session.currentSchool?.chalkVer === '3.0'

  return (query: PermissionQuery) => {
    if (!canWriteUser && query.permission === PermissionNameEnum.CoreUserWrite)
      return false

    const inCurrPermissions =
      query.isManager !== false &&
      hasPermissionInScopeAssignments(currentPermissions, query.permission)

    const currRoles = getRolesByIsManager(
      currentManagerRoles,
      currentManagedRoles,
      query.isManager,
    )

    const inCurrRoles = !!filterRoles(currRoles, query).length
    return inCurrPermissions || inCurrRoles
  }
}
