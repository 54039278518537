import { atom } from 'jotai'

import { hasManageableParentPluginAtom } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuType } from 'packages/web-layout/types'

export const adminDataSyncMenu = atom(get => {
  const isManager = get(hasManageableParentPluginAtom(PluginNameEnum.DataSync))
  if (!isManager) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.其他],
      name: PluginNameEnum.DataSync,
      label: $t('数据采集'),
      path: '/admin/plugins/data-sync',
      type: MenuType.AdminApps,
      sort: 809,
      subMenus: [
        {
          label: $t('学生信息'),
          path: '/admin/plugins/data-sync/students',
          icon: 'Student',
        },
        {
          label: $t('教师信息'),
          path: '/admin/plugins/data-sync/teachers',
          icon: 'Teacher',
        },
        {
          label: $t('行政班信息'),
          path: '/admin/plugins/data-sync/admin-classes',
          icon: 'AdministrativeClass',
        },
        {
          label: $t('空间信息'),
          path: '/admin/plugins/data-sync/places',
          icon: 'Space',
        },
      ],
    },
  ]
})
