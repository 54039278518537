/**
 * @file 学分管理 - 侧边栏菜单
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasEnabledPluginAtom,
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { useHasCurrentPermission } from 'packages/features/roles'
import { $t } from 'packages/locale'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

import { getCreditPluginAnyPermission } from '@/plugins/features/credit/utils'

export const adminCreditMenu = atom(get => {
  if (!get(hasEnabledPluginAtom(PluginEnum.Credit))) {
    return []
  }

  const isPluginManager = get(hasManageableParentPluginAtom(PluginEnum.Credit))

  const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.Credit))[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: PluginEnum.Credit,
      path: '/admin/plugin/credit',
      label: $t('学分管理'),
      shortcutIcon,
      sort: 700,
      permission: (func: typeof useHasCurrentPermission) => {
        const permissions = getCreditPluginAnyPermission()

        for (const permission of permissions) {
          if (func({ permission })) {
            return true
          }
        }

        return isPluginManager
      },
      subMenus: [
        {
          label: $t('学生列表'),
          path: '/admin/plugin/credit/students',
          icon: 'Student',
        },
        {
          label: $t('学分结构'),
          path: '/admin/plugin/credit/structures',
          permission: [PermissionNameEnum.CreditWrite],
          icon: 'Structure',
        },
        {
          label: $t('认定学分'),
          path: '/admin/plugin/credit/awards',
          permission: [PermissionNameEnum.CreditWrite],
          // FIXME
          icon: 'Structure',
        },
        {
          label: $t('设置'),
          path: '/admin/plugin/credit/settings',
          icon: 'Setting',
          permission: () => isPluginManager,
        },
      ],
    },
  ]
})
