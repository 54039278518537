import React from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminAdjustmentMenu, userAdjustmentMenu } from './menus'
import { routes } from './routes'
import { calendarEvents } from './schedule-related/Schedule/calendar-events'
import { todos } from './todos'

export const initAdjustment: InitPluginFeature = {
  name: PluginNameEnum.Adjustment,
  init: () => ({
    routes,
    menus: [adminAdjustmentMenu, userAdjustmentMenu],
    todos,
    calendarEvents,
    workflowRenders: {
      bizType: PluginNameEnum.Adjustment,
      render: {
        TodoCard: React.lazy(() =>
          import('./todos/ApproveTodo').then(m => ({
            default: m.AdjustmentFlowTodoCard,
          })),
        ),
      },
    },
    slots: {
      userCommonApplicationsEntry: () =>
        import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
  }),
}
