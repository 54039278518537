import { atom } from 'jotai'

import {
  PluginEnum,
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuProvider } from 'packages/web-layout/menu-utils'
import { MenuItemUnion } from 'packages/web-layout/types'

export const adminVisitorCenterMenus: MenuProvider = atom(get => {
  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.VisitorCenter),
  )?.[0]

  return [
    {
      prefix: [PluginCategoryEnum.后勤管理],
      name: PluginNameEnum.VisitorCenter,
      path: '/admin/plugin/visitor-center',
      type: MenuType.AdminApps,
      label: $t('访客管理'),
      sort: 700,
      icon: 'Log' as const,
      shortcutIcon: getPluginIcon(enabledPlugin),
      permission: () => {
        return get(hasManageableParentPluginAtom(PluginNameEnum.VisitorCenter))
      },
      subMenus: [
        {
          path: '/admin/plugin/visitor-center/records',
          label: $t('访客记录'),
          icon: 'Log' as const,
        },
        {
          path: '/admin/plugin/visitor-center/permissions',
          label: $t('设置'),
          icon: 'Setting' as const,
        },
      ],
    },
  ] as MenuItemUnion[]
})
