import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const routes: RouteConfig[] = [
  {
    path: 'admin/plugin/visitor-center',
    name: 'AdminPluginVisitorCenterRecords',
    getTitle: () => $t('访客管理'),
    module: [PluginCategoryEnum.后勤管理, PluginNameEnum.VisitorCenter],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    requireManageablePlugins: [PluginNameEnum.VisitorCenter],
    subRoutes: [
      {
        path: 'records',
        name: 'AdminPluginVisitorCenterRecords',
        getTitle: () => $t('访客记录'),
        component: lazy(() =>
          import('./pages/admin/Records').then(m => ({
            default: m.VisitorRecords,
          })),
        ),
        requireManageablePlugins: [PluginNameEnum.VisitorCenter],
      },
      {
        path: 'permissions',
        name: 'AdminPluginVisitorCenterPermissions',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('./pages/admin/Permissions').then(m => ({
            default: m.Permissions,
          })),
        ),
        requireManageablePlugins: [PluginNameEnum.VisitorCenter],
      },
    ],
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.VisitorCenter.CallerRecordModal',
    getTitle: () => $t('邀请详情'),
    component: lazy(() =>
      import('./pages/admin/ModalCallerRecord').then(m => ({
        default: m.ModalCallerRecord,
      })),
    ),
  },
  {
    name: 'Plugin.VisitorCenter.PhoneIframeModal',
    getTitle: () => $t('访客系统'),
    component: lazy(() =>
      import('./pages/user/ModalPhoneIframe').then(m => ({
        default: m.ModalPhoneIframe,
      })),
    ),
  },
]
