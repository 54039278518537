/**
 * @file 学生心理档案-页面路由定义-前台
 */

import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { getPsyProfileSubRoutes } from './profile-subroutes'

export const userRoutes: RouteConfig[] = [
  {
    path: 'psychological-file/student-profiles',
    name: 'PsychologicalFileStudentProfile',
    getTitle: () => $t('学生心理档案'),
    module: [PluginEnum.PsychologicalFile],
    component: React.lazy(() =>
      import('../pages/user/StudentProfile').then(m => ({
        default: m.StudentProfile,
      })),
    ),
  },
  // 前台的档案详情
  {
    path: 'psychological-file/student-profiles/:profileId/:studentId/details',
    name: 'StudentPsychologicalProfile',
    getTitle: () => $t('心理档案详情'),
    component: React.lazy(() =>
      import('../pages/admin/Information/StudentDetail/index').then(m => ({
        default: m.Student,
      })),
    ),
    subRoutes: getPsyProfileSubRoutes(),
  },
  {
    path: 'psychological-file/report',
    name: 'PsychologicalFileReportUser',
    getTitle: () => $t('学生问题上报'),
    module: [PluginEnum.PsychologicalFile],
    component: React.lazy(() =>
      import('../pages/user').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'submission',
        name: 'Submission',
        getTitle: () => $t('我上报的'),
        component: React.lazy(() =>
          import('../pages/user/Reports').then(m => ({
            default: m.Reports,
          })),
        ),
      },
      {
        path: 'receive',
        name: 'Receive',
        getTitle: () => $t('我收到的'),
        component: React.lazy(() =>
          import('../pages/user/Reports').then(m => ({
            default: m.Reports,
          })),
        ),
      },
    ],
  },
  {
    path: 'psychological-file/consults',
    name: 'PsychologicalFileConsults',
    getTitle: () => $t('咨询列表'),
    module: [PluginEnum.PsychologicalFile],
    component: React.lazy(() =>
      import('../pages/user/Consults').then(m => ({
        default: m.Consults,
      })),
    ),
  },
]
