import React from 'react'

import { ChalkRegisterWorkflowRenderType } from '@/features/workflows/register'

export const workflowRenders: ChalkRegisterWorkflowRenderType = {
  bizType: 'chat',
  render: {
    // 管理中心 - 审批管理表格
    WorkflowAdminTable: React.lazy(() =>
      import('./AdminWorkflowTable').then(m => ({
        default: m.PsyAdminWorkflowTable,
      })),
    ),
    // 审批中心 - 发起审批
    NewFlowModal: React.lazy(() =>
      import('./NewFlowWarningModal').then(m => ({
        default: m.NewFlowWarningModal,
      })),
    ),
    // 查看审批单
    ShowFlowModal: React.lazy(() =>
      import('./ShowFlowModal').then(m => ({
        default: m.ShowPsyChatFlowModal,
      })),
    ),
  },
}
