/**
 * @file 群组待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.CustomizedGroupSignupCreated,
    Render: lazy(() =>
      import('./CreateTodoCard').then(m => ({ default: m.CreateTodoCard })),
    ),
  },
  {
    type: TodoTypeEnum.CustomizedGroupSignupSubmissionReview,
    Render: lazy(() =>
      import('./ReviewTodoCard').then(m => ({ default: m.ReviewTodoCard })),
    ),
  },
  {
    type: TodoTypeEnum.CustomizedGroupInputAttendance,
    Render: lazy(() =>
      import('./InputAttendance').then(m => ({
        default: m.InputAttendanceTodoCard,
      })),
    ),
  },
]
