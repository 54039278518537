import { IPIcon } from '@seiue/ui'
import { createEnumHelper } from '@seiue/util'

import { $t } from 'packages/locale'

export type Group = {
  icon: IPIcon
  label: string
  key: MenuSettingKey
}

export type MenuSetting = {
  [groupId: string]: Group & {
    menus: string[]
  }
}

export enum MenuSettingKey {
  AdminTeachingManagement = 'adminTeachingManagement',
  StudentDevelopment = 'studentDevelopment',
  TeacherDevelopment = 'teacherDevelopment',
  GeneralAffairsManagement = 'generalAffairsManagement',
  AdminServices = 'adminServices',
  CommonTools = 'commonTools',
  SystemManagement = 'systemManagement',
  Other = 'other',
}

export const settingKeyToLabel = createEnumHelper(() => ({
  [MenuSettingKey.AdminTeachingManagement]: $t('教务教学管理'),
  [MenuSettingKey.StudentDevelopment]: $t('学生发展'),
  [MenuSettingKey.TeacherDevelopment]: $t('教师发展'),
  [MenuSettingKey.GeneralAffairsManagement]: $t('总务管理'),
  [MenuSettingKey.AdminServices]: $t('行政服务'),
  [MenuSettingKey.CommonTools]: $t('通用工具'),
  [MenuSettingKey.SystemManagement]: $t('系统管理'),
  [MenuSettingKey.Other]: $t('其他'),
}))

const settingKeyToIcon = createEnumHelper({
  [MenuSettingKey.AdminTeachingManagement]: 'Report' as const,
  [MenuSettingKey.StudentDevelopment]: 'UserUp' as const,
  [MenuSettingKey.TeacherDevelopment]: 'TeacherDevelopment' as const,
  [MenuSettingKey.GeneralAffairsManagement]: 'LogisticsManagement' as const,
  [MenuSettingKey.AdminServices]: 'Archives' as const,
  [MenuSettingKey.CommonTools]: 'GeneralTools' as const,
  [MenuSettingKey.SystemManagement]: 'System' as const,
  [MenuSettingKey.Other]: 'Other' as const,
})

/**
 * 获取所有分组
 *
 * @returns 分组
 */
export const getGroups = (): Group[] => {
  return Object.values(MenuSettingKey).map(key => {
    return {
      key,
      label: settingKeyToLabel.get(key),
      icon: settingKeyToIcon.get(key),
    }
  })
}

/**
 * 获取默认的分组设置
 *
 * @returns 分组设置
 */
export const getDefaultGroupSetting = () => {
  return Object.values(MenuSettingKey).reduce((result, key) => {
    return {
      ...result,
      [key]: {
        key,
        label: settingKeyToLabel.get(key),
        icon: settingKeyToIcon.get(key),
        menus: [],
      },
    }
  }, {} as MenuSetting)
}
