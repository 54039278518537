/**
 * @file routes
 */

import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const getAdminRoutes = (isPluginManager: boolean): RouteConfig[] => {
  const subRoutes = [
    {
      path: 'list',
      name: 'List',
      getTitle: () => $t('申报列表'),
      component: lazy(() =>
        import('./pages/management/List').then(m => ({
          default: m.ClassDeclarationManagementList,
        })),
      ),
    },
  ]

  if (isPluginManager) {
    subRoutes.push({
      path: 'roles',
      name: 'Roles',
      getTitle: () => $t('权限设置'),
      component: lazy(() =>
        import('./pages/management/Roles').then(m => ({
          default: m.ClassDeclarationManagementRoles,
        })),
      ),
    })
  }

  return [
    {
      path: 'admin/plugin/class_declaration',
      name: 'AdminClassDeclaration',
      module: [PluginCategoryEnum.教务教学, SchoolPluginsEnum.ClassDeclaration],
      getTitle: () => $t('课程申报管理'),
      component: lazy(() =>
        Promise.resolve({
          default: RouteMenus,
        }),
      ),
      subRoutes,
    },
    {
      path: 'admin/plugin/class_declaration/roles/new',
      getTitle: () => $t('创建授权管理角色'),
      component: lazy(() =>
        import('./pages/management/Roles/Edit').then(m => ({
          default: m.EditRole,
        })),
      ),
    },
    {
      path: 'admin/plugin/class_declaration/roles/:roleId/edit',
      getTitle: () => $t('编辑授权管理角色'),
      component: lazy(() =>
        import('./pages/management/Roles/Edit').then(m => ({
          default: m.EditRole,
        })),
      ),
    },
    {
      path: 'admin/plugin/class_declaration/:id/detail',
      name: 'AdminClassDeclarationDetail',
      getTitle: () => $t('课程申报详情'),
      component: lazy(() =>
        import('./pages/management/Management').then(m => ({
          default: m.ClassDeclarationManagement,
        })),
      ),
      subRoutes: [
        {
          path: 'list',
          name: 'List',
          getTitle: () => $t('申报列表'),
          component: lazy(() =>
            import('./pages/management/Management/List').then(m => ({
              default: m.ClassDeclarationManagementList,
            })),
          ),
        },
        {
          path: 'classes',
          name: 'Classes',
          getTitle: () => $t('课程发布'),
          component: lazy(() =>
            import('./pages/management/Management/DraftClasses').then(m => ({
              default: m.ClassDeclarationManagementDraftClasses,
            })),
          ),
        },
        {
          path: 'result',
          name: 'Result',
          getTitle: () => $t('结果统计'),
          component: lazy(() =>
            import('./pages/management/Management/Result').then(m => ({
              default: m.ClassDeclarationManagementResult,
            })),
          ),
        },
        {
          path: 'setting',
          name: 'Setting',
          getTitle: () => $t('申报设置'),
          component: lazy(() =>
            import('./pages/management/Management/Setting').then(m => ({
              default: m.ClassDeclarationManagementSetting,
            })),
          ),
        },
      ],
    },
    {
      path: 'admin/plugin/class_declaration/:id/classes/batch-edit',
      name: 'DraftClassBatchEdit',
      getTitle: () => $t('批量编辑课程'),
      component: lazy(() =>
        import(
          './pages/management/Management/DraftClasses/DraftClass/BatchEdit'
        ).then(m => ({
          default: m.DraftClassBatchEdit,
        })),
      ),
    },
    {
      path: 'admin/plugin/class_declaration/:id/classes/:classId',
      name: 'DraftClass',
      getTitle: () => $t('课程信息'),
      component: lazy(() =>
        import(
          './pages/management/Management/DraftClasses/DraftClass/Detail'
        ).then(m => ({
          default: m.DraftClassDetail,
        })),
      ),
      subRoutes: [
        {
          path: 'info',
          name: 'Info',
          getTitle: () => $t('课程信息'),
          component: lazy(() =>
            import(
              './pages/management/Management/DraftClasses/DraftClass/Info'
            ).then(m => ({
              default: m.DraftClassInfo,
            })),
          ),
        },
        {
          path: 'members',
          name: 'Members',
          getTitle: () => $t('人员管理'),
          component: lazy(() =>
            import(
              './pages/management/Management/DraftClasses/DraftClass/Members'
            ).then(m => ({
              default: m.DraftClassMembers,
            })),
          ),
        },
      ],
    },
    {
      path: 'admin/plugin/class_declaration/:id/classes/:classId/edit',
      name: 'DraftClassEdit',
      getTitle: () => $t('编辑课程'),
      component: lazy(() =>
        import(
          './pages/management/Management/DraftClasses/DraftClass/Edit'
        ).then(m => ({
          default: m.DraftClassEdit,
        })),
      ),
    },
  ]
}

export const appRoutes: RouteConfig[] = [
  {
    path: 'plugin/class_declarations',
    name: 'ClassDeclarationList',
    module: [SchoolPluginsEnum.ClassDeclaration],
    getTitle: () => $t('课程申报列表'),
    component: lazy(() =>
      import('./pages/main/List').then(m => ({
        default: m.ClassDeclarationList,
      })),
    ),
  },
  {
    path: 'plugin/class_declarations/:id/forms',
    name: 'ClassDeclarationForms',
    getTitle: () => $t('课程申报表单'),
    component: lazy(() =>
      import('./pages/main/Forms').then(m => ({
        default: m.ClassDeclarationForms,
      })),
    ),
  },
  {
    path: 'plugin/class_declarations/:id/forms/new',
    name: 'ClassDeclarationNew',
    getTitle: () => $t('新建课程申报表单'),
    component: lazy(() =>
      import('./pages/main/Modal').then(m => ({
        default: m.ClassDeclarationNew,
      })),
    ),
  },
  {
    path: 'plugin/class_declarations/:id/forms/:formId/edit',
    name: 'ClassDeclarationEdit',
    getTitle: () => $t('编辑课程申报表单'),
    component: lazy(() =>
      import('./pages/main/Modal').then(m => ({
        default: m.ClassDeclarationEdit,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'ClassDeclarationEditModal',
    getTitle: () => '',
    component: lazy(() =>
      import('./pages/management/Modal').then(m => ({
        default: m.ClassDeclarationModal,
      })),
    ),
  },
]
