/**
 * @file menus
 */

import { atom } from 'jotai'

import { HandoutPluginSettings } from 'packages/feature-utils/handout/types/plugin-settings'
import { gradeGroupIdsToMenus } from 'packages/feature-utils/handout/utils/grade-groups'
import {
  PluginEnum,
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
} from 'packages/feature-utils/plugins'
import { useHasCurrentPermission } from 'packages/features/roles'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'
import { MenuProvider } from 'packages/web-layout/menu-utils'

export const adminHandoutMenu: MenuProvider = atom(get => {
  const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.Handout))?.[0]
  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  const enabledGradeGroupIds =
    (enabledPlugin.settings as HandoutPluginSettings | null)?.bankSettings
      ?.gradeGroupIds ?? []

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      permission: (func: typeof useHasCurrentPermission) => {
        return (
          func({
            permission: PermissionNameEnum.HandoutRead,
          }) ||
          func({
            permission: PermissionNameEnum.BankRead,
          }) ||
          func({
            permission: PermissionNameEnum.CorePluginWrite,
          }) ||
          func({
            permission: PermissionNameEnum.CorePluginRead,
          }) ||
          get(hasManageableParentPluginAtom(PluginNameEnum.Handout))
        )
      },
      name: PluginNameEnum.Handout,
      path: '/admin/plugin/handouts',
      label: $t('学案库管理'),
      icon: 'Archives' as const,
      shortcutIcon,
      sort: 700,
      subMenus: [
        {
          path: '/admin/plugin/handouts',
          label: $t('学案库管理'),
          permission: (func: typeof useHasCurrentPermission) => {
            return (
              func({
                permission: PermissionNameEnum.HandoutRead,
              }) ||
              func({
                permission: PermissionNameEnum.CorePluginRead,
              }) ||
              get(hasManageableParentPluginAtom(PluginNameEnum.Handout))
            )
          },
          icon: 'Archives' as const,
        },
        ...gradeGroupIdsToMenus(enabledGradeGroupIds, get),
        {
          path: '/admin/plugin/handouts/settings',
          permission: (func: typeof useHasCurrentPermission) => {
            return (
              func({
                permission: PermissionNameEnum.CorePluginRead,
              }) ||
              func({
                permission: PermissionNameEnum.CorePluginWrite,
              }) ||
              get(hasManageableParentPluginAtom(PluginNameEnum.Handout))
            )
          },
          label: $t('设置'),
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})
