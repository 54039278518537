/**
 * @file 社团
 */

import { lazy } from 'react'

import { AppNameEnum } from 'packages/no-code/apps/types'

import { InitPluginFeature } from '@/plugins/types'

export const initClub: InitPluginFeature = {
  name: AppNameEnum.Club,
  init: () => ({
    slots: {
      CustomGroupPageHerderRight: lazy(() =>
        import('./slots/ExitClubButtonSlot').then(m => ({
          default: m.ExitClubButtonSlot,
        })),
      ),
      CustomGroupPageSignUp: lazy(() =>
        import('./slots/SignUpButtonSlot').then(m => ({
          default: m.SignUpButtonSlot,
        })),
      ),
    },
  }),
}
