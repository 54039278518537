import { State } from '@seiue/ui'
import { first } from '@seiue/util'
import React, { useMemo } from 'react'

import { TermTypeEnum } from 'packages/features/terms/types'
import { useRouteAuthorization } from 'packages/route'
import { NestedRoute, NotFound } from 'packages/route/components'
import { termApi$queryTerm } from 'packages/sdks-next/chalk'

import { getPath, RouteComponent } from '@/router'

/**
 * 报表中心基础路由
 *
 * @param param - Argument Object
 * @param param.route - 路由信息
 * @returns React.ReactNode
 */
export const BasicRoute: RouteComponent = ({ route }) => {
  // 判断是否有权限访问路由的函数
  const authRoute = useRouteAuthorization()

  // 用 authRoute 过滤
  const finalRoutes = useMemo(
    () => route?.subRoutes || [].filter(authRoute),
    [route, authRoute],
  )

  const defaultRoute = finalRoutes[0]

  const { data: categories, loading } = termApi$queryTerm.useApi(
    {
      type: TermTypeEnum.ReportCategory,
    },
    {
      disable: defaultRoute.name !== 'AdminReportCenter.Reports.List',
    },
  )

  // 处理无分类时的跳转
  const redirectTo = useMemo(() => {
    const firstCategory = first(categories)
    if (defaultRoute.name === 'AdminReportCenter.Reports.List') {
      if (firstCategory?.id) {
        return defaultRoute.path.replace(':categoryId', `${firstCategory.id}`)
      }

      return getPath('AdminReportCenter.ReportPublish', {})
    }

    return defaultRoute.path
  }, [categories, defaultRoute.name, defaultRoute.path])

  if (!finalRoutes.length) {
    return <NotFound />
  }

  return loading ? (
    <State.Loading />
  ) : (
    <NestedRoute
      routes={finalRoutes}
      redirect={{
        to: redirectTo,
      }}
    />
  )
}
