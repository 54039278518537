import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

/**
 * 学生档案课程数据看板
 */
export const initMoonshotBoard: InitPluginFeature = {
  name: PluginNameEnum.MoonshotBoard,
}
