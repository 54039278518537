import React, { lazy } from 'react'

import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = [
  {
    path: 'plugin/teacher/teaching-satisficing/:id/results',
    name: 'PluginTeacherSatisficingResults',
    getTitle: () => $t('满意度调查结果'),
    component: React.lazy(() =>
      import(
        '@/plugins/features/sz-teaching-satisficing/pages/teacher/SatisficingResults/index'
      ).then(m => ({
        default: m.SatisficingResults,
      })),
    ),
    subRoutes: [
      {
        path: 'admin-score',
        name: 'AdminScore',
        getTitle: () => $t('学科分数统计'),
        component: lazy(() =>
          import('./pages/teacher/SatisficingResults/StatisticsByScore').then(
            m => ({
              default: m.SatisficingResultsByScore,
            }),
          ),
        ),
      },
      {
        path: 'my-stat',
        name: 'MyStat',
        getTitle: () => $t('个人结果统计'),
        component: lazy(() =>
          import('./pages/teacher/SatisficingResults/MyStat').then(m => ({
            default: m.MyStat,
          })),
        ),
        subRoutes: [
          {
            path: 'by-score',
            name: 'ByScore',
            getTitle: () => $t('分数统计'),
            component: lazy(() =>
              import('./pages/teacher/SatisficingResults/TeacherTables').then(
                m => ({
                  default: m.TeacherTables,
                }),
              ),
            ),
          },
          {
            path: 'by-questions',
            name: 'ByQuestions',
            getTitle: () => $t('分题统计'),
            component: lazy(() =>
              import(
                './pages/teacher/SatisficingResults/StatisticsByQuestion'
              ).then(m => ({
                default: m.StatisticsByQuestion,
              })),
            ),
          },
        ],
      },
      {
        path: 'by-score',
        name: 'ByScore',
        getTitle: () => $t('分数统计'),
        component: lazy(() =>
          import('./pages/teacher/SatisficingResults/TeacherTables').then(
            m => ({
              default: m.TeacherTables,
            }),
          ),
        ),
      },
      {
        path: 'by-questions',
        name: 'ByQuestions',
        getTitle: () => $t('分题统计'),
        component: lazy(() =>
          import(
            './pages/teacher/SatisficingResults/StatisticsByQuestion'
          ).then(m => ({
            default: m.StatisticsByQuestion,
          })),
        ),
      },
    ],
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.TeachingSatisficingSetting',
    getTitle: () => $t('设置'),
    component: React.lazy(() =>
      import('./pages/admin/TeachingSatisficingSetting').then(m => ({
        default: m.TeachingSatisficingSetting,
      })),
    ),
  },
]
