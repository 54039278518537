/**
 * @file 人事管理
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminPersonnelProfileMenu } from './menus'
import { routes } from './routes'

export const initPersonnelProfile: InitPluginFeature = {
  name: PluginNameEnum.PersonnelProfile,
  init: () => ({
    setting: {
      type: 'module' as const,
      component: lazy(() =>
        import('./pages/Setting').then(m => ({
          default: m.Setting,
        })),
      ),
    },
    routes,
    menus: [adminPersonnelProfileMenu],
    slots: {
      adminQuestionnairePickerInfo: () =>
        import('./slots/data-slots').then(m => m.adminQuestionnairePickerInfo),
    },
  }),
}
