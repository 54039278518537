/**
 * @file 动态菜单
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { useHasCurrentPermission } from 'packages/features/roles'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'
import { MenuType, MenuItemUnion } from 'packages/web-layout/types'

export const adminCdMenu = atom(get => {
  const isPluginManager = get(
    hasManageableParentPluginAtom(PluginEnum.ClassDeclaration),
  )

  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.ClassDeclaration),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: SchoolPluginsEnum.ClassDeclaration,
      path: '/admin/plugin/class_declaration',
      label: $t('课程申报管理'),
      sort: 200,
      icon: 'CourseDeclaration' as const,
      shortcutIcon,
      permission: (func: typeof useHasCurrentPermission) => {
        return (
          func({
            permission: PermissionNameEnum.CoreDeclarationWrite,
            isManager: true,
          }) || isPluginManager
        )
      },
      subMenus: [
        {
          label: $t('申报列表'),
          path: '/admin/plugin/class_declaration/list',
          icon: 'CourseDeclaration' as const,
        },
        {
          label: $t('权限设置'),
          icon: 'Setting' as const,
          path: '/admin/plugin/class_declaration/roles',
        },
      ],
    },
  ]
})

export const userCdMenu = atom(get => {
  if (!get(hasUsablePluginAtom(PluginEnum.ClassDeclaration))) {
    return []
  }

  const subMenus: MenuItemUnion[] = [
    {
      path: '/plugin/class_declarations',
      label: $t('课程申报'),
      icon: 'CourseDeclaration' as const,
    },
  ]

  if (get(hasManageableParentPluginAtom(PluginEnum.ClassDeclaration))) {
    subMenus.push({
      path: '/admin/plugin/class_declaration',
      label: $t('前往申报管理'),
      icon: 'Backstage' as const,
    })
  }

  return [
    {
      name: SchoolPluginsEnum.ClassDeclaration,
      path: '/plugin/class_declarations',
      label: $t('课程申报'),
      type: MenuType.Apps,
      subMenus,
    },
  ]
})
