/**
 * @file 调代课路由定义
 */

import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { RouteMenus } from '@/router'

export const routes: RouteConfig[] = [
  {
    path: 'admin/class-adjustments',
    name: 'AdminClassAdjustment',
    module: ['courses'],
    getTitle: () => $t('调代课管理'),
    component: lazy(() =>
      import('./pages/admin').then(m => ({
        default: m.AdminClassAdjustmentIndex,
      })),
    ),
    requireManageablePlugins: [ModuleEnum.Adjustment],
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('调代课列表'),
        component: lazy(() =>
          import('./pages/admin/List').then(m => ({
            default: m.AdminClassAdjustmentList,
          })),
        ),
      },
      {
        path: 'setting',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('./pages/admin/Setting').then(m => ({
            default: m.AdminClassAdjustmentSetting,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/class-adjustments/flow-setting',
    name: 'AdminClassAdjustmentFlowSetting',
    getTitle: () => $t('设置审批'),
    component: lazy(() =>
      import('./pages/admin/Setting/FlowSetting').then(m => ({
        default: m.AdminClassAdjustmentFlowSetting,
      })),
    ),
  },
  {
    path: 'admin/class-adjustments/:id/detail',
    name: 'AdminClassAdjustmentDetail',
    getTitle: () => $t('调代课详情'),
    component: lazy(() =>
      import('./pages/Detail').then(m => ({
        default: m.ClassAdjustmentDetail,
      })),
    ),
  },
  {
    path: 'admin/class-adjustments/:semesterId/new/:type',
    name: 'AdminClassAdjustmentNew',
    getTitle: () => $t('新增调代课'),
    component: lazy(() =>
      import('./pages/New').then(m => ({
        default: m.NewClassAdjustment,
      })),
    ),
    requireManageablePlugins: [ModuleEnum.Adjustment],
  },
  {
    path: 'class-adjustments/:semesterId/new/:type',
    name: 'ClassAdjustmentNew',
    getTitle: () => $t('新增调代课'),
    component: lazy(() =>
      import('./pages/New').then(m => ({
        default: m.NewClassAdjustment,
      })),
    ),
  },
  {
    path: 'class-adjustments/:id/detail',
    name: 'ClassAdjustmentDetail',
    getTitle: () => $t('调代课详情'),
    component: lazy(() =>
      import('./pages/Detail').then(m => ({
        default: m.ClassAdjustmentDetail,
      })),
    ),
  },
  {
    path: 'class-adjustments',
    name: 'WorkspaceClassAdjustments',
    getTitle: () => $t('调代课'),
    module: [SchoolModulesEnum.Adjustment],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'applied',
        name: 'Applied',
        getTitle: () => $t('我申请的'),
        component: lazy(() =>
          import('./pages/List/CommonList').then(m => ({
            default: m.AppliedList,
          })),
        ),
      },
      {
        path: 'involved',
        name: 'Involved',
        getTitle: () => $t('我参与的'),
        component: lazy(() =>
          import('./pages/List/CommonList').then(m => ({
            default: m.InvolvedList,
          })),
        ),
      },
      {
        path: 'audited',
        name: 'Audited',
        getTitle: () => $t('我审核的'),
        component: lazy(() =>
          import('./pages/List/CommonList').then(m => ({
            default: m.AuditedList,
          })),
        ),
      },
      {
        path: 'cced',
        name: 'Cced',
        getTitle: () => $t('抄送我的'),
        component: lazy(() =>
          import('./pages/List/CommonList').then(m => ({
            default: m.CcedList,
          })),
        ),
      },
    ],
  },
]
