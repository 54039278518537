/**
 * @file 审批流
 */
import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { modalRoutes } from './routes'
import { workflowRenders } from './workflow-renders'

export const initApproval: InitPluginFeature = {
  name: PluginNameEnum.Approval,
  init: () => ({
    setting: {
      type: 'extendable',
      component: lazy(() =>
        import('./pages/Settings').then(m => ({ default: m.Setting })),
      ),
    },
    modalRoutes,
    workflowRenders,
  }),
}
