/**
 * @file 十五中成绩报告插件
 */

import { lazy } from 'react'

import { InitPluginFeature } from '@/plugins/types'

import { tabSlot } from './slot/tabSlot'

export const initFifteenGradesReports: InitPluginFeature = {
  name: 'bj15g-grades-reports',
  init: () => ({
    slots: {
      exportGradesReportItem: () =>
        import('./slot/export-grades-report-item').then(
          m => m.exportGradesReportItem,
        ),
      adminClassGradeSettings: lazy(() =>
        import('./slot/class-year-academic-setting').then(m => ({
          default: m.ClassGradeYearAcademicSetting,
        })),
      ),
      adminClassGradeSubRoutes: tabSlot,
    },
  }),
}
