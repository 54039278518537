/**
 * @file 补考 routes
 */

import { atom } from 'jotai'

import { hasManageableParentPluginAtom } from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminResitMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(SchoolPluginsEnum.ExamResit))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: SchoolPluginsEnum.ExamResit,
      label: $t('补考管理'),
      path: '/admin/plugin/resit',
      icon: 'MakeupExam' as const,
      sort: 708,
      subMenus: [
        {
          label: $t('全部补考'),
          path: '/admin/plugin/resit',
          icon: 'MakeupExam' as const,
        },
      ],
    },
  ]
})
