import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { isMenuOpenedAtom } from 'packages/feature-utils/menus'
import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuItemUnion } from 'packages/web-layout/types'

import { getStoreForAtom } from '@/store'

import {
  hasAuditAssessmentsAtom,
  hasManageAssessmentsAtom,
  hasTypeInAssessmentsAtom,
} from './atoms'

export const adminAssessmentMenu = atom(get => {
  const isManager = get(
    hasManageableParentPluginAtom(PluginEnum.TeacherAssessments),
  )

  if (!isManager) {
    return []
  }

  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.TeacherAssessments),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.教师发展],
      name: PluginEnum.TeacherAssessments,
      label: $t('教师考核'),
      path: '/admin/teacher-assessments',
      sort: 707,
      type: MenuType.AdminApps,
      icon: 'MoralEvaluation' as const,
      shortcutIcon,
      subMenus: [
        {
          label: $t('全部考核'),
          path: '/admin/teacher-assessments/list',
          icon: 'MoralEvaluation' as const,
        },
        {
          label: $t('操作日志'),
          path: '/admin/teacher-assessments/logs',
          icon: 'Log' as const,
        },
        {
          label: $t('设置'),
          path: '/admin/teacher-assessments/settings',
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})

export const userAssessmentMenu = loadable(
  atom(get => {
    const store = getStoreForAtom(get)
    const me = store.session.currentReflection
    if (!me) {
      return []
    }

    if (!get(hasUsablePluginAtom(PluginEnum.TeacherAssessments))) {
      return []
    }

    const menuOpened = get(isMenuOpenedAtom([PluginEnum.TeacherAssessments]))

    const hasTypeIn = menuOpened ? get(hasTypeInAssessmentsAtom) : false

    const hasAudit = menuOpened ? get(hasAuditAssessmentsAtom) : false

    const hasManage = menuOpened ? get(hasManageAssessmentsAtom) : false

    const subMenus: MenuItemUnion[] = []

    if (hasTypeIn) {
      subMenus.push({
        label: $t('我录入的'),
        path: '/teacher-assessments/type-in',
        icon: 'PenLine' as const,
      })
    }

    if (hasAudit) {
      subMenus.push({
        label: $t('我审核的'),
        path: '/teacher-assessments/audit',
        icon: 'Approval' as const,
      })
    }

    if (hasManage) {
      subMenus.push({
        label: $t('我管理的'),
        path: '/teacher-assessments/manage',
        icon: 'ManagedByMe' as const,
      })
    }

    subMenus.push({
      label: $t('我的考核'),
      path: '/teacher-assessments/assess-me',
      icon: 'SchoolReport' as const,
    })

    return [
      {
        type: MenuType.Apps,
        name: PluginEnum.TeacherAssessments,
        label: $t('教师考核'),
        subMenus,
      },
    ]
  }),
)
