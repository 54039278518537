/**
 * @file 学生心理档案-路由
 */

import { omit } from '@seiue/util'

import { adminRoutes } from './admin-routes'
import { userRoutes } from './user-routes'

export { modalRoutes } from './modal-routes'

export const routes = (isPluginManager: boolean) => [
  ...[...adminRoutes, ...userRoutes].map(item => {
    if (isPluginManager) {
      return omit(item, 'requirePermissions')
    }

    return item
  }),
]
