/**
 * @file 课程库 Routes slot
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const profileCourseHeadSubRoutes: RouteConfig[] = [
  {
    path: 'handout',
    name: 'CourseGroupHandout',
    getTitle: () => $t('学案'),
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/user/Course').then(m => ({
        default: m.CourseGroupHandout,
      })),
    ),
  },
]
