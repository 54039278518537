import { hasManageablePlugin } from 'packages/feature-utils/plugins'
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminCdMenu, userCdMenu } from './menus'
import { getAdminRoutes, appRoutes, modalRoutes } from './routes'
import { todos } from './todos'

export const initClassDeclarations: InitPluginFeature = {
  name: PluginNameEnum.ClassDeclaration,
  init: plugins => ({
    routes: [...getAdminRoutes(hasManageablePlugin(plugins)), ...appRoutes],
    menus: [adminCdMenu, userCdMenu],
    modalRoutes,
    todos,
    slots: {
      scopesBoardMetasWithoutDomainSlots: () =>
        import('./slots').then(m => m.scopesBoardMetasWithoutDomainSlots),
      userCommonApplicationsEntry: () =>
        import('./slots').then(m => m.CommonUseApplicationsEntry),
    },
  }),
}
