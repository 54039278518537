/**
 * @file 深中教学满意度调查插件
 */
import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import {
  routes,
  modalRoutes,
} from '@/plugins/features/sz-teaching-satisficing/routes'
import { InitPluginFeature } from '@/plugins/types'

export const initSzTeachingSatisficing: InitPluginFeature = {
  name: PluginNameEnum.SzTeachSatisficing,
  /*
   * 此处是因为关闭插件后注册的除 adminEvaluationViewHeaderRight 内容外都要生效
   * adminEvaluationViewHeaderRight 在自己内部做了判断
   */
  force: true,
  init: () => ({
    slots: {
      adminEvaluationViewHeaderRight: lazy(() =>
        import('./slots/AdminEvaluationViewHeaderRight').then(m => ({
          default: m.AdminEvaluationViewHeaderRight,
        })),
      ),
      adminEvaluationsStatisticsByScoreOverride: () =>
        import('./slots/data-slots').then(
          m => m.adminEvaluationsStatisticsByScoreOverride,
        ),
      adminEvaluationsSendResultOverride: () =>
        import('./slots/adminEvaluationsSendResultOverride').then(
          m => m.adminEvaluationsSendResultOverride,
        ),
    },
    routes,
    modalRoutes,
  }),
}
