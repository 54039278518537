/**
 * @file 异常考勤短信提醒
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initAbnormalAttendSmsNotify: InitPluginFeature = {
  name: PluginNameEnum.AbnormalAttendSms,
}
