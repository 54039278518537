/**
 * @file 靠谱 ai 工具
 */

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initKaopuAiTool: InitPluginFeature = {
  name: PluginNameEnum.KaopuAiTool,
  init: () => ({
    setting: {
      type: 'json',
      component: {
        appendSchema: () => [
          {
            name: 'clientId',
            getTitle: () => 'Client ID',
            type: 'string',
            required: true,
            'x-index': 0,
            'x-component-props': {
              placeholder: $t('请输入 Client ID'),
              style: {
                width: '320px',
              },
            },
          },
          {
            name: 'clientSecret',
            getTitle: () => 'Client Secret',
            type: 'string',
            required: true,
            'x-index': 1,
            'x-component-props': {
              placeholder: $t('请输入 Client Secret'),
              style: {
                width: '320px',
              },
            },
          },
        ],
        mapping: {
          'settings.clientId': 'clientId',
          'settings.clientSecret': 'clientSecret',
        },
      },
    },
    slots: {
      userCommonApplicationsEntry: () =>
        import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
  }),
}
