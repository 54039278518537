import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RoleEnum } from 'packages/sdks-next/chalk'

export const studentProfileRoutes = [
  {
    path: 'profiles/me/report-center/:id',
    name: 'StudentProfilesReportDetail',
    requireRoles: [RoleEnum.Student],
    getTitle: () => $t('我的报表'),
    component: lazy(() =>
      import('../pages/user/reportPublish/ReceiverReportDetail').then(m => ({
        default: m.ReceiverReportDetail,
      })),
    ),
  },
  {
    path: 'admin/profiles/:rid/report-center/:id',
    name: 'AdminStudentProfilesReportDetail',
    requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow, RoleEnum.Guardian],
    getTitle: () => $t('我的报表'),
    component: lazy(() =>
      import('../pages/user/reportPublish/ReceiverReportDetail').then(m => ({
        default: m.ReceiverReportDetail,
      })),
    ),
  },
]
