/**
 * @file 请假 - 路由定义
 */

import React, { lazy } from 'react'

import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'

import { RouteMenus } from '@/router'

export const routes: RouteConfig[] = [
  {
    name: 'PluginAdminAbsence',
    path: 'admin/plugin/absence',
    module: ['absences'],
    getTitle: () => $t('请假管理'),
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        name: 'SummaryForStudent',
        path: 'summary/student',
        getTitle: () => $t('学生请假'),
        component: lazy(() =>
          import('./pages/admin/Summary').then(m => ({
            default: m.SummaryForStudent,
          })),
        ),
        subRoutes: [
          {
            name: 'List',
            path: 'list',
            getTitle: () => $t('全部请假'),
            component: lazy(() =>
              import('./pages/admin/Summary/List').then(m => ({
                default: m.List,
              })),
            ),
          },
          {
            name: 'Stats',
            path: 'stats',
            getTitle: () => $t('请假统计'),
            component: lazy(() =>
              import('./pages/admin/Summary/Stat').then(m => ({
                default: m.Stat,
              })),
            ),
          },
        ],
      },
      {
        name: 'SummaryForTeacher',
        path: 'summary/teacher',
        getTitle: () => $t('教师请假'),
        component: lazy(() =>
          import('./pages/admin/Summary').then(m => ({
            default: m.SummaryForTeacher,
          })),
        ),
        subRoutes: [
          {
            name: 'List',
            path: 'list',
            getTitle: () => $t('全部请假'),
            component: lazy(() =>
              import('./pages/admin/Summary/List').then(m => ({
                default: m.List,
              })),
            ),
          },
          {
            name: 'Stats',
            path: 'stats',
            getTitle: () => $t('请假统计'),
            component: lazy(() =>
              import('./pages/admin/Summary/Stat').then(m => ({
                default: m.Stat,
              })),
            ),
          },
        ],
      },
    ],
  },
  {
    name: 'PluginAdminAbsenceWorkflow',
    path: 'admin/plugin/absence/workflows/:schoolPluginId',
    getTitle: () => $t('请假流程'),
    component: lazy(() =>
      import('@/features/workflows/pages/admin/index').then(m => ({
        default: m.AdminWorkflow,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('全部审批'),
        component: React.lazy(() =>
          import('@/features/workflows/pages/admin/WorkflowList').then(m => ({
            default: m.AdminWorkflowList,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('@/features/workflows/pages/admin/Settings').then(m => ({
            default: m.AdminWorkflowSettings,
          })),
        ),
      },
    ],
  },
  {
    name: 'PluginAbsenceRecords',
    path: 'admin/plugin/absence/records/:rid/:name',
    getTitle: () => $t('请假记录'),
    component: lazy(() =>
      import('./pages/user/Record').then(m => ({
        default: m.AbsenceRecords,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.AbsenceSettingsModal',
    getTitle: () => $t('配置请假'),
    component: React.lazy(() =>
      import('./pages/Settings').then(m => ({
        default: m.AbsenceSettingsRouteModal,
      })),
    ),
  },
  {
    name: 'Plugin.AbsenceAgentDetail',
    getTitle: () => $t('代请假详情'),
    component: React.lazy(() =>
      import('./pages/user/AgentAbsenceDetail').then(m => ({
        default: m.AgentAbsenceDetailModal,
      })),
    ),
  },
  {
    name: 'Plugin.NewAbsenceFlowModal',
    getTitle: () => $t('新建请假'),
    component: React.lazy(() =>
      import('./workflow/NewAbsenceFlowModal').then(m => ({
        default: m.NewAbsenceFlowModal,
      })),
    ),
  },
]
