/**
 * @file 通用群组日程
 */

import { lazy } from 'react'

import { CalendarEventType } from 'packages/features/calendars/utils/types'
import { $t } from 'packages/locale'

export const calendarEvents = [
  {
    type: CalendarEventType.Chat,
    Component: lazy(() =>
      import('./ScheduleChat').then(m => ({ default: m.ScheduleChat })),
    ),
    getEventTag: () => $t('约谈'),
  },
]
