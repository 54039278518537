/**
 * @file 深中成绩报告插件
 */

import { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@/plugins/types'

import { adminGzGradesMenu, userGzGradesMenu } from './menus'
import { routes } from './routes'

export const initSzGradesReports: InitPluginFeature = {
  name: PluginEnum.SzGradesReports,
  init: () => ({
    routes,
    menus: [adminGzGradesMenu, userGzGradesMenu],
    slots: {
      adminClassMemberHeaderRight: lazy(() =>
        import('./slots/AdminMemberHeaderRightForSZ').then(m => ({
          default: m.AdminMemberHeaderRightForSZ,
        })),
      ),
      exportGradesReportItem: () =>
        import('./slots/export-grades-report-item').then(
          m => m.ExportGradesReportItem,
        ),
    },
  }),
}
