/**
 * @file 数据大屏 menus
 */

import { atom } from 'jotai'

import {
  PluginEnum,
  hasManageableParentPluginAtom,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuProvider } from 'packages/web-layout/menu-utils'

export const adminDataScreenMenu: MenuProvider = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.DataScreen))) {
    return []
  }

  return [
    {
      path: '/admin/plugin/data-screen',
      prefix: [PluginCategoryEnum.教务教学],
      name: PluginEnum.DataScreen,
      label: $t('数据大屏管理'),
      icon: 'ClassInBgSystem' as const,
      type: MenuType.AdminApps,
      sort: 709,
      permission: () =>
        get(hasManageableParentPluginAtom(PluginEnum.DataScreen)),
      subMenus: [
        {
          label: $t('数据看板'),
          icon: 'ClassInBgSystem' as const,
          path: '/admin/plugin/data-screen/board',
        },
      ],
    },
  ]
})
