/**
 * @file route
 */

import React from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/contracts',
    name: 'PluginContractForAdmin.ContractList',
    module: [PluginCategoryEnum.学生成长, PluginNameEnum.Contract],
    getTitle: () => $t('契约管理'),
    component: React.lazy(() =>
      import('./pages/admin/ContractsList').then(m => ({
        default: m.AdminContractsList,
      })),
    ),
  },
  {
    path: 'admin/plugin/contracts/:id/profiles/:rid/detail',
    name: 'PluginContractProfileContractDetail',
    getTitle: () => $t('契约详情'),
    component: React.lazy(() =>
      import('./pages/user/ContractDetail').then(m => ({
        default: m.UserContractDetail,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.ContractContract,
      pluginPermission: SchoolPluginsEnum.Contract,
    },
  },
  {
    path: 'admin/profiles/:rid/contract/list',
    name: 'PluginContractProfileContractsList',
    getTitle: () => $t('全部契约'),
    component: React.lazy(() =>
      import('./pages/user/ContractsList').then(m => ({
        default: m.UserContractsList,
      })),
    ),
  },
]

export const userRoutes: RouteConfig[] = [
  {
    path: 'profiles/me/contract/:rid/list',
    name: 'PluginContractProfileContractsListForStudent',
    getTitle: () => $t('全部契约'),
    component: React.lazy(() =>
      import('./pages/user/ContractsList').then(m => ({
        default: m.UserContractsList,
      })),
    ),
  },
  {
    path: 'profiles/me/contract/detail/:id',
    name: 'PluginContractProfileContractDetailForStudent',
    getTitle: () => $t('契约详情'),
    component: React.lazy(() =>
      import('./pages/user/ContractDetail').then(m => ({
        default: m.UserContractDetail,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.ContractContract,
      pluginPermission: SchoolPluginsEnum.Contract,
    },
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'PluginContractCreateContractModal',
    getTitle: () => $t('创建契约'),
    component: React.lazy(() =>
      import('./pages/user/CreateContract').then(m => ({
        default: m.CreateContract,
      })),
    ),
  },
]
