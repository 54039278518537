import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/moral-assessments',
    name: 'AdminMoralAssessments',
    getTitle: () => $t('德育评价'),
    module: [PluginCategoryEnum.德育管理, PluginEnum.MoralAssessment],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('全部评价'),
        component: React.lazy(() =>
          import('@/plugins/features/moral-assessments/pages/admin/List').then(
            m => ({
              default: m.AdminMoralAssessmentList,
            }),
          ),
        ),
      },
      {
        path: 'logs',
        getTitle: () => $t('信息修改日志'),
        component: React.lazy(() =>
          import('@/plugins/features/moral-assessments/pages/admin/Logs').then(
            m => ({
              default: m.AdminMoralAssessmentLogs,
            }),
          ),
        ),
      },
      {
        path: 'settings',
        name: 'Settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/admin/Settings'
          ).then(m => ({
            default: m.AdminMoralAssessmentSettings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/moral-assessments/new',
    name: 'AdminMoralAssessmentNew',
    getTitle: () => $t('新增德育评价'),
    component: React.lazy(() =>
      import('@/plugins/features/moral-assessments/pages/admin/Edit').then(
        m => ({
          default: m.AdminMoralAssessmentNew,
        }),
      ),
    ),
  },
  {
    path: 'admin/moral-assessments/:id/edit',
    name: 'AdminMoralAssessmentEdit',
    getTitle: () => $t('编辑德育评价'),
    component: React.lazy(() =>
      import('@/plugins/features/moral-assessments/pages/admin/Edit').then(
        m => ({
          default: m.AdminMoralAssessmentEdit,
        }),
      ),
    ),
  },
  {
    path: 'admin/moral-assessments/:id/view',
    name: 'AdminMoralAssessmentView',
    getTitle: () => $t('德育评价详情'),
    component: React.lazy(() =>
      import('@/plugins/features/moral-assessments/pages/admin/View').then(
        m => ({
          default: m.AdminMoralAssessmentView,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'overview',
        name: 'Overview',
        getTitle: () => $t('汇总'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/admin/Overview'
          ).then(m => ({
            default: m.AdminMoralAssessmentOverview,
          })),
        ),
      },
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: React.lazy(() =>
          import('@/plugins/features/moral-assessments/pages/admin/Entry').then(
            m => ({
              default: m.AdminMoralAssessmentEntry,
            }),
          ),
        ),
      },
      {
        path: 'structures',
        name: 'Structures',
        getTitle: () => $t('结构'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/admin/Structures'
          ).then(m => ({
            default: m.AdminMoralAssessmentStructures,
          })),
        ),
      },
      {
        path: 'relations',
        name: 'Relations',
        getTitle: () => $t('评价人'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/admin/Relations'
          ).then(m => ({
            default: m.AdminMoralAssessmentRelations,
          })),
        ),
      },
      {
        path: 'members',
        name: 'Members',
        getTitle: () => $t('成员'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/admin/Members'
          ).then(m => ({
            default: m.AdminMoralAssessmentMembers,
          })),
        ),
      },
    ],
  },
]

export const userRoutes: RouteConfig[] = [
  {
    path: 'moral-assessments',
    getTitle: () => $t('德育评价'),
    module: [PluginEnum.MoralAssessment],
    component: React.lazy(() =>
      import('@/plugins/features/moral-assessments/pages/user').then(m => ({
        default: m.MoralAssessments,
      })),
    ),
    subRoutes: [
      {
        path: 'type-in',
        getTitle: () => $t('参与录入'),
        component: React.lazy(() =>
          import('@/plugins/features/moral-assessments/pages/user/TypeIn').then(
            m => ({
              default: m.MoralAssessmentTypeIn,
            }),
          ),
        ),
      },
      {
        path: 'assess-me',
        getTitle: () => $t('我的评价'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/user/MyMoralAssessments'
          ).then(m => ({
            default: m.MyMoralAssessments,
          })),
        ),
      },
      {
        path: 'trends',
        getTitle: () => $t('动态'),
        component: React.lazy(() =>
          import('@/plugins/features/moral-assessments/pages/user/Trends').then(
            m => ({
              default: m.UserMoralAssessmentTrends,
            }),
          ),
        ),
      },
    ],
  },
  {
    path: 'moral-assessments/:id/entry',
    name: 'MoralAssessmentEntry',
    getTitle: () => $t('录入'),
    component: React.lazy(() =>
      import('@/plugins/features/moral-assessments/pages/user/Entry').then(
        m => ({
          default: m.MoralAssessmentEntry,
        }),
      ),
    ),
  },
  {
    path: 'moral-assessments/:id/overview',
    name: 'MoralAssessmentOverview',
    getTitle: () => $t('汇总'),
    component: React.lazy(() =>
      import('@/plugins/features/moral-assessments/pages/user/Overview').then(
        m => ({
          default: m.MoralAssessmentOverview,
        }),
      ),
    ),
  },
]

export const teacherRoutes: RouteConfig[] = [
  {
    path: 'moral-assessments/:pathType/teacher/:id',
    name: 'TeacherMoralAssessmentView',
    getTitle: () => $t('德育评价详情'),
    module: [PluginEnum.MoralAssessment],
    component: React.lazy(() =>
      import('@/plugins/features/moral-assessments/pages/teacher/View').then(
        m => ({
          default: m.TeacherMoralAssessmentView,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'overview',
        name: 'Overview',
        getTitle: () => $t('汇总'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/teacher/Overview'
          ).then(m => ({
            default: m.TeacherMoralAssessmentOverview,
          })),
        ),
      },
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/teacher/Entry'
          ).then(m => ({
            default: m.TeacherMoralAssessmentEntry,
          })),
        ),
      },
      {
        path: 'analyses',
        name: 'Analyses',
        getTitle: () => $t('分析'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/teacher/Analyses'
          ).then(m => ({
            default: m.TeacherMoralAssessmentAnalyses,
          })),
        ),
      },
    ],
  },
]

export const studentRoutes: RouteConfig[] = [
  {
    path: 'moral-assessments/:pathType/student/:id',
    name: 'StudentMoralAssessmentView',
    getTitle: () => $t('德育评价详情'),
    module: [PluginEnum.MoralAssessment],
    component: React.lazy(() =>
      import('@/plugins/features/moral-assessments/pages/student/View').then(
        m => ({
          default: m.StudentMoralAssessmentView,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/student/Entry'
          ).then(m => ({
            default: m.StudentMoralAssessmentEntry,
          })),
        ),
        subRoutes: [
          {
            path: 'evaluated',
            name: 'Evaluated',
            getTitle: () => $t('自评'),
            component: React.lazy(() =>
              import(
                '@/plugins/features/moral-assessments/pages/student/Entry'
              ).then(m => ({
                default: m.StudentMoralAssessmentEvaluatedEntry,
              })),
            ),
          },
          {
            path: 'evaluator',
            name: 'Evaluator',
            getTitle: () => $t('小组互评'),
            component: React.lazy(() =>
              import(
                '@/plugins/features/moral-assessments/pages/student/Entry'
              ).then(m => ({
                default: m.StudentMoralAssessmentEvaluatorEntry,
              })),
            ),
          },
        ],
      },
      {
        path: 'analyses',
        name: 'Analyses',
        getTitle: () => $t('分析'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/moral-assessments/pages/student/Analyses'
          ).then(m => ({
            default: m.StudentMoralAssessmentAnalyses,
          })),
        ),
      },
    ],
  },
]

export const studentProfileRoutes = [
  {
    path: 'profiles/:rid/moral-grade/:id',
    name: 'StudentMoralGradeProfileDetails',
    getTitle: () => $t('德育评价详情'),
    component: React.lazy(() =>
      import('./pages/student/Profile/MoralGradeProfileDetails').then(m => ({
        default: m.MoralGradeProfileDetails,
      })),
    ),
  },
  {
    path: 'admin/profiles/:rid/moral-grade/:id',
    name: 'AdminStudentMoralGradeProfileDetails',
    getTitle: () => $t('德育评价详情'),
    component: React.lazy(() =>
      import('./pages/student/Profile/MoralGradeProfileDetails').then(m => ({
        default: m.MoralGradeProfileDetails,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.MoralAssessmentSettingsModal',
    getTitle: () => $t('德育审批'),
    component: React.lazy(() =>
      import('./workflow-renders/Settings').then(m => ({
        default: m.MoralWorkflowSettingsRouteModal,
      })),
    ),
  },
]
