/**
 * @file 契约插件
 */

import { InitPluginFeature } from '@/plugins/types'

import { adminContractMenu } from './menus'
import { adminRoutes, modalRoutes, userRoutes } from './routes'
import { tabSlot } from './slots'
import { todos } from './todos'

export const initContract: InitPluginFeature = {
  name: 'contract',
  init: () => ({
    routes: [...adminRoutes, ...userRoutes],
    modalRoutes,
    menus: [adminContractMenu],
    slots: {
      profileSubRoutes: tabSlot,
    },
    todos,
  }),
}
