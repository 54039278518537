/**
 * @file 选课待办注册
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

const ElectionTodoRender = lazy(() =>
  import('./ElectionTodoCard').then(m => ({ default: m.ElectionTodoRender })),
)

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.ElectionStudent,
    Render: ElectionTodoRender,
  },
  {
    type: TodoTypeEnum.ElectionTeacher,
    Render: ElectionTodoRender,
  },
  {
    type: TodoTypeEnum.ElectionGuardian,
    Render: ElectionTodoRender,
  },
]
