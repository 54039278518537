/**
 * @file 动态菜单
 */

import { env } from '@seiue/env'
import { atom } from 'jotai'

import {
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

export const adminAthenaMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.Athena))) {
    return []
  }

  return [
    {
      prefix: ['courses'],
      name: PluginEnum.Athena,
      icon: 'CourseScheduling' as const,
      shortcutIcon: 'CourseSchedulingSolid+EA7B25',
      path: env('CLIENT_ATHENA'),
      label: $t('排课管理'),
      sort: 201,
    },
  ]
})
