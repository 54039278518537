import { lazy } from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

/**
 * 学生档案-成绩-成绩分析 tab
 * HACK for 广州中学
 * 背景(2023-01-29)：
 * 广中校长将参加区内德育大会，并预期展示系统内的学生档案及广中成绩报告单
 * 但由于两部分内容在系统内比较分散，因此期望可以在「学生档案」-「成绩」下增加一个 tag，以便直接在学生档案页面直接打开成绩报告单。
 * 具体需求：
 * 增加考试管理「2025 届高一第一学期期末考试」（2022-2023 学年第一学期）的广中成绩分析报告至对应学生的学生档案中
 */
export const tabSlot: RouteConfig[] = [
  {
    path: 'analysis',
    getTitle: () => $t('成绩分析'),
    name: 'Analysis',
    component: lazy(() =>
      import(
        '@/plugins/features/gz-exam-res-analysis/slots/ProfileGradeSubRoutesAnalysis'
      ).then(m => ({
        default: m.AnalysisDetails,
      })),
    ),
    requireSchools: [145],
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.ScoreScore,
    },
  },
]
