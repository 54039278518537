/**
 * @file 广雅中学成绩单
 */
import { InitPluginFeature } from '@/plugins/types'

export const initGuangYaGrades: InitPluginFeature = {
  name: 'guang-ya-grades',
  init: () => ({
    slots: {
      exportGradesReportItem: () =>
        import('./slots').then(m => m.exportGradesReportItem),
    },
  }),
}
