/**
 * @file 宿舍待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.DormDailyNotice,
    Render: lazy(() =>
      import('./DailyNotice').then(m => ({ default: m.DailyNotice })),
    ),
  },
]
