import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const routes = [
  {
    name: 'AdminPluginDataSync',
    path: 'admin/plugins/data-sync',
    getTitle: () => $t('数据采集'),
    module: [PluginCategoryEnum.其他, PluginNameEnum.DataSync],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        name: 'Students',
        path: 'students',
        getTitle: () => $t('学生信息'),
        component: lazy(() =>
          import('./pages/admin/Management/Students').then(m => ({
            default: m.Students,
          })),
        ),
      },
      {
        name: 'Teachers',
        path: 'teachers',
        getTitle: () => $t('教师信息'),
        component: lazy(() =>
          import('./pages/admin/Management/Teachers').then(m => ({
            default: m.Teachers,
          })),
        ),
      },
      {
        name: 'AdminClasses',
        path: 'admin-classes',
        getTitle: () => $t('行政班信息'),
        component: lazy(() =>
          import('./pages/admin/Management/AdminClasses').then(m => ({
            default: m.AdminClasses,
          })),
        ),
      },
      {
        name: 'Places',
        path: 'places',
        getTitle: () => $t('空间信息'),
        component: lazy(() =>
          import('./pages/admin/Management/Places').then(m => ({
            default: m.Places,
          })),
        ),
      },
    ],
  },
]
