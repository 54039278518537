import React from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    name: 'Plugin.AdminCertifications',
    path: 'admin/certification-plugins/:pluginId',
    module: [PluginCategoryEnum.学生成长, PluginNameEnum.Certification],
    getTitle: () => $t('认证管理'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'certifications',
        getTitle: () => $t('全部认证'),
        component: React.lazy(() =>
          import('./pages/admin/List').then(m => ({
            default: m.AdminCertificationList,
          })),
        ),
      },
      {
        path: 'student-stats',
        getTitle: () => $t('数据统计'),
        component: React.lazy(() =>
          import('./pages/admin/StudentStats').then(m => ({
            default: m.AdminStudentStats,
          })),
        ),
      },
      {
        path: 'application-logs',
        getTitle: () => $t('申请记录'),
        component: React.lazy(() =>
          import('./pages/admin/ApplicationLogs').then(m => ({
            default: m.AdminApplicationLogs,
          })),
        ),
      },
    ],
  },
  {
    name: 'Plugin.AdminCertifications.CertifiedList',
    path: 'admin/certification-plugins/:pluginId/certifications/:id/certified-list',
    getTitle: () => $t('已获得的学生'),
    component: React.lazy(() =>
      import('./pages/admin/CertifiedList').then(m => ({
        default: m.AdminCertifiedList,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertifications.New',
    path: 'admin/certification-plugins/:pluginId/certifications/new',
    getTitle: () => $t('新增认证'),
    component: React.lazy(() =>
      import('./pages/admin/Edit').then(m => ({
        default: m.AdminCertificationsNew,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertifications.Edit',
    path: 'admin/certification-plugins/:pluginId/certifications/:id/edit',
    getTitle: () => $t('编辑认证'),
    component: React.lazy(() =>
      import('./pages/admin/Edit').then(m => ({
        default: m.AdminCertificationsEdit,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertifications.BatchEdit',
    path: 'admin/certification-plugins/:pluginId/certifications/batch-edit',
    getTitle: () => $t('批量编辑认证'),
    component: React.lazy(() =>
      import('./pages/admin/Edit/BatchEdit').then(m => ({
        default: m.AdminBatchEditCertifications,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertifications.View',
    path: 'admin/certification-plugins/:pluginId/certifications/:id/view',
    getTitle: () => $t('详情'),
    component: React.lazy(() =>
      import('./pages/admin/View').then(m => ({
        default: m.AdminCertificationsView,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertifications.StudentCertifiedList',
    path: 'admin/certification-plugins/:pluginId/student-stats/:rid/certified-list',
    getTitle: () => $t('已获得列表'),
    component: React.lazy(() =>
      import('./pages/admin/StudentCertifiedList').then(m => ({
        default: m.AdminStudentCertifiedList,
      })),
    ),
  },
  {
    name: 'AdminStudentProfiles.Certifications.Certified',
    path: 'admin/profiles/:rid/certification-plugins/:pluginId/certifications/:id/certified',
    getTitle: () => $t('获得详情'),
    component: React.lazy(() =>
      import(
        '@/plugins/features/certification/pages/student/ProfileCertified'
      ).then(m => ({
        default: m.ProfileCertificationCertified,
      })),
    ),
  },
]

export const teacherRoutes: RouteConfig[] = [
  {
    name: 'Plugin.TeacherCertifications',
    path: 'certification-plugins/:pluginId',
    module: [PluginNameEnum.Certification],
    getTitle: () => $t('认证'),
    component: React.lazy(() =>
      import('./pages/teacher/index').then(m => ({
        default: m.TeacherCertifications,
      })),
    ),
    subRoutes: [
      {
        path: 'application-logs',
        getTitle: () => $t('申请记录'),
        component: React.lazy(() =>
          import('./pages/teacher/ApplicationLogs').then(m => ({
            default: m.ApplicationLogs,
          })),
        ),
      },
      {
        path: 'certifications',
        getTitle: () => $t('授予认证'),
        component: React.lazy(() =>
          import('./pages/teacher/List').then(m => ({
            default: m.TeacherCertificationList,
          })),
        ),
      },
    ],
  },
  {
    name: 'Plugin.TeacherCertifications.CertifiedList',
    path: 'certification-plugins/:pluginId/certifications/:id/certified-list',
    getTitle: () => $t('已获得学生'),
    component: React.lazy(() =>
      import('./pages/teacher/CertifiedList').then(m => ({
        default: m.TeacherCertifiedList,
      })),
    ),
  },
  {
    name: 'Plugin.TeacherCertifications.View',
    path: 'certification-plugins/:pluginId/certifications/:id/view',
    getTitle: () => $t('认证详情'),
    component: React.lazy(() =>
      import('./pages/admin/View').then(m => ({
        default: m.AdminCertificationsView,
      })),
    ),
  },
]

export const studentRoutes: RouteConfig[] = [
  {
    path: 'profiles/me/certification-plugins/:pluginId/certifications/:id/certified',
    name: 'StudentProfiles.Certifications.Certified',
    getTitle: () => $t('获得详情'),
    component: React.lazy(() =>
      import(
        '@/plugins/features/certification/pages/student/ProfileCertified'
      ).then(m => ({
        default: m.ProfileCertificationCertified,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.CertificationReflectionModal',
    getTitle: () => $t('详情'),
    component: React.lazy(() =>
      import(
        '@/plugins/features/certification/modals/CertReflectionModal'
      ).then(m => ({
        default: m.CertificationReflectionModal,
      })),
    ),
  },
]
