/**
 * @file 数据采集插件
 */

import { InitPluginFeature } from '@/plugins/types'

import { adminDataSyncMenu } from './menus'
import { routes } from './routes'

export const initDataSync: InitPluginFeature = {
  name: 'data-sync',
  init: () => ({
    routes,
    menus: [adminDataSyncMenu],
  }),
}
