import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/teacher-assessments',
    name: 'AdminTeacherAssessments',
    getTitle: () => $t('教师考核'),
    module: [PluginCategoryEnum.教师发展, PluginEnum.TeacherAssessments],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('全部考核'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/admin/List'
          ).then(m => ({
            default: m.AdminTeacherAssessmentList,
          })),
        ),
      },
      {
        path: 'logs',
        getTitle: () => $t('信息修改日志'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/admin/Logs'
          ).then(m => ({
            default: m.AdminMoralAssessmentLogs,
          })),
        ),
      },
      {
        path: 'settings',
        name: 'Settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/admin/Settings'
          ).then(m => ({
            default: m.AdminTeacherAssessmentSettings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/teacher-assessments/new',
    name: 'AdminTeacherAssessmentNew',
    getTitle: () => $t('新增教师考核'),
    component: React.lazy(() =>
      import('@/plugins/features/teacher-assessments/pages/admin/Edit').then(
        m => ({
          default: m.AdminTeacherAssessmentNew,
        }),
      ),
    ),
  },
  {
    path: 'admin/teacher-assessments/:id/edit',
    name: 'AdminTeacherAssessmentEdit',
    getTitle: () => $t('编辑教师考核'),
    component: React.lazy(() =>
      import('@/plugins/features/teacher-assessments/pages/admin/Edit').then(
        m => ({
          default: m.AdminTeacherAssessmentEdit,
        }),
      ),
    ),
  },
  {
    path: 'admin/teacher-assessments/:id/view',
    name: 'AdminTeacherAssessmentView',
    getTitle: () => $t('教师考核详情'),
    component: React.lazy(() =>
      import('@/plugins/features/teacher-assessments/pages/admin/View').then(
        m => ({
          default: m.AdminTeacherAssessmentView,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'overview',
        name: 'Overview',
        getTitle: () => $t('汇总'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/admin/Overview'
          ).then(m => ({
            default: m.AdminTeacherAssessmentOverview,
          })),
        ),
      },
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/admin/Entry'
          ).then(m => ({
            default: m.AdminTeacherAssessmentEntry,
          })),
        ),
      },
      {
        path: 'structures',
        name: 'Structures',
        getTitle: () => $t('结构'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/admin/Structures'
          ).then(m => ({
            default: m.AdminTeacherAssessmentStructures,
          })),
        ),
      },
      {
        path: 'relations',
        name: 'Relations',
        getTitle: () => $t('评审设置'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/admin/Relations'
          ).then(m => ({
            default: m.AdminTeacherAssessmentRelations,
          })),
        ),
      },
    ],
  },
]

export const userRoutes: RouteConfig[] = [
  {
    path: 'teacher-assessments',
    name: 'TeacherAssessments',
    getTitle: () => $t('教师考核'),
    module: [PluginEnum.TeacherAssessments],
    component: React.lazy(() =>
      import('@/plugins/features/teacher-assessments/pages/user').then(m => ({
        default: m.TeacherAssessments,
      })),
    ),
    subRoutes: [
      {
        path: 'type-in',
        name: 'TypeIn',
        getTitle: () => $t('我录入的'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/user/TypeIn'
          ).then(m => ({
            default: m.TeacherAssessmentTypeIn,
          })),
        ),
      },
      {
        path: 'audit',
        name: 'Audit',
        getTitle: () => $t('我审核的'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/user/Audit'
          ).then(m => ({
            default: m.TeacherAssessmentAudit,
          })),
        ),
      },
      {
        path: 'manage',
        name: 'Manage',
        getTitle: () => $t('我管理的'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/user/Manage'
          ).then(m => ({
            default: m.TeacherAssessmentManage,
          })),
        ),
      },
      {
        path: 'assess-me',
        name: 'AssessMe',
        getTitle: () => $t('我的考核'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/user/MyMoralAssessments'
          ).then(m => ({
            default: m.MyMoralAssessments,
          })),
        ),
      },
      {
        path: 'trends',
        name: 'Trends',
        getTitle: () => $t('动态'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/teacher/Trends'
          ).then(m => ({
            default: m.UserMoralAssessmentTrends,
          })),
        ),
      },
    ],
  },
  {
    path: 'teacher-assessments/:id/entry',
    name: 'TeacherAssessmentEntry',
    getTitle: () => $t('录入'),
    component: React.lazy(() =>
      import('@/plugins/features/teacher-assessments/pages/user/Entry').then(
        m => ({
          default: m.TeacherAssessmentEntry,
        }),
      ),
    ),
  },
  {
    path: 'teacher-assessments/:id/overview',
    name: 'TeacherAssessmentOverview',
    getTitle: () => $t('汇总'),
    component: React.lazy(() =>
      import('@/plugins/features/teacher-assessments/pages/user/Overview').then(
        m => ({
          default: m.TeacherAssessmentOverview,
        }),
      ),
    ),
  },
  {
    path: 'teacher-assessments/manage/:id',
    name: 'TeacherAssessmentManageView',
    getTitle: () => $t('教师考核详情'),
    component: React.lazy(() =>
      import(
        '@/plugins/features/teacher-assessments/pages/user/Manage/View'
      ).then(m => ({
        default: m.TeacherAssessmentManageView,
      })),
    ),
    subRoutes: [
      {
        path: 'overview',
        name: 'Overview',
        getTitle: () => $t('汇总'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/user/Manage/View'
          ).then(m => ({
            default: m.TeacherAssessmentManageOverview,
          })),
        ),
      },
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/user/Manage/View'
          ).then(m => ({
            default: m.TeacherAssessmentManageEntry,
          })),
        ),
      },
    ],
  },
  {
    path: 'teacher-assessments/audit/:id/entry',
    name: 'TeacherAssessmentAuditEntry',
    getTitle: () => $t('我审核的 - 录入'),
    component: React.lazy(() =>
      import(
        '@/plugins/features/teacher-assessments/pages/user/Audit/Entry'
      ).then(m => ({
        default: m.TeacherAssessmentAuditEntry,
      })),
    ),
  },
]

export const teacherRoutes: RouteConfig[] = [
  {
    path: 'teacher-assessments/:pathType/teacher/:id',
    name: 'TeacherAssessmentView',
    getTitle: () => $t('教师考核详情'),
    module: [PluginEnum.TeacherAssessments],
    component: React.lazy(() =>
      import('@/plugins/features/teacher-assessments/pages/teacher/View').then(
        m => ({
          default: m.TeacherAssessmentView,
        }),
      ),
    ),
    subRoutes: [
      {
        path: 'entry',
        name: 'Entry',
        getTitle: () => $t('录入'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/teacher/Entry'
          ).then(m => ({
            default: m.TeacherAssessmentEntry,
          })),
        ),
        subRoutes: [
          {
            path: 'evaluated',
            name: 'Evaluated',
            getTitle: () => $t('自评'),
            component: React.lazy(() =>
              import(
                '@/plugins/features/teacher-assessments/pages/teacher/Entry'
              ).then(m => ({
                default: m.TeacherAssessmentEvaluatedEntry,
              })),
            ),
          },
          {
            path: 'evaluator',
            name: 'Evaluator',
            getTitle: () => $t('评价他人'),
            component: React.lazy(() =>
              import(
                '@/plugins/features/teacher-assessments/pages/teacher/Entry'
              ).then(m => ({
                default: m.TeacherAssessmentEvaluatorEntry,
              })),
            ),
          },
        ],
      },
      {
        path: 'analyses',
        name: 'Analyses',
        getTitle: () => $t('分析'),
        component: React.lazy(() =>
          import(
            '@/plugins/features/teacher-assessments/pages/teacher/Analyses'
          ).then(m => ({
            default: m.TeacherMoralAssessmentAnalyses,
          })),
        ),
      },
    ],
  },
]

export const teacherProfileRoutes = [
  {
    path: 'teacher-profiles/:rid/moral-grade/:id',
    name: 'TeacherAssessmentGradeProfileDetails',
    getTitle: () => $t('德育评价详情'),
    component: React.lazy(() =>
      import('./pages/teacher/Profile/MoralGradeProfileDetails').then(m => ({
        default: m.MoralGradeProfileDetails,
      })),
    ),
  },
  {
    path: 'admin/teacher-profiles/:rid/moral-grade/:id',
    name: 'AdminTeacherAssessmentGradeProfileDetails',
    getTitle: () => $t('德育评价详情'),
    component: React.lazy(() =>
      import('./pages/teacher/Profile/MoralGradeProfileDetails').then(m => ({
        default: m.MoralGradeProfileDetails,
      })),
    ),
  },
]
