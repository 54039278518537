/**
 * @file 在应用根节点上，用于打断/强制用户执行业务操作的弹窗机制
 */

import { userAgent } from '@seiue/ua'
import { noop } from '@seiue/util'
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useIsApolloLogin } from 'packages/features/sessions/hooks'

import { BaseInterruptModal } from './BasicModal'

type Register = (key: string) => void
type Done = (key: string) => void

const RootInterruptModalContext = createContext({
  register: noop,
  done: noop,
} as {
  register: Register
  currentKey: string | undefined
  done: Done
})

export const RootInterruptProvider: FC<{ sessionCreated?: boolean }> = ({
  children,
}) => {
  const [modalQueue, setModalQueue] = useState<string[]>([])

  const currentKey = modalQueue[0]

  const register = useCallback<Register>(key => {
    setModalQueue(queue => {
      const nextQueue = [...queue]

      nextQueue.push(key)

      return nextQueue
    })
  }, [])

  const done = useCallback((key: string) => {
    setModalQueue(queue => queue.filter(queueKey => queueKey !== key))
  }, [])

  return (
    <RootInterruptModalContext.Provider
      value={{
        register,
        currentKey,
        done,
      }}
    >
      {children}
    </RootInterruptModalContext.Provider>
  )
}

export const useCanShowInRootInterrupt = (key: string, isDone: boolean) => {
  const { register, currentKey, done } = useContext(RootInterruptModalContext)
  const isApolloLogin = useIsApolloLogin()

  useEffect(() => {
    if (isDone) {
      done(key)
    } else {
      register(key)
    }
    // eslint-disable-next-line
  }, [isDone])

  return !isApolloLogin && !userAgent.isC3AppWebView && currentKey === key
}

export const RootInterrupt = {
  Provider: RootInterruptProvider,
  useCanShow: useCanShowInRootInterrupt,
  BasicModal: BaseInterruptModal,
}
