/**
 * @file 国际校目标管理 - 路由定义
 */

import React from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const profileRoutes: RouteConfig[] = [
  {
    path: 'intl-goal',
    name: 'IntlGoal',
    getTitle: () => $t('升学目标'),
    component: React.lazy(() =>
      import('./pages/user/Profile').then(m => ({
        default: m.FurtherEduGoal,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.GoalIntlGoal,
      pluginPermissions: [SchoolModulesEnum.ProfileIntlGoal],
    },
  },
]

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/intl-goal',
    module: [PluginCategoryEnum.学生成长, PluginNameEnum.IntlGoal],
    getTitle: () => $t('目标管理'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'goals',
        getTitle: () => $t('目标列表'),
        component: React.lazy(() =>
          import('./pages/admin/GoalList/GoalList').then(m => ({
            default: m.AdminGoalList,
          })),
        ),
        subRoutes: [
          {
            path: 'normal',
            name: 'Plugin.IntlGoal.StudentsNormalList',
            getTitle: () => $t('在校学生'),
            component: React.lazy(() =>
              import('./pages/admin/GoalList/GoalList').then(m => ({
                default: m.NormalGoalList,
              })),
            ),
          },
          {
            path: 'achived',
            name: 'Plugin.IntlGoal.StudentsAchivedList',
            getTitle: () => $t('已归档学生'),
            component: React.lazy(() =>
              import('./pages/admin/GoalList/GoalList').then(m => ({
                default: m.ArchivedGoalList,
              })),
            ),
          },
        ],
      },
      {
        path: 'milestone-templates',
        getTitle: () => $t('里程碑模版库'),
        component: React.lazy(() =>
          import('./pages/admin/MilestoneTemplateList').then(m => ({
            default: m.AdminMilestoneTemplateList,
          })),
        ),
      },
      {
        path: 'setting',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('./pages/admin/Setting').then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/intl-goal/milestone-templates/:id/students',
    name: 'Plugin.IntlGoal.AdminMilestoneTemplateStudents',
    getTitle: () => $t('学生目标'),
    component: React.lazy(() =>
      import('./pages/admin/MilestoneStudentList').then(m => ({
        default: m.AdminMilestoneStudentList,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'Plugin.IntlGoal.UserAddUniversity',
    getTitle: () => $t('添加目标大学'),
    component: React.lazy(() =>
      import(
        './pages/user/Profile/components/AddGoal/AddGoalUniversityModal'
      ).then(m => ({
        default: m.AddGoalUniversityModal,
      })),
    ),
  },
]
