/**
 * @file 学业目标管理菜单
 */

import { atom } from 'jotai'

import {
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminAcademicGoalMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.AcademicGoal))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.学生成长, SchoolModulesEnum.Goal],
      path: '/admin/plugin/academic-goal',
      label: $t('学业目标'),
      icon: 'Target' as const,
      sort: 703,
    },
    {
      prefix: [PluginCategoryEnum.学生成长, SchoolModulesEnum.Goal],
      path: '/admin/plugin/graduates-info',
      label: $t('毕业生信息'),
      icon: 'GraduateInformation' as const,
      sort: 704,
    },
  ]
})
