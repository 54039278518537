import { uniqBy } from '@seiue/util'
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { isMenuOpenedAtom } from 'packages/feature-utils/menus'
import {
  findEnabledPluginsAtom,
  findManageableParentPluginAtom,
  getPluginIcon,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { getCurrentReflection } from 'packages/feature-utils/store'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { NetDiskRange } from 'packages/plugins/types'
import { MenuType } from 'packages/route'
import {
  PluginCategoryEnum,
  netdiskApi$getPersonalNetdiskOwners,
} from 'packages/sdks-next/chalk'

import type { GroupNetdiskOwner } from '@/plugins/features/net-disk/pages/group/components/GroupNetDiskFileDetails'

export const adminNetDiskMenu = atom(get => {
  const plugin = get(findManageableParentPluginAtom(SchoolPluginsEnum.NetDisk))

  if (!plugin || !plugin.settings) {
    return []
  }

  const setting = plugin.settings as NetDiskRange

  const enabledPlugin = get(
    findEnabledPluginsAtom(SchoolPluginsEnum.NetDisk),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  const subMenu = []
  const hasUser = setting.usersRange && setting.usersRange.length
  if (hasUser) {
    subMenu.push({
      path: '/admin/net-disk/users',
      label: $t('个人网盘管理'),
      icon: 'Netdisk' as const,
    })
  }

  if (setting.groupsRange && setting.groupsRange.length) {
    subMenu.push({
      path: '/admin/net-disk/groups',
      label: $t('群组网盘管理'),
      icon: 'GroupNetdisk' as const,
    })
  }

  if (hasUser) {
    subMenu.push({
      path: '/admin/net-disk/shared',
      label: $t('共享网盘管理'),
      icon: 'SharedNetDisk' as const,
    })
  }

  return [
    {
      label: $t('网盘管理'),
      prefix: [PluginCategoryEnum.通用工具],
      name: SchoolPluginsEnum.NetDisk,
      sort: 700,
      icon: 'Netdisk' as const,
      shortcutIcon,
      subMenus: [
        ...subMenu,
        {
          path: '/admin/net-disk/files',
          label: $t('文件列表'),
          icon: 'Form' as const,
        },
      ],
      type: MenuType.AdminApps,
    },
  ]
})

export const userNetDiskMenu = loadable(
  atom(async get => {
    if (!get(hasUsablePluginAtom(SchoolPluginsEnum.NetDisk))) {
      return []
    }

    const me = getCurrentReflection()

    const menuOpened = get(isMenuOpenedAtom([PluginEnum.NetDisk]))

    const { data: owners } = menuOpened
      ? await netdiskApi$getPersonalNetdiskOwners.api(me.id, {
          expand: ['ownerName', 'ownerManagers'],
          tryExpand: ['topFile'],
          paginated: 0,
        })
      : { data: [] }

    const sharedNetdiskOwners = owners.filter(
      item => item.type === 'shared' && item.topFile?.id,
    )

    const customizedData = uniqBy(
      owners.filter(item =>
        item.type
          ? ![
              'seiue.course_group',
              'seiue.class_group',
              'teacher',
              'student',
              'shared',
            ].includes(item.type)
          : false,
      ),
      'type',
    )

    const cgDiskMenus = []
    const sharedNetDiskMenus = []

    if (owners.find(item => item.type === 'seiue.course_group')) {
      cgDiskMenus.push({
        label: $t('课程库'),
        path: `/net-disk/workspace/users/groups/course`,
      })
    }

    if (owners.find(item => item.type === 'seiue.class_group')) {
      cgDiskMenus.push({
        label: $t('课程班'),
        path: `/net-disk/workspace/users/groups/class`,
      })
    }

    if (customizedData.length !== 0) {
      cgDiskMenus.push(
        ...customizedData.map(item => ({
          label: (item as GroupNetdiskOwner).owner.typeEntity?.name || '-',
          path: `/net-disk/workspace/users/groups/customized/customized-${item.type}`,
        })),
      )
    }

    if (sharedNetdiskOwners.length !== 0) {
      sharedNetDiskMenus.push(
        ...sharedNetdiskOwners.map(item => ({
          label: (item as GroupNetdiskOwner).topFile?.name || '-',
          path: `/net-disk/workspace/users/shared/${item.id}`,
        })),
      )
    }

    return [
      {
        label: $t('网盘'),
        name: PluginEnum.NetDisk,
        type: MenuType.Apps,
        subMenus: [
          {
            label: $t('个人网盘'),
            path: '/net-disk/workspace/users/my-file',
            icon: 'Netdisk' as const,
          },
          {
            label: $t('群组网盘'),
            path: '/net-disk/workspace/users/groups',
            icon: 'GroupNetdisk' as const,
            subMenus: [...cgDiskMenus],
          },
          ...(sharedNetdiskOwners.length
            ? [
                {
                  label: $t('共享网盘'),
                  path: '/net-disk/workspace/users/shared',
                  icon: 'SharedNetDisk' as const,
                  subMenus: sharedNetDiskMenus,
                },
              ]
            : []),
          {
            label: $t('我的收藏'),
            path: '/net-disk/workspace/users/collection',
            icon: 'MoralEvaluation' as const,
          },
        ],
      },
    ]
  }),
)
