/**
 * @file 动态菜单
 */

import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'
import { MenuItemUnion } from 'packages/web-layout/types'

import { szGradeReportsPermissions } from '@/plugins/features/sz-grades-reports/utils/utils'
import { getStoreForAtom } from '@/store'

export const adminGzGradesMenu = atom<MenuItemUnion[]>(get => {
  const store = getStoreForAtom(get)
  const me = store.session.currentReflection

  if (!me) {
    return []
  }

  if (!get(hasEnabledPluginAtom(PluginEnum.SzGradesReports))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.其他],
      name: PluginEnum.SzGradesReports,
      label: $t('成绩报告'),
      type: MenuType.AdminApps,
      sort: 800,
      permission: [PermissionNameEnum.CoreGradeReportWrite],
      subMenus: [
        {
          label: $t('报告单'),
          path: '/admin/sz-grades-reports/reports',
          icon: 'Report',
        },
        {
          label: $t('成绩分析'),
          path: '/admin/sz-grades-reports/analysis',
          icon: 'SchoolReport',
        },
        {
          label: $t('成绩状态'),
          path: '/admin/sz-grades-reports/status',
          icon: 'MoralEvaluation',
        },
        {
          label: $t('设置'),
          path: '/admin/sz-grades-reports/settings',
          icon: 'Setting',
        },
      ],
    },
  ]
})

export const userGzGradesMenu = atom<MenuItemUnion[]>(get => {
  const store = getStoreForAtom(get)
  const me = store.session.currentReflection

  if (!me) {
    return []
  }

  if (!get(hasEnabledPluginAtom(PluginEnum.SzGradesReports))) {
    return []
  }

  return [
    {
      name: 'sz-grades-reports',
      label: $t('成绩报告'),
      type: MenuType.Apps,
      sort: 800,
      permission: szGradeReportsPermissions,
      subMenus: [
        {
          label: $t('报告单'),
          path: '/user/sz-grades-reports/normal',
          icon: 'Report',
        },
        {
          label: $t('成绩分析'),
          path: '/user/sz-grades-reports/analysis',
          icon: 'SchoolReport',
        },
        {
          label: $t('成绩状态'),
          path: '/user/sz-grades-reports/status',
          icon: 'MoralEvaluation',
        },
        {
          label: $t('设置'),
          path: '/user/sz-grades-reports/settings',
          icon: 'Setting',
        },
      ],
    },
  ]
})
