/**
 * @file 场景中断 modal
 */

import { Modal } from '@seiue/ui'
import styled from 'styled-components'

import type { InterruptMessage } from 'packages/features/accounts/hooks/use-binds'
import { TextColor } from 'packages/theme'

export const BaseInterruptModal: React.FC<
  Pick<
    InterruptMessage,
    'title' | 'description' | 'visible' | 'onCancel' | 'closable'
  > & {
    img: string
  }
> = ({ title, description, visible, onCancel, closable, children, img }) => (
  <Modal
    visible={visible}
    footer={null}
    headerStyle={{
      backgroundColor: 'transparent',
    }}
    width={480}
    onCancel={onCancel}
    closable={closable}
  >
    <Wrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Img src={img} />
      {children}
    </Wrapper>
  </Modal>
)

const Wrapper = styled.div`
  padding-bottom: 46px;
  text-align: center;
`

const Title = styled.h3`
  color: ${TextColor._1};
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
`

const Description = styled.div`
  width: 320px;
  margin: 12px auto 0;
  color: ${TextColor._2};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

const Img = styled.img`
  display: block;
  width: 236px;
  height: 176px;
  margin: 56px auto;
`
