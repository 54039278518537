/**
 * @file slots
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const tabSlot: RouteConfig[] = [
  {
    path: 'gzzx-cgr-grades-reports',
    getTitle: () => $t('广州中学报告单'),
    name: 'GzzxCgrGradesReports',
    component: lazy(() =>
      import('../components/ProfileGradesReport').then(mod => ({
        default: mod.ProfileGradesReport,
      })),
    ),
    requireSchools: [145],
  },
]
