/**
 * @file routes
 */

import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const routes: RouteConfig[] = [
  {
    path: 'plugin/gz-freshman-questionnaire/list',
    getTitle: () => $t('新生入学问卷'),
    module: [SchoolPluginsEnum.GzFreshmanQuestionnaire],
    component: lazy(() =>
      import('./pages/FreshmanQuestionnaireList/index').then(mod => ({
        default: mod.FreshmanQuestionnaireList,
      })),
    ),
  },
]
