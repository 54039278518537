import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

/**
 * 心理档案教师前台和管理元后台子路由目前完全一致，所以抽离复用
 *
 * @returns routes
 */
export const getPsyProfileSubRoutes = (): RouteConfig[] => {
  return [
    {
      path: 'home',
      name: 'Home',
      getTitle: () => $t('主页'),
      component: lazy(() =>
        import('../pages/admin/Information/StudentDetail/Home').then(m => ({
          default: m.Home,
        })),
      ),
    },
    // 广中定制：学生心理档案表
    {
      path: 'student-psy-form',
      name: 'GzStudentPsyForm',
      getTitle: () => $t('心理档案'),
      component: lazy(() =>
        import(
          '@/plugins/features/gz-student-psy-form/pages/ProfileStudentPsyForm'
        ).then(m => ({
          default: m.ProfileStudentPsyForm,
        })),
      ),
    },
    {
      path: 'history',
      name: 'History',
      getTitle: () => $t('测评历史'),
      component: lazy(() =>
        import(
          '../pages/admin/Information/StudentDetail/EvaluationHistory'
        ).then(m => ({
          default: m.EvaluationHistory,
        })),
      ),
      subRoutes: [
        {
          path: 'plan',
          name: 'Plan',
          getTitle: () => $t('测评计划'),
          component: lazy(() =>
            import(
              '../pages/admin/Information/StudentDetail/EvaluationHistory/Plan'
            ).then(m => ({
              default: m.Plan,
            })),
          ),
        },
        {
          path: 'scale-warning',
          name: 'ScaleWarning',
          getTitle: () => $t('量表预警'),
          component: lazy(() =>
            import(
              '../pages/admin/Information/StudentDetail/EvaluationHistory/Warning'
            ).then(m => ({
              default: m.Warning,
            })),
          ),
        },
      ],
    },
    {
      path: 'psy-chats',
      name: 'PsyChats',
      getTitle: () => $t('咨询记录'),
      component: lazy(() =>
        import('../pages/user/PsyChats').then(m => ({
          default: m.PsyChats,
        })),
      ),
    },
    {
      path: 'intervention',
      name: 'Intervention',
      getTitle: () => $t('危机干预'),
      component: lazy(() =>
        import('../pages/admin/Information/StudentDetail/Intervention').then(
          m => ({
            default: m.Intervention,
          }),
        ),
      ),
    },
    {
      path: 'problem-report',
      name: 'ProblemReport',
      getTitle: () => $t('上报问题'),
      component: lazy(() =>
        import('../pages/admin/Information/StudentDetail/ProblemReport').then(
          m => ({
            default: m.ProblemReports,
          }),
        ),
      ),
    },
    {
      path: 'collaboration-record',
      name: 'CollaborationRecord',
      getTitle: () => $t('档案协同'),
      component: lazy(() =>
        import('../pages/admin/Information/StudentDetail/Collaboration').then(
          m => ({
            default: m.Collaboration,
          }),
        ),
      ),
    },
  ]
}
