/**
 * @file slots
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const tabSlot: RouteConfig[] = [
  {
    path: 'academic-year-grade',
    getTitle: () => $t('学年成绩'),
    name: 'academicYearGrade',
    component: lazy(() =>
      import('./AcademicYearGrade').then(m => ({
        default: m.AcademicYearGrade,
      })),
    ),
  },
]
