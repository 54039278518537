/**
 * @file 学业目标-选科-待办注册
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.AcademicGoalConfirm,
    Render: lazy(() =>
      import('./ConfirmTodo').then(m => ({ default: m.ConfirmTodo })),
    ),
  },
]
