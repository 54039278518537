/**
 * @file 动态菜单
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasEnabledPluginAtom,
  hasManageableParentPluginAtom,
  PluginEnum,
  useHasEnabledParentPlugin,
} from 'packages/feature-utils/plugins'
import { useHasPluginManagementPermission } from 'packages/features/plugins/utils/check-plugin'
import {
  useHasCurrentPermission,
  useHasCurrentPermissionFunction,
} from 'packages/features/roles/hooks'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'
import { MenuPermissionFunc } from 'packages/web-layout/types'

import { getStoreForAtom } from '@/store'

/**
 * 判断是否有 cgr 插件入口
 * 此处 PermissionNameEnum 的枚举因为原来是深圳湾的，所以沿用了原来的名称
 *
 * @param isStudent - 是否学生
 * @returns 是否
 */
export const useHasCgrEntry = (isStudent?: boolean) => {
  const hasPermission = useHasCurrentPermissionFunction()

  // 被 cgr 管理员授予了管理权限
  const had = [
    PermissionNameEnum.CgrExamReportDownload,
    PermissionNameEnum.CgrExamReportRead,
    PermissionNameEnum.CgrSzwSemesterReportDownload,
    PermissionNameEnum.CgrSzwSemesterReportRead,
  ].some(permission => hasPermission({ permission, isManager: true }))

  const enabled = useHasEnabledParentPlugin(PluginEnum.Cgr)
  const has = useHasPluginManagementPermission('cgr')

  if (isStudent) {
    return enabled
  }

  return has || had
}

export const adminCgrMenu = atom(get => {
  const store = getStoreForAtom(get)
  const me = store.session.currentReflection
  if (!me) {
    return []
  }

  if (!get(hasEnabledPluginAtom(SchoolPluginsEnum.Cgr))) {
    return []
  }

  const isManager = get(hasManageableParentPluginAtom(SchoolPluginsEnum.Cgr))

  const checkPermission: MenuPermissionFunc = (
    hasPermission: typeof useHasCurrentPermission,
  ) => {
    const had = [
      PermissionNameEnum.CgrExamReportDownload,
      PermissionNameEnum.CgrExamReportRead,
      PermissionNameEnum.CgrSzwSemesterReportDownload,
      PermissionNameEnum.CgrSzwSemesterReportRead,
    ].some(permission => hasPermission({ permission, isManager: true }))

    return had || isManager
  }

  const enabledPlugin = get(findEnabledPluginsAtom(SchoolPluginsEnum.Cgr))[0]
  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.其他],
      name: SchoolPluginsEnum.Cgr,
      label: $t('定制成绩单'),
      type: MenuType.AdminApps,
      permission: checkPermission,
      sort: 800,
      icon: 'Report' as const,
      shortcutIcon,
      subMenus: [
        {
          label: $t('报告单'),
          path: '/admin/plugin/cgr/reports',
          icon: 'Report' as const,
        },
        {
          label: $t('成绩状态'),
          path: '/admin/plugin/cgr/status',
          icon: 'MoralEvaluation' as const,
          permission: () => isManager,
        },
        {
          label: $t('设置'),
          path: '/admin/plugin/cgr/settings',
          icon: 'Setting' as const,
          permission: () => isManager,
        },
      ],
    },
  ]
})
