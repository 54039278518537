import { lazy } from 'react'

import { ModuleEnum, PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { SubjectAnalysisTabKeyEnum } from '@/features/exams/pages/types'
import { DataSlotFns } from '@/plugins/slot/data-slots'

/**
 * 考试管理-考试列表--下拉「阅卷」
 *
 * @param param0 - props
 * @param param0.examId - 考试 id
 * @param param0.history - return of useHistory
 * @returns 下拉菜单
 */
export const examScoreDropdownItems: DataSlotFns['examScoreDropdownItems'] = ({
  examId,
  history,
}) => [
  {
    // 如果开了阅卷，默认展示在最上方
    index: 0,
    label: $t('阅卷'),
    callback: () => {
      history.push('AdminExamScorings', {
        examId,
      })
    },
  },
]

/**
 * 考试前台 sub routes slot
 */
export const examFrontendSubRoutes: RouteConfig[] = [
  {
    path: 'scoring-list',
    getTitle: () => $t('阅卷列表'),
    requireModules: [ModuleEnum.Exam, PluginEnum.OnlineScoring],
    component: lazy(() =>
      import('./pages/teacher/ExamScorings').then(m => ({
        default: m.ParticipatedScorings,
      })),
    ),
  },
]

/**
 * 考试 - 科目分析详情左侧边栏 tab 配置
 *
 * @param param0 - props
 * @param param0.isImportScoreMethod - 是否是外部导入试题
 * @param param0.hasScore - 是否有分数
 * @returns tab 项
 */
export const examSubjectAnalysisSidebarTabItems: DataSlotFns['examSubjectAnalysisSidebarTabItems'] =
  ({ isImportScoreMethod, hasScore }) => {
    if (!hasScore) return []
    return [
      {
        label: '',
        key: 'teaching-divider',
        isDivider: true,
      },
      {
        label: $t('题目分析'),
        key: 'question-analysis',
        isSub: true,
      },
      {
        label: $t('题目概况'),
        key: SubjectAnalysisTabKeyEnum.QuestionOverview,
      },
      {
        label: $t('题目得分情况'),
        key: SubjectAnalysisTabKeyEnum.QuestionCondition,
      },
      // 如果是外部导入试题和分数的阅卷，不展示 「题目作答分布」界面
      ...(isImportScoreMethod
        ? []
        : [
            {
              label: $t('题目作答分布'),
              key: SubjectAnalysisTabKeyEnum.QuestionDistribution,
            },
          ]),
      {
        label: '',
        key: 'question-divider',
        isDivider: true,
      },
      {
        label: $t('知识点分析'),
        key: 'knowledge-analysis',
        isSub: true,
      },
      {
        label: $t('知识点得分率'),
        key: SubjectAnalysisTabKeyEnum.KnowledgeScoreRate,
      },
      {
        label: '',
        key: 'knowledge-divider',
        isDivider: true,
      },
      {
        label: $t('学生成绩'),
        key: 'student-score',
        isSub: true,
      },
      {
        label: $t('学生小分表'),
        key: SubjectAnalysisTabKeyEnum.StudentScoreTable,
      },
    ]
  }
