/**
 * @file routes
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { getProfileSubRoutes } from '@/features/profiles/Route/profileSubRoutes'

/**
 * 获取插件路由
 *
 * @returns 路由
 */
export const getGzarchiveRoutes = (): RouteConfig[] => [
  {
    path: 'admin/plugin/gz-archive/students/profiles',
    name: 'AdminGZArchiveStudentsProfiles',
    getTitle: () => $t('学生档案'),
    component: lazy(() =>
      import('./pages/Manage/Profiles').then(m => ({
        default: m.AdminGZProfileModal,
      })),
    ),
    subRoutes: getProfileSubRoutes(),
  },
  {
    path: 'admin/plugin/gz-archive/students',
    name: 'AdminGZArchiveStudents',
    getTitle: () => $t('学生列表'),
    module: [PluginCategoryEnum.学生成长, PluginNameEnum.GzArchive],
    component: lazy(() =>
      import('./pages/Manage/Students').then(m => ({
        default: m.AdminGZArchiveStudents,
      })),
    ),
    subRoutes: [
      {
        path: 'normal',
        name: 'Normal',
        getTitle: () => $t('在校学生'),
        component: lazy(() =>
          import('./pages/Manage/Students/Normal').then(m => ({
            default: m.AdminGZArchiveNormalStudents,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档学生'),
        component: lazy(() =>
          import('./pages/Manage/Students/Archived').then(m => ({
            default: m.AdminGZArchiveArchivedStudents,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/gz-archive/teachers',
    name: 'AdminGZArchiveTeachers',
    getTitle: () => $t('教师综合档案'),
    module: [PluginCategoryEnum.教师发展, PluginNameEnum.GzArchive],
    component: lazy(() =>
      import('./pages/Manage/Teachers').then(m => ({
        default: m.AdminGZArchiveTeachers,
      })),
    ),
    subRoutes: [
      {
        path: 'normal',
        name: 'Normal',
        getTitle: () => $t('在校教师'),
        component: lazy(() =>
          import('./pages/Manage/Teachers/Normal').then(m => ({
            default: m.AdminGZArchiveNormalTeachers,
          })),
        ),
      },
      {
        path: 'archived',
        getTitle: () => $t('已归档教师'),
        component: lazy(() =>
          import('./pages/Manage/Teachers/Archived').then(m => ({
            default: m.AdminGZArchiveArchivedTeachers,
          })),
        ),
      },
    ],
  },
]
