import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

/**
 * 第三方阅卷- 小题成绩录入
 */
export const examScoresThirdPartySubRoutes: RouteConfig[] = [
  {
    path: 'gained-score',
    name: 'AdminExamGainedScore',
    getTitle: () => $t('小题成绩录入'),
    component: lazy(() =>
      import('./pages/GainedScore/index').then(m => ({
        default: m.AdminExamGainedScore,
      })),
    ),
  },
]
/**
 * 考试前台 sub routes slot
 */
export const examFrontendQuestionSubRoutes: RouteConfig[] = [
  {
    path: 'question-list',
    getTitle: () => $t('出卷列表'),
    component: lazy(() =>
      import('./pages/user/QuestionList').then(m => ({
        default: m.QuestionList,
      })),
    ),
  },
]
