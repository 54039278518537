/**
 *@file 课表查询 routes
 */

import { omit } from '@seiue/util'
import { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

/**
 * 课表查询路由
 *
 * @param isPluginManager -- 是否是插件管理员
 * @returns routes -- 路由
 */
export const getRoutes = (isPluginManager: boolean) => {
  const subRoutes: RouteConfig[] = [
    {
      name: 'Teacher',
      path: 'teacher',
      getTitle: () => $t('教师课表'),
      component: lazy(() =>
        import('./pages/Teacher').then(m => ({
          default: m.Teacher,
        })),
      ),
      requirePermissions: [PermissionNameEnum.ClassScheduleTeacherScheduleRead],
    },
    {
      name: 'Student',
      path: 'student',
      getTitle: () => $t('学生课表'),
      component: lazy(() =>
        import('./pages/Student').then(m => ({
          default: m.Student,
        })),
      ),
      requirePermissions: [PermissionNameEnum.ClassScheduleStudentScheduleRead],
    },
    {
      name: 'Classroom',
      path: 'classroom',
      getTitle: () => $t('教室课表'),
      component: lazy(() =>
        import('./pages/Classroom').then(m => ({
          default: m.Classroom,
        })),
      ),
      requirePermissions: [
        PermissionNameEnum.ClassScheduleClassroomScheduleRead,
      ],
    },
    {
      name: 'Group',
      path: 'group',
      getTitle: () => $t('教研组课表'),
      component: lazy(() =>
        import('./pages/Group').then(m => ({
          default: m.Group,
        })),
      ),
      requirePermissions: [PermissionNameEnum.ClassScheduleGroupScheduleRead],
    },
    {
      name: 'AdminClass',
      path: 'admin-class',
      getTitle: () => $t('行政班课表'),
      component: lazy(() =>
        import('./pages/AdminClass').then(m => ({
          default: m.AdminClass,
        })),
      ),
      requirePermissions: [
        PermissionNameEnum.ClassScheduleAdminClassScheduleRead,
      ],
    },
  ]

  if (isPluginManager) {
    subRoutes.push({
      path: 'settings',
      getTitle: () => $t('设置'),
      component: lazy(() =>
        import('./pages/Settings').then(m => ({
          default: m.Settings,
        })),
      ),
    })
  }

  return [
    {
      name: 'Schedule',
      path: 'admin/plugin/schedule',
      getTitle: () => $t('课表查询'),
      module: [PluginEnum.SzzxSchedule],
      component: lazy(() =>
        Promise.resolve({
          default: RouteMenus,
        }),
      ),
      subRoutes: isPluginManager
        ? subRoutes.map(item => omit(item, 'requirePermissions'))
        : subRoutes,
    },

    {
      path: 'admin/plugin/schedule/settings/roles/new',
      name: 'AdminScheduleSettingsRolesNew',
      getTitle: () => $t('创建授权管理角色'),
      component: lazy(() =>
        import('./pages/Settings/NewRole').then(m => ({
          default: m.NewRole,
        })),
      ),
    },
    {
      path: 'admin/plugin/schedule/settings/roles/:roleId/edit',
      name: 'AdminScheduleSettingsRolesEdit',
      getTitle: () => $t('编辑授权管理角色'),
      component: lazy(() =>
        import('./pages/Settings/NewRole').then(m => ({
          default: m.NewRole,
        })),
      ),
    },
  ]
}
