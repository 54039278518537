import { atom } from 'jotai'

import {
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuItemUnion } from 'packages/web-layout/types'

export const classReviewAdminMenu = atom<MenuItemUnion[]>(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.ClassReview))) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.教师发展],
      name: PluginEnum.ClassReview,
      label: $t('听评课管理'),
      type: MenuType.AdminApps,
      path: '/admin/plugin/class-review',
      sort: 710,
      subMenus: [
        {
          label: $t('听评课管理'),
          path: '/admin/plugin/class-review/lessons',
          icon: 'Listening' as const,
        },
        {
          label: $t('教师数据统计'),
          path: '/admin/plugin/class-review/statistics',
          icon: 'Statistics' as const,
        },
        {
          label: $t('设置'),
          path: '/admin/plugin/class-review/settings',
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})

export const classReviewUserMenu = atom<MenuItemUnion[]>(() => {
  return [
    {
      type: MenuType.Apps,
      name: PluginEnum.ClassReview,
      label: $t('听评课'),
      subMenus: [
        {
          label: $t('我的听评课'),
          path: '/plugin/class-review/participated',
          icon: 'Examination' as const,
        },
      ],
    },
  ]
})
