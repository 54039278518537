/**
 * @file IM 插件
 */

import { lazy } from 'react'

import { hasUsablePlugin } from 'packages/feature-utils/plugins'
import { VirtualPlugin } from 'packages/features/plugins/utils/virtual-plugin'

import { InitPluginFeature } from '@/plugins/types'

export const initIm: InitPluginFeature = {
  name: 'im',
  init: plugins => {
    const pluginUsable = hasUsablePlugin(plugins)

    if (pluginUsable) {
      import('packages/plugins/features/im').then(m => m.initTim())
    }

    return {
      setting: {
        type: 'module' as const,
        component: lazy(() =>
          import('./components/PluginSetting').then(m => ({
            default: m.Setting,
          })),
        ),
        beforeChecked: async (plugin: VirtualPlugin) => {
          const schoolPluginSettings = plugin?.schoolPlugin?.settings
          let option = { checked: true, openSetting: false }
          // 未设置范围时 settings 为一个空数组，或者 undefined
          if (
            !schoolPluginSettings ||
            (Array.isArray(schoolPluginSettings) &&
              schoolPluginSettings.length === 0)
          ) {
            // 如果首次启用，范围设置为空，直接进入设置页面，否则只启用
            option = { checked: true, openSetting: true }
          }

          return option
        },
      },
      slots: pluginUsable
        ? {
            homeLayoutHeaderRight: lazy(() =>
              import('./slot/EnterIcon').then(m => ({
                default: m.ImEnterIcon,
              })),
            ),
          }
        : undefined,
    }
  },
}
