/**
 * @file 德育评价 / 学生档案页 slots
 */

import { lazy } from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

// 学生档案-学籍表路由 slot
export const tabSlots: RouteConfig[] = [
  {
    path: 'credit',
    name: 'Credit',
    getTitle: () => $t('学分'),
    component: lazy(() =>
      import('../pages/admin/Detail').then(m => ({
        default: m.Detail,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.CreditCredit,
    },
  },
]
