import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { GroupPermissionTypeEnum } from 'packages/features/groups/types'

export const studentProfileTabSlots = [
  {
    path: 'report-center',
    name: 'ReportCenter',
    getTitle: () => $t('报表'),
    component: lazy(() =>
      import('../pages/student/ReportList').then(m => ({
        default: m.ReportList,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.ReportCenter,
      pluginPermissions: [SchoolPluginsEnum.ReportCenter],
    },
  },
]

export const teacherProfileTabSlots = [
  {
    path: 'report-center',
    name: 'ReportCenter',
    getTitle: () => $t('报表'),
    component: lazy(() =>
      import('../pages/teacher/ReportList').then(m => ({
        default: m.ReportList,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.ReportCenter,
      pluginPermissions: [SchoolPluginsEnum.ReportCenter],
    },
  },
]
