/**
 * @file 学生心理档案-主入口
 */
import { first, omit } from '@seiue/util'

import { hasManageablePlugin } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import {
  PsychologicalGroup,
  acmApi$queryConfigItemsByGroup,
  groupApi$getPsychologicalGroupTypeApi,
  acmApi$mutationConfigItems,
} from 'packages/sdks-next/chalk'

import { InitPluginFeature } from '@/plugins/types'

import { calendarEvents } from './calendar-events'
import { PLUGIN_NAME } from './const'
import { adminPsychologicalFileMenu, userPsychologicalMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { todos } from './todos'
import { workflowRenders } from './workflow-renders'

const getRoleGroupName = (from?: string) => {
  switch (from) {
    case 'psychological':
      return $t('可选管理员职务')
    case 'system':
      return $t('可选业务员角色')
    default:
      return ''
  }
}

export const initPsychologicalFile: InitPluginFeature = {
  name: PLUGIN_NAME,
  init: plugins => ({
    setting: {
      type: 'json',
      beforeChecked: async val => {
        const scope = `school.${val.schoolPlugin?.schoolId}`
        const service = 'seiue.sgms'
        const group = 'psychological.problem_report'
        const name = 'receiver_selected'
        // 获取上报对象设置的数据
        const { data: configItems } = await acmApi$queryConfigItemsByGroup.api(
          scope,
          service,
          group,
          { nameIn: name },
        )

        // 上报对象设置如果已经初始化了，则类型为 PsychologicalGroup，没有初始化则是 string，数据固定为['导师', '班主任', '宿舍管理员', '任课教师']
        const roles: (PsychologicalGroup | string)[] =
          first(configItems)?.value || []

        // 如果上报对象设置没有初始化，先初始化
        if (roles.every(r => typeof r === 'string')) {
          const { data: receiversRoles } =
            await groupApi$getPsychologicalGroupTypeApi.api()

          const receiversRolesOptions =
            receiversRoles
              // 过滤掉课程库负责人，课程库负责人不负责学生的心理问题上报
              ?.filter(item => item.type !== 'seiue.course_group')
              .map(r => ({
                label: r.roleName,
                value: r.id,
                group: {
                  label: getRoleGroupName(r.from),
                  id: r.from === 'psychological' ? 1 : 2,
                },
                ...r,
              })) || []

          const payload = receiversRolesOptions
            .filter(item => roles.includes(item.label))
            .map((r, idx) => ({
              ...omit(r, ['label', 'value', 'group']),
              sort: idx,
            }))

          await acmApi$mutationConfigItems.api(scope, service, [
            {
              name,
              group,
              value: payload,
            },
          ])

          return { checked: true }
        }

        return { checked: true }
      },
    },
    routes: routes(hasManageablePlugin(plugins)),
    modalRoutes,
    todos,
    menus: [adminPsychologicalFileMenu, userPsychologicalMenu],
    slots: {
      GroupMemberActionItem: () =>
        import('./slots').then(m => m.GroupMemberActionItem),
      userCommonApplicationsEntry: () =>
        import('./slots').then(m => m.CommonUseApplicationsEntry),
      scheduleCreateButtonAction: () =>
        import('./slots').then(m => m.ScheduleCreateButtonAction),
    },
    workflowRenders,
    calendarEvents,
  }),
}
