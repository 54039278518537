/**
 * @file 选课插件入口
 */

import { InitPluginFeature } from '@/plugins/types'

import { adminElectionMenu } from './menus'
import { routes } from './routes'
import { todos } from './todos'

export const initElection: InitPluginFeature = {
  name: 'election',
  init: () => ({ routes, todos, menus: [adminElectionMenu] }),
}
