import { atom } from 'jotai'

import { hasManageableParentPluginAtom } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuType } from 'packages/web-layout/types'

export const adminClassStopMenu = atom(get => {
  const isManager = get(hasManageableParentPluginAtom(PluginNameEnum.Tkgl))
  if (!isManager) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: PluginNameEnum.Tkgl,
      label: $t('停课管理'),
      path: '/admin/plugin/closed-class/list',
      sort: 709,
      type: MenuType.AdminApps,
      icon: 'StudentSuspension' as const,
      shortcutIcon: 'StudentSuspensionSolid+8E6BF1',
      subMenus: [
        {
          label: $t('停课生'),
          path: '/admin/plugin/closed-class/list',
          icon: 'StudentSuspension' as const,
        },
      ],
    },
  ]
})
