import { reportToSentry } from '@seiue/sentry'

import { EnabledPlugin } from 'packages/feature-utils/plugins'

import { InitPluginParams } from '@/plugins/types'

import { SlotTypes } from '../slot'

import { initAbnormalAttendNotify } from './abnormal-attend-notify'
import { initAbnormalAttendSmsNotify } from './abnormal-attend-sms-notify'
import { initAbsence } from './absence'
import { initAcademicGoals } from './academic-goal'
import { initAccAssessment } from './acc-assessments'
import { initAccScoreConverter } from './acc-score-converter'
import { initAdjustment } from './adjustment'
import { initAITeacher } from './ai-teacher'
import { initApproval } from './approval'
import { initAthena } from './athena'
import { initBjMonitorPlatform } from './bj-monitor-platform'
import { initFourthGradesReports } from './bj4cz-report'
import { initBjszgzbGradesReports } from './bjszgzb-grades-reports'
import { initCertification } from './certification'
import { initCertificationCredit } from './certification-credit'
import { initCgr } from './cgr'
import { initCgrGzzx } from './cgr-gzzx'
import { initChalkAI } from './chalk-ai'
import { initChat } from './chat'
import { initClassDeclarations } from './class-declarations'
import { initClassIn } from './class-in'
import { initClassReview } from './class-review'
import { initClosedClass } from './class-stop'
import { initClassTimetable } from './class-timetable'
import { initClub } from './club'
import { initContract } from './contract'
import { initCopilot } from './copilot'
import { initCredit } from './credit'
import { initCustomizedGroups } from './customized-groups'
import { initDataScreen } from './data-screen'
import { initDataSync } from './data-sync'
import { initDorms } from './dorms'
import { initEems } from './eems'
import { initElecCertificate } from './elec-certificate'
import { initElection } from './election'
import { initEvents } from './events'
import { initExamExport } from './exam-export'
import { initExamSchedules } from './exam-rooms'
import { initExamsFrontend } from './exams-frontend'
import { initFifteenGradesReports } from './fifteen-grades-reports'
import { initGst } from './general-schedule-tool'
import { initGradeSubmitReview } from './grade-submit-review'
import { initGuangYaGrades } from './guang-ya-grades'
import { initGZArchive } from './gz-archive'
import { initGzExamResAnalysis } from './gz-exam-res-analysis'
import { initGzFreshmanQuestionnaire } from './gz-freshman-questionnaire'
import { initGzzxHomeMenusSetting } from './gzzx-home-menus-setting'
import { initHandouts } from './handouts'
import { initIm } from './im'
import { initIntlGoal } from './intl-goal'
import { initKaopuAiTool } from './kaopu-ai-tool'
import { initLiveTasks } from './live-tasks'
import { initLMS } from './lms'
import { initMoonshotBoard } from './moonshot-board'
import { initMoralAssessments } from './moral-assessments'
import { initNetDisk } from './net-disk'
import { initOnlineScoring } from './online-scoring'
import { initOrganizationStructure } from './organization-structure'
import { initPersonalization } from './personalization'
import { initPersonnelProfile } from './personnel-profile'
import { initPsychologicalFile } from './psychological-file'
import { initQuestionnaire } from './questionnaire'
import { initReportCenter } from './report-center'
import { initResit } from './resit'
import { initSmsPlatform } from './sms-platform'
import { initSSO } from './sso'
import { initStopStudentGrade } from './stop-student-grade'
import { initStudentMerge } from './student-merge'
import { initStudentStatusProfile } from './student-status-profile'
import { initSuccessCriteria } from './success-criteria'
import { initSZCustomResitRule } from './sz-custom-resit-rule'
import { initSzGradesReports } from './sz-grades-reports'
import { initSzSubjectSelectionStat } from './sz-subject-selection-stat'
import { initSzTeachingSatisficing } from './sz-teaching-satisficing'
import { initSzhpsyGradesReports } from './szhpsy-grades-reports'
import { initSzzxSchedule } from './szzx-schedule'
import { initTeacherAssessment } from './teacher-assessments'
import { initTeacherAttendance } from './teacher-attendance'
import { initTeacherArchives } from './teacher-profile'
import { initThirdPartyScoring } from './third-party-scoring'
import { initTranscriptSetting } from './transcript-setting'
import { initVisitorCenter } from './visitor-center'
import { initWechatMp } from './wechat-mp'
import { initYuque } from './yuque'

const plugins = [
  // 认证优先加载，保证相关的 slots 排在第一个
  initCertification,
  initCertificationCredit,
  initCopilot,
  initPersonalization,
  initAbsence,
  initSmsPlatform,
  initSzTeachingSatisficing,
  initEems,
  initNetDisk,
  initMoralAssessments,
  initExamSchedules,
  initCgr,
  initCgrGzzx,
  initYuque,
  initCustomizedGroups,
  initDataSync,
  initClassDeclarations,
  initGst,
  initSzGradesReports,
  initElection,
  initElecCertificate,
  initEvents,
  initAthena,
  initClassIn,
  initClosedClass,
  initFifteenGradesReports,
  initBjszgzbGradesReports,
  initQuestionnaire,
  initApproval,
  initResit,
  initFourthGradesReports,
  initIntlGoal,
  initAcademicGoals,
  initGuangYaGrades,
  initSzhpsyGradesReports,
  initIm,
  initChat,
  initGzExamResAnalysis,
  initGzFreshmanQuestionnaire,
  initTeacherArchives,
  initCredit,
  initExamExport,
  initSzzxSchedule,
  initAbnormalAttendSmsNotify,
  initWechatMp,
  initGradeSubmitReview,
  initBjMonitorPlatform,
  initStopStudentGrade,
  initContract,
  initHandouts,
  initSZCustomResitRule,
  initSzSubjectSelectionStat,
  initSSO,
  initPersonnelProfile,
  initAbnormalAttendNotify,
  initSuccessCriteria,
  initAccAssessment,
  initAccScoreConverter,
  initStudentStatusProfile,
  initClassTimetable,
  initOrganizationStructure,
  initReportCenter,
  initTeacherAssessment,
  initLMS,
  initDorms,
  initOnlineScoring,
  initThirdPartyScoring,
  initLiveTasks,
  initGzzxHomeMenusSetting,
  initMoonshotBoard,
  initAdjustment,
  initExamsFrontend,
  initTranscriptSetting,
  initStudentMerge,
  initVisitorCenter,
  initKaopuAiTool,
  initChalkAI,
  initAITeacher,
  initDataScreen,
  // GzArchive 的插件注册必须在底部，因为它的路由注册依赖其他插件的初始化
  initGZArchive,
  initClub,
  initClassReview,
  initTeacherAttendance,
  initPsychologicalFile,
]

/**
 * 初始化 plugin features
 *
 * @param params - init plugin params
 * @param enabledPlugins - 已启用的插件列表
 */
export const initPluginFeatures = (
  params: InitPluginParams,
  enabledPlugins: EnabledPlugin[],
) => {
  const allPlugins = plugins

  allPlugins.forEach(({ force, name, init }) => {
    /*
     * 隔离 Plugin 初始化可能出现的崩溃问题
     * 使其不再影响主系统的渲染
     */
    try {
      const _plugins = enabledPlugins.filter(p => p.pluginName === name)
      const isPluginEnabled = !!_plugins.length

      const {
        calendarEvents,
        todos,
        menus,
        routes,
        modalRoutes,
        slots,
        setting,
        workflowRenders,
        widgets,
      } = init?.(_plugins) || {}

      // 日程/待办/消息等通用机制始终注册 (为了插件不启用时相关数据也能正常显示）
      if (calendarEvents?.length) {
        params.registerCalendarEvents(calendarEvents)
      }

      if (todos?.length) {
        params.registerTodos(todos)
      }

      if (workflowRenders) {
        params.registerWorkflowRenders(workflowRenders)
      }

      if (force || isPluginEnabled) {
        params.addRoutes(routes, modalRoutes)
        params.registerWidgets(widgets || [])
      }

      if (menus?.length && (force || isPluginEnabled)) {
        menus.forEach(menu => params.addMenuProvider(menu))
      }

      if ((force || isPluginEnabled) && slots) {
        Object.keys(slots).forEach(key => {
          const slotName = key as keyof SlotTypes
          const slotContent = slots[slotName]
          if (slotContent) params.registerToSlot(slotName, slotContent)
        })
      }

      params.registerSetting(name, setting)
    } catch (e) {
      console.error(`Seiue Plugin ${name} init failed!`)

      reportToSentry(e, {
        ExceptionType: 'PluginInitFailed',
      })
    }
  })
}
