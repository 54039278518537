/**
 * @file 动态菜单
 */

import { atom } from 'jotai'

import { useHasPluginManagementPermission } from 'packages/features/plugins/utils/check-plugin'
import {
  useHasCurrentPermission,
  useHasCurrentPermissionFunction,
} from 'packages/features/roles/hooks'
import { useCheckCurrentSchoolId } from 'packages/features/schools/hooks'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'
import { MenuPermissionFunc } from 'packages/web-layout/types'

import { getStoreForAtom } from '@/store'

import { UseMenus } from '../types'

const mustHasPermission = [
  PermissionNameEnum.GzzxHistoryCgrExamReportRead,
  PermissionNameEnum.GzzxHistoryCgrExamReportDownload,
  PermissionNameEnum.GzzxAssessmentCgrExamReportRead,
  PermissionNameEnum.GzzxAssessmentCgrExamReportDownload,
  PermissionNameEnum.GzzxCgrExamReportRead,
  PermissionNameEnum.GzzxCgrExamReportDownload,
]

export const useHasGzzxCgrEntry = () => {
  const hasPermission = useHasCurrentPermissionFunction()

  // 被 cgr 管理员授予了管理权限
  const had = mustHasPermission.some(permission =>
    hasPermission({ permission, isManager: true }),
  )

  const has = useHasPluginManagementPermission('gzzxcgr')
  return has || had
}

export const useGzzxCgrMenu: UseMenus = () => {
  const isGzzx = useCheckCurrentSchoolId(145)
  const hasPermission = useHasCurrentPermissionFunction()

  // 被 cgr 管理员授予了管理权限

  const gzzxHad = mustHasPermission.some(permission =>
    hasPermission({ permission, isManager: true }),
  )

  const has = useHasPluginManagementPermission('gzzxcgr')

  // 如果是管理员, 菜单是 @/features/plugins 统一添加的
  if (isGzzx && !has && gzzxHad) {
    return [
      {
        label: $t('广州中学定制成绩单'),
        path: '/admin/plugin/gzzx/cgr/reports/normal',
        type: MenuType.Apps,
      },
    ]
  }

  return []
}

export const adminCgrGzzxMenu = atom(get => {
  const store = getStoreForAtom(get)
  const me = store.session.currentReflection

  if (!me || me.schoolId !== 145) {
    return []
  }

  const checkPermission: MenuPermissionFunc = (
    hasPermission: typeof useHasCurrentPermission,
  ) => {
    return mustHasPermission.some(permission =>
      hasPermission({ permission, isManager: true }),
    )
  }

  return [
    {
      prefix: [PluginCategoryEnum.其他],
      name: 'cgr-gzzx',
      label: $t('广州中学定制成绩单'),
      path: '/admin/plugin/gzzx/cgr/reports',
      type: MenuType.AdminApps,
      permission: checkPermission,
      sort: 800,
      subMenus: [
        {
          label: $t('报告单'),
          path: '/admin/plugin/gzzx/cgr/reports',
          icon: 'Report',
        },
        {
          label: $t('成绩状态'),
          path: '/admin/plugin/gzzx/cgr/status',
          icon: 'MoralEvaluation',
        },
        {
          label: $t('设置'),
          path: '/admin/plugin/gzzx/cgr/settings',
          icon: 'Setting',
        },
      ],
    },
  ]
})
