/**
 * @file 学分管理插件
 */

import { hasManageablePlugin } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@/plugins/types'

import { adminCreditMenu } from './menus'
import { getRoutes } from './routes'
import { tabSlots } from './slots/tab-slots'

export const initCredit: InitPluginFeature = {
  name: 'credit',
  init: plugins => ({
    routes: getRoutes(hasManageablePlugin(plugins)),
    menus: [adminCreditMenu],
    slots: {
      profileGradesSubRoutes: tabSlots,
      profileModulePermissions: () =>
        import('./slots/permissions-slots').then(
          m => m.getProfileModulePermissionsSlots,
        ),
      scopesBoardMetasWithoutDomainSlots: () =>
        import('./slots/scopes-slot').then(
          m => m.getScopesBoardMetasWithoutDomainSlots,
        ),
    },
  }),
}
