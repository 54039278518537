/* eslint-disable max-lines */
/**
 * @file 活动插件路由
 */
import React from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/event/home',
    getTitle: () => $t('活动管理'),
    name: 'EventsAdminDefault',
    module: [PluginCategoryEnum.学生成长, SchoolPluginsEnum.Event],
    component: React.lazy(() =>
      import('./management/pages/index').then(m => ({
        default: m.Default,
      })),
    ),
  },
  {
    path: 'admin/plugin/event/:pid',
    getTitle: () => $t('活动管理'),
    name: 'EventsAdmin',
    module: [PluginCategoryEnum.学生成长, SchoolPluginsEnum.Event],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'EventsList',
        getTitle: () => $t('活动列表'),
        component: React.lazy(() =>
          import('./management/pages/EventList').then(m => ({
            default: m.EventsList,
          })),
        ),
        subRoutes: [
          {
            path: 'active',
            getTitle: () => $t('未结束'),
            name: 'Active',
            component: React.lazy(() =>
              import('./management/pages/EventList/List').then(m => ({
                default: m.List,
              })),
            ),
          },
          {
            path: 'overdue',
            getTitle: () => $t('已结束'),
            name: 'Overdue',
            component: React.lazy(() =>
              import('./management/pages/EventList/List').then(m => ({
                default: m.List,
              })),
            ),
          },
        ],
      },
      {
        path: 'batch-edit',
        getTitle: () => $t('批量编辑'),
        name: 'BatchEdit',
        component: React.lazy(() =>
          import('./management/pages/EventList/BatchEdit').then(m => ({
            default: m.BatchEventsEdit,
          })),
        ),
      },
      {
        path: 'application',
        getTitle: () => $t('申报列表'),
        name: 'ApplicationList',
        component: React.lazy(() =>
          import('./management/pages/ApplicationList').then(m => ({
            default: m.ApplicationList,
          })),
        ),
      },
      {
        path: 'member-statistics',
        getTitle: () => $t('成员统计'),
        name: 'MembersStatistics',
        component: React.lazy(() =>
          import('./management/pages/MembersStatistics').then(m => ({
            default: m.MembersStatistics,
          })),
        ),
      },
      {
        path: 'events-calendar',
        getTitle: () => $t('活动日历'),
        name: 'EventsCalendar',
        component: React.lazy(() =>
          import('./management/pages/EventsCalendar').then(m => ({
            default: m.EventsCalendar,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('设置'),
        name: 'Settings',
        component: React.lazy(() =>
          import('./management/pages/Settings').then(m => ({
            default: m.Settings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/event/:pid/personal-details/:rid/:name',
    getTitle: () => $t('详情'),
    name: 'PersonalStatistic',
    component: React.lazy(() =>
      import('./management/pages/PersonalDetails').then(m => ({
        default: m.PersonalDetailsEntry,
      })),
    ),
    subRoutes: [
      {
        path: 'joined',
        name: 'joined',
        getTitle: () => $t('参与活动'),
        component: React.lazy(() =>
          import('./management/pages/PersonalDetails').then(m => ({
            default: m.PersonalJoined,
          })),
        ),
      },
      {
        path: 'initiated',
        name: 'Initiated',
        getTitle: () => $t('发起活动'),
        component: React.lazy(() =>
          import('./management/pages/PersonalDetails').then(m => ({
            default: m.PersonalInitiated,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/event/:pid/new',
    getTitle: () => $t('新增活动'),
    name: 'NewEvent',
    component: React.lazy(() =>
      import('./management/pages/EditEvent').then(m => ({
        default: m.NewEvent,
      })),
    ),
  },
  {
    path: 'admin/plugin/event/:pid/edit/:eid',
    getTitle: () => $t('编辑活动'),
    name: 'EditEvent',
    component: React.lazy(() =>
      import('./management/pages/EditEvent').then(m => ({
        default: m.EditEvent,
      })),
    ),
  },

  {
    path: 'admin/plugin/event/:pid/event/:eid',
    getTitle: () => $t('活动详情'),
    name: 'Event',
    component: React.lazy(() =>
      import('./management/pages/EventInfo').then(m => ({
        default: m.EventInfo,
      })),
    ),
    subRoutes: [
      {
        path: 'info',
        name: 'EventInfo',
        getTitle: () => $t('活动信息'),
        component: React.lazy(() =>
          import('./management/pages/EventInfo/Info').then(m => ({
            default: m.Info,
          })),
        ),
      },
      {
        path: 'members',
        name: 'Members',
        getTitle: () => $t('人员管理'),
        component: React.lazy(() =>
          import('./management/pages/EventInfo/MembersManagement').then(m => ({
            default: m.MembersManagement,
          })),
        ),
      },
    ],
  },
]

export const userRoutes: RouteConfig[] = [
  {
    name: 'EventsUser',
    path: 'plugin/event/:pid',
    getTitle: () => $t('活动'),
    module: [SchoolPluginsEnum.Event],
    component: React.lazy(() =>
      import('@/plugins/features/events/user/index').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        name: 'EventsList',
        path: 'list',
        getTitle: () => $t('活动列表'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/List/List').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        name: 'Applied',
        path: 'Applied',
        getTitle: () => $t('我报名的'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/List/Applied').then(m => ({
            default: m.Applied,
          })),
        ),
      },
      {
        name: 'Initiate',
        path: 'initiate',
        getTitle: () => $t('我发起的'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/List/Initiate').then(m => ({
            default: m.Initiate,
          })),
        ),
      },
    ],
  },
  {
    name: 'EventsDetails',
    path: 'plugin/event/:pid/view/:eid/as/:as',
    getTitle: () => $t('活动详情'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/Event').then(m => ({
        default: m.EventDetails,
      })),
    ),
    subRoutes: [
      {
        name: 'Home',
        path: 'home',
        getTitle: () => $t('主页'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/Event/Home').then(m => ({
            default: m.Home,
          })),
        ),
      },
      {
        name: 'Attendance',
        path: 'attendance',
        getTitle: () => $t('考勤'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/Event/Attendance').then(m => ({
            default: m.Attendance,
          })),
        ),
        subRoutes: [
          {
            name: 'SessionAttendance',
            path: 'sessionattendance',
            getTitle: () => $t('时段考勤'),
            component: React.lazy(() =>
              import(
                '@/plugins/features/events/user/Event/Attendance/SessionAttendance'
              ).then(m => ({
                default: m.SessionAttendance,
              })),
            ),
          },
          {
            name: 'AttendanceStatistic',
            path: 'attendancestatistic',
            getTitle: () => $t('考勤统计'),
            component: React.lazy(() =>
              import(
                '@/plugins/features/events/user/Event/Attendance/Statictis'
              ).then(m => ({
                default: m.Statictis,
              })),
            ),
          },
        ],
      },
      {
        name: 'Ranking',
        path: 'ranking',
        getTitle: () => $t('排行榜'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/Event/Ranking').then(m => ({
            default: m.EventRanking,
          })),
        ),
      },
      {
        name: 'PunchData',
        path: 'punch-data',
        getTitle: () => $t('打卡数据'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/Event/PunchData').then(m => ({
            default: m.EventPunchData,
          })),
        ),
      },
      {
        name: 'MyPunch',
        path: 'my-punch',
        getTitle: () => $t('我的数据'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/Event/MyPunchData').then(
            m => ({
              default: m.MyEventPunchData,
            }),
          ),
        ),
      },
      {
        name: 'Discussion',
        path: 'discussion',
        getTitle: () => $t('讨论'),
        component: React.lazy(() =>
          import('@/plugins/features/events/user/Event/Discussion').then(m => ({
            default: m.EventDiscussion,
          })),
        ),
      },
    ],
  },
  {
    path: 'plugin/event/:pid/discussions/:eid/for-reflection/:rid/collections',
    name: 'EventsDiscussionCollectionsForReflection',
    getTitle: () => $t('我的收藏'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/Event/Collections').then(m => ({
        default: m.Collections,
      })),
    ),
  },
  {
    path: 'plugin/event/:pid/discussions/:eid/for-reflection/:rid/posts',
    name: 'EventsDiscussionPostsForReflection',
    getTitle: () => $t('我的发布'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/Event/Posts').then(m => ({
        default: m.Posts,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'Plugin.EventApprovalProcess',
    getTitle: () => '',
    component: React.lazy(() =>
      import(
        './management/pages/Settings/ApprovalProcess/ApprovalProcess'
      ).then(m => ({
        default: m.ApprovalProcess,
      })),
    ),
  },
  {
    name: 'PreviewApplicationForm',
    getTitle: () => '',
    component: React.lazy(() =>
      import('./management/pages/Settings/PreviewFormModal').then(m => ({
        default: m.Preview,
      })),
    ),
  },
  {
    name: 'ApplicationDetail',
    getTitle: () => $t('申报详情'),
    component: React.lazy(() =>
      import('./components/ApplicationDetails').then(m => ({
        default: m.ApplicationDetails,
      })),
    ),
  },
  {
    name: 'InitEvent',
    getTitle: () => $t('发起活动'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/InitEventModal').then(m => ({
        default: m.InitEvent,
      })),
    ),
  },
  {
    name: 'SignUpManagement',
    getTitle: () => $t('报名审批'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/SignUpManagement').then(m => ({
        default: m.SignUpManagement,
      })),
    ),
  },
  {
    name: 'MemberManagement',
    getTitle: () => $t('人员管理'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/MemberManagementModal').then(
        m => ({
          default: m.MemberManagement,
        }),
      ),
    ),
  },
  {
    name: 'SignIn',
    getTitle: () => $t('扫码考勤'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/SignInCodeModal').then(m => ({
        default: m.SignInCode,
      })),
    ),
  },
  {
    name: 'EntryEventAttendance',
    getTitle: () => $t('录入考勤'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/EntryAttendanceModal').then(m => ({
        default: m.EntryAttendanceModal,
      })),
    ),
  },
  {
    name: 'SignUpReason',
    getTitle: () => $t('报名理由'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/ReasonUploadModal').then(m => ({
        default: m.ReasonUploadModal,
      })),
    ),
  },
  {
    name: 'EditSignUp',
    getTitle: () => $t('修改报名'),
    component: React.lazy(() =>
      import('@/plugins/features/events/user/EditSignUpModal').then(m => ({
        default: m.EditSignUpModal,
      })),
    ),
  },
]
