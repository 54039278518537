/**
 * @file 国际校目标管理
 */

import { PluginEnum } from 'packages/feature-utils/plugins'
import { PluginNameEnum } from 'packages/plugins/types'
import { RoleEnum } from 'packages/sdks-next/chalk'

import { DataSlotFns } from '@/plugins/slot/data-slots'
import { InitPluginFeature } from '@/plugins/types'

import { adminIntlGoalMenus } from './menus'
import { profileRoutes, adminRoutes, modalRoutes } from './routes'

export const initIntlGoal: InitPluginFeature = {
  name: PluginNameEnum.IntlGoal,
  init: () => {
    return {
      routes: adminRoutes,
      menus: [adminIntlGoalMenus],
      modalRoutes,
      slots: {
        profileGoalsSubRoutes: profileRoutes,
        userCommonApplicationsEntry: () => Promise.resolve(intlGoalEntry),
      },
    }
  },
}

const intlGoalEntry: DataSlotFns['userCommonApplicationsEntry'] = ({
  historyOrigin,
}) => [
  {
    name: PluginEnum.IntlGoal,
    callback: ({ me, isFromManage }) => {
      if (isFromManage) {
        historyOrigin.push('/admin/plugin/intl-goal')
      } else if (me.role === RoleEnum.Student) {
        historyOrigin.push('/profiles/me/goals/intl-goal')
      } else {
        historyOrigin.push('/admin/plugin/intl-goal')
      }
    },
  },
]
