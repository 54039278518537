import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  findManageableParentPluginAtom,
  getPluginIcon,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuProvider } from 'packages/web-layout/menu-utils'

export const adminMenus: MenuProvider = atom(get => {
  const plugin = get(
    findManageableParentPluginAtom(PluginEnum.OrganizationStructure),
  )

  if (!plugin) {
    return []
  }

  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.OrganizationStructure),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.通用工具],
      permission: () => true,
      name: PluginEnum.OrganizationStructure,
      path: '/admin/plugin/organization-structure',
      label: $t('组织架构'),
      icon: 'Archives' as const,
      shortcutIcon,
      sort: 800,
      subMenus: [
        {
          path: '/admin/plugin/organization-structure',
          label: $t('组织架构'),
          permission: () => true,
          icon: 'Archives' as const,
        },
      ],
    },
  ]
})
