/**
 * @file 问卷
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { userQuestionnaireMenu, adminQuestionnaireMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { todos } from './types'

export const initQuestionnaire: InitPluginFeature = {
  name: PluginNameEnum.Questionnaire,
  init: () => ({
    routes,
    modalRoutes,
    todos,
    menus: [userQuestionnaireMenu, adminQuestionnaireMenu],
    slots: {
      userCommonApplicationsEntry: () =>
        import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
  }),
}
