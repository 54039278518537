/**
 * @file 活动插件-入口
 */

import { lazy } from 'react'

import { hasManageablePlugin } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@/plugins/types'

import { calendarEvents } from './calendarEvent'
import { adminEventMenu, userEventMenu } from './menus'
import { userRoutes, adminRoutes, modalRoutes } from './route'
import { todos, workflowRenders } from './todos'

export const initEvents: InitPluginFeature = {
  name: 'event',
  init: plugins => {
    let routes
    if (hasManageablePlugin(plugins)) {
      routes = [...userRoutes, ...adminRoutes]
    } else {
      // 活动子插件的使用范围是全部人员，工作台所有人可见
      routes = userRoutes
    }

    return {
      setting: {
        type: 'extendable' as const,
        component: lazy(() =>
          import('./setting').then(m => ({ default: m.Setting })),
        ),
      },
      calendarEvents,
      routes,
      menus: [adminEventMenu, userEventMenu],
      modalRoutes,
      workflowRenders,
      todos,
      slots: {
        userCommonApplicationsEntry: () =>
          import('./slots').then(m => m.CommonUseApplicationsEntry),
      },
    }
  },
}
