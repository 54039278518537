/**
 * @file 和平实验小学定制成绩单
 */

import { InitPluginFeature } from '@/plugins/types'

import { adminRoutes } from './routes'

export const initSzhpsyGradesReports: InitPluginFeature = {
  name: 'szhpsy-report',
  init: () => ({
    routes: adminRoutes,
    slots: {
      adminGradeListExportItem: () =>
        import('./slots/send-reports').then(m => m.sendSzhpsyReports),
      adminClassSemesterGradeItem: () =>
        import('./slots/download').then(m => m.getSemesterGradeItems),
      teacherGradeOverviewExportItem: () =>
        import('./slots/export-class-grades-statistics').then(
          m => m.exportClassGradesStatistics,
        ),
    },
  }),
}
