/**
 * @file 课程班 Routes slot，一些优先级相对高一点的 class sub routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const profileClassImportantSubRoutes: RouteConfig[] = [
  {
    path: 'handout',
    name: 'ClassGroupHandout',
    getTitle: () => $t('学案'),
    component: lazy(() =>
      import('@/plugins/features/handouts/pages/user/Class').then(m => ({
        default: m.ClassGroupHandout,
      })),
    ),
  },
]
