/**
 * @file routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    path: 'admin/plugin/gzzx/cgr/reports',
    getTitle: () => $t('报告单'),
    module: [PluginCategoryEnum.其他, 'cgr-gzzx'],
    component: lazy(() =>
      import('./pages').then(mod => ({
        default: mod.CgrMain,
      })),
    ),
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('在校学生'),
        component: lazy(() =>
          import('./pages/reports/List').then(m => ({
            default: m.GradesReportsList,
          })),
        ),
      },

      {
        path: 'archived',
        getTitle: () => $t('已归档学生'),
        component: lazy(() =>
          import('./pages/reports/List').then(m => ({
            default: m.GradesReportsList,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/gzzx/cgr/status',
    getTitle: () => $t('成绩状态'),
    module: [PluginCategoryEnum.其他, 'cgr-gzzx'],
    component: lazy(() =>
      import('./pages/status').then(m => ({
        default: m.StatuReportsPlugin,
      })),
    ),
  },

  {
    path: 'admin/plugin/gzzx/cgr/settings',
    getTitle: () => $t('设置'),
    module: [PluginCategoryEnum.其他, 'cgr-gzzx'],
    component: lazy(() =>
      import('./pages/settings').then(m => ({
        default: m.SettingsGradesReports,
      })),
    ),
  },
  {
    path: 'admin/plugin/gzzx/cgr/settings/roles/new',
    getTitle: () => $t('编辑成绩分析角色'),
    component: lazy(() =>
      import('./pages/settings/components/roles/components/roleEdit').then(
        m => ({
          default: m.RoleEditWrapper,
        }),
      ),
    ),
    // requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },

  {
    path: 'admin/plugin/gzzx/cgr/settings/roles/:roleId/edit',
    getTitle: () => $t('编辑成绩分析角色'),
    component: lazy(() =>
      import('./pages/settings/components/roles/components/roleEdit').then(
        m => ({
          default: m.RoleEditWrapper,
        }),
      ),
    ),
    // requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },

  {
    path: 'admin/plugin/gzzx/cgr/student/report/:sid',
    getTitle: () => $t('学生报告单'),
    component: lazy(() =>
      import('./pages/reports/student').then(m => ({
        default: m.Student,
      })),
    ),
  },
]
