/**
 * @file 排考场
 */
import { calendarEvents } from '@/plugins/features/exam-rooms/calendar-events'
import { InitPluginFeature } from '@/plugins/types'

import { routes, modalRoutes } from './routes'

export const initExamSchedules: InitPluginFeature = {
  name: 'exam_schedule',
  init: () => ({
    routes,
    modalRoutes,
    calendarEvents,
    slots: {
      examEntryImportMenuItem: () =>
        import('./slots/import-missing-exam-list').then(
          m => m.importMissingExamList,
        ),
    },
  }),
}
