/**
 * @file 注册审批渲染器
 */
import React, { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { ChalkRegisterWorkflowRenderType } from '@/features/workflows/register'

export const workflowRenders: ChalkRegisterWorkflowRenderType[] = [
  {
    bizType: PluginNameEnum.Absence,
    render: {
      FlowCard: lazy(() =>
        import('./FlowCard').then(m => ({ default: m.AbsenceCenterCard })),
      ),
      TodoCard: React.lazy(() =>
        import('./TodoCard').then(m => ({ default: m.AbsenceTodoCard })),
      ),
      NewFlowModal: React.lazy(() =>
        import('./NewFlowModal').then(m => ({
          default: m.AbsenceNewFlowModal,
        })),
      ),
      ShowFlowModal: React.lazy(() =>
        import('./ShowFlowModal/index').then(m => ({
          default: m.ShowAbsenceModal,
        })),
      ),
      WorkflowAdminTable: React.lazy(() =>
        import('./AbsenceAdminTable').then(m => ({
          default: m.AbsenceAdminTable,
        })),
      ),
    },
  },
  {
    // @ts-expect-error 特殊的请假销假审批流注册
    bizType: 'absence_verification',
    render: {
      TodoCard: React.lazy(() =>
        import('./TodoCard').then(m => ({
          default: m.AbsenceVerifictionTodoCard,
        })),
      ),
    },
  },
]
