/**
 * @file 课程表，开启后会在首页日程新增一个课表视图
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initClassTimetable: InitPluginFeature = {
  name: PluginNameEnum.ClassTimetable,
  init: () => {
    return {
      slots: {
        scheduleViewOptionsOverride: () =>
          import('./slots/data-slots').then(m => m.scheduleViewOptionsOverride),
      },
    }
  },
}
