import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initChalkAI: InitPluginFeature = {
  name: PluginNameEnum.ChalkAI,
  init: () => {
    return {
      slots: {
        homeLayoutHeaderRight: lazy(() =>
          import('./slots/homeLayoutHeaderRight').then(m => ({
            default: m.ChalkAIHeaderEntry,
          })),
        ),
      },
    }
  },
}
