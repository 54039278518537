/**
 * @file AI 教师路由定义
 */
import React, { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum, RoleEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/ai-teacher',
    module: [PluginCategoryEnum.教务教学, PluginNameEnum.AITeacher],
    getTitle: () => $t('AI教师管理'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    requireManageablePlugins: [PluginNameEnum.AITeacher],
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('AI教师列表'),
        component: lazy(() =>
          import('./pages/admin/List').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        path: 'setting',
        getTitle: () => $t('设置'),
        component: lazy(() =>
          import('./pages/admin/Setting').then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
]

export const userRoutes: RouteConfig[] = [
  {
    path: 'user/ai-teacher',
    module: [PluginCategoryEnum.教务教学, PluginNameEnum.AITeacher],
    getTitle: () => $t('AI教师'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    requireRoles: [RoleEnum.Teacher],
    subRoutes: [
      {
        path: 'main',
        getTitle: () => $t('主页'),
        component: lazy(() =>
          import('./pages/user/Main').then(m => ({
            default: m.Main,
          })),
        ),
      },
    ],
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.AITeacher.ConversationList',
    getTitle: () => $t('对话列表'),
    component: lazy(() =>
      import('./pages/admin/List/ConversationList').then(m => ({
        default: m.ConversationListModal,
      })),
    ),
  },
  {
    name: 'Plugin.AITeacher.SelectTemplate',
    getTitle: () => $t('选择模版'),
    component: lazy(() =>
      import('./pages/user/Main/SelectTemplate').then(m => ({
        default: m.SelectTemplateModal,
      })),
    ),
  },
  {
    name: 'Plugin.AITeacher.CreateBotModal',
    getTitle: () => $t('创建AI教师'),
    component: lazy(() =>
      import('./pages/user/Main/CreateBot').then(m => ({
        default: m.CreateBotModal,
      })),
    ),
  },
  {
    name: 'Plugin.AITeacher.ShareBotModal',
    getTitle: () => $t('分享AI教师'),
    component: lazy(() =>
      import('./pages/user/Main/ShareBot').then(m => ({
        default: m.ShareBotModal,
      })),
    ),
  },
  {
    name: 'Plugin.AITeacher.Conversation',
    getTitle: () => $t('AI答疑'),
    component: lazy(() =>
      import('./pages/user/ConversationModal').then(m => ({
        default: m.ConversationModal,
      })),
    ),
  },
  {
    name: 'Plugin.AITeacher.ConversationRecord',
    getTitle: () => $t('答疑记录'),
    component: lazy(() =>
      import('./pages/user/ConversationModal/ConversationRecord').then(m => ({
        default: m.ConversationRecordModal,
      })),
    ),
  },
]
