/**
 * @file 学生心理档案 - 常量
 */
import { PermissionNameEnum } from 'packages/sdks-next/chalk'

export const PLUGIN_NAME = 'psychological-file'

/**
 * 目前心理档案只有档案权限的管理内容，若后续开发中需要增加权限，需要分别在 permissionsList 文件，usePermissionGroups 文件中，进行配置，若需要在管理范围的高级设置中添加则需要配置 scopes-board-metas 文件
 */

// 档案权限-读权限
export const PERM_PSYCHOLOGICAL_READ = [
  PermissionNameEnum.PsychologicalAdminAuthRead,
  PermissionNameEnum.PsychologicalResponsibilityRead,
]

// 档案权限-写权限
export const PERM_PSYCHOLOGICAL_WRITE = [
  PermissionNameEnum.PsychologicalAdminAuthWrite,
  PermissionNameEnum.PsychologicalResponsibilityCreate,
  PermissionNameEnum.PsychologicalResponsibilityUpdate,
  PermissionNameEnum.PsychologicalResponsibilityDelete,
  PermissionNameEnum.PsychologicalPositionCreate,
  PermissionNameEnum.PsychologicalPositionUpdate,
  PermissionNameEnum.PsychologicalPositionDelete,
]

// 问题上报-读权限
export const PERM_PSYCHOLOGICAL_REPORT_READ = [
  PermissionNameEnum.PsychologicalRecordRead,
]

// 问题上报-下载权限
export const PERM_PSYCHOLOGICAL_REPORT_EXPORT = [
  PermissionNameEnum.PsychologicalRecordExport,
]

// 问题上报-写权限
export const PERM_PSYCHOLOGICAL_REPORT_WRITE = [
  PermissionNameEnum.PsychologicalRecordReport,
]

// 心理测评-可查看
export const PERM_PSY_ASSESSMENT_READ = [
  PermissionNameEnum.PsychologicalAssessmentManage,
  PermissionNameEnum.PsychologicalAssessmentRead,
  PermissionNameEnum.PsychologicalAssessmentResultListRead,
  PermissionNameEnum.PsychologicalAssessmentResultStatsRead,
  PermissionNameEnum.PsychologicalAssessmentResultDetailRead,
  PermissionNameEnum.PsychologicalPersonalAssessmentResultListRead,
  PermissionNameEnum.PsychologicalPersonalAssessmentResultDetailRead,
]

// 心理测评-可编辑
export const PERM_PSY_ASSESSMENT_WRITE = [
  PermissionNameEnum.PsychologicalAssessmentCreate,
  PermissionNameEnum.PsychologicalAssessmentStart,
  PermissionNameEnum.PsychologicalAssessmentEnd,
  PermissionNameEnum.PsychologicalAssessmentDelay,
  PermissionNameEnum.PsychologicalAssessmentEdit,
  PermissionNameEnum.PsychologicalAssessmentUnsubmittedStudentNotice,
  PermissionNameEnum.PsychologicalAssessmentDelete,
]

// 心理测评-可下载
export const PERM_PSY_ASSESSMENT_DOWNLOAD = [
  PermissionNameEnum.PsychologicalAssessmentResultExport,
  PermissionNameEnum.PsychologicalAssessmentResultStatsExport,
]

// 心理档案详情-查看
export const PERM_PSY_PROFILE_READ = [
  PermissionNameEnum.PsychologicalProfileRead,
]

// 心理档案详情-编辑
export const PERM_PSY_PROFILE_WRITE = [
  PermissionNameEnum.PsychologicalProfileImport,
  PermissionNameEnum.PsychologicalProfileBgInfoButtonUpdate,
  PermissionNameEnum.PsychologicalProfileCollaboratorUpdate,
  PermissionNameEnum.PsychologicalProfileHomeBgInfoUpdate,
  PermissionNameEnum.PsychologicalProfileHomeBgInfoCreate,
  PermissionNameEnum.PsychologicalProfileBgInfoSetUpdate,
  PermissionNameEnum.PsychologicalProfileProblemSetUpdate,
  PermissionNameEnum.PsychologicalProfileInterventionSetUpdate,
]
// 心理档案详情-下载
export const PERM_PSY_PROFILE_DOWNLOAD = [
  PermissionNameEnum.PsychologicalProfileExport,
  PermissionNameEnum.PsychologicalProfileLogExport,
]

// 预警评估-查看
export const PERM_PSY_FOREWARNING_READ = [
  PermissionNameEnum.PsychologicalForewarningRead,
]

// 预警评估-编辑
export const PERM_PSY_FOREWARNING_WRITE = [
  PermissionNameEnum.PsychologicalForewarningWrite,
]

// 预警评估-导出
export const PERM_PSY_FOREWARNING_DOWNLOAD = [
  PermissionNameEnum.PsychologicalForewarningExport,
]

// 危机干预-查看
export const PERM_PSY_INTERVENTION_READ = [
  PermissionNameEnum.PsychologicalEiTabRead,
  PermissionNameEnum.PsychologicalEiManageRead,
  PermissionNameEnum.PsychologicalEiHomeDetailRead,
]

// 危机干预-编辑
export const PERM_PSY_INTERVENTION_WRITE = [
  PermissionNameEnum.PsychologicalEiHomeEvaluationCreate,
  PermissionNameEnum.PsychologicalEiHomeInterventionCreate,
  PermissionNameEnum.PsychologicalEiManageEvaluationUpdate,
  PermissionNameEnum.PsychologicalEiManageInterventionCreate,
  PermissionNameEnum.PsychologicalEiManageInterventionEndUpdate,
  PermissionNameEnum.PsychologicalEiManageInterventionUpdate,
  PermissionNameEnum.PsychologicalEiEvaluationCreate,
  PermissionNameEnum.PsychologicalEiInterventionCreate,
  PermissionNameEnum.PsychologicalAssessmentReportCreateByReport,
]

// 危机干预-下载
export const PERM_PSY_INTERVENTION_DOWNLOAD = [
  PermissionNameEnum.PsychologicalEiExport,
]

// 咨询管理-查看
export const PERM_PSY_CHAT_READ = [PermissionNameEnum.PsychologicalChatRead]

// 咨询管理-编辑
export const PERM_PSY_CHAT_WRITE = [PermissionNameEnum.PsychologicalChatWrite]

// 咨询管理-导出
export const PERM_PSY_CHAT_DOWNLOAD = [
  PermissionNameEnum.PsychologicalChatExport,
]

// 功能权限-全部读权限-控制表格多选框的表头权限
export const ADMIN_PERM_PSYCHOLOGICAL_READ_GROUP = [
  ...PERM_PSYCHOLOGICAL_READ,
  ...PERM_PSYCHOLOGICAL_REPORT_READ,
  ...PERM_PSY_ASSESSMENT_READ,
  ...PERM_PSY_INTERVENTION_READ,
  ...PERM_PSY_PROFILE_READ,
]

// 功能权限-全部写权限-控制表格多选框的表头权限
export const ADMIN_PERM_PSYCHOLOGICAL_WRITE_GROUP = [
  ...PERM_PSYCHOLOGICAL_WRITE,
  ...PERM_PSYCHOLOGICAL_REPORT_WRITE,
  ...PERM_PSY_ASSESSMENT_WRITE,
  ...PERM_PSY_INTERVENTION_WRITE,
  ...PERM_PSY_PROFILE_WRITE,
]

// 功能权限-全部下载权限-控制表格多选框的表头权限
export const ADMIN_PERM_PSYCHOLOGICAL_DOWNLOAD_GROUP = [
  ...PERM_PSYCHOLOGICAL_REPORT_EXPORT,
  ...PERM_PSY_ASSESSMENT_DOWNLOAD,
  ...PERM_PSY_INTERVENTION_DOWNLOAD,
  ...PERM_PSY_PROFILE_DOWNLOAD,
]
