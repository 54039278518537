/**
 * @file modal routes
 */

import React from 'react'

import { $t } from 'packages/locale'

export const modalRoutes = [
  {
    name: 'PluginAcademicGoalsSelectGoalModal',
    getTitle: () => $t('设立目标'),
    component: React.lazy(() =>
      import('../pages/user/SelectGoalModal').then(m => ({
        default: m.SelectGoalModal,
      })),
    ),
  },
  {
    name: 'PluginAcademicGoalsEditGoalLogsModal',
    getTitle: () => $t('编辑记录'),
    component: React.lazy(() =>
      import('../pages/user/EditGoalLogsModal').then(m => ({
        default: m.EditGoalLogsModal,
      })),
    ),
  },
  {
    name: 'PluginAcademicGoalsSelectSubjectModal',
    getTitle: () => $t('新建选科'),
    component: React.lazy(() =>
      import('../pages/user/SelectSubjectModal').then(m => ({
        default: m.SelectSubjectModal,
      })),
    ),
  },
  {
    name: 'PluginAcademicGoalsEditSelectSubjectConfirmTodo',
    getTitle: () => $t('确认目标'),
    component: React.lazy(() =>
      import('../pages/user/ConfirmTodoModal').then(m => ({
        default: m.ConfirmTodoModal,
      })),
    ),
  },
  {
    name: 'PluginAcademicGoalsEditMilestoneModal',
    getTitle: () => $t('编辑模板'),
    component: React.lazy(() =>
      import('../pages/admin/milestones/Edit').then(m => ({
        default: m.EditMilestoneModal,
      })),
    ),
  },
  {
    name: 'PluginAcademicGoalsEditPathMilestoneModal',
    getTitle: () => $t('路径里程碑'),
    component: React.lazy(() =>
      import('../pages/admin/WayDetail/EditPathMilestoneModal').then(m => ({
        default: m.EditPathMilestoneModal,
      })),
    ),
  },
  {
    name: 'PluginAcademicGoalsEditUserMilestoneModal',
    getTitle: () => $t('个人里程碑'),
    component: React.lazy(() =>
      import('../pages/user/EditUserMilestoneModal').then(m => ({
        default: m.EditUserMilestoneModal,
      })),
    ),
  },
  {
    name: 'PluginAcademicGoalsMilestoneDetailModal',
    getTitle: () => $t('查看里程碑'),
    component: React.lazy(() =>
      import('../pages/user/MilestoneDetailModal').then(m => ({
        default: m.MilestoneDetailModal,
      })),
    ),
  },
]
