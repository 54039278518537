/**
 * @file 德育评价
 */

import { InitPluginFeature } from '@/plugins/types'

import { adminMoralMenu, userMoralMenu } from './menus'
import {
  adminRoutes,
  teacherRoutes,
  userRoutes,
  studentRoutes,
  studentProfileRoutes,
  modalRoutes,
} from './routes'
import { adminClassDefaultSubRoutes } from './slots/adminClassDefaultSubRoutes'
import { tabSlots } from './slots/tab-slots'
import { MoralAssessmentPluginName } from './types'
import { widgets } from './widgets'
import { workflowRenders } from './workflow-renders'

export const initMoralAssessments: InitPluginFeature = {
  name: MoralAssessmentPluginName,
  init: () => ({
    routes: [
      ...adminRoutes,
      ...teacherRoutes,
      ...userRoutes,
      ...studentRoutes,
      ...studentProfileRoutes,
    ],
    modalRoutes,
    menus: [adminMoralMenu, userMoralMenu],
    widgets,
    slots: {
      adminClassDefaultSubRoutes,
      profileSubRoutes: tabSlots,
      profileModulePermissions: () =>
        import('./slots/permissions-slots').then(
          m => m.getProfileModulePermissionsSlots,
        ),
      userCommonApplicationsEntry: () =>
        import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
    workflowRenders,
  }),
}
