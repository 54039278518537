/**
 * @file 前台 route
 */

import React from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups/types'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const userRoutes: RouteConfig[] = [
  {
    path: 'academic-goal',
    name: 'AcademicGoal',
    getTitle: () => $t('学业目标'),
    component: React.lazy(() =>
      import('../pages/user').then(m => ({
        default: m.Home,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.GoalAcademicGoal,
      pluginPermissions: [SchoolModulesEnum.ProfileAcademicGoal],
    },
  },
]
