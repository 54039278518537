/**
 * @file 北京市监测平台
 */

import { registerBjMonitorPlatform } from 'packages/plugins/features/bj-monitor-platform/utils'
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initBjMonitorPlatform: InitPluginFeature = {
  name: PluginNameEnum.BjMonitorPlatform,
  init: plugins => {
    if (plugins.length) {
      registerBjMonitorPlatform(plugins[0].schoolId)
    }

    return {}
  },
}
