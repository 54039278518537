/**
 * @file 教师考核
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminAssessmentMenu, userAssessmentMenu } from './menus'
import {
  adminRoutes,
  teacherRoutes,
  userRoutes,
  teacherProfileRoutes,
} from './routes'
import { teacherProfilesSubRoutes } from './slots/teacherProfileSubRoutes'

/**
 * 因整体产品设计沿袭「德育评价」
 * 为提高代码复用程度，部分组件引用自 `apps/chalk/src/plugins/features/moral-assessments/components`
 * 仅参数略有差异
 */
export const initTeacherAssessment: InitPluginFeature = {
  name: PluginNameEnum.TeacherAssessments,
  init: () => ({
    routes: [
      ...adminRoutes,
      ...teacherRoutes,
      ...userRoutes,
      ...teacherProfileRoutes,
    ],
    menus: [adminAssessmentMenu, userAssessmentMenu],
    slots: {
      teacherProfilesEvaluationSubRoutes: teacherProfilesSubRoutes,
    },
  }),
}
