/**
 * @file 宿舍 routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'

export const modalRoutes = [
  {
    name: 'DormAttendanceInputAttendanceModal',
    getTitle: () => $t('录入考勤'),
    component: lazy(() =>
      import('../pages/user/attendances/admin/InputAttendanceModal').then(
        m => ({
          default: m.InputAttendanceModal,
        }),
      ),
    ),
  },
  {
    name: 'StudentArchivedMessageModal',
    getTitle: () => $t('已归档宿生名单'),
    component: lazy(() =>
      import('../pages/user/StudentArchivedMessageModal').then(m => ({
        default: m.StudentArchivedMessageModal,
      })),
    ),
  },
]
