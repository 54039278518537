/**
 * @file 德育评价
 */
import { lazy } from 'react'

import { VirtualPlugin } from 'packages/features/plugins/utils/virtual-plugin'

import { InitPluginFeature } from '@/plugins/types'

import { adminNetDiskMenu, userNetDiskMenu } from './menus'
import { adminRoutes, usersWorkspaceRoutes } from './routes'
import { profileClassGroupSubRoutes } from './slots/ClassWorkspaceRoutes'
import { profileCourseGroupSubRoutes } from './slots/CourseWorkspaceRoutes'
import { profileCustomizedUserSubRoutes } from './slots/CustomizedWorkspaceRoutes'

export const initNetDisk: InitPluginFeature = {
  name: 'netdisk',
  init: () => ({
    setting: {
      type: 'module' as const,
      component: lazy(() =>
        import('./components/PluginSetting').then(m => ({
          default: m.Setting,
        })),
      ),
      beforeChecked: async (plugin: VirtualPlugin) => {
        const schoolPluginSettings = plugin?.schoolPlugin?.settings
        let option = { checked: true, openSetting: false }
        // 未设置范围时 settings 为一个空数组
        if (
          !schoolPluginSettings ||
          (Array.isArray(schoolPluginSettings) &&
            schoolPluginSettings.length === 0)
        ) {
          // 如果首次启用，范围设置为空，直接进入设置页面，否则只启用
          option = { checked: true, openSetting: true }
        }

        return option
      },
    },
    routes: [...adminRoutes, ...usersWorkspaceRoutes],
    menus: [adminNetDiskMenu, userNetDiskMenu],
    slots: {
      profileClassGroupSubRoutes,
      profileCourseGroupSubRoutes,
      profileCustomizedUserSubRoutes,
      userCommonApplicationsEntry: () =>
        import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
  }),
}
