/**
 * @file 动态菜单
 */

import { env } from '@seiue/env'
import { atom } from 'jotai'

import {
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

export const adminElectionMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.Election))) {
    return []
  }

  return [
    {
      prefix: ['courses'],
      path: `${env('CLIENT_ELECTION')}/admin/electives`,
      label: $t('选课管理'),
      sort: 202,
      name: 'election',
      icon: 'CourseSelection' as const,
      shortcutIcon: 'CoursesSelectionSolid+EA7B25',
    },
  ]
})
