/**
 * @file slots
 */

import React from 'react'

import { CertificationPluginKind } from 'packages/feature-utils/certifications/types'
import { getCertificationPluginKind } from 'packages/feature-utils/certifications/utils'
import { StudentProfileSubRoute } from 'packages/feature-utils/profiles'
import { GroupPermissionTypeEnum } from 'packages/features/groups/types'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { RoleEnum } from 'packages/sdks-next/chalk'

import { DataSlotFns } from '@/plugins/slot/data-slots'

/**
 * 学生档案的认证页签
 */
export const profileSubRoutesSlot: RouteConfig[] = [
  {
    path: 'certification-plugins',
    name: StudentProfileSubRoute.Certifications,
    getTitle: () => $t('认证'),
    component: React.lazy(() =>
      import('./pages/student/ProfileCertifications').then(m => ({
        default: m.ProfileCertifications,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.CertificationCertification,
    },
  },
]

/**
 * 课程班详情的认证页签
 */
export const classSubRoutesSlot: RouteConfig[] = [
  {
    path: 'certifications',
    name: 'Certifications',
    getTitle: () => $t('认证'),
    component: React.lazy(() =>
      import('./components/CertPluginTabs').then(m => ({
        default: m.ClassCertPluginTabs,
      })),
    ),
    requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow],
  },
]

/**
 * 行政班详情的认证页签
 */
export const adminClassSubRoutesSlot: RouteConfig[] = [
  {
    path: 'certifications',
    name: 'Certifications',
    getTitle: () => $t('认证'),
    component: React.lazy(() =>
      import('./components/CertPluginTabs').then(m => ({
        default: m.AdminClassCertPluginTabs,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.CertificationCertification,
    },
    requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow],
  },
]

/**
 * 认证子插件 - 前台入口
 *
 * @param props - props
 * @param props.historyOrigin - 路由
 * @returns 跳转方法
 */
export const CommonUseApplicationsEntry: DataSlotFns['userCommonApplicationsEntry'] =
  ({ historyOrigin }) => [
    {
      name: SchoolPluginsEnum.Certification,
      callback: ({ plugin, isFromManage }) => {
        if (isFromManage) {
          historyOrigin.push(
            `/admin/certification-plugins/${plugin.id}/certifications`,
          )
        } else {
          historyOrigin.push(
            getCertificationPluginKind(plugin) ===
              CertificationPluginKind.OnlineIncentive
              ? `/certification-plugins/${plugin.id}/application-logs`
              : `/certification-plugins/${plugin.id}/certifications`,
          )
        }
      },
    },
  ]
