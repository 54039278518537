/**
 * @file 学生心理档案-菜单
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasEnabledPluginAtom,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { useHasCurrentPermission } from 'packages/features/roles/hooks'
import { $t } from 'packages/locale'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'
import { MenuItemUnion, MenuType } from 'packages/web-layout/types'

import { PLUGIN_NAME } from './const'

export const adminPsychologicalFileMenu = atom<MenuItemUnion[]>(get => {
  if (!get(hasEnabledPluginAtom(PluginEnum.PsychologicalFile))) {
    return []
  }

  const isPluginManager = get(
    hasManageableParentPluginAtom(PluginEnum.PsychologicalFile),
  )

  const checkPermissionFunc = (func: typeof useHasCurrentPermission) => {
    const hasPFReadPermission = func({
      permission: PermissionNameEnum.PsychologicalAdminAuthRead,
      isManager: true,
    })

    return hasPFReadPermission || isPluginManager
  }

  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.PsychologicalFile),
  )[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.学生成长],
      name: PLUGIN_NAME,
      label: $t('心理档案管理'),
      icon: 'PsychologicalArchives' as const,
      shortcutIcon,
      type: MenuType.AdminApps,
      sort: 780,
      subMenus: [
        {
          path: '/admin/psychological-file/information',
          label: $t('心理档案'),
          icon: 'PsychologicalArchives',
          permission: [PermissionNameEnum.PsychologicalProfileRead],
        },
        {
          path: '/admin/psychological-file/assessments-management',
          label: $t('测评管理'),
          icon: 'PsychologicalEvaluation',
          permission: [PermissionNameEnum.PsychologicalAssessmentRead],
        },
        {
          path: '/admin/psychological-file/forewarning-evaluations',
          label: $t('预警评估'),
          icon: 'Forewarning',
          permission: [PermissionNameEnum.PsychologicalForewarningRead],
        },
        {
          path: '/admin/psychological-file/crisis-interventions',
          label: $t('危机干预'),
          icon: 'EvaluationIntervention',
          permission: [PermissionNameEnum.PsychologicalEiManageRead],
        },
        {
          path: '/admin/psychological-file/report',
          label: $t('问题上报'),
          icon: 'Report',
          permission: [PermissionNameEnum.PsychologicalRecordRead],
        },
        {
          path: '/admin/psychological-file/consult-management',
          label: $t('咨询管理'),
          icon: 'Consult',
          permission: [PermissionNameEnum.PsychologicalChatRead],
        },
        {
          path: '/admin/psychological-file/permissions',
          label: $t('权限管理'),
          icon: 'Jurisdiction',
          permission: checkPermissionFunc,
        },
      ],
    },
  ]
})

export const userPsychologicalMenu = atom<MenuItemUnion[]>(get => {
  const usable = get(hasUsablePluginAtom(PluginEnum.PsychologicalFile))
  const manageable = get(
    hasManageableParentPluginAtom(PluginEnum.PsychologicalFile),
  )

  if (!usable && !manageable) {
    return []
  }

  return [
    {
      name: PLUGIN_NAME,
      label: $t('心理档案'),
      type: MenuType.Apps,
      subMenus: [
        {
          label: $t('学生问题上报'),
          path: '/psychological-file/report',
          icon: 'Report',
        },
        {
          label: $t('学生心理档案'),
          path: '/psychological-file/student-profiles',
          icon: 'PsychologicalArchives',
        },
        {
          label: $t('咨询列表'),
          path: '/psychological-file/consults',
          icon: 'Consult',
        },
      ],
    },
  ]
})
