/**
 * @file 约谈-待办注册
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.PluginChatSubmitRecord,
    Render: lazy(() =>
      import('./SubmitRecordTodo').then(m => ({ default: m.SubmitRecordTodo })),
    ),
  },
  {
    type: TodoTypeEnum.PluginChatSubmitEvaluation,
    Render: lazy(() =>
      import('./SubmitEvaluationTodo').then(m => ({
        default: m.SubmitEvaluationTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.PluginChatSubmitFeedback,
    Render: lazy(() =>
      import('./SubmitFeedbackTodo').then(m => ({
        default: m.SubmitFeedbackTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.PluginChatApplyChatTask,
    Render: lazy(() =>
      import('./ApplyChatTaskTodo').then(m => ({
        default: m.ApplyChatTaskTodo,
      })),
    ),
  },
]
