import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminRoutes } from './routes'

/**
 * 认证积分插件
 */
export const initCertificationCredit: InitPluginFeature = {
  name: PluginNameEnum.CertificationCredit,
  init: () => ({
    setting: {
      type: 'extendable',
      component: lazy(() =>
        import('./pages/settings').then(m => ({
          default: m.CertificationCreditSettingsModal,
        })),
      ),
    },
    routes: adminRoutes,
  }),
}
