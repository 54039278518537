import React, { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    path: 'admin/plugin/sms-platform',
    name: 'AdminSmsPlatform',
    getTitle: () => $t('短信'),
    module: [PluginCategoryEnum.通用工具, PluginEnum.SmsPlatform],
    component: React.lazy(() =>
      import('@/plugins/features/sms-platform/pages/admin').then(m => ({
        default: m.AdminSmsPlatform,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('任务列表'),
        component: React.lazy(() =>
          import('@/plugins/features/sms-platform/pages/admin/List').then(
            m => ({
              default: m.AdminSmsTaskList,
            }),
          ),
        ),
      },
      {
        path: 'settings',
        name: 'Settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('@/plugins/features/sms-platform/pages/admin/Settings').then(
            m => ({
              default: m.AdminSmsPlatformSettings,
            }),
          ),
        ),
        subRoutes: [
          {
            path: 'sign',
            getTitle: () => $t('签名管理'),
            name: 'SmsSign',
            component: React.lazy(() =>
              import(
                '@/plugins/features/sms-platform/pages/admin/Settings/Sign/index'
              ).then(m => ({
                default: m.SmsSign,
              })),
            ),
          },
          {
            path: 'template',
            getTitle: () => $t('模板管理'),
            name: 'SmsTemplate',
            component: React.lazy(() =>
              import(
                '@/plugins/features/sms-platform/pages/admin/Settings/Template/index'
              ).then(m => ({
                default: m.SmsTemplate,
              })),
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'admin/plugin/sms-platform/:id/detail/',
    name: 'AdminSmsPlatformDetail',
    getTitle: () => $t('详情名称'),
    component: React.lazy(() =>
      import('@/plugins/features/sms-platform/pages/admin/Detail').then(m => ({
        default: m.AdminSmsPlatformDetail,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'Plugin.SendSmsModal',
    getTitle: () => $t('创建短信任务'),
    component: lazy(() =>
      import('@/plugins/features/sms-platform/pages/admin/New').then(m => ({
        default: m.SendSmsModal,
      })),
    ),
  },
]
