import { PathToDetailEnum } from 'packages/feature-utils/moral-assessments/types'

export const MoralAssessmentPluginName = 'moral_assessment'

export type MoralAssessmentRouteParams = {
  AdminMoralAssessmentNew: null
  AdminMoralAssessmentSettings: null
  AdminMoralAssessmentEdit: { id: number }
  AdminMoralAssessmentView: { id: number }
  'AdminMoralAssessmentView.Overview': { id: number }
  'AdminMoralAssessmentView.Entry': { id: number }
  'AdminMoralAssessmentView.Relations': { id: number }
  'AdminMoralAssessmentView.Structures': { id: number }
  'AdminMoralAssessmentView.Members': { id: number }
  AdminMoralAssessments: null
  'AdminMoralAssessments.Settings': null
  TeacherMoralAssessmentView: { id: number; pathType: PathToDetailEnum }
  'TeacherMoralAssessmentView.Overview': {
    id: number
    pathType: PathToDetailEnum
  }
  'TeacherMoralAssessmentView.Entry': { id: number; pathType: PathToDetailEnum }
  'TeacherMoralAssessmentView.Analyses': {
    /**
     * 评价 id
     */
    id: number
    /**
     * 来源
     */
    pathType: PathToDetailEnum
    /**
     * 评价项 id
     */
    itemId?: number
  }
  StudentMoralAssessmentView: {
    id: number
    pathType: PathToDetailEnum
    /**
     * 被评价人名（导师查看分析详情时需显示）
     */
    showName?: string
  }
  'StudentMoralAssessmentView.Overview': {
    id: number
    pathType: PathToDetailEnum
  }
  'StudentMoralAssessmentView.Entry': { id: number; pathType: PathToDetailEnum }
  'StudentMoralAssessmentView.Analyses': {
    /**
     * 评价 id
     */
    id: number
    /**
     * 来源
     */
    pathType: PathToDetailEnum
    /**
     * 评价项 id
     */
    itemId?: number
    /**
     * 被评价人 id
     */
    rid?: number
  }
  StudentMoralGradeProfileDetails: { rid: number; id: number }
  AdminStudentMoralGradeProfileDetails: { rid: number; id: number }
  MoralAssessmentEntry: { id: number }
  MoralAssessmentOverview: { id: number }
  MoralAssessmentSearch: { id: number }
}

export type MoralAssessmentModalRouteParams = {
  'Plugin.MoralAssessmentSettingsModal': {
    id?: number
    parentId?: number
    groupId?: number
    templateLabel?: string
  }
}
