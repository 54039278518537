import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { isMenuOpenedAtom } from 'packages/feature-utils/menus'
import {
  myTypeInMoralAssessmentsAtom,
  shouldShowMoralTrends,
} from 'packages/feature-utils/moral-assessments'
import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { Assessment } from 'packages/sdks-next/vnas'

import { getStoreForAtom } from '@/store'

export const adminMoralMenu = atom(get => {
  const isManager = get(
    hasManageableParentPluginAtom(PluginEnum.MoralAssessment),
  )

  if (!isManager) {
    return []
  }

  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.MoralAssessment),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.德育管理],
      name: PluginEnum.MoralAssessment,
      label: $t('德育评价'),
      path: '/admin/moral-assessments',
      sort: 707,
      type: MenuType.AdminApps,
      icon: 'MoralEvaluation' as const,
      shortcutIcon,
      subMenus: [
        {
          label: $t('全部评价'),
          path: '/admin/moral-assessments/list',
          icon: 'MoralEvaluation' as const,
        },
        {
          label: $t('操作日志'),
          path: '/admin/moral-assessments/logs',
          icon: 'Log' as const,
        },
        {
          label: $t('设置'),
          path: '/admin/moral-assessments/settings',
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})

export const userMoralMenu = loadable(
  atom(get => {
    const isManager = get(
      hasManageableParentPluginAtom(PluginEnum.MoralAssessment),
    )

    const store = getStoreForAtom(get)
    const me = store.session.currentReflection
    if (!me) {
      return []
    }

    if (!get(hasUsablePluginAtom(PluginEnum.MoralAssessment))) {
      return []
    }

    const menuOpened = get(isMenuOpenedAtom([PluginEnum.MoralAssessment]))

    const myTypeInMoralAssessments: Assessment[] =
      menuOpened && me ? get(myTypeInMoralAssessmentsAtom) : []

    const subMenus = [
      {
        label: $t('参与录入'),
        path: '/moral-assessments/type-in',
        icon: 'PenLine' as const,
        permission: () => !!myTypeInMoralAssessments.length,
      },
      {
        label: $t('我的评价'),
        path: '/moral-assessments/assess-me',
        icon: 'SchoolReport' as const,
      },
      {
        label: $t('动态'),
        path: '/moral-assessments/trends',
        icon: 'Activity' as const,
        permission: () => shouldShowMoralTrends(me) || isManager,
      },
    ]

    return [
      {
        type: MenuType.Apps,
        name: PluginEnum.MoralAssessment,
        label: $t('德育评价'),
        subMenus,
      },
    ]
  }),
)
