/**
 * @file 学案 - 待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.HandoutAnswer,
    Render: lazy(() =>
      import('./HandoutAnswerTodo').then(m => ({
        default: m.HandoutAnswerTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.HandoutReview,
    Render: lazy(() =>
      import('./HandoutReviewTodo').then(m => ({
        default: m.HandoutReviewTodo,
      })),
    ),
  },
]
