/**
 * @file 德育评价 / 学生档案页 slots
 */

import React from 'react'

import { GroupPermissionTypeEnum } from 'packages/features/groups/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const tabSlots: RouteConfig[] = [
  {
    path: 'moral-grade',
    getTitle: () => $t('评价'),
    name: 'StudentMoralGradeProfile',
    component: React.lazy(() =>
      import('../pages/student/Profile').then(m => ({
        default: m.StudentMoralGradeProfile,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.MoralScoreStats,
    },
  },
]
