import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initLMS: InitPluginFeature = {
  name: PluginNameEnum.LMS,
  init: () => ({
    setting: {
      type: 'module',
      component: lazy(() =>
        import('./pages/setting').then(m => ({ default: m.Setting })),
      ),
    },
  }),
}
