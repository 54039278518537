/**
 * @file 课程班总成绩提交审核
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initGradeSubmitReview: InitPluginFeature = {
  name: PluginNameEnum.GradeSubmitReview,
  init: () => ({
    slots: {
      adminClassGradeSettingExpand: lazy(() =>
        import('./slot').then(m => ({
          default: m.ClassGradeSubmitReviewSetting,
        })),
      ),
    },
  }),
}
