/**
 * @file 行政班-宿舍评价记录
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const adminClassDefaultSubRoutes: RouteConfig[] = [
  {
    path: 'dorm-assessment',
    name: 'DormAssessment',
    getTitle: () => $t('宿舍评价'),
    component: lazy(() =>
      import(
        '@/plugins/features/dorms/pages/admin/Statistics/List/AssessmentRecord'
      ).then(m => ({
        default: m.AssessmentRecord,
      })),
    ),
  },
]
