/**
 * @file 学业目标管理
 */

import { hasManageablePlugin, PluginEnum } from 'packages/feature-utils/plugins'
import { RoleEnum } from 'packages/sdks-next/chalk'

import { DataSlotFns } from '@/plugins/slot/data-slots'
import { InitPluginFeature } from '@/plugins/types'

import { PLUGIN_NAME } from './const'
import { adminAcademicGoalMenu } from './menus'
import { adminRoutes, modalRoutes, userRoutes } from './routes'
import { todos } from './todos'

const academicGoalEntry: DataSlotFns['userCommonApplicationsEntry'] = ({
  historyOrigin,
}) => [
  {
    name: PluginEnum.AcademicGoal,
    callback: ({ me, isFromManage }) => {
      if (isFromManage) {
        historyOrigin.push('/admin/plugin/academic-goal')
      } else if (me.role === RoleEnum.Student) {
        historyOrigin.push('/profiles/me/goals/academic-goal')
      } else {
        historyOrigin.push('/admin/plugin/academic-goal')
      }
    },
  },
]

export const initAcademicGoals: InitPluginFeature = {
  name: PLUGIN_NAME,
  // 家长也需要能看（通常是看学生档案页面），所以初始化时需要加载
  force: true,
  init: plugins => {
    let routes
    if (hasManageablePlugin(plugins)) {
      routes = adminRoutes
    }

    return {
      setting: {
        type: 'json',
        config: {
          enabledScopes: [RoleEnum.Student],
        },
      },
      routes,
      menus: [adminAcademicGoalMenu],
      modalRoutes,
      slots: {
        profileGoalsSubRoutes: userRoutes,
        userCommonApplicationsEntry: () => Promise.resolve(academicGoalEntry),
      },
      todos,
    }
  },
}
