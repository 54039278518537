import { lazy } from 'react'

import { ReportNameEnum } from 'packages/components/ReportCenter/Reporting/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const gradeSubRoutes: RouteConfig[] = [
  {
    path: `admin/report-center/reports/${ReportNameEnum.ScoreAnalysis}/bodyDetail`,
    getTitle: () => $t('过评录入统计'),
    component: lazy(() =>
      import('../pages/admin/report/BodyDetail').then(m => ({
        default: m.BodyDetail,
      })),
    ),
  },
]
