/**
 * @file 短信平台
 */

import { lazy } from 'react'

import { VirtualPlugin } from 'packages/features/plugins/utils/virtual-plugin'
import { PluginNameEnum } from 'packages/plugins/types'

import { querySmsConfig } from '@/plugins/features/sms-platform/utils/apis'
import { InitPluginFeature } from '@/plugins/types'

import { adminSmsMenu } from './menus'
import { modalRoutes, routes } from './routes'

export const initSmsPlatform: InitPluginFeature = {
  name: PluginNameEnum.SmsPlatform,
  init: () => ({
    routes,
    modalRoutes,
    menus: [adminSmsMenu],
    setting: {
      type: 'module' as const,
      component: lazy(() =>
        import('./components/PluginSetting').then(m => ({
          default: m.Setting,
        })),
      ),
      beforeChecked: async (plugin: VirtualPlugin) => {
        const configItems = await querySmsConfig(
          plugin?.schoolPlugin?.schoolId || 0,
        )

        const schoolPluginSettings = configItems?.[0].value
        let option = { checked: true, openSetting: false }
        // 未设置范围时 settings 为一个空数组
        if (
          !schoolPluginSettings ||
          (Array.isArray(schoolPluginSettings) &&
            schoolPluginSettings.length === 0)
        ) {
          // 如果首次启用，范围设置为空，直接进入设置页面，否则只启用
          option = { checked: true, openSetting: true }
        }

        return option
      },
    },
  }),
}
