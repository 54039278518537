import { PluginNameEnum } from 'packages/plugins/types'

import {
  examFrontendQuestionSubRoutes,
  examScoresThirdPartySubRoutes,
} from '@/plugins/features/third-party-scoring/slots'
import { InitPluginFeature } from '@/plugins/types'

import { adminRoutes, userRoutes } from './routes'

export const initThirdPartyScoring: InitPluginFeature = {
  name: PluginNameEnum.ThirdPartyScoring,
  init: () => {
    return {
      slots: {
        adminExamScoresSubRoutes: examScoresThirdPartySubRoutes,
        examFrontendSubRoutes: examFrontendQuestionSubRoutes,
      },
      routes: [...adminRoutes, ...userRoutes],
    }
  },
}
