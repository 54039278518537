import { compact, isEmpty } from '@seiue/util'

import {
  useFindEnabledParentPlugin,
  useHasEnabledParentPlugin,
  useQueryAppCenterPluginGroup,
} from 'packages/feature-utils/plugins'
import { PluginNameEnum } from 'packages/plugins/types'
import { EnabledSchoolPlugin } from 'packages/sdks-next/chalk'
import {
  LayoutMenus,
  MenuItemUnion,
  MenuType,
  isExtraMenuGroup,
} from 'packages/web-layout/types'

import { useToEntry } from '@/features/plugins/utils/entry'

import { MenuSetting, MenuSettingKey } from './components/PluginSetting/data'
import {
  BaseMenuKey,
  getMenuByKey,
  BasePluginKey,
  getPluginByKey,
} from './components/PluginSetting/utils'

/**
 * 是否自定义了首页菜单
 *
 * @returns 是否自定义了首页菜单
 */
export const useIsCustomHomeMenu = () => {
  const isPluginEnabled = useHasEnabledParentPlugin(
    PluginNameEnum.GzzxHomeMenusSetting,
  )

  const settingPlugin = useFindEnabledParentPlugin(
    PluginNameEnum.GzzxHomeMenusSetting,
  )

  return (
    isPluginEnabled &&
    !!settingPlugin?.settings &&
    !isEmpty(settingPlugin?.settings)
  )
}

/**
 * 获取首页菜单设置
 *
 * @param originLayoutMenus - 原始菜单
 * @returns 新的菜单
 */
export const useHomeMenuSetting = (originLayoutMenus: LayoutMenus) => {
  const isCustomHomeMenu = useIsCustomHomeMenu()

  const settingPlugin = useFindEnabledParentPlugin(
    PluginNameEnum.GzzxHomeMenusSetting,
  )

  const { plugins: appPlugins, loading: loadingPlugins } =
    useQueryAppCenterPluginGroup({
      options: {
        staleTime: 2 * 60,
      },
    })

  const toEntry = useToEntry()

  if (!isCustomHomeMenu) return originLayoutMenus

  // 在这个插件里，我们聚合 admin/adminApp 的菜单内容
  const { admin, adminApps, ...rest } = originLayoutMenus

  let adminMenus: MenuItemUnion[] = []

  const flattenMenus = [...admin, ...adminApps]

  if (!loadingPlugins) {
    // 开始组织新的菜单
    const menuSettings = settingPlugin?.settings as MenuSetting

    const newMenus: MenuItemUnion[] = Object.values(MenuSettingKey).map(key => {
      const setting = menuSettings[key]

      const menus = setting.menus.map(menuKey => {
        if (menuKey.startsWith(BaseMenuKey)) {
          const targetMenu = getMenuByKey(menuKey, flattenMenus)

          return targetMenu
        }

        if (appPlugins && menuKey.startsWith(BasePluginKey)) {
          const targetPlugin = getPluginByKey(menuKey, appPlugins)

          if (targetPlugin && targetPlugin.isAdmin) {
            return {
              label: targetPlugin.label,
              name: targetPlugin.plugin.name,
              type: MenuType.Admin,
              path: '',
              before: () => {
                // 插件的点击单独对接
                toEntry({
                  entry: targetPlugin as unknown as EnabledSchoolPlugin,
                  isFromManage: true,
                  recordRecent: true,
                })

                return false
              },
            }
          }
        }

        return null
      })

      return {
        label: setting.label,
        icon: setting.icon,
        name: key,
        type: MenuType.Admin,
        subMenus: compact(menus),
      }
    })

    adminMenus = newMenus.filter(menu => {
      if (isExtraMenuGroup(menu)) {
        return menu.subMenus.length > 0
      }

      return true
    })
  }

  return {
    ...rest,
    admin: adminMenus,
    adminApps: [],
  }
}
