/**
 * @file 四中初中部定制学生成绩单插件
 */

import { InitPluginFeature } from '@/plugins/types'

export const initFourthGradesReports: InitPluginFeature = {
  name: 'bj4cz-report',
  init: () => ({
    slots: {
      exportGradesReportItem: () =>
        import('./slot').then(res => res.exportGradesReportItem),
    },
  }),
}
