/**
 * @file 注册审批渲染器
 */
import React from 'react'

import { ChalkRegisterWorkflowRenderType } from '@/features/workflows/register'

export const workflowRenders: ChalkRegisterWorkflowRenderType = {
  bizType: 'visitor',
  render: {
    FlowCard: React.lazy(() =>
      import('./FlowCard').then(m => ({ default: m.VisitorFlowCard })),
    ),
    TodoCard: React.lazy(() =>
      import('./TodoCard').then(m => ({ default: m.VisitorTodoCard })),
    ),
    NewFlowModal: React.lazy(() =>
      import('./NewFlowModal').then(m => ({ default: m.NewVisitorFlowModal })),
    ),
    ShowFlowModal: React.lazy(() =>
      import('./ShowFlowModal').then(m => ({
        default: m.ShowVisitorFlowModal,
      })),
    ),
    WorkflowAdminTable: React.lazy(() =>
      import('./WorkflowAdminTable').then(m => ({
        default: m.VisitorAdminTable,
      })),
    ),
  },
}
