import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const routes = [
  {
    path: 'admin/plugin/copilot/stats',
    name: 'AdminCopilotStats',
    component: lazy(() =>
      import('../pages/Admin/Stats').then(m => ({
        default: m.CopilotAdminStats,
      })),
    ),
    getTitle: () => $t('数据统计'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.Copilot],
  },
]

export const modalRoutes = [
  {
    name: 'CopilotKnowledgeBase',
    component: lazy(() =>
      import('../pages/Admin/KnowledgeBase').then(m => ({
        default: m.CopilotAdminKnowledgeBase,
      })),
    ),
    getTitle: () => $t('知识库'),
  },
]
