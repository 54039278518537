/**
 * @file ClassIn
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'
import { classinApi$queryClassinSettings } from 'packages/sdks-next/chalk'

import { InitPluginFeature } from '@/plugins/types'

import { classinAdminMenu, userOpenClassMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { todos } from './todos'

export const initClassIn: InitPluginFeature = {
  name: PluginNameEnum.ClassIn,
  init: () => ({
    routes,
    menus: [classinAdminMenu, userOpenClassMenu],
    modalRoutes,
    todos,
    setting: {
      type: 'module',
      component: lazy(() =>
        import('./pages/Setting').then(m => ({ default: m.Setting })),
      ),
      beforeChecked: async plugin => {
        const schoolPluginId = plugin?.schoolPluginId
        let option = { checked: true, openSetting: false }
        if (schoolPluginId) {
          const { data: classinField } =
            await classinApi$queryClassinSettings.api()
          // 如果首次启用，sid 为空，直接进入设置页面，否则只启用
          if (!classinField?.sid?.length) {
            option = { checked: true, openSetting: true }
          }
        }

        return option
      },
    },
    slots: {
      customScheduleDetailAttributes: () =>
        import('./slot/CustomScheduleDetail').then(
          m => m.getCustomScheduleDetail,
        ),
      customScheduleModalFormConfig: () =>
        import('./slot/CustomScheduleModalField').then(m => m.getCompereConfig),
      lessonScheduleActions: () =>
        import('./slot/lesson-schedule-go-class-in').then(
          m => m.LessonScheduleGoClassIn,
        ),
      customSchedulePopupActions: () =>
        import('./slot/custom-schedule-popover-go-class-in').then(
          m => m.customSchedulePopoverGoClassIn,
        ),
      customScheduleCardActions: () =>
        import('./slot/custom-schedule-card-go-class-in').then(
          m => m.customScheduleCardGoClassIn,
        ),
      HomeTools: lazy(() =>
        import('./slot/ClassInToolLink').then(m => ({
          default: m.ClassInToolLink,
        })),
      ),
      userCommonApplicationsEntry: () =>
        import('./slot/data-slots').then(m => m.CommonUseApplicationsEntry),
    },
  }),
}
