/**
 * @file 审批流路由定义
 */

import React from 'react'

import { $t } from 'packages/locale'
import { ModalRouteNode } from 'packages/route'

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.ApprovalSettingsModal',
    getTitle: () => $t('设置审批'),
    component: React.lazy(() =>
      import('./pages/Settings/ApprovalSettingsModal').then(m => ({
        default: m.ApprovalSettingsRouteModal,
      })),
    ),
  },
]
