import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { examsFrontendMenu } from './menus'

export const initExamsFrontend: InitPluginFeature = {
  name: PluginNameEnum.ExamsFrontend,
  init: () => ({
    menus: [examsFrontendMenu],
  }),
}
