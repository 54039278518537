/**
 * @file 出入校管理菜单
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  findManageableChildPluginsAtom,
  getPluginIcon,
  getPluginLabel,
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuItemUnion, MenuType } from 'packages/web-layout/types'

export const adminVisitorMenu = atom(get => {
  // 仅超管和插件商店管理员为 true
  const isManager = get(hasManageableParentPluginAtom(PluginEnum.Eems))

  const workflowPlugins = get(findManageableChildPluginsAtom(PluginEnum.Eems))

  const subMenus: MenuItemUnion[] = workflowPlugins.map(plugin => ({
    label: getPluginLabel(plugin),
    path: `/admin/plugin/eems/workflows/${plugin.id}`,
    icon: 'Approval',
  }))

  if (!subMenus.length && !isManager) {
    return []
  }

  if (isManager) {
    subMenus.push(
      {
        label: $t('打卡日志'),
        path: '/admin/plugin/eems/iot-logs',
        icon: 'DataChart',
      },
      {
        label: $t('物联网管理'),
        path: '/admin/plugin/eems/iot-management',
        icon: 'Setting',
      },
    )
  }

  const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.Eems))?.find(
    plugin => !plugin.parentId,
  )

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.后勤管理],
      name: PluginEnum.Eems,
      label: $t('出入校管理'),
      icon: 'Approval' as const,
      shortcutIcon,
      sort: 710,
      type: MenuType.AdminApps,
      subMenus,
    },
  ]
})
