/**
 * @file 异常考勤通知
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initAbnormalAttendNotify: InitPluginFeature = {
  name: PluginNameEnum.AbnormalAttendNotify,
}
