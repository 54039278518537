/* eslint-disable max-lines */
/**
 * @file 后台 routes
 */

import React from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

const groupRoutes = [
  {
    path: 'admin/plugin/customized-group/settings',
    getTitle: () => $t('群组设置'),
    name: 'PluginCustomizedGroupsSettings',
    module: [SchoolPluginsEnum.CustomizedGroup],
    component: React.lazy(() =>
      import('../pages/admin/Settings').then(m => ({
        default: m.Settings,
      })),
    ),
  },
  {
    path: 'admin/plugin/customized-group/:pid',
    getTitle: () => $t('群组'),
    name: 'PluginCustomizedGroupsGroupsForAdmin',
    module: [SchoolPluginsEnum.CustomizedGroup],
    component: React.lazy(() =>
      import('../pages/admin/Groups').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('列表'),
        component: React.lazy(() =>
          import('../pages/admin/Groups/Groups').then(m => ({
            default: m.Groups,
          })),
        ),
      },
      {
        path: 'member-stat',
        getTitle: () => $t('成员统计'),
        component: React.lazy(() =>
          import('../pages/admin/Groups/MembersStat').then(m => ({
            default: m.MembersStat,
          })),
        ),
      },
      {
        path: 'archived-groups',
        getTitle: () => $t('已归档'),
        component: React.lazy(() =>
          import('../pages/admin/Groups/ArchivedGroups').then(m => ({
            default: m.ArchivedGroups,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/customized-group/:pid/groups/fields',
    getTitle: () => $t('字段管理'),
    name: 'PluginCustomizedGroupsGroupsForAdmin.FieldsManagement',
    component: React.lazy(() =>
      import('../pages/admin/Groups/FieldsManagement').then(m => ({
        default: m.FieldsManagement,
      })),
    ),
  },
  {
    path: 'admin/plugin/customized-group/:pid/groups/new',
    name: 'PluginCustomizedGroupsGroupsForAdmin.NewGroup',
    getTitle: () => $t('新增群组'),
    component: React.lazy(() =>
      import('../pages/admin/Groups/EditGroup').then(m => ({
        default: m.EditGroup,
      })),
    ),
  },
  {
    path: 'admin/plugin/customized-group/:pid/groups/edit/:gid',
    name: 'PluginCustomizedGroupsGroupsForAdmin.EditGroup',
    getTitle: () => $t('编辑群组'),
    component: React.lazy(() =>
      import('../pages/admin/Groups/EditGroup').then(m => ({
        default: m.EditGroup,
      })),
    ),
  },
  {
    path: 'admin/plugin/customized-group/:pid/groups/batch-edit',
    name: 'PluginCustomizedGroupsGroupsForAdmin.BatchEditGroup',
    getTitle: () => $t('批量编辑群组'),
    component: React.lazy(() =>
      import('../pages/admin/Groups/BatchEditGroups').then(m => ({
        default: m.BatchEditGroups,
      })),
    ),
  },
  {
    path: 'admin/plugin/customized-group/:pid/groups/:gid/:machineName/detail',
    name: 'PluginCustomizedGroupsGroupsForAdmin.GroupDetail',
    getTitle: () => $t('详情'),
    component: React.lazy(() =>
      import('../pages/admin/Groups/GroupDetail').then(m => ({
        default: m.GroupDetail,
      })),
    ),
    subRoutes: [
      {
        path: 'base-info',
        getTitle: () => $t('基本信息'),
        component: React.lazy(() =>
          import('../pages/admin/Groups/GroupDetail/BaseInfo').then(m => ({
            default: m.BaseInfo,
          })),
        ),
      },
      {
        path: 'member-management',
        name: 'MemberManagement',
        getTitle: () => $t('人员管理'),
        component: React.lazy(() =>
          import('../pages/admin/Groups/GroupDetail/MemberManagement').then(
            m => ({
              default: m.MemberManagement,
            }),
          ),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/customized-group/:pid/member-stat/:mid/:username',
    name: 'PluginCustomizedGroupsGroupsForMember',
    getTitle: () => $t('成员详情'),
    component: React.lazy(() =>
      import('../pages/admin/Groups/MemberGroupDetail').then(m => ({
        default: m.MemberGroupDetail,
      })),
    ),
    subRoutes: [
      {
        path: 'participated',
        name: 'Participated',
        getTitle: () => $t('参与群组'),
        component: React.lazy(() =>
          import('../pages/admin/Groups/MemberGroupDetail/Participated').then(
            m => ({
              default: m.Participated,
            }),
          ),
        ),
      },
      {
        path: 'management',
        name: 'Management',
        getTitle: () => $t('管理群组'),
        component: React.lazy(() =>
          import('../pages/admin/Groups/MemberGroupDetail/Management').then(
            m => ({
              default: m.Management,
            }),
          ),
        ),
      },
    ],
  },
]

const signupRoutes = [
  {
    path: 'admin/plugin/customized-group/:pid/signups',
    getTitle: () => $t('报名管理'),
    name: 'AdminPluginCustomizedGroupsSignups',
    component: React.lazy(() =>
      import('../pages/admin/Signups/List').then(m => ({
        default: m.List,
      })),
    ),
  },
  {
    path: 'admin/plugin/customized-group/:pid/signups/:signupId',
    getTitle: () => $t('报名详情'),
    name: 'AdminPluginCustomizedGroupsSignupDetail',
    component: React.lazy(() =>
      import('../pages/admin/Signups/Detail').then(m => ({
        default: m.Detail,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('报名列表'),
        component: React.lazy(() =>
          import('../pages/admin/Signups/Detail/List').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        path: 'setting',
        name: 'Setting',
        getTitle: () => $t('报名设置'),
        component: React.lazy(() =>
          import('../pages/admin/Signups/Detail/Setting').then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/customized-group/:pid/signups/:signupId/groups/:gid',
    getTitle: () => $t('群组报名详情'),
    name: 'AdminPluginCustomizedGroupsSignupDetailForGroup',
    component: React.lazy(() =>
      import('../pages/admin/Signups/Group').then(m => ({
        default: m.GroupDetail,
      })),
    ),
    subRoutes: [
      {
        path: 'list',
        name: 'List',
        getTitle: () => $t('报名情况'),
        component: React.lazy(() =>
          import('../pages/admin/Signups/Group/Members').then(m => ({
            default: m.Members,
          })),
        ),
      },
      {
        path: 'setting',
        name: 'Setting',
        getTitle: () => $t('报名规则'),
        component: React.lazy(() =>
          import('../pages/admin/Signups/Group/Rules').then(m => ({
            default: m.Rules,
          })),
        ),
      },
    ],
  },
]

const taskRoutes = [
  {
    path: 'admin/plugin/customized-group/:pid/tasks',
    getTitle: () => $t('任务管理'),
    name: 'AdminPluginCustomizedGroupsTasks',
    component: React.lazy(() =>
      import('../pages/admin/Tasks').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'group-tasks-stat',
        getTitle: () => $t('群组任务统计'),
        component: React.lazy(() =>
          import('../pages/admin/Tasks/GroupTasksStat').then(m => ({
            default: m.GroupTasksStat,
          })),
        ),
      },
      {
        path: 'member-tasks-stat',
        getTitle: () => $t('成员任务统计'),
        component: React.lazy(() =>
          import('../pages/admin/Tasks/MemberTasksStat').then(m => ({
            default: m.MemberTasksStat,
          })),
        ),
      },
      {
        path: 'post-tasks-stat',
        getTitle: () => $t('发布任务统计'),
        component: React.lazy(() =>
          import('../pages/admin/Tasks/PostTasksStat').then(m => ({
            default: m.PostTasksStat,
          })),
        ),
      },
      {
        path: 'setting',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('../pages/admin/Tasks/Setting').then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/customized-group/:pid/tasks/groups/:gid/tasks',
    getTitle: () => $t('群组任务列表'),
    name: 'AdminPluginCustomizedGroupsTasksGroupTasks',
    component: React.lazy(() =>
      import('../pages/admin/Tasks/GroupTasks').then(m => ({
        default: m.GroupTasks,
      })),
    ),
  },
  {
    path: 'admin/plugin/customized-group/:pid/tasks/for-reflection/:rid/tasks',
    getTitle: () => $t('成员任务列表'),
    name: 'AdminPluginCustomizedGroupsTasksForReflectionTasks',
    component: React.lazy(() =>
      import('../pages/admin/Tasks/MemberTasks').then(m => ({
        default: m.MemberTasks,
      })),
    ),
  },
]

const attendancesRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/customized-group/:pid/attendances',
    getTitle: () => $t('考勤管理'),
    name: 'AdminPluginCustomizedGroupsAttendances',
    component: React.lazy(() =>
      import('../pages/admin/Attendances').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'records',
        getTitle: () => $t('考勤记录'),
        component: React.lazy(() =>
          import('../pages/admin/Attendances/AttendancesRecord').then(m => ({
            default: m.AttendancesRecord,
          })),
        ),
      },
      {
        path: 'group-stat',
        getTitle: () => $t('群组考勤统计'),
        component: React.lazy(() =>
          import('../pages/admin/Attendances/GroupsAttendancesRecord').then(
            m => ({
              default: m.GroupsAttendancesRecord,
            }),
          ),
        ),
      },
      {
        path: 'member-stat',
        getTitle: () => $t('人员考勤统计'),
        component: React.lazy(() =>
          import('../pages/admin/Attendances/MemberAttendancesRecord').then(
            m => ({
              default: m.MemberAttendancesRecord,
            }),
          ),
        ),
      },
      {
        path: 'setting',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('../pages/admin/Attendances/Setting').then(m => ({
            default: m.Setting,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/customized-group/:pid/attendances/for-reflections/:rid/:name',
    getTitle: () => $t('考勤详情'),
    name: 'AdminPluginCustomizedGroupsAttendancesForReflection',
    component: React.lazy(() =>
      import('../pages/admin/Attendances/ReflectionAttendances').then(m => ({
        default: m.Entry,
      })),
    ),
  },
  {
    path: 'admin/plugin/customized-group/:pid/attendances/groups/:gid',
    getTitle: () => $t('考勤详情'),
    name: 'AdminPluginCustomizedGroupsAttendancesForGroup',
    component: React.lazy(() =>
      import('../pages/admin/Attendances/Group').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'records',
        getTitle: () => $t('考勤记录'),
        component: React.lazy(() =>
          import('../pages/admin/Attendances/Group/AttendancesRecord').then(
            m => ({
              default: m.AttendancesRecord,
            }),
          ),
        ),
      },
      {
        path: 'detail',
        getTitle: () => $t('考勤详情'),
        component: React.lazy(() =>
          import('../pages/admin/Attendances/Group/AttendancesDetail').then(
            m => ({
              default: m.AttendancesDetail,
            }),
          ),
        ),
      },
      {
        path: 'member-stat',
        getTitle: () => $t('人员考勤统计'),
        component: React.lazy(() =>
          import('../pages/admin/Attendances/Group/MembersAttendances').then(
            m => ({
              default: m.MembersAttendances,
            }),
          ),
        ),
      },
    ],
  },
]

export const adminRoutes: RouteConfig[] = [
  ...groupRoutes,
  ...signupRoutes,
  ...taskRoutes,
  ...attendancesRoutes,
]
