import { PluginEnum } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@/plugins/types'

import { adminVisitorCenterMenus } from './menu'
import { modalRoutes, routes } from './routes'

export const initVisitorCenter: InitPluginFeature = {
  name: PluginEnum.VisitorCenter,
  init: () => ({
    routes,
    modalRoutes,
    menus: [adminVisitorCenterMenus],
    slots: {
      userCommonApplicationsEntry: () =>
        import('./slot').then(m => m.visitorCenterEntry),
    },
  }),
}
