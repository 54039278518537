/**
 * @file 学籍管理
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminStudentStatusProfileMenu } from './menus'
import { routes, modalRoutes } from './routes'
import { tabSlots } from './slots/routes'

export const initStudentStatusProfile: InitPluginFeature = {
  name: PluginNameEnum.StudentStatusProfile,
  init: () => {
    return {
      setting: {
        type: 'module' as const,
        component: lazy(() =>
          import('./pages/Setting').then(m => ({
            default: m.Setting,
          })),
        ),
      },
      routes,
      modalRoutes,
      menus: [adminStudentStatusProfileMenu],
      slots: {
        profileSubRoutes: tabSlots,
        profileModulePermissions: () =>
          import('./slots/permission').then(
            m => m.getProfileModulePermissionsSlots,
          ),
        adminQuestionnairePickerInfo: () =>
          import('./slots/data-slots').then(
            m => m.adminQuestionnairePickerInfo,
          ),
      },
    }
  },
}
