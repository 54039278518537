/**
 * @file 素养转化成绩
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initAccScoreConverter: InitPluginFeature = {
  name: PluginNameEnum.AccScoreConverter,
  init: () => ({
    setting: {
      type: 'module',
      component: lazy(() =>
        import('./pages/Setting').then(m => ({ default: m.Setting })),
      ),
    },
  }),
}
