import React, { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

const getQuestionnaireDetailSubRoutes = () => {
  return [
    {
      name: 'Details',
      path: 'details',
      getTitle: () => $t('评课详情'),
      component: React.lazy(() =>
        import(
          '@/plugins/features/class-review/components/QuestionnaireDetail/Details'
        ).then(m => ({
          default: m.ReviewQuestionnaireDetails,
        })),
      ),
    },
    {
      name: 'Statistics',
      path: 'statistics',
      getTitle: () => $t('评课统计'),
      component: React.lazy(() =>
        import(
          '@/plugins/features/class-review/components/QuestionnaireDetail/Statistics'
        ).then(m => ({
          default: m.ReviewQuestionnaireStatistics,
        })),
      ),
    },
  ]
}

export const userRoutes: RouteConfig[] = [
  {
    name: 'Plugin.ClassReviewTeacher',
    path: 'plugin/class-review',
    module: [PluginEnum.ClassReview],
    getTitle: () => $t('听评课'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        name: 'Participated',
        path: 'participated',
        getTitle: () => $t('我的听评课'),
        component: React.lazy(() =>
          import('./pages/teacher/Participated').then(m => ({
            default: m.ParticipatedClassReviews,
          })),
        ),
      },
    ],
  },
]

export const adminRoutes: RouteConfig[] = [
  {
    name: 'Plugin.ClassReview',
    path: 'admin/plugin/class-review',
    getTitle: () => $t('听评课管理'),
    module: [PluginCategoryEnum.教师发展, PluginEnum.ClassReview],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        name: 'Lessons',
        path: 'lessons',
        getTitle: () => $t('听评课管理'),
        component: React.lazy(() =>
          import('./pages/admin/ReviewLessons').then(m => ({
            default: m.ReviewLessons,
          })),
        ),
      },
      {
        name: 'Settings',
        path: 'settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('./pages/admin/Settings').then(m => ({
            default: m.Settings,
          })),
        ),
      },
      {
        name: 'Statistics',
        path: 'statistics',
        getTitle: () => $t('教师数据统计'),
        component: React.lazy(() =>
          Promise.resolve({
            default: RouteMenus,
          }),
        ),
        subRoutes: [
          {
            name: 'Teachers',
            path: 'teachers',
            getTitle: () => $t('教师数据统计'),
            component: React.lazy(() =>
              import('./pages/admin/TeachersStatistics').then(m => ({
                default: m.TeachersStatistics,
              })),
            ),
            subRoutes: [
              {
                name: 'Normal',
                path: 'normal',
                getTitle: () => $t('在校教师'),
                component: React.lazy(() =>
                  import(
                    '@/plugins/features/class-review/pages/admin/TeachersStatistics/Normal'
                  ).then(m => ({
                    default: m.NormalTeachersStatistics,
                  })),
                ),
              },
              {
                name: 'Archive',
                path: 'archive',
                getTitle: () => $t('归档教师'),
                component: React.lazy(() =>
                  import(
                    '@/plugins/features/class-review/pages/admin/TeachersStatistics/Archive'
                  ).then(m => ({
                    default: m.ArchiveTeachersStatistics,
                  })),
                ),
              },
            ],
          },
          {
            name: 'Teacher',
            path: 'teacher/:id',
            getTitle: () => $t('教师数据统计'),
            component: React.lazy(() =>
              Promise.resolve({
                default: RouteMenus,
              }),
            ),
            subRoutes: [
              {
                name: 'Records',
                path: 'records',
                getTitle: () => $t('评课记录'),
                component: React.lazy(() =>
                  import(
                    '@/plugins/features/class-review/pages/admin/TeacherRecords'
                  ).then(m => ({
                    default: m.TeacherRecords,
                  })),
                ),
                subRoutes: [
                  {
                    name: 'Review',
                    path: 'review',
                    getTitle: () => $t('被评记录'),
                    component: React.lazy(() =>
                      import(
                        '@/plugins/features/class-review/pages/admin/TeacherRecords/Review'
                      ).then(m => ({
                        default: m.ReviewRecords,
                      })),
                    ),
                  },
                  {
                    name: 'Listening',
                    path: 'listening',
                    getTitle: () => $t('听课记录'),
                    component: React.lazy(() =>
                      import(
                        '@/plugins/features/class-review/pages/admin/TeacherRecords/Listening'
                      ).then(m => ({
                        default: m.ListeningRecords,
                      })),
                    ),
                  },
                ],
              },
              {
                name: 'QuestionnaireDetail',
                path: 'questionnaire-detail/:reviewId',
                getTitle: () => $t('评课详情'),
                component: React.lazy(() =>
                  import('./pages/admin/QuestionnaireDetail/TeacherView').then(
                    m => ({
                      default: m.TeacherViewQuestionnaireDetail,
                    }),
                  ),
                ),
                subRoutes: getQuestionnaireDetailSubRoutes(),
              },
            ],
          },
        ],
      },
      {
        name: 'QuestionnaireDetail',
        path: 'questionnaire-detail/:id',
        getTitle: () => $t('评课详情'),
        component: React.lazy(() =>
          import('./pages/admin/QuestionnaireDetail/View').then(m => ({
            default: m.ReviewQuestionnaireDetail,
          })),
        ),
        subRoutes: getQuestionnaireDetailSubRoutes(),
      },
    ],
  },
]

export const routes = [
  ...adminRoutes,
  ...userRoutes,
  {
    path: 'admin/teacher-profiles/:rid/class-reviews/:reviewId/statistics',
    name: 'AdminTeacherProfiles.ClassReviewStatistics',
    getTitle: () => $t('听评课统计'),
    component: lazy(() =>
      import('./pages/teacher/Profile/StatisticsByQuestions').then(m => ({
        default: m.TeacherStatisticsByQuestions,
      })),
    ),
  },
  {
    path: 'teacher-profiles/me/class-reviews/:reviewId/statistics',
    name: 'TeacherProfiles.ClassReviewStatistics',
    getTitle: () => $t('听评课统计'),
    component: lazy(() =>
      import('./pages/teacher/Profile/StatisticsByQuestions').then(m => ({
        default: m.TeacherStatisticsByQuestions,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.ClassReviewFormSettingModal',
    getTitle: () => $t('配置表单'),
    component: React.lazy(() =>
      import('./pages/admin/EvaluateFormSettingModal').then(m => ({
        default: m.EvaluateFormSettingModal,
      })),
    ),
  },
  {
    name: 'Plugin.EditClassReviewFormModal',
    getTitle: () => $t('填写评课表'),
    component: React.lazy(() =>
      import('./pages/admin/ClassReviewFormModal/Edit').then(m => ({
        default: m.EditClassReviewFormModal,
      })),
    ),
  },
  {
    name: 'Plugin.ViewClassReviewFormModal',
    getTitle: () => $t('评课表'),
    component: React.lazy(() =>
      import('./pages/admin/ClassReviewFormModal/View').then(m => ({
        default: m.ViewClassReviewFormModal,
      })),
    ),
  },
  {
    name: 'Plugin.NewClassReviewModal',
    getTitle: () => $t('安排听评课'),
    component: React.lazy(() =>
      import('./pages/admin/EditReviewLessonModal').then(m => ({
        default: m.EditReviewLessonModal,
      })),
    ),
  },
  {
    name: 'Plugin.TeacherCreateClassReviewModal',
    getTitle: () => $t('我要听课'),
    component: React.lazy(() =>
      import('./pages/teacher/CreateClassReview').then(m => ({
        default: m.CreateClassReviewModal,
      })),
    ),
  },
  {
    name: 'ClassReviewArrangementMessageDetail',
    getTitle: () => $t('听评课详情'),
    component: React.lazy(() =>
      import('./components/ClassReviewArrangementModal').then(m => ({
        default: m.ClassReviewArrangementModal,
      })),
    ),
  },
]
