/**
 * @file 学案 index
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminHandoutMenu } from './menu'
import { adminRoutes, modalRoutes } from './routes'
import { profileClassImportantSubRoutes } from './slots/profileClassImportantSubRoutes'
import { profileCourseHeadSubRoutes } from './slots/profileCourseHeadSubRoutes'
import { todos } from './todos'

export const initHandouts: InitPluginFeature = {
  name: PluginNameEnum.Handout,
  init: () => ({
    setting: {
      type: 'module',
      component: lazy(() =>
        import('./components/PluginSetting').then(m => ({
          default: m.Setting,
        })),
      ),
    },
    routes: [...adminRoutes],
    menus: [adminHandoutMenu],
    modalRoutes,
    slots: {
      profileCourseHeadSubRoutes,
      profileClassImportantSubRoutes,
      gradeAssessmentRelatedItems: () =>
        import('./slots/gradeAssesmentRelatedItems').then(
          m => m.gradeAssesmentRelatedItems,
        ),
      GradeAssesmentItemTag: lazy(() =>
        import('./slots/gradeAssesmentItemTag').then(m => ({
          default: m.GradeAssesmentItemTag,
        })),
      ),
      gradeAssessmentExtendedQuery: () =>
        import('./slots/gradeAssessmentExtendedQuery').then(
          m => m.gradeAssessmentExtendedQuery,
        ),
    },
    todos,
  }),
}
