/**
 * @file 数据大屏路由定义
 */
import React, { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/data-screen',
    module: [PluginCategoryEnum.教务教学, PluginNameEnum.DataScreen],
    getTitle: () => $t('数据大屏管理'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    requireManageablePlugins: [PluginNameEnum.DataScreen],
    subRoutes: [
      {
        path: 'board',
        getTitle: () => $t('数据看板'),
        component: lazy(() =>
          import('./pages/Board').then(m => ({
            default: m.Board,
          })),
        ),
      },
    ],
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'Plugin.DataScreen.BoardDemo',
    getTitle: () => $t('数据看板'),
    component: lazy(() =>
      import('./pages/BoardDemo').then(m => ({
        default: m.BoardDemoModal,
      })),
    ),
  },
]
