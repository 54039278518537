/**
 * @file 选科管理-选科详情-选科统计
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

/**
 * 选科统计-问卷表单维度
 */
export const subjectSelectionFormStatSubRoutes: RouteConfig[] = [
  {
    path: 'direct-statistics',
    name: 'DirectStatistic',
    getTitle: () => $t('选科统计'),
    component: lazy(() =>
      import(
        '@/plugins/features/sz-subject-selection-stat/pages/admin/SubjectSelectionFormStat'
      ).then(m => ({
        default: m.SubjectSelectionFormStat,
      })),
    ),
  },
]
