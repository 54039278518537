/**
 * @file 请假管理菜单
 */

import { atom } from 'jotai'

import {
  findManageableChildPluginsAtom,
  getPluginLabel,
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { MenuItemUnion } from 'packages/web-layout/types'

export const adminAbsenceMenu = atom(get => {
  // 仅仅超管和插件管理员为 true
  const isManager = get(hasManageableParentPluginAtom(PluginEnum.Absence))

  const absencePlugins = get(
    findManageableChildPluginsAtom(PluginEnum.Absence),
  ).filter(p => p.entrypoints?.includes('application_center'))

  if (!isManager && !absencePlugins.length) {
    return []
  }

  const wfMenus = absencePlugins.map(plugin => ({
    label: getPluginLabel(plugin),
    path: `/admin/plugin/absence/workflows/${plugin.id}`,
  }))

  const subMenus: MenuItemUnion[] = [
    {
      name: 'student-absence',
      label: $t('学生请假'),
      path: '/admin/plugin/absence/summary/student',
      icon: 'StudentsLeave' as const,
      shortcutIcon: 'StudentsLeaveSolid+15B8B1',
    },
    {
      name: 'teacher-absence',
      label: $t('教师请假'),
      icon: 'TeacherLeave' as const,
      shortcutIcon: 'TeachersLeaveSolid+17A0DE',
      path: '/admin/plugin/absence/summary/teacher',
    },
  ]

  if (wfMenus.length) {
    subMenus.push({
      name: 'absence-workflows',
      label: $t('请假流程'),
      path: wfMenus[0].path,
      icon: 'Application' as const,
      shortcutIcon: 'LeaveProcess+757AA6',
      subMenus: wfMenus,
    })
  }

  return [
    {
      name: 'absences',
      label: $t('请假管理'),
      icon: 'Clock' as const,
      sort: 15,
      path: '/admin/plugin/absence',
      type: MenuType.Admin,
      subMenus,
    },
  ]
})
