/**
 * @file 定制成绩单
 * 之前为深圳湾定制成绩单，现包含学校「深圳湾」和「协和培明」，「深圳中学初中部」
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminCgrMenu } from './menus'
import { routes } from './routes'
import { adminClassGradeSubRoutesTail } from './slots/adminClassGradeSubRoutesTail'
import { tabSlot } from './slots/tab-slots'

export const initCgr: InitPluginFeature = {
  name: PluginNameEnum.Cgr,
  init: () => ({
    routes,
    menus: [adminCgrMenu],
    slots: {
      profileSubRoutes: tabSlot,
      adminClassGradeSubRoutesTail,
      profileModulePermissions: () =>
        import('./slots/profileModulePermissions').then(
          m => m.profileModulePermissions,
        ),
    },
  }),
}
