/**
 * @file 广州中学定制成绩分析
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { analysisRoutes } from './route'
import { adminClassGradeSubRoutesTail } from './slots/adminClassGradeSubRoutesTail'
import { adminClassRoutes } from './slots/adminClassRoutes'
import { tabSlot } from './slots/tab-slots'

export const initGzExamResAnalysis: InitPluginFeature = {
  name: PluginNameEnum.GzExamResAnalysis,
  init: () => ({
    slots: {
      adminClassGradeSubRoutesTail,
      adminClassRoutes,
      examScoreDropdownItems: () =>
        import('./slots/examScoreDropdownItems').then(
          m => m.examScoreDropdownItems,
        ),
      ExamFormItem: lazy(() =>
        import('./slots/GradeAnalysisRulesSlot').then(m => ({
          default: m.GradeAnalysisRulesSlot,
        })),
      ),
      profileGradesSubRoutes: tabSlot,
    },
    routes: analysisRoutes,
  }),
}
