/**
 * @file 选科管理-选科结果-选科统计
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

/**
 * 选科统计-班级或学校维度
 */
export const subjectSelectionResultStatSubRoutes: RouteConfig[] = [
  {
    path: 'stat',
    name: 'Stat',
    getTitle: () => $t('选科统计'),
    component: lazy(() =>
      import(
        '@/plugins/features/sz-subject-selection-stat/pages/admin/SubjectSelectionResultStat'
      ).then(m => ({
        default: m.SubjectSelectionResultStat,
      })),
    ),
  },
]
