/**
 * @file 补考管理插件
 */
import { InitPluginFeature } from '@/plugins/types'

import { adminResitMenu } from './menus'
import { adminRoutes } from './routes'

export const initResit: InitPluginFeature = {
  name: 'exam-resit',
  init: () => ({
    routes: adminRoutes,
    menus: [adminResitMenu],
  }),
}
