import { atom, useAtomValue } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  MoralAssessmentTypeEnum,
  TeacherAssessmentRoleEnum,
  teacherAssessmentApi$queryAssessmentsForRecorder,
  commonApi$queryAssessmentsForAdmin,
} from 'packages/sdks-next/vnas'

/**
 * 教师考核-我的录入 atom
 */
export const hasTypeInAssessmentsAtom = atom(async () => {
  const { data: assessments } =
    await teacherAssessmentApi$queryAssessmentsForRecorder.api({
      perPage: 1,
    })

  return !!assessments?.length
})

/**
 * 加载完的教师考核-我的录入 atom
 */
const hasTypeInAssessmentsLoadableAtom = loadable(
  atom(get => get(hasTypeInAssessmentsAtom)),
)

/**
 * 获取教师考核-我的录入，评价数据
 *
 *
 * @returns 数据
 */
export const useHasTypeInAssessments = (): {
  has: boolean
  loading: boolean
} => {
  const gotIt = useAtomValue(hasTypeInAssessmentsLoadableAtom)
  if (gotIt.state === 'loading' || gotIt.state === 'hasError') {
    return { has: false, loading: gotIt.state === 'loading' }
  }

  return { has: gotIt.data, loading: false }
}

/**
 * 教师考核-我的管理 atom
 */
export const hasManageAssessmentsAtom = atom(async () => {
  const { data: assessments } = await commonApi$queryAssessmentsForAdmin.api(
    MoralAssessmentTypeEnum.TeacherAssessment,
    {
      perPage: 1,
      teacherAssessmentAsRole: TeacherAssessmentRoleEnum.LimitedAdmin,
    },
  )

  return !!assessments?.length
})

/**
 * 加载完的教师考核-我的管理 atom
 */
const hasManageAssessmentsLoadableAtom = loadable(
  atom(get => get(hasManageAssessmentsAtom)),
)

/**
 * 获取教师考核-我的管理，评价数据
 *
 * @returns 数据
 */
export const useHasManageAssessments = (): {
  has: boolean
  loading: boolean
} => {
  const gotIt = useAtomValue(hasManageAssessmentsLoadableAtom)
  if (gotIt.state === 'loading' || gotIt.state === 'hasError') {
    return { has: false, loading: gotIt.state === 'loading' }
  }

  return { has: gotIt.data, loading: false }
}

/**
 * 教师考核-我的审核 atom
 */
export const hasAuditAssessmentsAtom = atom(async () => {
  const { data: assessments } = await commonApi$queryAssessmentsForAdmin.api(
    MoralAssessmentTypeEnum.TeacherAssessment,
    {
      perPage: 1,
      teacherAssessmentAsRole: TeacherAssessmentRoleEnum.Reviewer,
    },
  )

  return !!assessments?.length
})

/**
 * 加载完的教师考核-我的审核 atom
 */

const hasAuditAssessmentsLoadableAtom = loadable(
  atom(get => get(hasAuditAssessmentsAtom)),
)

/**
 * 获取教师考核-我的审核，评价数据
 *
 * @returns 数据
 */
export const useHasAuditAssessments = (): {
  has: boolean
  loading: boolean
} => {
  const gotIt = useAtomValue(hasAuditAssessmentsLoadableAtom)
  if (gotIt.state === 'loading' || gotIt.state === 'hasError') {
    return { has: false, loading: gotIt.state === 'loading' }
  }

  return { has: gotIt.data, loading: false }
}
