/**
 * @file routes
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'
import { RoleEnum } from 'packages/sdks-next/form'

import { RouteMenus } from '@/router'

export const routes: RouteConfig[] = [
  {
    path: 'admin/plugin/questionnaire',
    name: 'AdminPluginQuestionnaire',
    getTitle: () => $t('问卷管理'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.Questionnaire],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('全部问卷'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/List').then(m => ({
            default: m.QuestionnaireList,
          })),
        ),
      },
      {
        path: 'statistics',
        getTitle: () => $t('问卷统计'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/Statistics').then(m => ({
            default: m.Statistics,
          })),
        ),
      },
      {
        path: 'templates',
        name: 'Templates',
        getTitle: () => $t('问卷模板'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/Templates').then(m => ({
            default: m.QuestionnaireTemplateList,
          })),
        ),
      },
      {
        path: 'permission-settings',
        name: 'PermissionSettings',
        getTitle: () => $t('权限设置'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/PermissionSetting').then(m => ({
            default: m.PermissionSetting,
          })),
        ),
      },
    ],
  },
  {
    name: 'QuestionnaireStatisticsDetail',
    path: 'admin/plugin/questionnaire/statistics/:id/detail',
    getTitle: () => $t('问卷详情'),
    component: lazy(() =>
      import('./pages/questionnaire-manage/Statistics/Detail/index').then(
        mod => ({
          default: mod.Detail,
        }),
      ),
    ),
  },
  {
    path: 'admin/plugin/questionnaire/view/:id',
    name: 'AdminQuestionnaireView',
    getTitle: () => $t('问卷详情'),
    subRoutes: [
      {
        name: 'Detail',
        path: 'detail',
        getTitle: () => $t('问卷详情'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/Details').then(m => ({
            default: m.AdminQuestionnaireDetails,
          })),
        ),
      },
      {
        name: 'Stat',
        path: 'statistics/by-score',
        getTitle: () => $t('结果统计'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/StatisticsByQuestions').then(
            m => ({
              default: m.QuestionnaireStatisticsByQuestions,
            }),
          ),
        ),
      },
      {
        name: 'Setting',
        path: 'setting',
        getTitle: () => $t('问卷设置'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/Setting').then(m => ({
            default: m.AdminQuestionnaireSetting,
          })),
        ),
      },
      {
        name: 'PermissionsSetting',
        path: 'permissions-setting',
        getTitle: () => $t('权限设置'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/AdminsSetting').then(m => ({
            default: m.AdminsSetting,
          })),
        ),
      },
    ],
    component: lazy(() =>
      import('./pages/questionnaire-manage/View').then(mod => ({
        default: mod.AdminQuestionnaireView,
      })),
    ),
  },
  {
    path: 'admin/plugin/questionnaire/view/:id/detail/:rid/owner',
    name: 'AdminSinglePersonView',
    getTitle: () => $t('查看个人'),
    component: lazy(() =>
      import('./pages/questionnaire-manage/SinglePersonView').then(m => ({
        default: m.SinglePersonView,
      })),
    ),
  },
  {
    name: 'PluginQuestionnaireSquare',
    path: 'user/questionnaire',
    module: [PluginNameEnum.Questionnaire],
    getTitle: () => $t('问卷'),
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        name: 'All',
        path: 'all',
        getTitle: () => $t('问卷广场'),
        component: lazy(() =>
          import('./pages/QuestionnaireSquare/All').then(m => ({
            default: m.All,
          })),
        ),
      },
      {
        path: 'mine',
        getTitle: () => $t('我填写的'),
        component: lazy(() =>
          import('./pages/QuestionnaireSquare/Mine').then(m => ({
            default: m.Mine,
          })),
        ),
      },
      {
        path: 'from-me',
        getTitle: () => $t('我发起的'),
        component: lazy(() =>
          import('./pages/QuestionnaireSquare/FromMe').then(m => ({
            default: m.FromMe,
          })),
        ),
        requirePermissions: [
          PermissionNameEnum.QuestionnaireQuestionnaireCreate,
        ],
      },
      {
        path: 'manage',
        getTitle: () => $t('我管理的'),
        component: lazy(() =>
          import('./pages/QuestionnaireSquare/Manage').then(m => ({
            default: m.Manage,
          })),
        ),
        requireRoles: [RoleEnum.Teacher],
      },
    ],
  },
  {
    path: 'user/questionnaire/from-me/view/:id',
    name: 'UserFromMeQuestionnaireView',
    getTitle: () => $t('问卷详情'),
    subRoutes: [
      {
        name: 'Detail',
        path: 'detail',
        getTitle: () => $t('问卷详情'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/Details').then(m => ({
            default: m.AdminQuestionnaireDetails,
          })),
        ),
      },
      {
        name: 'Stat',
        path: 'statistics/by-score',
        getTitle: () => $t('结果统计'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/StatisticsByQuestions').then(
            m => ({
              default: m.QuestionnaireStatisticsByQuestions,
            }),
          ),
        ),
      },
      {
        name: 'Setting',
        path: 'setting',
        getTitle: () => $t('问卷设置'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/Setting').then(m => ({
            default: m.AdminQuestionnaireSetting,
          })),
        ),
      },
      {
        name: 'PermissionsSetting',
        path: 'permissions-setting',
        getTitle: () => $t('权限设置'),
        component: lazy(() =>
          import('./pages/questionnaire-manage/AdminsSetting').then(m => ({
            default: m.AdminsSetting,
          })),
        ),
      },
    ],
    component: lazy(() =>
      import('./pages/questionnaire-manage/View').then(mod => ({
        default: mod.AdminQuestionnaireView,
      })),
    ),
  },
]

export const modalRoutes = [
  {
    name: 'EditQuestionnairenModal',
    getTitle: () => $t('编辑问卷'),
    component: lazy(() =>
      import('./pages/questionnaire-manage/Edit/index').then(m => ({
        default: m.EditQuestionnairenModal,
      })),
    ),
  },
  {
    name: 'EditFilledQuestionnairenModal',
    getTitle: () => $t('编辑填报问卷'),
    component: lazy(() =>
      import('./pages/questionnaire-manage/Filled/index').then(m => ({
        default: m.EditFilledQuestionnairenModal,
      })),
    ),
  },
  {
    name: 'SubmitQuestionnaireModal',
    getTitle: () => $t('提交问卷'),
    component: lazy(() =>
      import('@/components/Questionnaire/SubmitRouteModal').then(m => ({
        default: m.SubmitQuestionnaireRouteModal,
      })),
    ),
  },
  {
    name: 'EditQuestionnaireTemplateModal',
    getTitle: () => $t('编辑问卷模板'),
    component: lazy(() =>
      import('./pages/questionnaire-manage/EditTemplate').then(m => ({
        default: m.QuestionnaireTemplateEditModal,
      })),
    ),
  },
  {
    name: 'AdminQuestionnaireViewOwner',
    getTitle: () => $t('查看个人'),
    component: lazy(() =>
      import('./pages/questionnaire-manage/Owner').then(m => ({
        default: m.QuestionnaireOwner,
      })),
    ),
  },
  {
    name: 'Sy2xQuestionnaireAnalysisExportModal',
    getTitle: () => $t('分析导出'),
    component: lazy(() =>
      import('./pages/Sy2xQuestionnaireAnalysisExportModal').then(m => ({
        default: m.Sy2xQuestionnaireAnalysisExportModal,
      })),
    ),
  },
]
