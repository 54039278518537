/**
 * @file 素养评价 menus
 */

import { atom } from 'jotai'

import { hasManageableParentPluginAtom } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminAccMenu = atom(get => {
  const hasAccAssessmentPermission = get(
    hasManageableParentPluginAtom(PluginNameEnum.AccAssessment),
  )

  if (!hasAccAssessmentPermission) {
    return []
  }

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: PluginNameEnum.AccAssessment,
      path: '/admin/plugin/acc-assessments',
      label: $t('素养评价'),
      sort: 710,
      type: MenuType.AdminApps,
      icon: 'MoralEvaluation' as const,
      subMenus: [
        {
          path: '/admin/plugin/acc-assessments/list',
          label: $t('评价列表'),
          icon: 'MoralEvaluation' as const,
        },
        {
          path: '/admin/plugin/acc-assessments/table',
          label: $t('素养课程图谱'),
          icon: 'Table' as const,
        },
      ],
    },
  ]
})
