/**
 * @file 注册调代课日程事件
 *
 * 但实际上该日程事件的注册并不会反映到首页日程/课程班日程上，仅会表现在调代课新建/编辑/详情上
 */

import { lazy } from 'react'

import { CalendarEventType } from 'packages/features/calendars/utils/types'
import { $t } from 'packages/locale'

export const calendarEvents = [
  {
    type: CalendarEventType.ClassAdjustment,
    Component: lazy(() =>
      import('./ClassAdjustmentEvent').then(m => ({
        default: m.ClassAdjustmentEvent,
      })),
    ),
    getEventTag: () => $t('调代课'),
  },
  {
    type: CalendarEventType.ClassAdjustmentTeacherReplace,
    Component: lazy(() =>
      import('./TeacherReplaceEvent').then(m => ({
        default: m.TeacherReplaceEvent,
      })),
    ),
    getEventTag: () => $t('代课教师日程'),
  },
  {
    type: CalendarEventType.ClassAdjustmentLessonFold,
    Component: lazy(() =>
      import('./ScheduleFoldPopLesson').then(m => ({
        default: m.ScheduleFoldPopLesson,
      })),
    ),
    getEventTag: () => $t('课程'),
  },
  {
    type: CalendarEventType.ClassAdjustmentIdle,
    Component: lazy(() =>
      import('./ClassAdjustmentIdleEvent').then(m => ({
        default: m.ClassAdjustmentIdleEvent,
      })),
    ),
    getEventTag: () => $t('调代课'),
  },
]
