/**
 * @file 行政班-德育评价详情
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const adminClassDefaultSubRoutes: RouteConfig[] = [
  {
    path: 'assessment',
    name: 'Assessment',
    getTitle: () => $t('德育评价'),
    component: lazy(() =>
      import(
        '@/plugins/features/moral-assessments/pages/user/AdminClassAssessment'
      ).then(m => ({
        default: m.AdminClassAssessment,
      })),
    ),
  },
]
