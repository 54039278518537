import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    name: 'Plugin.AdminTeacherAttendance',
    path: 'admin/teacher-attendance',
    module: [PluginCategoryEnum.教师发展, PluginNameEnum.TeacherAttendance],
    getTitle: () => $t('教师考勤统计'),
    component: React.lazy(() =>
      import('./pages/admin/index').then(m => ({
        default: m.AdminTeacherAttendance,
      })),
    ),
    subRoutes: [
      {
        path: 'periods',
        getTitle: () => $t('考勤组各时段统计'),
        component: React.lazy(() =>
          import('./pages/admin/PeriodList').then(m => ({
            default: m.AdminPeriodList,
          })),
        ),
      },
      {
        path: 'teachers',
        getTitle: () => $t('教师统计'),
        component: React.lazy(() =>
          import('./pages/admin/TeacherList').then(m => ({
            default: m.AdminTeacherList,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/teacher-attendance/periods/:id/teachers',
    name: 'Plugin.AdminTeacherAttendance.PeriodTeachers',
    getTitle: () => $t('教师考勤时段详情'),
    component: React.lazy(() =>
      import('./pages/admin/PeriodTeacherList').then(m => ({
        default: m.AdminPeriodTeacherList,
      })),
    ),
  },
  {
    path: 'admin/teacher-attendance/teachers/:id/records',
    name: 'Plugin.AdminTeacherAttendance.TeacherRecords',
    getTitle: () => $t('教师考勤统计详情'),
    component: React.lazy(() =>
      import('./pages/admin/TeacherRecordList').then(m => ({
        default: m.AdminTeacherRecordList,
      })),
    ),
  },
  {
    path: 'admin/teacher-attendance/settings',
    module: [PluginCategoryEnum.教师发展, PluginNameEnum.TeacherAttendance],
    getTitle: () => $t('教师考勤设置'),
    component: React.lazy(() =>
      import('./pages/admin/Settings').then(m => ({
        default: m.AdminTeacherAttendanceSettings,
      })),
    ),
  },
]

export const userRoutes: RouteConfig[] = [
  {
    path: 'teacher-attendance',
    module: [PluginEnum.TeacherAttendance],
    requireModules: [PluginEnum.TeacherAttendance],
    getTitle: () => $t('教师考勤'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'me-records',
        getTitle: () => $t('我的考勤'),
        component: React.lazy(() =>
          import('./pages/user/MeRecords').then(m => ({
            default: m.MeRecords,
          })),
        ),
      },
    ],
  },
]
