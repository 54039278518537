/**
 * @file 出入校管理插件
 */

import { Toast } from '@seiue/ui'
import { lazy } from 'react'

import { hijackMessageClick } from 'packages/features/messages/utils'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminVisitorMenu } from './menus'
import { modalRoutes, adminRoutes } from './routes'
import { workflowRenders } from './workflow-renders'

export const initEems: InitPluginFeature = {
  name: PluginNameEnum.Eems,
  init: () => {
    hijackMessageClick('visitor.access_approved', () => {
      Toast.warning($t('请前往移动端查看'))
    })

    return {
      routes: adminRoutes,
      modalRoutes,
      menus: [adminVisitorMenu],
      setting: {
        type: 'extendable',
        component: lazy(() =>
          import('./pages/Settings').then(m => ({
            default: m.VisitorSettings,
          })),
        ),
      },
      slots: {
        ExtendablePluginSettingRightHeaderBottom: lazy(() =>
          import('./slots/SmsMessage').then(m => ({
            default: m.SmsMessage,
          })),
        ),
        userCommonApplicationsEntry: () =>
          import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
        extendablePluginSettingNewButton: () =>
          import('./slots/data-slots').then(
            m => m.extendablePluginSettingNewButton,
          ),
      },
      workflowRenders,
    }
  },
}
