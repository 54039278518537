/**
 * @file 调代课 menus（包含管理中心和工作台）
 */

import { atom } from 'jotai'

import {
  hasEnabledPluginAtom,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  ModuleEnum,
} from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { MenuProvider } from 'packages/web-layout/menu-utils'

export const userAdjustmentMenu: MenuProvider = atom(get => {
  if (!get(hasUsablePluginAtom(ModuleEnum.Adjustment))) {
    return []
  }

  return [
    {
      path: '/class-adjustments',
      name: SchoolModulesEnum.Adjustment,
      label: $t('调代课'),
      icon: 'CourseExchange' as const,
      type: MenuType.Apps,
      subMenus: [
        {
          label: $t('我申请的'),
          icon: 'PersonPlus' as const,
          path: '/class-adjustments/applied',
        },
        {
          label: $t('我参与的'),
          icon: 'PersonCheck' as const,
          path: '/class-adjustments/involved',
        },
        {
          label: $t('我审核的'),
          icon: 'PersonCheck' as const,
          path: '/class-adjustments/audited',
        },
        {
          label: $t('抄送我的'),
          icon: 'PersonCheck' as const,
          path: '/class-adjustments/cced',
        },
      ],
    },
  ]
})

export const adminAdjustmentMenu: MenuProvider = atom(get => {
  if (!get(hasEnabledPluginAtom(ModuleEnum.Adjustment))) {
    return []
  }

  return [
    {
      prefix: ['courses'],
      permission: () =>
        get(hasManageableParentPluginAtom(ModuleEnum.Adjustment)),
      path: '/admin/class-adjustments',
      icon: 'CourseExchange' as const,
      shortcutIcon: 'CourseExchangeSolid+757AA6',
      name: SchoolModulesEnum.Adjustment,
      label: $t('调代课管理'),
      sort: 200,
    },
  ]
})
