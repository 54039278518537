/**
 *@file 课表查询 routes
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasEnabledPluginAtom,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

export const adminScheduleMenu = atom(get => {
  if (!get(hasEnabledPluginAtom(PluginEnum.SzzxSchedule))) {
    return []
  }

  const isManager = get(hasManageableParentPluginAtom(PluginEnum.SzzxSchedule))

  const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.SzzxSchedule))[0]
  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.教务教学],
      name: PluginEnum.SzzxSchedule,
      label: $t('课表查询'),
      path: '/admin/plugin/schedule',
      type: MenuType.AdminApps,
      icon: 'Timetable',
      shortcutIcon,
      sort: 710,
      subMenus: [
        {
          label: $t('教师课表'),
          path: '/admin/plugin/schedule/teacher',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleTeacherScheduleRead],
        },
        {
          label: $t('学生课表'),
          path: '/admin/plugin/schedule/student',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleStudentScheduleRead],
        },
        {
          label: $t('教室课表'),
          path: '/admin/plugin/schedule/teacher',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleClassroomScheduleRead],
        },
        {
          label: $t('教研组课表'),
          path: '/admin/plugin/schedule/group',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleGroupScheduleRead],
        },
        {
          label: $t('行政班课表'),
          path: '/admin/plugin/schedule/admin-class',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleAdminClassScheduleRead],
        },
        {
          label: $t('设置'),
          path: '/admin/plugin/schedule/settings',
          icon: 'Setting',
          permission: () => isManager,
        },
      ],
    },
  ]
})

export const userScheduleMenu = atom(get => {
  const isManager = get(hasManageableParentPluginAtom(PluginEnum.SzzxSchedule))
  const isUsable = get(hasUsablePluginAtom(PluginEnum.SzzxSchedule))

  if (!isManager && !isUsable) {
    return []
  }

  return [
    {
      name: PluginEnum.SzzxSchedule,
      label: $t('课表查询'),
      path: '/admin/plugin/schedule',
      type: MenuType.Apps,
      sort: 710,
      subMenus: [
        {
          label: $t('教师课表'),
          path: '/admin/plugin/schedule/teacher',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleTeacherScheduleRead],
        },
        {
          label: $t('学生课表'),
          path: '/admin/plugin/schedule/student',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleStudentScheduleRead],
        },
        {
          label: $t('教室课表'),
          path: '/admin/plugin/schedule/classroom',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleClassroomScheduleRead],
        },
        {
          label: $t('教研组课表'),
          path: '/admin/plugin/schedule/group',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleGroupScheduleRead],
        },
        {
          label: $t('行政班课表'),
          path: '/admin/plugin/schedule/admin-class',
          icon: 'Timetable',
          permission: [PermissionNameEnum.ClassScheduleAdminClassScheduleRead],
        },
        {
          label: $t('设置'),
          path: '/admin/plugin/schedule/settings',
          icon: 'Setting',
          permission: () => isManager,
        },
      ],
    },
  ]
})
