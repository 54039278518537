/**
 * @file 前台 route
 */

import React from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { getUserSubRoutes } from './sub-routes'

/**
 * Get user routes
 *
 * @returns - routes
 */
export const getUserRoutes: () => RouteConfig[] = () => [
  {
    path: 'plugin/customized-group/:pid/groups/:gid',
    getTitle: () => $t('群组'),
    name: 'PluginCustomizedGroupsGroups',
    component: React.lazy(() =>
      import('../pages/user/index').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: getUserSubRoutes(),
  },
  {
    path: 'plugin/customized-group/:pid/groups/:gid/for-reflection/:rid',
    getTitle: () => $t('群组'),
    name: 'PluginCustomizedGroupsGroupForReflection',
    component: React.lazy(() =>
      import('../pages/user/index').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: getUserSubRoutes(),
  },
  {
    path: 'plugin/customized-group/:pid/groups/:gid/discussions/for-reflection/:rid/collections',
    name: 'PluginCustomizedGroupDiscussionCollectionsForReflection',
    getTitle: () => $t('我的收藏'),
    component: React.lazy(() =>
      import('../pages/user/Discussion/Collections').then(m => ({
        default: m.Collections,
      })),
    ),
  },
  {
    path: 'plugin/customized-group/:pid/groups/:gid/discussions/for-reflection/:rid/posts',
    name: 'PluginCustomizedGroupDiscussionPostsForReflection',
    getTitle: () => $t('我的发布'),
    component: React.lazy(() =>
      import('../pages/user/Discussion/Posts').then(m => ({
        default: m.Posts,
      })),
    ),
  },
  {
    path: 'plugin/customized-group/:pid/groups/:gid/exams/:examId/analysis',
    getTitle: () => $t('考试分析'),
    name: 'PluginCustomizedMentorExam',
    component: React.lazy(() =>
      import('../pages/user/ExamAnalysis').then(m => ({
        default: m.ExamAnalysis,
      })),
    ),
    subRoutes: [
      {
        path: 'total-score',
        getTitle: () => $t('成绩总表'),
        component: React.lazy(() =>
          import('@/features/exams/components/Analysis/TotalScore').then(m => ({
            default: m.ExamTotalScoreAnalysis,
          })),
        ),
      },
      {
        path: 'mentor-analysis',
        name: 'MentorAnalysis',
        getTitle: () => $t('导师分析'),
        component: React.lazy(() =>
          import('../pages/user/ExamAnalysis/MentorAnalysis').then(m => ({
            default: m.MentorAnalysis,
          })),
        ),
      },
    ],
  },
]
