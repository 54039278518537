/**
 * @file 课表查询插件入口
 */

import { lazy } from 'react'

import { hasManageablePlugin } from 'packages/feature-utils/plugins'
import { CalendarEventType } from 'packages/features/calendars'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminScheduleMenu, userScheduleMenu } from './menus'
import { getRoutes } from './routes'

export const initSzzxSchedule: InitPluginFeature = {
  name: PluginNameEnum.SzzxSchedule,
  init: plugins => ({
    routes: [...getRoutes(hasManageablePlugin(plugins))],
    menus: [adminScheduleMenu, userScheduleMenu],
    slots: {
      scopesBoardMetasWithoutDomainSlots: () =>
        import('./slots').then(m => m.getScopesBoardMetasWithoutDomainSlots),
      roleItemRangeTextSlots: () =>
        import('./slots').then(m => m.getRoleItemRangeTextSlots),
    },
    calendarEvents: [
      {
        type: CalendarEventType.Free,
        getEventTag: () => $t('空闲'),
        Component: lazy(() =>
          import('./component/FreeCard/index').then(m => ({
            default: m.FreeCard,
          })),
        ),
      },
    ],
  }),
}
