/**
 * @file customized-group
 */

import { lazy } from 'react'

import {
  hasManageablePlugin,
  hasUsablePlugin,
} from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@/plugins/types'

import { calendarEvents } from './calendar-events'
import { adminCgMenu } from './menus'
import { getUserRoutes, adminRoutes, modalRoutes } from './routes'
import { todos } from './todos'

export const initCustomizedGroups: InitPluginFeature = {
  name: 'customized-group',
  init: plugins => {
    let routes
    if (hasManageablePlugin(plugins)) {
      routes = [...getUserRoutes(), ...adminRoutes]
    }

    if (!hasManageablePlugin(plugins) && hasUsablePlugin(plugins)) {
      routes = getUserRoutes()
    }

    return {
      setting: {
        type: 'extendable',
        component: lazy(() =>
          import('./pages/setting').then(m => ({ default: m.Setting })),
        ),
      },
      calendarEvents,
      routes,
      todos,
      menus: [adminCgMenu],
      modalRoutes,
      slots: {
        studentTableColumns: () =>
          import('./slots/studentMentorsField').then(
            m => m.getStudentMentorsField,
          ),
      },
    }
  },
}
