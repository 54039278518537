import React from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const adminRoutes: RouteConfig[] = [
  {
    name: 'Plugin.AdminCertCredit',
    path: 'admin/certification-credit',
    module: [PluginCategoryEnum.学生成长, PluginNameEnum.Certification],
    getTitle: () => $t('认证积分'),
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'students',
        getTitle: () => $t('学生汇总'),
        component: React.lazy(() =>
          import('./pages/admin/StudentList').then(m => ({
            default: m.AdminCertCreditStudents,
          })),
        ),
      },
      {
        path: 'prizes',
        getTitle: () => $t('奖品列表'),
        component: React.lazy(() =>
          import('./pages/admin/PrizeList').then(m => ({
            default: m.AdminCertCreditPrizes,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('设置'),
        component: React.lazy(() =>
          import('./pages/admin/Settings').then(m => ({
            default: m.AdminCertCreditSettings,
          })),
        ),
      },
    ],
  },
  {
    name: 'Plugin.AdminCertCreditPrizes.New',
    path: 'admin/certification-credit/prizes/new',
    getTitle: () => $t('新增奖品'),
    component: React.lazy(() =>
      import('./pages/admin/PrizeEdit').then(m => ({
        default: m.AdminCertCreditPrizesNew,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertCreditPrizes.Edit',
    path: 'admin/certification-credit/prizes/:id/edit',
    getTitle: () => $t('编辑奖品'),
    component: React.lazy(() =>
      import('./pages/admin/PrizeEdit').then(m => ({
        default: m.AdminCertCreditPrizesEdit,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertCreditPrizes.BatchEdit',
    path: 'admin/certification-credit/prizes/batch-edit',
    getTitle: () => $t('批量编辑奖品'),
    component: React.lazy(() =>
      import('./pages/admin/PrizeEdit/BatchEdit').then(m => ({
        default: m.AdminCertCreditPrizesBatchEdit,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertCreditPrizes.View',
    path: 'admin/certification-credit/prizes/:id/view',
    getTitle: () => $t('奖品详情'),
    component: React.lazy(() =>
      import('./pages/admin/PrizeView').then(m => ({
        default: m.AdminCertCreditPrizesView,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertCreditPrizes.Exchanges',
    path: 'admin/certification-credit/prizes/:id/exchanges',
    getTitle: () => $t('查看兑换名单'),
    component: React.lazy(() =>
      import('./pages/admin/PrizeExchangeList').then(m => ({
        default: m.AdminCertCreditPrizesExchangeList,
      })),
    ),
  },
  {
    name: 'Plugin.AdminCertCreditStudnets.View',
    path: 'admin/certification-credit/students/:rid/view',
    getTitle: () => $t('学生详情'),
    component: React.lazy(() =>
      import('./pages/admin/StudentView').then(m => ({
        default: m.AdminCertCreditStudentsView,
      })),
    ),
  },
]
