/**
 * @file 群组 menu
 */
import { arrayToMap } from '@seiue/util'
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  findEnabledParentPluginAtom,
  findEnabledPluginsAtom,
  findManageableChildPluginsAtom,
  getPluginIcon,
  getPluginLabel,
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  GroupModuleEnum,
  GroupTypeSysTemplateEnum,
  customGroupApi$queryGroupTypePlugins,
} from 'packages/sdks-next/chalk'
import { MenuItemUnion } from 'packages/web-layout/types'

export const adminCgMenu = loadable(
  atom(async get => {
    const customizedGroupPlugins = !!get(
      findEnabledParentPluginAtom(PluginEnum.CustomizedGroup),
    )

    if (!customizedGroupPlugins) return []

    const cgPlugins = get(
      findManageableChildPluginsAtom(PluginEnum.CustomizedGroup),
    )

    const enabledPlugin = get(
      findEnabledPluginsAtom(PluginEnum.CustomizedGroup),
    )?.find(plugin => !plugin.parentId)

    const shortcutIcon = enabledPlugin
      ? getPluginIcon(enabledPlugin)
      : 'Other+477cff'

    const { data: typePlugins } = cgPlugins.length
      ? await customGroupApi$queryGroupTypePlugins.api({
          enabled: true,
          expand: ['bizEntity'] as const,
        })
      : { data: [] }

    const typePluginsMap = arrayToMap(typePlugins, 'bizId')

    const subMenus: MenuItemUnion[] = cgPlugins.map(plugin => {
      const typePlugin = typePluginsMap[plugin.bizId || 0]

      const isModuleEnabled = (module: GroupModuleEnum): boolean =>
        !!typePlugin?.bizEntity?.modules?.includes(module)

      const subSubMenus = [
        {
          path: `/admin/plugin/customized-group/${plugin.bizId || 0}`,
          label: $t('{name}管理', {
            name: getPluginLabel(plugin),
          }),
        },
      ]

      if (isModuleEnabled(GroupModuleEnum.Task)) {
        subSubMenus.push({
          path: `/admin/plugin/customized-group/${plugin.bizId || 0}/tasks`,
          label: $t('任务管理'),
        })
      }

      if (isModuleEnabled(GroupModuleEnum.Attendance)) {
        subSubMenus.push({
          path: `/admin/plugin/customized-group/${
            plugin.bizId || 0
          }/attendances`,
          label: $t('考勤管理'),
        })
      }

      if (isModuleEnabled(GroupModuleEnum.Signup)) {
        subSubMenus.push({
          path: `/admin/plugin/customized-group/${plugin.bizId || 0}/signups`,
          label: $t('报名管理'),
        })
      }

      const isMentorGroup =
        typePlugin.bizEntity.sysTemplate === GroupTypeSysTemplateEnum.Mentor

      return {
        label: $t('{name}管理', {
          name: getPluginLabel(plugin),
        }),
        name: getPluginLabel(plugin),
        icon: isMentorGroup ? ('Tutor' as const) : ('GroupUsers' as const),
        shortcutIcon,
        subMenus: subSubMenus,
      }
    })

    if (get(hasManageableParentPluginAtom(PluginEnum.CustomizedGroup))) {
      subMenus.push({
        label: $t('群组配置'),
        name: 'settings',
        icon: 'Setting',
        shortcutIcon: 'GroupSettingSolid+8E6BF1',
        path: `/admin/plugin/customized-group/settings`,
      })
    }

    return [
      {
        label: $t('群组管理'),
        name: SchoolPluginsEnum.CustomizedGroup,
        icon: 'GroupUsers' as const,
        type: MenuType.Admin,
        sort: 20,
        subMenus,
      },
    ]
  }),
)
