import React from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const schoolConfigSubRoutes: RouteConfig[] = [
  {
    path: 'workbench-views',
    name: 'Plugin.AdminWorkbenchViewList',
    getTitle: () => $t('工作台视图'),
    component: React.lazy(() =>
      import('./pages/admin/WorkbenchViewList').then(m => ({
        default: m.AdminWorkbenchViewList,
      })),
    ),
  },
]
