import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { modalRoutes } from './routes'

export const initStudentMerge: InitPluginFeature = {
  name: PluginNameEnum.StudentMerge,
  init: () => ({
    modalRoutes,
    slots: {
      classImportSubItem: () =>
        import('./slots').then(m => m.classImportSubItem),
    },
  }),
}
