/**
 * @file AI 教师 menus（包含管理中心和工作台）
 */

import { atom } from 'jotai'

import {
  PluginEnum,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuProvider } from 'packages/web-layout/menu-utils'

export const userAITeacherMenu: MenuProvider = atom(get => {
  if (!get(hasUsablePluginAtom(PluginEnum.AITeacher))) {
    return []
  }

  return [
    {
      path: '/user/ai-teacher',
      prefix: [PluginCategoryEnum.教务教学],
      name: PluginEnum.AITeacher,
      label: $t('AI教师'),
      icon: 'Target' as const,
      type: MenuType.Apps,
      sort: 710,
      subMenus: [
        {
          label: $t('主页'),
          icon: 'Home' as const,
          path: '/user/ai-teacher/main',
        },
      ],
    },
  ]
})

export const adminAITeacherMenu: MenuProvider = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.AITeacher))) {
    return []
  }

  return [
    {
      path: '/admin/plugin/ai-teacher',
      prefix: [PluginCategoryEnum.教务教学],
      name: PluginEnum.AITeacher,
      label: $t('AI教师管理'),
      icon: 'Target' as const,
      type: MenuType.AdminApps,
      sort: 709,
      permission: () =>
        get(hasManageableParentPluginAtom(PluginEnum.AITeacher)),
      subMenus: [
        {
          label: $t('AI教师列表'),
          icon: 'CardList' as const,
          path: '/admin/plugin/ai-teacher/list',
        },
        {
          label: $t('设置'),
          icon: 'Setting' as const,
          path: '/admin/plugin/ai-teacher/setting',
        },
      ],
    },
  ]
})
