/**
 * @file 教师档案
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.TeacherProfileAnswer,
    Render: lazy(() =>
      import('./TeacherProfilesTodoRender').then(m => ({
        default: m.TeacherProfileTodo,
      })),
    ),
  },
]
