/**
 * @file 排课
 */

import { InitPluginFeature } from '@/plugins/types'

import { adminAthenaMenu } from './menus'

export const initAthena: InitPluginFeature = {
  name: 'athena',
  init: () => ({
    menus: [adminAthenaMenu],
  }),
}
