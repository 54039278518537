import React from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RoleEnum, WidgetTypeEnum } from 'packages/sdks-next/chalk'

import {
  RegisterWidget,
  WidgetKeyEnum,
  WidgetLayoutSize,
} from '@/features/widgets/types'

export const widgets: RegisterWidget[] = [
  {
    key: WidgetKeyEnum.MoralAssessmentScore,
    label: () => $t('德育评价'),
    icon: 'MoralEvaluation',
    type: WidgetTypeEnum.PluginBiz,
    pluginName: PluginNameEnum.MoralAssessment,
    enableRoles: [RoleEnum.Student, RoleEnum.Guardian],
    sizes: [WidgetLayoutSize.Small],
    layout: {
      rows: 4,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./Score').then(m => ({
        default: m.MoralAssessmentScorePreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./Score').then(m => ({
        default: m.MoralAssessmentScore,
      })),
    ),
  },
]
