/**
 * @file modal routes
 */

import React from 'react'

import { $t } from 'packages/locale'

export const modalRoutes = [
  {
    name: 'Plugin.Chat.ChatDetailModal',
    getTitle: () => $t('约谈详情'),
    component: React.lazy(() =>
      import('../pages/user/ChatDetailModal').then(m => ({
        default: m.ChatDetailModal,
      })),
    ),
  },
  {
    name: 'Plugin.Chat.TeacherCreateModal',
    getTitle: () => $t('创建约谈'),
    component: React.lazy(() =>
      import('../pages/user/CreateChatModal').then(m => ({
        default: m.CreateChatModal,
      })),
    ),
  },
  {
    name: 'Plugin.Chat.SetTimesModal',
    getTitle: () => $t('设置时段'),
    component: React.lazy(() =>
      import('../pages/user/SetTimesModal').then(m => ({
        default: m.SetTimesModal,
      })),
    ),
  },
  {
    name: 'Plugin.Chat.StudentCreateModal',
    getTitle: () => $t('约谈教师'),
    component: React.lazy(() =>
      import('../pages/user/ChatTeacherModal').then(m => ({
        default: m.ChatTeacherModal,
      })),
    ),
  },
  {
    name: 'Plugin.Chat.RecordFormSettings',
    getTitle: () => $t('配置约谈记录表'),
    component: React.lazy(() =>
      import('../pages/admin/RecordFormSettings').then(m => ({
        default: m.ChatRecordFormSettings,
      })),
    ),
  },
  {
    name: 'Plugin.Chat.SubmitRecordModal',
    getTitle: () => $t('填写约谈记录'),
    component: React.lazy(() =>
      import('../pages/user/ChatSubmitRecordModal').then(m => ({
        default: m.ChatSubmitRecordModal,
      })),
    ),
  },
  {
    name: 'Plugin.Chat.OpenTaskNewModal',
    getTitle: () => $t('新增开放预约任务'),
    component: React.lazy(() =>
      import('../pages/admin/OpenTaskEdit').then(m => ({
        default: m.OpenTaskNewModal,
      })),
    ),
  },
  {
    name: 'Plugin.Chat.OpenTaskEditModal',
    getTitle: () => $t('编辑开放预约任务'),
    component: React.lazy(() =>
      import('../pages/admin/OpenTaskEdit').then(m => ({
        default: m.OpenTaskEditModal,
      })),
    ),
  },
]
