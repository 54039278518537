/**
 * @file 为 packages/route 中的各种 hook 注入了 chalk 的路由类型信息
 */

/* eslint-disable no-restricted-imports */

import {
  useHistory as useHistoryRaw,
  getPath as getPathRaw,
  useRouteModalHistory as useRouteModalHistoryRaw,
  useRouteModalParams as useRouteModalParamsRaw,
  useParams as useParamsRaw,
  useLocation as useLocationRaw,
  useRouteMatch as useRouteMatchRaw,
} from 'packages/route'

import { RouteModalParams, RouteParams } from './types'

/**
 * 根据路由名称和参数生成 url path
 *
 * @param name - 路由名称
 * @param params - 路由参数
 * @returns url path with query
 */
export const getPath = <TName extends keyof RouteParams>(
  name: TName,
  params: RouteParams[TName],
) => getPathRaw<RouteParams, TName>(name, params)

/**
 * useHistory with RouteParams typings
 *
 * @returns typed { push, replace, goBack } methods
 */
export const useHistory = () => useHistoryRaw<RouteParams>()

/**
 * useParams with RouteParams typings
 *
 * @returns RouteParams[TName]
 */
export const useParams = <TName extends keyof RouteParams>() =>
  useParamsRaw<RouteParams, TName>()

/**
 * direct export from react-router-dom
 */
export const useLocation = useLocationRaw

/**
 * useRouteMatch with RouteParams typings
 *
 * @param name - 路由名称
 * @returns match
 */
export const useRouteMatch = <TName extends keyof RouteParams>(name: TName) =>
  useRouteMatchRaw<RouteParams, TName>(name)

/**
 * useHistory 的 Modal 版本，路由名和参数来自于 RouteModalParams
 *
 * @returns typed { push, replace } methods
 */
export const useRouteModalHistory = () =>
  useRouteModalHistoryRaw<RouteModalParams>()

/**
 * useParams 的 Modal 版本，路由名和参数来自于 RouteModalParams
 *
 * @param defaultQuery - 默认的 query 参数
 * @returns RouteModalParams[TName]
 */
export const useRouteModalParams = <TName extends keyof RouteModalParams>(
  defaultQuery: Partial<RouteModalParams[TName]> = {},
) => useRouteModalParamsRaw<RouteModalParams, TName>(defaultQuery)
