import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const teacherProfileRecentSubRoutes: RouteConfig[] = [
  {
    path: 'attendances',
    getTitle: () => $t('考勤'),
    component: lazy(() =>
      import('./pages/user/TeacherProfile').then(m => ({
        default: m.TeacherProfileAttendances,
      })),
    ),
  },
]
