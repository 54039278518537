/**
 * @file 学生档案-学籍表路由 slot
 */

import { lazy } from 'react'

import { StudentProfileSubRoute } from 'packages/feature-utils/profiles'
import { $t } from 'packages/locale'

export const tabSlots = [
  {
    path: 'student-status-profile',
    name: StudentProfileSubRoute.StudentStatusProfile,
    getTitle: () => $t('学籍表'),
    component: lazy(() =>
      import('../pages/students/ProfileFormView').then(m => ({
        default: m.StudentProfileFormView,
      })),
    ),
  },
]
