/**
 * @file 约谈
 */
import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { calendarEvents } from './calendar-events'
import { adminChatMenu, userChatMenu } from './menus'
import {
  adminRoutes,
  userRoutes,
  modalRoutes,
  profileRecentRoutes,
} from './routes'
import { todos } from './todos'

export const initChat: InitPluginFeature = {
  name: PluginNameEnum.Chat,
  init: plugins => {
    const hasEnabled = !!plugins.length

    return {
      setting: {
        type: 'extendable',
        component: lazy(() =>
          import('./pages/Setting').then(m => ({
            default: m.ChatPluginSetting,
          })),
        ),
      },
      routes: [...adminRoutes, ...userRoutes],
      modalRoutes,
      calendarEvents,
      menus: [adminChatMenu, userChatMenu],
      slots: {
        profileRecentSubRoutes: hasEnabled ? profileRecentRoutes : undefined,
        scheduleCreateButtonAction: () =>
          import('./pages/user/CreateChatButton').then(m => m.createChatButton),
        userCommonApplicationsEntry: () =>
          import('./slots/data-slots').then(m => m.CommonUseApplicationsEntry),
      },
      todos,
    }
  },
}
