import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { GroupPermissionTypeEnum } from 'packages/features/groups'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

/**
 * 学生档案-群组-宿舍 tab
 */
export const tabSlot: RouteConfig[] = [
  {
    path: 'dorms',
    name: 'DormProfile',
    getTitle: () => $t('宿舍'),
    component: lazy(() =>
      import('@/plugins/features/dorms/components/DormProfile').then(m => ({
        default: m.Dorms,
      })),
    ),
    customMeta: {
      pluginPermissions: [ModuleEnum.Dorm],
      groupPermission: GroupPermissionTypeEnum.GroupDorm,
    },
  },
]
