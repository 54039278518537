import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminRoutes: RouteConfig[] = [
  {
    path: 'admin/plugin/organization-structure',
    name: 'AdminPluginOrganizationStructure',
    getTitle: () => $t('组织架构'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.OrganizationStructure],
    component: lazy(() =>
      import(
        '@/plugins/features/organization-structure/pages/admin/Entry'
      ).then(m => ({
        default: m.OrganizationStructureEntry,
      })),
    ),
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'DeptFromGroup',
    getTitle: () => $t('选择群组创建部门'),
    component: lazy(() =>
      import(
        '@/plugins/features/organization-structure/pages/admin/DeptFromGroup'
      ).then(m => ({ default: m.DeptFromGroup })),
    ),
  },
  {
    name: 'DeptManagement',
    getTitle: () => $t('部门管理'),
    component: lazy(() =>
      import(
        '@/plugins/features/organization-structure/pages/admin/DeptManagement'
      ).then(m => ({ default: m.DeptManagement })),
    ),
  },
]
