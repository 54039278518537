/**
 * @file types
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'
import { BizModuleEnum } from 'packages/sdks-next/form'

export type QuestionnaireRouteParams = {
  'PluginQuestionnaireSquare.All': null
  AdminPluginQuestionnaire: null
  'AdminPluginQuestionnaire.Templates': null
  QuestionnaireStatisticsDetail: {
    id: number
  }
  AdminQuestionnaireView: { id: number }
  UserFromMeQuestionnaireView: { id: number }
  AdminSinglePersonView: {
    id: number
    rid: number
  }
}

export type QuestionnairenRouteModalParams = {
  SubmitQuestionnaireModal: { id: number; taskId?: number }
  EvaluationUncompletedModal: {
    id: number
    role: 'mentor' | 'admin_class_teacher'
  }
  EditQuestionnairenModal: { id?: number; templateId?: number }
  EditQuestionnaireTemplateModal: { id?: number }
  EditFilledQuestionnairenModal: { id?: number; bizModule?: BizModuleEnum }
  AdminQuestionnaireViewOwner: {
    id: number
    rid: number
    needVerify?: boolean
    // 从待办和问卷中心-我管理的 tab 进入只能查看问卷详情-权限设置处管理范围内的人员
    onlyCurrentManaged?: boolean
  }
  Sy2xQuestionnaireAnalysisExportModal: null
}

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.QuestionnaireAnswer,
    Render: lazy(() =>
      import('./todos/WriteQuestionnaire').then(m => ({
        default: m.QuestionnaireAnswerTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.QuestionnaireRelation,
    Render: lazy(() =>
      import('./todos/ApprovalQuestionnaire').then(m => ({
        default: m.QuestionnaireApprovalTodo,
      })),
    ),
  },
]
