import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RoleEnum } from 'packages/sdks-next/chalk'

export const teacherProfileRoutes = [
  {
    path: 'teacher-profiles/me/report-center/:id',
    name: 'TeacherProfilesReportDetail',
    getTitle: () => $t('我的报表'),
    requireRoles: [RoleEnum.Teacher],
    component: lazy(() =>
      import('../pages/user/reportPublish/ReceiverReportDetail').then(m => ({
        default: m.ReceiverReportDetail,
      })),
    ),
  },
  {
    path: 'admin/teacher-profiles/:rid/report-center/:id',
    name: 'AdminTeacherProfilesReportDetail',
    requireRoles: [RoleEnum.Teacher, RoleEnum.Shadow],
    getTitle: () => $t('我的报表'),
    component: lazy(() =>
      import('../pages/user/reportPublish/ReceiverReportDetail').then(m => ({
        default: m.ReceiverReportDetail,
      })),
    ),
  },
]
