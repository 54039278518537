/**
 * @file 成功标准
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initSuccessCriteria: InitPluginFeature = {
  name: PluginNameEnum.SuccessCriteria,
  init: () => ({
    setting: {
      type: 'json',
    },
    slots: {
      taskFormSchema: () =>
        import('./slots/data-slots').then(m => m.taskFormSchema),
      teacherTaskSubmissionForm: () =>
        import('./slots/data-slots').then(m => m.teacherTaskSubmissionForm),
    },
  }),
}
