import React, { useMemo } from 'react'

import { useRouteAuthorization } from 'packages/route'
import { NestedRoute, NotFound } from 'packages/route/components'

import { RouteComponent } from './types'

/**
 * RouteMenus
 *
 * @param props - 参数
 * @param props.route - 路由
 * @returns NestedRoute
 */
export const RouteMenus: RouteComponent = ({ route }) => {
  // 判断是否有权限访问路由的函数
  const authRoute = useRouteAuthorization()

  // 用 authRoute 过滤
  const finalRoutes = useMemo(
    () => (route?.subRoutes || []).filter(authRoute),
    [route, authRoute],
  )

  if (!finalRoutes.length) {
    return <NotFound />
  }

  const defaultRoute = finalRoutes[0]

  return (
    <NestedRoute
      routes={finalRoutes}
      redirect={{
        to: defaultRoute.path,
      }}
    />
  )
}
