/**
 * @file setting
 */

import { Setting } from './types'

const settings: {
  [key: string]: Setting
} = {}

export const registerSetting = (pluginName: string, setting?: Setting) => {
  settings[pluginName] = setting || { type: 'json' as const }
}

export const getSetting = (pluginName: string) =>
  // json 配置里有配合多语言的 $t('xxx')，此处直接返回
  settings[pluginName]
